export enum UserRoute {
  OVERVIEW = 'overview',
  MEDICAL = 'medical',
  MOVEMENTS = 'movements',
  UPLOADED_VIDEOS = 'uploaded-videos',
  VIDEO_PLAYER = 'video-player',
  PROGRESS = 'progress',
  ANALYSIS_FEEDBACK = 'analysis-feedback',
  COUNTING = 'counting',

  HEALTH_DATA = 'health-data',
  PHYSICAL_COMPLAINTS = 'physical-complaints',

  MOVEMENT_ANAMNESIS = 'movement-anamnesis',
  MIND_ANAMNESIS = 'mind-anamnesis',

  MOVEMENT_HISTORY = 'movement-history',
  MIND_HISTORY = 'mind-history',

  MIND_EXCERCISES_COUNTING = 'mind-excercises-counting',
  MIND_CALMING_SOUNDS_COUNTING = 'mind-calming-sounds-counting',
  MIND_TRAINING_COUNTING = 'mind-training-history-counting',
  BODY_TRAINING_COUNTING = 'body-training-history-counting',

  BODY_EXCERCISES_COUNTING = 'body-excercises-counting',
}

