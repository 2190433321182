export interface Dictionary<T> {
  [key: string]: T;
}

export type QueryListResult<Key extends string, Type> = {
  data: {
    [key in Key]: {
      items: Type[];
      nextToken?: string | null
    };
  };
};

export type QueryResult<Key extends string, Type> = {
  data: {
    [key in Key]: Type;
  };
};

export enum GRAPHQL_AUTH_MODE {
  API_KEY = 'API_KEY',
  AWS_IAM = 'AWS_IAM',
  OPENID_CONNECT = 'OPENID_CONNECT',
  AMAZON_COGNITO_USER_POOLS = 'AMAZON_COGNITO_USER_POOLS',
}
