import React from 'react';
import { createPortal } from 'react-dom';
import './Modal.component.scss';
import { useTranslation } from 'react-i18next';
import { Button, ButtonType } from '../button/Button.component';

export interface ModalProps {
  opened: boolean;
  children?: React.ReactNode;
  onDanger: () => void;
  title: string;
  dangerButtonText?: string;
  primaryButtonText?: string;
  onPrimary?: () => void;
  maxWidth?: string;
  disabled?: boolean;
}

export const Modal = (props: ModalProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    opened,
    children,
    onDanger,
    title,
    dangerButtonText = t('@T_General_CancelLabel'),
    primaryButtonText,
    onPrimary = () => {},
    maxWidth = '600px',
    disabled = false,
  } = props;

  const content = opened && (
    <div className='overlay'>
      <div className='app-modal' style={{ maxWidth }}>
        <div className='app-modal__body'>
          <div>
            <h1>
              {title}
            </h1>
            <div>
              {children}
            </div>
          </div>

          <div className='app-btn-group text-right'>
            <Button
              className='m-2 p-2'
              buttonType={ButtonType.DANGER}
              type='button'
              onClick={onDanger}
            >{dangerButtonText}
            </Button>
            {primaryButtonText && onPrimary &&
            <Button disabled={disabled} className='m-2 p-2' onClick={onPrimary} type='button'>{primaryButtonText}</Button>}
          </div>
        </div>
      </div>
    </div>
  );

  return createPortal(content, document.body);
};
