export enum Routes {
  HOME = '/',

  SIGN_IN = '/sign-in',

  JOB_MANAGEMENT = '/job-management',
  JOB_MANAGEMENT_OVERVIEW = '/job-management/overview/:id',
  JOB_MANAGEMENT_ADD = '/job-management/add',

  TENANT_MANAGEMENT = '/tenant-management',
  TENANT_MANAGEMENT_OVERVIEW = '/tenant-management/overview/:id',
  TENANT_MANAGEMENT_ADD = '/tenant-management/add',
  TENANT_MANAGEMENT_ADD_LICENSES = '/tenant-management/:tenantId/licenses/add',
  TENANT_MANAGEMENT_ADD_CODES = '/tenant-management/:tenantId/codes/add',

  LICENSE_MANAGEMENT = '/license-management',
  LICENSE_MANAGEMENT_EDIT = '/license-management/edit/:id',

  USERS_MANAGEMENT = '/users-management',
  USER_DETAILS = '/users-management/user-details/:id/*',
  USER_CHANGE_TENANT = '/users-management/users-details/:id/change-tenant',
  USER_CHANGE_EMAIL = '/users-management/users-details/:id/change-email',

  DELETED_USERS_MANAGEMENT = '/deleted-users-management',

  CLIENT_MANAGEMENT = '/client-management',
  CLIENT_MANAGEMENT_EDIT = '/client-management/edit/:id',

  EVALUATION_MATRIX = '/evaluation-matrix',
}
