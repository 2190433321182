import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API, graphqlOperation } from 'aws-amplify';

import { ListBody } from 'components/shared';
import { QueryListResult } from 'global/models/helper.model';
import { ModelAttributeTypes, ModelSortDirection, RegistrationCode } from 'api/API';
import { usePromise } from 'hooks/usePromise';
import { getLocalTime, DATE_FORMAT_SECONDARY } from 'config';
import { listRegistrationCodesByTenantUsedAt } from 'api/graphql/custom';

type Result = QueryListResult<'listRegistrationCodesByTenantUsedAt', RegistrationCode>;
type NextTokenUnion = string | null;

export const UsedRegistrationCodeList = ({ tenant_id }: { tenant_id: string }) => {
  const { t } = useTranslation();
  const [codes, setCodes] = useState<RegistrationCode[]>([]);
  const [nextToken, setNextToken] = useState<NextTokenUnion>();
  const [fetchUsedCodes, { result, isLoading, error }] = usePromise<Result, [NextTokenUnion?]>(async () => {
    const fetchResult = await API.graphql(graphqlOperation(listRegistrationCodesByTenantUsedAt, {
      tenant_id,
      sortDirection: ModelSortDirection.ASC,
      filter: {
        license_id: {
          attributeType: ModelAttributeTypes.string,
        },
      },
      nextToken,
    })) as Result;

    return fetchResult;
  });

  const resultItems = result?.data?.listRegistrationCodesByTenantUsedAt?.items;
  const fetchedCodes = useMemo(() => resultItems || [], [resultItems]);
  const token = result?.data?.listRegistrationCodesByTenantUsedAt?.nextToken;

  useEffect(
    () => {
      fetchUsedCodes();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      setNextToken(token);
    },
    [token],
  );

  useEffect(
    () => {
      setCodes(prevCodes => [...prevCodes, ...fetchedCodes]);
    },
    [fetchedCodes],
  );

  return (
    <ListBody
      isLoading={!codes.length && isLoading}
      loadMore={!!nextToken}
      errorMessage={error?.message}
      onLoadMore={() => fetchUsedCodes(nextToken)}
    >
      <table>
        <thead>
          <tr>
            <th>{t('@T_License_Code')}</th>
            <th>{t('@T_General_Redeemed')}</th>
            <th>{t('@T_License_License')}</th>
          </tr>
        </thead>
        <tbody>
          {codes?.map(code => (
            <tr key={code.code}>
              <td>{code.code}</td>
              <td>{getLocalTime(code.used_at!, DATE_FORMAT_SECONDARY)}</td>
              <td>{code.license_id}</td>
            </tr>
          )) }
        </tbody>
      </table>
    </ListBody>
  );
};
