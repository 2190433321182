import { put, fork, all, take, call } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { authGroups } from 'config';
import { ActionCreators, AuthActionTypes, SignInForm } from './actions';

export interface CognitoAttributes {
  given_name: string;
  family_name: string;
  email: string;
  sub: string;
  'custom:user_id': string;
}

const getError = (error: { message: string; code: string }) => {
  const errorMessageMapping: { [code: string]: string } = {
    UserNotFoundException: '@T_Authorization_NoAccountError',
    NotAuthorizedException: '@T_Authorization_WrongPasswordError',
    NotAuthorizedAdminGroupException: '@T_Authorization_NoPermissionError',
  };
  return { ...error, message: errorMessageMapping[error.code] || error.message };
};

export function* isUserAuthorized(cognitoUser: CognitoUser, next: () => any, authorizedGroups = authGroups) {
  const groups = cognitoUser.getSignInUserSession()?.getAccessToken()?.payload['cognito:groups'];

  if (!groups || !authorizedGroups.some(authorizedGroup => groups.includes(authorizedGroup))) {
    yield put(ActionCreators.signInFail(getError({ code: 'NotAuthorizedAdminGroupException', message: '' })));
  } else {
    yield next();
  }
}

export function* doSignIn(f: SignInForm) {
  try {
    const cognitoUser: CognitoUser & { attributes: CognitoAttributes } = yield Auth.signIn(f.email?.toLowerCase() as string, f.password as string);
    const attrs = cognitoUser.attributes;
    const groups = cognitoUser.getSignInUserSession()?.getAccessToken()?.payload['cognito:groups'];

    // eslint-disable-next-line func-names
    yield isUserAuthorized(cognitoUser, function* () {
      yield put(ActionCreators.signInSuccess({
        subId: attrs.sub,
        email: attrs.email,
        givenName: attrs.given_name,
        familyName: attrs.family_name,
        groups,
        'custom:user_id': attrs['custom:user_id'],
      }));
    });
  } catch (error) {
    yield put(ActionCreators.signInFail(getError(error)));
  }
}

export function* doSignOut() {
  try {
    yield Auth.signOut();
  } catch (error) {
    console.error(error);
  }
}

export function* reHydrateUser() {
  try {
    const user: CognitoUser & { attributes: CognitoAttributes } = yield Auth.currentAuthenticatedUser({
      bypassCache: true,
    });

    const groups = user.getSignInUserSession()?.getAccessToken()?.payload['cognito:groups'];

    // eslint-disable-next-line func-names
    yield isUserAuthorized(user, function* () {
      yield put(ActionCreators.signInSuccess({
        subId: user.attributes.sub,
        email: user.attributes.email,
        givenName: user.attributes.given_name,
        familyName: user.attributes.family_name,
        groups,
        'custom:user_id': user.attributes['custom:user_id'],
      }));
    });
  } catch (error) {
    console.warn(error);
    yield put(ActionCreators.signInFail(getError(error)));
    yield put(ActionCreators.signOut());
  }

}

function* watchSignIn() {
  while (true) {
    const { payload } = yield take(AuthActionTypes.SIGN_IN);
    yield doSignIn(payload);
  }
}

function* watchSignOut() {
  while (true) {
    yield take(AuthActionTypes.SIGN_OUT);
    yield doSignOut();
  }
}

function* watchRehydrate() {
  yield reHydrateUser();
}

export function* saga() {
  yield all([
    fork(watchSignIn),
    fork(watchSignOut),
    call(watchRehydrate),
  ]);
}
