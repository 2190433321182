import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Amplify } from 'aws-amplify';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { awsConfig } from './aws.config';
import { store } from './redux/store';
import { AuthActionTypes } from './redux/modules/auth/sign-in/actions';
import { WithProviders } from './providers';

Amplify.configure(awsConfig);

store.dispatch({ type: AuthActionTypes.REHYDRATE_USER });

ReactDOM.render(
  <React.StrictMode>
    <WithProviders>
      <App />
    </WithProviders>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
