import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useParams, useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { API } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import { adminQuery, UserWithAdminProps } from 'services/adminQuery.service';
import { usePromise } from 'hooks';
import { Button, ButtonType, List, ListBody, ListHeader, LoadingButton } from 'components/shared';
import { updateLicense, updateLead, updateRegistrationCode, changeTenantExternalProviders } from 'api/graphql/mutations';
import { getUserByCustomId } from 'utils';
import { toast } from 'react-toastify';

interface SelectedOption {
  label: string;
  value: string | undefined;
}

export const UserChangeTenant = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<UserWithAdminProps>();
  const [newTenant, setNewTenant] = useState<SelectedOption>();
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const handleChange = (selectedOption: SelectedOption | null) => {
    setNewTenant(selectedOption as SelectedOption);
  };

  const fetchingUser = async () => {
    try {
      const user = await getUserByCustomId(id!);

      setCurrentUser(user);
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onLoadTenants = async (searchValue: string) => {
    try {
      const response = await adminQuery.listTenantsWithLicensesByName({ name: searchValue });

      const { items: fetchedTenants } = response.data?.listTenantsByName;

      const filteredTenant = fetchedTenants
        .filter(tenants => tenants.name.toLowerCase().includes(searchValue.toLowerCase()))
        .map(option => {
          return {
            label: option.name,
            value: option.id,
          };
        });

      return filteredTenant;
    } catch (err) {
      console.error(err);
      setError(err);
      return [];
    }
  };

  const onSubmit = async () => {
    try {
      const licenseResponse = await adminQuery.getLicenseByUser(id!);
      const leadResponse = await adminQuery.getLeadByEmail(currentUser?.Attributes.email!);

      const licenseId: string = licenseResponse.data.listLicensesByByUser.items[0].id!;
      const registrationCodeId: string = currentUser?.Attributes['custom:registration_code_id'];
      const lead = leadResponse.data.leadByEmail.items;

      await API.graphql({
        query: changeTenantExternalProviders,
        variables: {
          new_tenant: {
            id: newTenant!.value,
            tenant_name: newTenant!.label,
          },
          old_tenant: {
            id: currentUser?.Attributes?.['custom:tenant_id'],
            tenant_name: currentUser?.Attributes?.['custom:tenant_name'],
          },
          user_id: currentUser?.Attributes['custom:user_id'],
        },
      });

      if (currentUser) {
        await adminQuery.updateTenantId(currentUser!, newTenant!.value, newTenant!.label);
      }

      if (licenseId) {
        await API.graphql({
          query: updateLicense,
          variables: {
            input: {
              id: licenseId,
              tenant_id: newTenant!.value,
            },
          },
        });
      }

      if (registrationCodeId) {
        await API.graphql({
          query: updateRegistrationCode,
          variables: {
            input: {
              id: registrationCodeId,
              tenant_id: newTenant!.value,
            },
          },
        });
      }

      if (lead?.length) {
        const leadId: string = leadResponse.data.leadByEmail.items[0].id!;
        await API.graphql({
          query: updateLead,
          variables: {
            input: {
              id: leadId,
              tenant_id: newTenant!.value,
            },
          },
        });
      }

      navigate(-1);
    } catch (err) {
      toast.error((err.message));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [submit, { result: _result, isLoading: isSaving, error: errorSubmit }] = usePromise(onSubmit);

  useEffect(
    () => {
      fetchingUser();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <List>
      <ListHeader title={t('@T_UserOverview_Change_Tenant')} />

      {loading && <div className='full-size-centered white-opacity'><Spinner animation='border' variant='primary' /></div>}

      <ListBody
        isLoading={loading}
        errorMessage={error}
        className='p-3'
        style={{ height: '80vh' }}
      >
        <div>
          <h5 className='mb-3'><b>{t('@T_UserOverview_Change_Tenant_Current_Tenant')}:</b> {currentUser?.Attributes['custom:tenant_name']}</h5>
          <h5><b>{t('@T_UserOverview_Change_Tenant_New_Tenant')}:</b></h5>
          <AsyncSelect
            loadOptions={onLoadTenants}
            defaultOptions
            loadingMessage={() => t('@T_General_LoadingIndicator')}
            noOptionsMessage={() => t('@T_UserOverview_Change_Tenant_Loading_Option_Message')}
            placeholder={t('@T_UserOverview_Change_Tenant_Placeholder')}
            onChange={handleChange}
            isClearable={true}
          />
        </div>

        <div className='btn-group justify-content-between pt-4'>
          <Button className='rounded py-1 px-3' disabled={isSaving} buttonType={ButtonType.DANGER} onClick={() => navigate(-1)} >
            {t('@T_UserOverview_Change_Tenant_Cancel')}
          </Button>
          <LoadingButton className='rounded py-1 px-3' onClick={submit} disabled={!newTenant || isSaving} isLoading={isSaving}>
            {isSaving ? t('@T_UserOverview_Change_Tenant_Save_New_Tenant_Loading') : t('@T_UserOverview_Change_Tenant_Save_New_Tenant')}
          </LoadingButton>
        </div>
      </ListBody>
    </List>
  );
};
