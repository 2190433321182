import { Gender } from './user.model';

export interface AnamnesisAnswers {
  is_performing_job: boolean;
  is_pregnant: boolean;
  has_contraindications: boolean;
  has_body_part_pain: boolean; // not a part of data model
  is_taking_medications: boolean; // not a part of data model
  selected_body_parts: BodyPart[]; // not a part of data model
  body_part_pain: BodyPartPain[];
  pain_medication: PainMedication;

  gender?: Gender;
  body_size?: number;
  body_weight?: number;
  birthdate?: string;
  family_name?: string; // not a part of data model
}

export interface AnamnesisResult extends Omit<AnamnesisAnswers, 'has_body_part_pain' | 'is_taking_medications' | 'selected_body_parts' | 'family_name'> {
  id: string;
  identity_sub: string;
  anamnese_type: AnamnesisType;
  processed_at: string;
  __typename?: string;
}

export interface BodyPartPain {
  body_part: BodyPart;
  severity: number;
}

export enum BodyPart {
  KNEE = 'KNEE',
  HIP = 'HIP',
  ANKLE = 'ANKLE',
  FOOT = 'FOOT',
  HAND = 'HAND',
  ELBOW = 'ELBOW',
  LOWER_BACK = 'LOWER_BACK',
  NECK = 'NECK',
  SHOULDER = 'SHOULDER',
  THORACIC_SPINE = 'THORACIC_SPINE',
}

export enum AnamnesisConsent {
  HAVE_NO_DISEASE = 'HAVE_NO_DISEASE',
  CONFIRM_LATER = 'CONFIRM_LATER',
}

export enum BodyPartTranslations {
  KNEE = '@T_BodyParts_Knee',
  HIP = '@T_BodyParts_Hip',
  ANKLE = '@T_BodyParts_Ankle',
  FOOT = '@T_BodyParts_Foot',
  HAND = '@T_BodyParts_Hand',
  ELBOW = '@T_BodyParts_Elbow',
  LOWER_BACK = '@T_BodyParts_LowerBack',
  NECK = '@T_BodyParts_Neck',
  SHOULDER = '@T_BodyParts_Shoulder',
  THORACIC_SPINE = '@T_BodyParts_ThoracicSpine',
}

export enum PainMedication {
  NONE = 'NONE',
  ONCE_A_WEEK = 'ONCE_A_WEEK',
  EVERY_OTHER_DAY = 'EVERY_OTHER_DAY',
  ONCE_A_DAY = 'ONCE_A_DAY',
  TWICE_OR_MORE_A_DAY = 'TWICE_OR_MORE_A_DAY',
}

export enum BodySide {
  FRONT = 'FRONT',
  BACK = 'BACK',
}

export enum AnamnesisType {
  MOBILE_V1 = 'MOBILE_V1',
  MANUAL = 'MANUAL',
  UNCHANGED = 'UNCHANGED',
}

export enum ContraindicationType {
  ALL = 'ALL',
}
