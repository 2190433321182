import { useEffect } from 'react';
import { toast } from 'react-toastify';

interface ErrorResponse {
  message?: string;
  errors?: ErrorResponse[];
}

export const useErrorToast = (error: ErrorResponse) => {
  useEffect(
    () => {
      if (!error) {
        return;
      }

      const parsedError = error.message || error.errors?.[0]?.message || error;
      toast.error(parsedError);
    },
    [error],
  );
};
