export enum keyboardKeys {
  SPACE = 'Space',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_LEFT = 'ArrowLeft',
}

export enum PlayerStatus {
  Play = 'Play',
  Pause = 'Pause',
  Replay = 'Replay',
}

export const ProgressBarColors = {
  primary: '#E65D4F',
  secondary: '#4DA2EF',
  background: '#707070',
  loaded: '#838181',
  playedSeconds: '#D70040',
};