import React, { ButtonHTMLAttributes } from 'react';
import './Button.component.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { ButtonType, Button } from './Button.component';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: ButtonType;
  icon: IconProp;
  action: () => void;
}

export const IconButton = ({ buttonType = ButtonType.SECONDARY, className, style, action, icon }: IconButtonProps): JSX.Element => (
  <Button buttonType={buttonType} className={`app-icon-btn ${className || ''}`} onClick={action} style={style}>
    <FontAwesomeIcon icon={icon} />
  </Button>
);
