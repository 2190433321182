import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UserRoute } from '../config';
import { UserComponentBaseProps } from '../model';
import { UserHealthDataMovement, UserHealthDataMind } from './';


// temporary content
export const UserMedical = (props: UserComponentBaseProps) => {
  const { user, movementAnamnesisResults, mindAnamnesisResults } = props;
  const { t } = useTranslation();
  
  const [selectedTab, setSelectedTab] = useState(UserRoute.MOVEMENT_ANAMNESIS);

  const switchTab = (tab: string | null) => {
    setSelectedTab(tab as UserRoute);
  };

  return (
    <div className='mb-4'>
      <Tabs defaultActiveKey={selectedTab} onSelect={switchTab}>
        <Tab eventKey={UserRoute.MOVEMENT_ANAMNESIS} title={t('@T_Anamnesis_MovementLabel')}>
          <UserHealthDataMovement user={user} movementAnamnesisResults={movementAnamnesisResults} />
        </Tab>
        <Tab eventKey={UserRoute.MIND_ANAMNESIS} title={t('@T_Anamnesis_MindLabel')}>
          <UserHealthDataMind user={user} mindAnamnesisResults={mindAnamnesisResults} />
        </Tab>
      </Tabs>
    </div>
  );
};
