import React, { ButtonHTMLAttributes } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './Button.component.scss';
import classnames from 'classnames';

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: ButtonType;
}

export const Button = ({ children, buttonType = ButtonType.SECONDARY, className, ...rest }: ButtonProps): JSX.Element => {
  const buttonClasses = classnames({
    'app-btn': true,
    'app-btn-primary': (buttonType === ButtonType.PRIMARY),
    'app-btn-secondary': (buttonType === ButtonType.SECONDARY),
    'app-btn-danger': (buttonType === ButtonType.DANGER),
    'app-btn-disabled': rest.disabled,
  });
  const extendedButtonClasses = (className) ? `${buttonClasses} ${className}` : buttonClasses;

  return (
    <button {...rest} className={extendedButtonClasses}>
      {children}
    </button>
  );
};

interface LoadingButtonProps extends ButtonProps {
  isLoading?: boolean;
}

export const LoadingButton = ({ children, isLoading = false, ...rest }: LoadingButtonProps): JSX.Element => {

  return (
    <Button {...rest}>
      {isLoading && <Spinner size='sm' animation='border' className={'me-2'} />}
      {children}
    </Button>
  );
};
