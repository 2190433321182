import { groupBy, flattenDeep, result } from 'lodash';
import {
  UserAssignmentWithIds,
  UserAssignment,
  ParsedTrainingStructure,
  ExpandedModule,
  ExpandedTask,
  ExpandedSegment,
  ExpandedUnit,
  ExpandedBlock,
  MovementTaskType,
  MovementTrainingStructureType,
  ModuleV2 as Module,
  Block,
  UnitV2 as Unit,
  Task,
} from '../models/movement-training.model';

export const getFirstNotCompletedModule = (modules?: ExpandedModule[] | null) => {
  return (modules || []).find(module => !isModuleCompleted(module));
};

export const getFirstNotCompletedBlock = (module?: ExpandedModule | null) => {
  return module?.blocks.find(block => !isBlockCompleted(block));
};

export const getFirstNotCompletedUnit = (block?: ExpandedBlock | null) => {
  return block?.units.find(unit => !isUnitCompleted(unit));
};

export const isSingleTaskBlock = (block: ExpandedBlock) => (
  block?.units?.length === 1 && block.units[0].segments?.length === 1 && block.units[0].segments[0].tasks?.length === 1
);

export const getFirstNotCompletedTaskIndexes = (unit: ExpandedUnit) => {
  let segmentIndex = 0;
  let taskIndex = 0;

  if (isUnitCompleted(unit) || !unit?.segments) {
    return { segmentIndex, taskIndex };
  }

  while (isTaskCompleted(unit.segments[segmentIndex]?.tasks?.[taskIndex])) {
    if (!!unit.segments[segmentIndex]?.tasks?.[taskIndex + 1]) {
      taskIndex += 1;
    } else if (!!unit.segments[segmentIndex + 1]?.tasks?.[0]) {
      segmentIndex += 1;
      taskIndex = 0;
    } else {
      return { segmentIndex, taskIndex };
    }
  }

  return { segmentIndex, taskIndex };
};

export const getNextTaskIndexes = (unit: ExpandedUnit, currentSegmentIndex: number, currentTaskNumber: number) => {
  let segmentIndex = currentSegmentIndex;
  let taskIndex = currentTaskNumber;

  if (!!unit.segments[segmentIndex]?.tasks?.[taskIndex + 1]) {
    taskIndex += 1;
  } else if (!!unit.segments[segmentIndex + 1]?.tasks?.[0]) {
    segmentIndex += 1;
    taskIndex = 0;
  } else {
    return null;
  }

  return { segmentIndex, taskIndex };
};

export const mapSort = <Type>(linkedList: Type[], prevKey: string, key = 'id'): Type[] => {
  const sortedList: Type[] = [];
  const map = new Map();
  const keys = linkedList.map(item => result(item, key));
  let currentId = null;

  for (let i = 0; i < linkedList.length; i += 1) {
    const item = linkedList[i];
    if (!result(item, prevKey) || !keys.includes(result(item, prevKey))) {
      currentId = result(item, key);
      sortedList.push(item);
    } else {
      map.set(result(item, prevKey), i);
    }
  }

  while (sortedList.length < linkedList.length) {
    // get the item with a previous item ID referencing the current item
    const nextItem: any = linkedList[map.get(currentId)];
    sortedList.push(nextItem);
    currentId = result(nextItem, key);
  }

  return sortedList;
};

const withIds = (item: UserAssignment): UserAssignmentWithIds => {
  const { block: { moduleId }, parentBlockId: blockId, unitId, segmentId } = item?.source;

  return {
    ...item,
    moduleId,
    blockId,
    unitId,
    segmentId,
  };
};

const mapTasks = (userAssignment: UserAssignmentWithIds) => {
  return {
    ...userAssignment?.source,
    userAssignment,
  };
};

const mapSegments = (segmentAssignments: UserAssignmentWithIds[]) => {
  return {
    ...segmentAssignments[0]?.source?.segment,
    segmentPredecessor: segmentAssignments.find(({ source }) => !!source?.segmentPredecessor)?.source?.segmentPredecessor,
    tasks: mapSort(segmentAssignments.map(mapTasks), 'userAssignment.predecessorId', 'userAssignment.id')
      .filter(task => !task.userAssignment.disabled),
  };
};

const mapUnits = (unitAssignments: UserAssignmentWithIds[]) => {
  return {
    ...unitAssignments[0]?.source?.unit,
    unitPredecessor: unitAssignments.find(({ source }) => !!source?.unitPredecessor)?.source?.unitPredecessor,
    segments: mapSort(Object.values(groupBy(unitAssignments, 'segmentId')).map(mapSegments), 'segmentPredecessor')
      .filter(segment => !!segment.tasks?.length),
  };
};

const mapBlocks = (blockAssignments: UserAssignmentWithIds[]) => {
  return {
    ...blockAssignments[0]?.source?.block,
    units: mapSort(Object.values(groupBy(blockAssignments, 'unitId')).map(mapUnits), 'unitPredecessor')
      .filter(unit => !!unit.segments?.length),
  };
};

const mapModules = (moduleAssignments: UserAssignmentWithIds[]) => {
  return {
    id: moduleAssignments[0]?.moduleId,
    modulePredecessor: moduleAssignments.find(({ source }) => !!source?.block?.modulePredecessor)?.source?.block?.modulePredecessor,
    blocks: mapSort(Object.values(groupBy(moduleAssignments, 'blockId')).map(mapBlocks), 'blockPredecessor')
      .filter(block => !!block.units?.length),
  };
};

export const parseMovements = (userAssignments: UserAssignment[]): ParsedTrainingStructure => {
  const withFlattenIds = userAssignments.map(withIds);
  const modules = mapSort(Object.values(groupBy(withFlattenIds, 'moduleId')).map(mapModules), 'modulePredecessor')
    .filter(module => !!module.blocks?.length);

  return { modules };
};

export const parseMovementsV2 = (userMovementAssignments: string): Module[] => {
  const parsedResult = JSON.parse(userMovementAssignments);

  const clusterMovementTrainingStructure = (movementTrainingStructureType: MovementTrainingStructureType, itemClusteringKey: string) => {

    return parsedResult[movementTrainingStructureType].reduce(
      (cluster: any, item: any) => ({
        ...cluster,
        [item[itemClusteringKey]]: [...(cluster[item[itemClusteringKey]] || []), item],
      }),
      {} as any,
    );
  };

  const clusteredBlocks = clusterMovementTrainingStructure(MovementTrainingStructureType.BLOCK, 'moduleId');
  const clusteredUnits = clusterMovementTrainingStructure(MovementTrainingStructureType.UNIT, 'blockId');
  const clusteredTasks = clusterMovementTrainingStructure(MovementTrainingStructureType.TASK, 'unitId');
  const clusteredUserAssignments = clusterMovementTrainingStructure(MovementTrainingStructureType.USER_ASSIGNMENT, 'sourceId');

  return (parsedResult.modules || []).map((module: Module) => ({
    ...module,
    blocks: (clusteredBlocks[module.id] || []).map((block: Block) => ({
      ...block,
      units: (clusteredUnits[block.id] || []).map((unit: Unit) => ({
        ...unit,
        tasks: (clusteredTasks[unit.id] || []).map((task: Task) => ({
          ...task,
          userAssignment: task && clusteredUserAssignments[task.id][0],
        })),
      })),
    })),
  }));
};

export const flattenModules = (modules: ExpandedModule[]) => (
  flattenDeep(
    modules.map(module => (
      module.blocks.map(block => (
        block.units.map(unit => (
          unit.segments.map(segment => (
            segment.tasks
          ))
        ))
      ))
    )),
  ) as ExpandedTask[]
);

export const flattenModulesV2 = (modules: Module[]) => (
  flattenDeep(
    modules.map(module => (
      module.blocks.map(block => (
        block.units.map(unit => unit.tasks)
      ))
    )),
  ) as Task[]
);

export const getFlattenBlocks = (modules: Module[]) => (
  flattenDeep(
    modules.map(module => module.blocks),
  ) as Block[]
);

export const getBlocksIndexedById = (blocks: Block[]): Record<string, Block> => blocks.reduce(
  (cluster: Record<string, Block>, item: Block) => ({
    ...cluster,
    [item.id]: { ...(cluster[item.id] || {}), ...item },
  }),
  {} as Record<string, Block>,
);

export const getClusteredFutureBlocks = (tasks: Task[]): Record<string, Task[]> => tasks.reduce(
  (cluster: Record<string, Task[]>, item: Task) => ({
    ...cluster,
    [item.parentBlockId]: [...(cluster[item.parentBlockId] || []), item],
  }),
  {} as Record<string, Task[]>,
);

export const getFutureTasks = (tasks: Task[]) => tasks.filter(task => !task.userAssignment?.completedAt);
export const getCompletedTasks = (tasks: Task[]) => tasks.filter(task => task.userAssignment?.completedAt);

export const hasTaskStarted = (task: ExpandedTask) => !task || !!task.userAssignment.startedAt;
export const isTaskCompleted = (task: ExpandedTask) => !task || !!task.userAssignment.completedAt;
// original version - keeping it for now
// export const isSegmentCompleted = (segment: ExpandedSegment) => segment?.tasks?.every(isTaskCompleted);
export const isSegmentCompleted = (segment: ExpandedSegment) => isTaskCompleted(segment.tasks[segment.tasks?.length - 1]);
export const isUnitCompleted = (unit: ExpandedUnit) => unit?.segments?.every(isSegmentCompleted);
export const isBlockCompleted = (block: ExpandedBlock) => block?.units?.every(isUnitCompleted);
export const isModuleCompleted = (module: ExpandedModule) => module?.blocks?.every(isBlockCompleted);

type Variations = { [taskId: string]: number };

export const taskDuration = (task: ExpandedTask, countAll: boolean, variationIndex?: number) => {
  const variationTime = (
    task.taskType === MovementTaskType.CONTENT_WITH_VARIATION &&
    typeof(variationIndex) === 'number' &&
    task?.content?.variations?.[variationIndex]?.time
  );

  const taskTime = variationTime || task?.content?.time || task?.content?.variations?.[0]?.time;

  if (countAll) {
    return taskTime ?? 0;
  }

  if (task.userAssignment.disabled) {
    return 0;
  }

  return isTaskCompleted(task) ? 0 : taskTime;
};

export const segmentDuration = (segment: ExpandedSegment, countAll = true, variations?: Variations) => (
  segment.tasks.reduce((time, task) => time + (taskDuration(task, countAll, variations?.[task.id]) || 0), 0)
);
export const unitDuration = (unit: ExpandedUnit, countAll = true, variations?: Variations) => (
  unit.segments.reduce((time, segment) => time + segmentDuration(segment, countAll, variations), 0)
);
export const blockDuration = (block: ExpandedBlock, countAll = true, variations?: Variations) => (
  block.units.reduce((time, unit) => time + unitDuration(unit, countAll, variations), 0)
);
export const sortCompletedTasks = (taskA: ExpandedTask, taskB: ExpandedTask) => {
  return new Date(taskA.userAssignment.completedAt as string).getTime() - new Date(taskB.userAssignment.completedAt as string).getTime();
};
export const sortCompletedTasksV2 = (taskA: Task, taskB: Task) => {
  return new Date(taskA.userAssignment?.completedAt as string).getTime() - new Date(taskB.userAssignment?.completedAt as string).getTime();
};
export const firstTaskFromBlock = (block: ExpandedBlock) => block?.units?.[0]?.segments[0]?.tasks?.[0];
