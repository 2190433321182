import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, gql } from '@apollo/client';

import { getJobWithTenants } from 'api/graphql/custom';
import { useFields } from 'hooks/shared/useFields';
import { EditJob } from 'components/job/EditJob.component';
import { JobDetails } from 'components/job/JobDetails.component';
import { LICENSES_LIMIT } from 'config/app.config';
import { TenantList } from 'components/tenant/TenantList.component';
import { JobTenant, Tenant } from '../../global/models';
import { JobFields } from './AddJob.screen';

export interface TenantFields {
  name: string;
  email_domains: string[];
}

export const JobOverview = (): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { loading, error, data } = useQuery(gql`${getJobWithTenants}`, {
    variables: {
      id,
      limit: LICENSES_LIMIT,
    },
  });
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [tenants, setTenants] = useState<Tenant[]>([]);

  const [fields, setFields, onUpdateField] = useFields<JobFields>({
    name: '',
  });

  const toggleEdit = (cancelled = false) => {
    if (!data) {
      return;
    }

    if (cancelled) {
      setFields(data?.getJob);
    }

    setEditMode(!isEditMode);
  };

  useEffect(() => {
    if (data && data.getJob) {
      setFields(data.getJob);

      setTenants(data.getJob?.tenants?.items.map((jobTenant: JobTenant) => jobTenant.tenant));
    }

  }, [data, setFields]);

  return (
    <div data-testid='job-overview'>

      {error && <div>{t('@T_General_Error')}</div>}

      {loading ?
        <div>{t('@T_General_LoadingIndicator')}</div>
        : (
          <div>
            { isEditMode ?
              <EditJob {...{ fields, setFields, onUpdateField, toggleEdit }} /> :
              <JobDetails {...{ fields, toggleEdit }}/> }

            <TenantList tenants={tenants} />
          </div>
        )}
    </div>
  );
};
