import React from 'react';
import { ProgressBar } from 'react-bootstrap';

import './Progressbar.component.scss'; 

interface ProgressbarComponentProps {
  data: number; 
  title: string; 
}

export const ProgressbarComponent = ({ data, title }: ProgressbarComponentProps) => { 
  const isCompleted = data === 100; 
  
  return (
    <div className='progressbar'>
      <h6>{title}</h6>
      <ProgressBar now={data} label={`${data}%`} variant={isCompleted ? 'success' : 'warning'}/>
    </div>
  ); 
}; 