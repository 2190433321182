import React, { ReactNode } from 'react';
import { useAccordionButton } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

interface AccordionToggleProps {
  children: ReactNode;
  eventKey: string;
  onClick: () => void;
}

export const AccordionToggle = ({ children, eventKey, onClick } : AccordionToggleProps) : JSX.Element => {
  const toggleOnClick = useAccordionButton(eventKey, onClick);
 
  return (
    <Accordion
      as={Card.Header}
      className='cursor-pointer'
      onClick={toggleOnClick}
    >
      {children}
    </Accordion> 
  );
};
