/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ModelTenantFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelTenantTypeInput | null,
  category?: ModelTenantCategoryInput | null,
  name?: ModelStringInput | null,
  email_domains?: ModelStringInput | null,
  code?: ModelStringInput | null,
  and?: Array< ModelTenantFilterInput | null > | null,
  or?: Array< ModelTenantFilterInput | null > | null,
  not?: ModelTenantFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelTenantTypeInput = {
  eq?: TenantType | null,
  ne?: TenantType | null,
};

export enum TenantType {
  TENANT = "TENANT",
}


export type ModelTenantCategoryInput = {
  eq?: TenantCategory | null,
  ne?: TenantCategory | null,
};

export enum TenantCategory {
  TENANT = "TENANT",
  TENANT_INTERNAL = "TENANT_INTERNAL",
}


export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelTenantConnection = {
  __typename: "ModelTenantConnection",
  items:  Array<Tenant | null >,
  nextToken?: string | null,
};

export type Tenant = {
  __typename: "Tenant",
  id: string,
  type: TenantType,
  category?: TenantCategory | null,
  name: string,
  email_domains?: Array< string > | null,
  code?: string | null,
  licenses?: ModelLicenseConnection | null,
  jobs?: ModelJobTenantConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelLicenseConnection = {
  __typename: "ModelLicenseConnection",
  items:  Array<License | null >,
  nextToken?: string | null,
};

export type License = {
  __typename: "License",
  id: string,
  type: LicenseType,
  tenant_id: string,
  invoice_id?: string | null,
  origin?: LicenseOrigin | null,
  identity_sub?: string | null,
  user?: string | null,
  validTo: string,
  tenant?: Tenant | null,
  createdAt: string,
  updatedAt: string,
};

export enum LicenseType {
  LICENSE = "LICENSE",
}


export enum LicenseOrigin {
  ADMIN_APP = "ADMIN_APP",
  REGISTER_FORM = "REGISTER_FORM",
}


export type ModelJobTenantConnection = {
  __typename: "ModelJobTenantConnection",
  items:  Array<JobTenant | null >,
  nextToken?: string | null,
};

export type JobTenant = {
  __typename: "JobTenant",
  id: string,
  job_id: string,
  tenant_id: string,
  tenant: Tenant,
  createdAt: string,
  updatedAt: string,
  job: Job,
};

export type Job = {
  __typename: "Job",
  id: string,
  name: string,
  type: JobType,
  tenants?: ModelJobTenantConnection | null,
  createdAt: string,
  updatedAt: string,
};

export enum JobType {
  AIRPORT = "AIRPORT",
  NURSING = "NURSING",
}


export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserMovementAnalysisResultFilterInput = {
  id?: ModelIDInput | null,
  identity_sub?: ModelIDInput | null,
  followup?: ModelIDInput | null,
  internal_comment?: ModelStringInput | null,
  evaluation_score_total?: ModelFloatInput | null,
  user_screenshot?: ModelIDInput | null,
  evaluation_measurements?: ModelStringInput | null,
  feedback_texts?: ModelStringInput | null,
  status?: ModelAnalysisResultStatusInput | null,
  draft_approved_at?: ModelStringInput | null,
  draft_approved_by?: ModelIDInput | null,
  approved_at?: ModelStringInput | null,
  approved_by?: ModelIDInput | null,
  draft_at?: ModelStringInput | null,
  draft_by?: ModelIDInput | null,
  and?: Array< ModelUserMovementAnalysisResultFilterInput | null > | null,
  or?: Array< ModelUserMovementAnalysisResultFilterInput | null > | null,
  not?: ModelUserMovementAnalysisResultFilterInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelAnalysisResultStatusInput = {
  eq?: AnalysisResultStatus | null,
  ne?: AnalysisResultStatus | null,
};

export enum AnalysisResultStatus {
  DRAFT = "DRAFT",
  COMPLETE = "COMPLETE",
}


export type ModelUserMovementAnalysisResultConnection = {
  __typename: "ModelUserMovementAnalysisResultConnection",
  items:  Array<UserMovementAnalysisResult | null >,
  nextToken?: string | null,
};

export type UserMovementAnalysisResult = {
  __typename: "UserMovementAnalysisResult",
  id: string,
  identity_sub: string,
  followup?: string | null,
  internal_comment?: string | null,
  user_assignments?: ModelUserAssignmentConnection | null,
  evaluation_score_total?: number | null,
  user_screenshot?: string | null,
  evaluation_measurements?: string | null,
  feedback_texts?: string | null,
  status?: AnalysisResultStatus | null,
  draft_approved_at?: string | null,
  draft_approved_by?: string | null,
  approved_at?: string | null,
  approved_by?: string | null,
  draft_at?: string | null,
  draft_by?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelUserAssignmentConnection = {
  __typename: "ModelUserAssignmentConnection",
  items:  Array<UserAssignment | null >,
  nextToken?: string | null,
};

export type UserAssignment = {
  __typename: "UserAssignment",
  id: string,
  successor_assignment?: ModelUserAssignmentConnection | null,
  identity_sub: string,
  source_type: AssignmentSourceType,
  predecessor_id?: string | null,
  predecessor_assignment?: UserAssignment | null,
  result_id?: string | null,
  result?: UserMovementAnalysisResult | null,
  analysis_assets?: ModelUserMovementAnalysisAssetConnection | null,
  source_id: string,
  completed_at?: string | null,
  started_at?: string | null,
  due_at?: string | null,
  created_by_client_id?: string | null,
  repetitions_done?: number | null,
  deleted_at?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum AssignmentSourceType {
  MOVEMENT_TASK = "MOVEMENT_TASK",
  ANAMNESE = "ANAMNESE",
}


export type ModelUserMovementAnalysisAssetConnection = {
  __typename: "ModelUserMovementAnalysisAssetConnection",
  items:  Array<UserMovementAnalysisAsset | null >,
  nextToken?: string | null,
};

export type UserMovementAnalysisAsset = {
  __typename: "UserMovementAnalysisAsset",
  id: string,
  identity_sub: string,
  user_assignment_id: string,
  user_assignment?: UserAssignment | null,
  type?: AnalysisAssetType | null,
  src_file?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum AnalysisAssetType {
  VIDEO = "VIDEO",
  IMAGE = "IMAGE",
}


export type ModelUserMovementAnalysisAssetFilterInput = {
  id?: ModelIDInput | null,
  identity_sub?: ModelIDInput | null,
  user_assignment_id?: ModelIDInput | null,
  type?: ModelAnalysisAssetTypeInput | null,
  src_file?: ModelStringInput | null,
  and?: Array< ModelUserMovementAnalysisAssetFilterInput | null > | null,
  or?: Array< ModelUserMovementAnalysisAssetFilterInput | null > | null,
  not?: ModelUserMovementAnalysisAssetFilterInput | null,
};

export type ModelAnalysisAssetTypeInput = {
  eq?: AnalysisAssetType | null,
  ne?: AnalysisAssetType | null,
};

export type ModelRegistrationCodeFilterInput = {
  id?: ModelIDInput | null,
  code?: ModelStringInput | null,
  tenant_id?: ModelIDInput | null,
  identity_sub?: ModelIDInput | null,
  license_id?: ModelIDInput | null,
  used_at?: ModelStringInput | null,
  valid_to?: ModelStringInput | null,
  reserved_at?: ModelStringInput | null,
  and?: Array< ModelRegistrationCodeFilterInput | null > | null,
  or?: Array< ModelRegistrationCodeFilterInput | null > | null,
  not?: ModelRegistrationCodeFilterInput | null,
};

export type ModelRegistrationCodeConnection = {
  __typename: "ModelRegistrationCodeConnection",
  items:  Array<RegistrationCode | null >,
  nextToken?: string | null,
};

export type RegistrationCode = {
  __typename: "RegistrationCode",
  id: string,
  code: string,
  tenant_id: string,
  identity_sub?: string | null,
  license_id?: string | null,
  used_at?: string | null,
  valid_to: string,
  reserved_at?: string | null,
  tenant?: Tenant | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelLicenseFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelLicenseTypeInput | null,
  tenant_id?: ModelIDInput | null,
  invoice_id?: ModelStringInput | null,
  origin?: ModelLicenseOriginInput | null,
  identity_sub?: ModelIDInput | null,
  user?: ModelStringInput | null,
  validTo?: ModelStringInput | null,
  and?: Array< ModelLicenseFilterInput | null > | null,
  or?: Array< ModelLicenseFilterInput | null > | null,
  not?: ModelLicenseFilterInput | null,
};

export type ModelLicenseTypeInput = {
  eq?: LicenseType | null,
  ne?: LicenseType | null,
};

export type ModelLicenseOriginInput = {
  eq?: LicenseOrigin | null,
  ne?: LicenseOrigin | null,
};

export type ChangeTenantInput = {
  id: string,
  tenant_name: string,
};

export type SendRegistrationCodeMailInput = {
  code: string,
  name?: string | null,
  email: string,
  is_admin_action?: boolean | null,
  warming?: boolean | null,
};

export type VerifyCognitoUserResult = {
  __typename: "VerifyCognitoUserResult",
  success: boolean,
  message: string,
};

export type CreateUserMappingInput = {
  id?: string | null,
  custom_user_id: string,
  identity_sub: string,
};

export type ModelUserMappingConditionInput = {
  identity_sub?: ModelIDInput | null,
  and?: Array< ModelUserMappingConditionInput | null > | null,
  or?: Array< ModelUserMappingConditionInput | null > | null,
  not?: ModelUserMappingConditionInput | null,
};

export type UserMapping = {
  __typename: "UserMapping",
  id: string,
  custom_user_id: string,
  identity_sub: string,
  createdAt: string,
  updatedAt: string,
};

export type TeamAccessMapping = {
  __typename: "TeamAccess";
  access_code: string,
  validity: string,
  updatedAt: string,
  createdAt: string,
  id: string,
  tenant_access_id: string,
  tenant_id: string,
};

export type UpdateUserMappingInput = {
  id: string,
  custom_user_id?: string | null,
  identity_sub?: string | null,
};

export type DeleteUserMappingInput = {
  id: string,
};

export type CreateWatchedItemInput = {
  id?: string | null,
  name: string,
  contentful_id: string,
  contentful_version: string,
  start_date: string,
  end_date?: string | null,
  length_in_seconds: number,
  closed_at_seconds?: number | null,
  type: WatchedItemType,
  tenant_id: string,
  custom_user_id: string,
};

export enum WatchedItemType {
  BODY_EXERCISE = "BODY_EXERCISE",
  BODY_TRAINING = "BODY_TRAINING",
  MIND_EXERCISE = "MIND_EXERCISE",
  MIND_TRAINING = "MIND_TRAINING",
  MIND_CALMING_SOUNDS = "MIND_CALMING_SOUNDS",
}


export type ModelWatchedItemConditionInput = {
  name?: ModelStringInput | null,
  contentful_id?: ModelStringInput | null,
  contentful_version?: ModelStringInput | null,
  start_date?: ModelStringInput | null,
  end_date?: ModelStringInput | null,
  length_in_seconds?: ModelIntInput | null,
  closed_at_seconds?: ModelIntInput | null,
  type?: ModelWatchedItemTypeInput | null,
  tenant_id?: ModelIDInput | null,
  and?: Array< ModelWatchedItemConditionInput | null > | null,
  or?: Array< ModelWatchedItemConditionInput | null > | null,
  not?: ModelWatchedItemConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelWatchedItemTypeInput = {
  eq?: WatchedItemType | null,
  ne?: WatchedItemType | null,
};

export type WatchedItem = {
  __typename: "WatchedItem",
  id: string,
  name: string,
  contentful_id: string,
  contentful_version: string,
  start_date: string,
  end_date?: string | null,
  length_in_seconds: number,
  closed_at_seconds?: number | null,
  type: WatchedItemType,
  tenant_id: string,
  custom_user_id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateWatchedItemInput = {
  id: string,
  name?: string | null,
  contentful_id?: string | null,
  contentful_version?: string | null,
  start_date?: string | null,
  end_date?: string | null,
  length_in_seconds?: number | null,
  closed_at_seconds?: number | null,
  type?: WatchedItemType | null,
  tenant_id?: string | null,
  custom_user_id?: string | null,
};

export type DeleteWatchedItemInput = {
  id: string,
};

export type CreateTenantInput = {
  id?: string | null,
  type: TenantType,
  category?: TenantCategory | null,
  name: string,
  email_domains?: Array< string > | null,
  code?: string | null,
};

export type ModelTenantConditionInput = {
  type?: ModelTenantTypeInput | null,
  category?: ModelTenantCategoryInput | null,
  name?: ModelStringInput | null,
  email_domains?: ModelStringInput | null,
  code?: ModelStringInput | null,
  and?: Array< ModelTenantConditionInput | null > | null,
  or?: Array< ModelTenantConditionInput | null > | null,
  not?: ModelTenantConditionInput | null,
};

export type UpdateTenantInput = {
  id: string,
  type?: TenantType | null,
  category?: TenantCategory | null,
  name?: string | null,
  email_domains?: Array< string > | null,
  code?: string | null,
};

export type DeleteTenantInput = {
  id: string,
};

export type CreateTenantBookedMindPackageInput = {
  id?: string | null,
  tenant_id: string,
  package_id: string,
};

export type ModelTenantBookedMindPackageConditionInput = {
  tenant_id?: ModelIDInput | null,
  package_id?: ModelStringInput | null,
  and?: Array< ModelTenantBookedMindPackageConditionInput | null > | null,
  or?: Array< ModelTenantBookedMindPackageConditionInput | null > | null,
  not?: ModelTenantBookedMindPackageConditionInput | null,
};

export type TenantBookedMindPackage = {
  __typename: "TenantBookedMindPackage",
  id: string,
  tenant_id: string,
  package_id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTenantBookedMindPackageInput = {
  id: string,
  tenant_id?: string | null,
  package_id?: string | null,
};

export type DeleteTenantBookedMindPackageInput = {
  id: string,
};

export type CreateTenantBookedTrainingModuleInput = {
  id?: string | null,
  tenant_id: string,
  module_id: string,
  with_analyse?: boolean | null,
};

export type ModelTenantBookedTrainingModuleConditionInput = {
  tenant_id?: ModelIDInput | null,
  module_id?: ModelStringInput | null,
  with_analyse?: ModelBooleanInput | null,
  and?: Array< ModelTenantBookedTrainingModuleConditionInput | null > | null,
  or?: Array< ModelTenantBookedTrainingModuleConditionInput | null > | null,
  not?: ModelTenantBookedTrainingModuleConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type TenantBookedTrainingModule = {
  __typename: "TenantBookedTrainingModule",
  id: string,
  tenant_id: string,
  module_id: string,
  with_analyse?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTenantBookedTrainingModuleInput = {
  id: string,
  tenant_id?: string | null,
  module_id?: string | null,
  with_analyse?: boolean | null,
};

export type DeleteTenantBookedTrainingModuleInput = {
  id: string,
};

export type CreateLicenseInput = {
  id?: string | null,
  type: LicenseType,
  tenant_id: string,
  invoice_id?: string | null,
  origin?: LicenseOrigin | null,
  identity_sub?: string | null,
  user?: string | null,
  validTo: string,
};

export type ModelLicenseConditionInput = {
  type?: ModelLicenseTypeInput | null,
  tenant_id?: ModelIDInput | null,
  invoice_id?: ModelStringInput | null,
  origin?: ModelLicenseOriginInput | null,
  user?: ModelStringInput | null,
  validTo?: ModelStringInput | null,
  and?: Array< ModelLicenseConditionInput | null > | null,
  or?: Array< ModelLicenseConditionInput | null > | null,
  not?: ModelLicenseConditionInput | null,
};

export type UpdateLicenseInput = {
  id: string,
  type?: LicenseType | null,
  tenant_id?: string | null,
  invoice_id?: string | null,
  origin?: LicenseOrigin | null,
  identity_sub?: string | null,
  user?: string | null,
  validTo?: string | null,
};

export type DeleteLicenseInput = {
  id: string,
};

export type CreateRegistrationCodeInput = {
  id?: string | null,
  code: string,
  tenant_id: string,
  identity_sub?: string | null,
  license_id?: string | null,
  used_at?: string | null,
  valid_to: string,
  reserved_at?: string | null,
};

export type ModelRegistrationCodeConditionInput = {
  code?: ModelStringInput | null,
  tenant_id?: ModelIDInput | null,
  identity_sub?: ModelIDInput | null,
  license_id?: ModelIDInput | null,
  used_at?: ModelStringInput | null,
  valid_to?: ModelStringInput | null,
  reserved_at?: ModelStringInput | null,
  and?: Array< ModelRegistrationCodeConditionInput | null > | null,
  or?: Array< ModelRegistrationCodeConditionInput | null > | null,
  not?: ModelRegistrationCodeConditionInput | null,
};

export type UpdateRegistrationCodeInput = {
  id: string,
  code?: string | null,
  tenant_id?: string | null,
  identity_sub?: string | null,
  license_id?: string | null,
  used_at?: string | null,
  valid_to?: string | null,
  reserved_at?: string | null,
};

export type DeleteRegistrationCodeInput = {
  id: string,
};

export type CreateJobTenantInput = {
  id?: string | null,
  job_id: string,
  tenant_id: string,
};

export type ModelJobTenantConditionInput = {
  job_id?: ModelIDInput | null,
  tenant_id?: ModelIDInput | null,
  and?: Array< ModelJobTenantConditionInput | null > | null,
  or?: Array< ModelJobTenantConditionInput | null > | null,
  not?: ModelJobTenantConditionInput | null,
};

export type UpdateJobTenantInput = {
  id: string,
  job_id?: string | null,
  tenant_id?: string | null,
};

export type DeleteJobTenantInput = {
  id: string,
};

export type CreateJobInput = {
  id?: string | null,
  name: string,
  type: JobType,
};

export type ModelJobConditionInput = {
  name?: ModelStringInput | null,
  type?: ModelJobTypeInput | null,
  and?: Array< ModelJobConditionInput | null > | null,
  or?: Array< ModelJobConditionInput | null > | null,
  not?: ModelJobConditionInput | null,
};

export type ModelJobTypeInput = {
  eq?: JobType | null,
  ne?: JobType | null,
};

export type UpdateJobInput = {
  id: string,
  name?: string | null,
  type?: JobType | null,
};

export type DeleteJobInput = {
  id: string,
};

export type CreateAnamneseInput = {
  id?: string | null,
  identity_sub: string,
  anamnese_type: AnamneseType,
  processed_at?: string | null,
  gender: Gender,
  body_size?: number | null,
  body_weight?: number | null,
  birthdate?: string | null,
  is_performing_job?: boolean | null,
  is_pregnant?: boolean | null,
  has_contraindications?: boolean | null,
  pain_medication?: PainMedication | null,
  body_part_pain: Array< BodyPartPainInput | null >,
};

export enum AnamneseType {
  MOBILE_V1 = "MOBILE_V1",
  MANUAL = "MANUAL",
  UNCHANGED = "UNCHANGED",
}


export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  DIVERSE = "DIVERSE",
}


export enum PainMedication {
  NONE = "NONE",
  ONCE_A_WEEK = "ONCE_A_WEEK",
  EVERY_OTHER_DAY = "EVERY_OTHER_DAY",
  ONCE_A_DAY = "ONCE_A_DAY",
  TWICE_OR_MORE_A_DAY = "TWICE_OR_MORE_A_DAY",
}


export type BodyPartPainInput = {
  body_part?: BodyPart | null,
  severity?: number | null,
};

export enum BodyPart {
  KNEE = "KNEE",
  HIP = "HIP",
  ANKLE = "ANKLE",
  FOOT = "FOOT",
  HAND = "HAND",
  ELBOW = "ELBOW",
  LOWER_BACK = "LOWER_BACK",
  NECK = "NECK",
  SHOULDER = "SHOULDER",
  THORACIC_SPINE = "THORACIC_SPINE",
}


export type ModelAnamneseConditionInput = {
  anamnese_type?: ModelAnamneseTypeInput | null,
  processed_at?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  body_size?: ModelFloatInput | null,
  body_weight?: ModelFloatInput | null,
  birthdate?: ModelStringInput | null,
  is_performing_job?: ModelBooleanInput | null,
  is_pregnant?: ModelBooleanInput | null,
  has_contraindications?: ModelBooleanInput | null,
  pain_medication?: ModelPainMedicationInput | null,
  and?: Array< ModelAnamneseConditionInput | null > | null,
  or?: Array< ModelAnamneseConditionInput | null > | null,
  not?: ModelAnamneseConditionInput | null,
};

export type ModelAnamneseTypeInput = {
  eq?: AnamneseType | null,
  ne?: AnamneseType | null,
};

export type ModelGenderInput = {
  eq?: Gender | null,
  ne?: Gender | null,
};

export type ModelPainMedicationInput = {
  eq?: PainMedication | null,
  ne?: PainMedication | null,
};

export type Anamnese = {
  __typename: "Anamnese",
  id: string,
  identity_sub: string,
  anamnese_type: AnamneseType,
  processed_at?: string | null,
  gender: Gender,
  body_size?: number | null,
  body_weight?: number | null,
  birthdate?: string | null,
  is_performing_job?: boolean | null,
  is_pregnant?: boolean | null,
  has_contraindications?: boolean | null,
  pain_medication?: PainMedication | null,
  body_part_pain:  Array<BodyPartPain | null >,
  createdAt: string,
  updatedAt: string,
};

export type BodyPartPain = {
  __typename: "BodyPartPain",
  body_part?: BodyPart | null,
  severity?: number | null,
};

export type UpdateAnamneseInput = {
  id: string,
  identity_sub?: string | null,
  anamnese_type?: AnamneseType | null,
  processed_at?: string | null,
  gender?: Gender | null,
  body_size?: number | null,
  body_weight?: number | null,
  birthdate?: string | null,
  is_performing_job?: boolean | null,
  is_pregnant?: boolean | null,
  has_contraindications?: boolean | null,
  pain_medication?: PainMedication | null,
  body_part_pain?: Array< BodyPartPainInput | null > | null,
};

export type DeleteAnamneseInput = {
  id: string,
};

export type CreateMindAnamneseInput = {
  id?: string | null,
  identity_sub: string,
  processed_at?: string | null,
  has_contraindications?: boolean | null,
};

export type ModelMindAnamneseConditionInput = {
  processed_at?: ModelStringInput | null,
  has_contraindications?: ModelBooleanInput | null,
  and?: Array< ModelMindAnamneseConditionInput | null > | null,
  or?: Array< ModelMindAnamneseConditionInput | null > | null,
  not?: ModelMindAnamneseConditionInput | null,
};

export type MindAnamnese = {
  __typename: "MindAnamnese",
  id: string,
  identity_sub: string,
  processed_at?: string | null,
  has_contraindications?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMindAnamneseInput = {
  id: string,
  identity_sub?: string | null,
  processed_at?: string | null,
  has_contraindications?: boolean | null,
};

export type DeleteMindAnamneseInput = {
  id: string,
};

export type CreateUserMindProgressInput = {
  id?: string | null,
  identity_sub: string,
  source_id: string,
  source_type: MindTaskType,
  result_id?: string | null,
  completed_at?: string | null,
  started_at?: string | null,
};

export enum MindTaskType {
  SESSION = "SESSION",
  EVALUATION = "EVALUATION",
  FEEDBACK = "FEEDBACK",
  HEALTH_QUESTIONNAIRE = "HEALTH_QUESTIONNAIRE",
}


export type ModelUserMindProgressConditionInput = {
  source_id?: ModelIDInput | null,
  source_type?: ModelMindTaskTypeInput | null,
  result_id?: ModelIDInput | null,
  completed_at?: ModelStringInput | null,
  started_at?: ModelStringInput | null,
  and?: Array< ModelUserMindProgressConditionInput | null > | null,
  or?: Array< ModelUserMindProgressConditionInput | null > | null,
  not?: ModelUserMindProgressConditionInput | null,
};

export type ModelMindTaskTypeInput = {
  eq?: MindTaskType | null,
  ne?: MindTaskType | null,
};

export type UserMindProgress = {
  __typename: "UserMindProgress",
  id: string,
  identity_sub: string,
  source_id: string,
  source_type: MindTaskType,
  result_id?: string | null,
  result?: UserMindEvaluationResult | null,
  completed_at?: string | null,
  started_at?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UserMindEvaluationResult = {
  __typename: "UserMindEvaluationResult",
  id: string,
  identity_sub: string,
  mind_task_id: string,
  state: MindState,
  createdAt: string,
  updatedAt: string,
};

export enum MindState {
  STRESSED = "STRESSED",
  OKAY = "OKAY",
  RELAXED = "RELAXED",
}


export type UpdateUserMindProgressInput = {
  id: string,
  identity_sub?: string | null,
  source_id?: string | null,
  source_type?: MindTaskType | null,
  result_id?: string | null,
  completed_at?: string | null,
  started_at?: string | null,
};

export type DeleteUserMindProgressInput = {
  id: string,
};

export type CreateUserMindEvaluationResultInput = {
  id?: string | null,
  identity_sub: string,
  mind_task_id: string,
  state: MindState,
};

export type ModelUserMindEvaluationResultConditionInput = {
  mind_task_id?: ModelIDInput | null,
  state?: ModelMindStateInput | null,
  and?: Array< ModelUserMindEvaluationResultConditionInput | null > | null,
  or?: Array< ModelUserMindEvaluationResultConditionInput | null > | null,
  not?: ModelUserMindEvaluationResultConditionInput | null,
};

export type ModelMindStateInput = {
  eq?: MindState | null,
  ne?: MindState | null,
};

export type UpdateUserMindEvaluationResultInput = {
  id: string,
  identity_sub?: string | null,
  mind_task_id?: string | null,
  state?: MindState | null,
};

export type DeleteUserMindEvaluationResultInput = {
  id: string,
};

export type CreateUserMindPackageFeedbackInput = {
  id?: string | null,
  identity_sub: string,
  package_id: string,
  rating?: number | null,
};

export type ModelUserMindPackageFeedbackConditionInput = {
  package_id?: ModelIDInput | null,
  rating?: ModelFloatInput | null,
  and?: Array< ModelUserMindPackageFeedbackConditionInput | null > | null,
  or?: Array< ModelUserMindPackageFeedbackConditionInput | null > | null,
  not?: ModelUserMindPackageFeedbackConditionInput | null,
};

export type UserMindPackageFeedback = {
  __typename: "UserMindPackageFeedback",
  id: string,
  identity_sub: string,
  package_id: string,
  rating?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserMindPackageFeedbackInput = {
  id: string,
  identity_sub?: string | null,
  package_id?: string | null,
  rating?: number | null,
};

export type DeleteUserMindPackageFeedbackInput = {
  id: string,
};

export type CreateUserAssignmentInput = {
  id?: string | null,
  identity_sub: string,
  source_type: AssignmentSourceType,
  predecessor_id?: string | null,
  result_id?: string | null,
  source_id: string,
  completed_at?: string | null,
  started_at?: string | null,
  due_at?: string | null,
  created_by_client_id?: string | null,
  repetitions_done?: number | null,
  deleted_at?: string | null,
};

export type ModelUserAssignmentConditionInput = {
  source_type?: ModelAssignmentSourceTypeInput | null,
  predecessor_id?: ModelIDInput | null,
  result_id?: ModelIDInput | null,
  source_id?: ModelIDInput | null,
  completed_at?: ModelStringInput | null,
  started_at?: ModelStringInput | null,
  due_at?: ModelStringInput | null,
  created_by_client_id?: ModelStringInput | null,
  repetitions_done?: ModelIntInput | null,
  deleted_at?: ModelStringInput | null,
  and?: Array< ModelUserAssignmentConditionInput | null > | null,
  or?: Array< ModelUserAssignmentConditionInput | null > | null,
  not?: ModelUserAssignmentConditionInput | null,
};

export type ModelAssignmentSourceTypeInput = {
  eq?: AssignmentSourceType | null,
  ne?: AssignmentSourceType | null,
};

export type UpdateUserAssignmentInput = {
  id: string,
  identity_sub?: string | null,
  source_type?: AssignmentSourceType | null,
  predecessor_id?: string | null,
  result_id?: string | null,
  source_id?: string | null,
  completed_at?: string | null,
  started_at?: string | null,
  due_at?: string | null,
  created_by_client_id?: string | null,
  repetitions_done?: number | null,
  deleted_at?: string | null,
};

export type DeleteUserAssignmentInput = {
  id: string,
};

export type CreateUserMovementAssignmentInput = {
  id?: string | null,
  identity_sub: string,
  source_type: AssignmentSourceType,
  predecessor_id?: string | null,
  source_id: string,
  completed_at?: string | null,
  started_at?: string | null,
  due_at?: string | null,
  deleted_at?: string | null,
};

export type ModelUserMovementAssignmentConditionInput = {
  source_type?: ModelAssignmentSourceTypeInput | null,
  predecessor_id?: ModelIDInput | null,
  source_id?: ModelIDInput | null,
  completed_at?: ModelStringInput | null,
  started_at?: ModelStringInput | null,
  due_at?: ModelStringInput | null,
  deleted_at?: ModelStringInput | null,
  and?: Array< ModelUserMovementAssignmentConditionInput | null > | null,
  or?: Array< ModelUserMovementAssignmentConditionInput | null > | null,
  not?: ModelUserMovementAssignmentConditionInput | null,
};

export type UserMovementAssignment = {
  __typename: "UserMovementAssignment",
  id: string,
  successor_assignment?: ModelUserMovementAssignmentConnection | null,
  identity_sub: string,
  source_type: AssignmentSourceType,
  predecessor_id?: string | null,
  predecessor_assignment?: UserMovementAssignment | null,
  source_id: string,
  completed_at?: string | null,
  started_at?: string | null,
  due_at?: string | null,
  deleted_at?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelUserMovementAssignmentConnection = {
  __typename: "ModelUserMovementAssignmentConnection",
  items:  Array<UserMovementAssignment | null >,
  nextToken?: string | null,
};

export type UpdateUserMovementAssignmentInput = {
  id: string,
  identity_sub?: string | null,
  source_type?: AssignmentSourceType | null,
  predecessor_id?: string | null,
  source_id?: string | null,
  completed_at?: string | null,
  started_at?: string | null,
  due_at?: string | null,
  deleted_at?: string | null,
};

export type DeleteUserMovementAssignmentInput = {
  id: string,
};

export type CreateMovementTaskRatingInput = {
  id?: string | null,
  identity_sub: string,
  movement_task_id: string,
  rating: number,
};

export type ModelMovementTaskRatingConditionInput = {
  movement_task_id?: ModelIDInput | null,
  rating?: ModelFloatInput | null,
  and?: Array< ModelMovementTaskRatingConditionInput | null > | null,
  or?: Array< ModelMovementTaskRatingConditionInput | null > | null,
  not?: ModelMovementTaskRatingConditionInput | null,
};

export type MovementTaskRating = {
  __typename: "MovementTaskRating",
  id: string,
  identity_sub: string,
  movement_task_id: string,
  rating: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateMovementTaskRatingInput = {
  id: string,
  identity_sub?: string | null,
  movement_task_id?: string | null,
  rating?: number | null,
};

export type DeleteMovementTaskRatingInput = {
  id: string,
};

export type CreateUserMovementAnalysisResultInput = {
  id?: string | null,
  identity_sub: string,
  followup?: string | null,
  internal_comment?: string | null,
  evaluation_score_total?: number | null,
  user_screenshot?: string | null,
  evaluation_measurements?: string | null,
  feedback_texts?: string | null,
  status?: AnalysisResultStatus | null,
  draft_approved_at?: string | null,
  draft_approved_by?: string | null,
  approved_at?: string | null,
  approved_by?: string | null,
  draft_at?: string | null,
  draft_by?: string | null,
};

export type ModelUserMovementAnalysisResultConditionInput = {
  followup?: ModelIDInput | null,
  internal_comment?: ModelStringInput | null,
  evaluation_score_total?: ModelFloatInput | null,
  user_screenshot?: ModelIDInput | null,
  evaluation_measurements?: ModelStringInput | null,
  feedback_texts?: ModelStringInput | null,
  status?: ModelAnalysisResultStatusInput | null,
  draft_approved_at?: ModelStringInput | null,
  draft_approved_by?: ModelIDInput | null,
  approved_at?: ModelStringInput | null,
  approved_by?: ModelIDInput | null,
  draft_at?: ModelStringInput | null,
  draft_by?: ModelIDInput | null,
  and?: Array< ModelUserMovementAnalysisResultConditionInput | null > | null,
  or?: Array< ModelUserMovementAnalysisResultConditionInput | null > | null,
  not?: ModelUserMovementAnalysisResultConditionInput | null,
};

export type UpdateUserMovementAnalysisResultInput = {
  id: string,
  identity_sub?: string | null,
  followup?: string | null,
  internal_comment?: string | null,
  evaluation_score_total?: number | null,
  user_screenshot?: string | null,
  evaluation_measurements?: string | null,
  feedback_texts?: string | null,
  status?: AnalysisResultStatus | null,
  draft_approved_at?: string | null,
  draft_approved_by?: string | null,
  approved_at?: string | null,
  approved_by?: string | null,
  draft_at?: string | null,
  draft_by?: string | null,
};

export type DeleteUserMovementAnalysisResultInput = {
  id: string,
};

export type CreateLeadInput = {
  id?: string | null,
  firstname: string,
  lastname: string,
  company: string,
  email_address: string,
  tenant_id?: string | null,
  code?: string | null,
  code_send_at?: string | null,
  code_used_at?: string | null,
  user_id?: string | null,
  consent_conditions_at?: string | null,
};

export type ModelLeadConditionInput = {
  firstname?: ModelStringInput | null,
  lastname?: ModelStringInput | null,
  company?: ModelStringInput | null,
  email_address?: ModelStringInput | null,
  tenant_id?: ModelIDInput | null,
  code?: ModelStringInput | null,
  code_send_at?: ModelStringInput | null,
  code_used_at?: ModelStringInput | null,
  user_id?: ModelIDInput | null,
  consent_conditions_at?: ModelStringInput | null,
  and?: Array< ModelLeadConditionInput | null > | null,
  or?: Array< ModelLeadConditionInput | null > | null,
  not?: ModelLeadConditionInput | null,
};

export type Lead = {
  __typename: "Lead",
  id: string,
  firstname: string,
  lastname: string,
  company: string,
  email_address: string,
  tenant_id?: string | null,
  code?: string | null,
  code_send_at?: string | null,
  code_used_at?: string | null,
  user_id?: string | null,
  consent_conditions_at?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateLeadInput = {
  id: string,
  firstname?: string | null,
  lastname?: string | null,
  company?: string | null,
  email_address?: string | null,
  tenant_id?: string | null,
  code?: string | null,
  code_send_at?: string | null,
  code_used_at?: string | null,
  user_id?: string | null,
  consent_conditions_at?: string | null,
};

export type DeleteLeadInput = {
  id: string,
};

export type CreateUserMovementAnalysisAssetInput = {
  id?: string | null,
  identity_sub: string,
  user_assignment_id: string,
  type?: AnalysisAssetType | null,
  src_file?: string | null,
};

export type ModelUserMovementAnalysisAssetConditionInput = {
  user_assignment_id?: ModelIDInput | null,
  type?: ModelAnalysisAssetTypeInput | null,
  src_file?: ModelStringInput | null,
  and?: Array< ModelUserMovementAnalysisAssetConditionInput | null > | null,
  or?: Array< ModelUserMovementAnalysisAssetConditionInput | null > | null,
  not?: ModelUserMovementAnalysisAssetConditionInput | null,
};

export type UpdateUserMovementAnalysisAssetInput = {
  id: string,
  identity_sub?: string | null,
  user_assignment_id?: string | null,
  type?: AnalysisAssetType | null,
  src_file?: string | null,
};

export type DeleteUserMovementAnalysisAssetInput = {
  id: string,
};

export type CreateDeletedUserInput = {
  id?: string | null,
  type: DeletedUserType,
  email: string,
  original_user_id: string,
  tenant_id?: string | null,
  license_id?: string | null,
  username?: string | null,
  request_received_at?: string | null,
  identity_deleted_at?: string | null,
  braze_profile_deleted_at?: string | null,
  aws_pinpoint_profile_deleted_at?: string | null,
  crisp_profile_deleted_at?: string | null,
  intercom_profile_deleted_at?: string | null,
  completed_by?: string | null,
  completed_at?: string | null,
};

export enum DeletedUserType {
  DELETED_USER = "DELETED_USER",
}


export type ModelDeletedUserConditionInput = {
  type?: ModelDeletedUserTypeInput | null,
  email?: ModelStringInput | null,
  original_user_id?: ModelStringInput | null,
  tenant_id?: ModelStringInput | null,
  license_id?: ModelStringInput | null,
  username?: ModelStringInput | null,
  request_received_at?: ModelStringInput | null,
  identity_deleted_at?: ModelStringInput | null,
  braze_profile_deleted_at?: ModelStringInput | null,
  aws_pinpoint_profile_deleted_at?: ModelStringInput | null,
  crisp_profile_deleted_at?: ModelStringInput | null,
  intercom_profile_deleted_at?: ModelStringInput | null,
  completed_by?: ModelIDInput | null,
  completed_at?: ModelStringInput | null,
  and?: Array< ModelDeletedUserConditionInput | null > | null,
  or?: Array< ModelDeletedUserConditionInput | null > | null,
  not?: ModelDeletedUserConditionInput | null,
};

export type ModelDeletedUserTypeInput = {
  eq?: DeletedUserType | null,
  ne?: DeletedUserType | null,
};

export type DeletedUser = {
  __typename: "DeletedUser",
  id: string,
  type: DeletedUserType,
  email: string,
  original_user_id: string,
  tenant_id?: string | null,
  license_id?: string | null,
  username?: string | null,
  request_received_at?: string | null,
  identity_deleted_at?: string | null,
  braze_profile_deleted_at?: string | null,
  aws_pinpoint_profile_deleted_at?: string | null,
  crisp_profile_deleted_at?: string | null,
  intercom_profile_deleted_at?: string | null,
  completed_by?: string | null,
  completed_at?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDeletedUserInput = {
  id: string,
  type?: DeletedUserType | null,
  email?: string | null,
  original_user_id?: string | null,
  tenant_id?: string | null,
  license_id?: string | null,
  username?: string | null,
  request_received_at?: string | null,
  identity_deleted_at?: string | null,
  braze_profile_deleted_at?: string | null,
  aws_pinpoint_profile_deleted_at?: string | null,
  crisp_profile_deleted_at?: string | null,
  intercom_profile_deleted_at?: string | null,
  completed_by?: string | null,
  completed_at?: string | null,
};

export type DeleteDeletedUserInput = {
  id: string,
};

export type CreateManagementInput = {
  id: string,
  index?: number | null,
};

export type ModelManagementConditionInput = {
  index?: ModelIntInput | null,
  and?: Array< ModelManagementConditionInput | null > | null,
  or?: Array< ModelManagementConditionInput | null > | null,
  not?: ModelManagementConditionInput | null,
};

export type Management = {
  __typename: "Management",
  id: string,
  index?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateManagementInput = {
  id: string,
  index?: number | null,
};

export type DeleteManagementInput = {
  id: string,
};

export type CreateAuditLogInput = {
  id?: string | null,
  time: string,
  typeName?: string | null,
  fieldName?: string | null,
  arguments?: string | null,
  userSub: string,
  userId: string,
  clientId?: string | null,
  ip?: string | null,
  sourceDevice?: string | null,
  origin?: string | null,
};

export type ModelAuditLogConditionInput = {
  time?: ModelStringInput | null,
  typeName?: ModelStringInput | null,
  fieldName?: ModelStringInput | null,
  arguments?: ModelStringInput | null,
  userSub?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  ip?: ModelStringInput | null,
  sourceDevice?: ModelStringInput | null,
  origin?: ModelStringInput | null,
  and?: Array< ModelAuditLogConditionInput | null > | null,
  or?: Array< ModelAuditLogConditionInput | null > | null,
  not?: ModelAuditLogConditionInput | null,
};

export type AuditLog = {
  __typename: "AuditLog",
  id: string,
  time: string,
  typeName?: string | null,
  fieldName?: string | null,
  arguments?: string | null,
  userSub: string,
  userId: string,
  clientId?: string | null,
  ip?: string | null,
  sourceDevice?: string | null,
  origin?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateAuditLogInput = {
  id: string,
  time?: string | null,
  typeName?: string | null,
  fieldName?: string | null,
  arguments?: string | null,
  userSub?: string | null,
  userId?: string | null,
  clientId?: string | null,
  ip?: string | null,
  sourceDevice?: string | null,
  origin?: string | null,
};

export type DeleteAuditLogInput = {
  id: string,
};

export type CreateClientConfigurationInput = {
  id?: string | null,
  minimum_version: string,
  valid_from?: string | null,
  valid_to?: string | null,
  configured_by?: string | null,
};

export type ModelClientConfigurationConditionInput = {
  minimum_version?: ModelStringInput | null,
  valid_from?: ModelStringInput | null,
  valid_to?: ModelStringInput | null,
  configured_by?: ModelStringInput | null,
  and?: Array< ModelClientConfigurationConditionInput | null > | null,
  or?: Array< ModelClientConfigurationConditionInput | null > | null,
  not?: ModelClientConfigurationConditionInput | null,
};

export type ClientConfiguration = {
  __typename: "ClientConfiguration",
  id: string,
  minimum_version: string,
  valid_from?: string | null,
  valid_to?: string | null,
  configured_by?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateClientConfigurationInput = {
  id: string,
  minimum_version?: string | null,
  valid_from?: string | null,
  valid_to?: string | null,
  configured_by?: string | null,
};

export type DeleteClientConfigurationInput = {
  id: string,
};

export type SubmitRegisterFormInput = {
  firstname: string,
  lastname: string,
  company: string,
  email_address: string,
  token: string,
  warming?: boolean | null,
};

export enum OperatingSystem {
  IOS = "IOS",
  ANDROID = "ANDROID",
}


export type ModelWatchedItemFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  contentful_id?: ModelStringInput | null,
  contentful_version?: ModelStringInput | null,
  start_date?: ModelStringInput | null,
  end_date?: ModelStringInput | null,
  length_in_seconds?: ModelIntInput | null,
  closed_at_seconds?: ModelIntInput | null,
  type?: ModelWatchedItemTypeInput | null,
  tenant_id?: ModelIDInput | null,
  custom_user_id?: ModelIDInput | null,
  and?: Array< ModelWatchedItemFilterInput | null > | null,
  or?: Array< ModelWatchedItemFilterInput | null > | null,
  not?: ModelWatchedItemFilterInput | null,
};

export type ModelWatchedItemConnection = {
  __typename: "ModelWatchedItemConnection",
  items:  Array<WatchedItem | null >,
  nextToken?: string | null,
};

export type ModelTenantBookedMindPackageFilterInput = {
  id?: ModelIDInput | null,
  tenant_id?: ModelIDInput | null,
  package_id?: ModelStringInput | null,
  and?: Array< ModelTenantBookedMindPackageFilterInput | null > | null,
  or?: Array< ModelTenantBookedMindPackageFilterInput | null > | null,
  not?: ModelTenantBookedMindPackageFilterInput | null,
};

export type ModelTenantBookedMindPackageConnection = {
  __typename: "ModelTenantBookedMindPackageConnection",
  items:  Array<TenantBookedMindPackage | null >,
  nextToken?: string | null,
};

export type ModelTenantBookedTrainingModuleFilterInput = {
  id?: ModelIDInput | null,
  tenant_id?: ModelIDInput | null,
  module_id?: ModelStringInput | null,
  with_analyse?: ModelBooleanInput | null,
  and?: Array< ModelTenantBookedTrainingModuleFilterInput | null > | null,
  or?: Array< ModelTenantBookedTrainingModuleFilterInput | null > | null,
  not?: ModelTenantBookedTrainingModuleFilterInput | null,
};

export type ModelTenantBookedTrainingModuleConnection = {
  __typename: "ModelTenantBookedTrainingModuleConnection",
  items:  Array<TenantBookedTrainingModule | null >,
  nextToken?: string | null,
};

export type ModelJobTenantFilterInput = {
  id?: ModelIDInput | null,
  job_id?: ModelIDInput | null,
  tenant_id?: ModelIDInput | null,
  and?: Array< ModelJobTenantFilterInput | null > | null,
  or?: Array< ModelJobTenantFilterInput | null > | null,
  not?: ModelJobTenantFilterInput | null,
};

export type ModelAnamneseFilterInput = {
  id?: ModelIDInput | null,
  identity_sub?: ModelIDInput | null,
  anamnese_type?: ModelAnamneseTypeInput | null,
  processed_at?: ModelStringInput | null,
  gender?: ModelGenderInput | null,
  body_size?: ModelFloatInput | null,
  body_weight?: ModelFloatInput | null,
  birthdate?: ModelStringInput | null,
  is_performing_job?: ModelBooleanInput | null,
  is_pregnant?: ModelBooleanInput | null,
  has_contraindications?: ModelBooleanInput | null,
  pain_medication?: ModelPainMedicationInput | null,
  and?: Array< ModelAnamneseFilterInput | null > | null,
  or?: Array< ModelAnamneseFilterInput | null > | null,
  not?: ModelAnamneseFilterInput | null,
};

export type ModelAnamneseConnection = {
  __typename: "ModelAnamneseConnection",
  items:  Array<Anamnese | null >,
  nextToken?: string | null,
};

export type ModelMindAnamneseFilterInput = {
  id?: ModelIDInput | null,
  identity_sub?: ModelIDInput | null,
  processed_at?: ModelStringInput | null,
  has_contraindications?: ModelBooleanInput | null,
  and?: Array< ModelMindAnamneseFilterInput | null > | null,
  or?: Array< ModelMindAnamneseFilterInput | null > | null,
  not?: ModelMindAnamneseFilterInput | null,
};

export type ModelMindAnamneseConnection = {
  __typename: "ModelMindAnamneseConnection",
  items:  Array<MindAnamnese | null >,
  nextToken?: string | null,
};

export type ModelUserMindProgressFilterInput = {
  id?: ModelIDInput | null,
  identity_sub?: ModelIDInput | null,
  source_id?: ModelIDInput | null,
  source_type?: ModelMindTaskTypeInput | null,
  result_id?: ModelIDInput | null,
  completed_at?: ModelStringInput | null,
  started_at?: ModelStringInput | null,
  and?: Array< ModelUserMindProgressFilterInput | null > | null,
  or?: Array< ModelUserMindProgressFilterInput | null > | null,
  not?: ModelUserMindProgressFilterInput | null,
};

export type ModelUserMindProgressConnection = {
  __typename: "ModelUserMindProgressConnection",
  items:  Array<UserMindProgress | null >,
  nextToken?: string | null,
};

export type ModelUserMindEvaluationResultFilterInput = {
  id?: ModelIDInput | null,
  identity_sub?: ModelIDInput | null,
  mind_task_id?: ModelIDInput | null,
  state?: ModelMindStateInput | null,
  and?: Array< ModelUserMindEvaluationResultFilterInput | null > | null,
  or?: Array< ModelUserMindEvaluationResultFilterInput | null > | null,
  not?: ModelUserMindEvaluationResultFilterInput | null,
};

export type ModelUserMindEvaluationResultConnection = {
  __typename: "ModelUserMindEvaluationResultConnection",
  items:  Array<UserMindEvaluationResult | null >,
  nextToken?: string | null,
};

export type ModelUserMindPackageFeedbackFilterInput = {
  id?: ModelIDInput | null,
  identity_sub?: ModelIDInput | null,
  package_id?: ModelIDInput | null,
  rating?: ModelFloatInput | null,
  and?: Array< ModelUserMindPackageFeedbackFilterInput | null > | null,
  or?: Array< ModelUserMindPackageFeedbackFilterInput | null > | null,
  not?: ModelUserMindPackageFeedbackFilterInput | null,
};

export type ModelUserMindPackageFeedbackConnection = {
  __typename: "ModelUserMindPackageFeedbackConnection",
  items:  Array<UserMindPackageFeedback | null >,
  nextToken?: string | null,
};

export type ModelUserAssignmentFilterInput = {
  id?: ModelIDInput | null,
  identity_sub?: ModelIDInput | null,
  source_type?: ModelAssignmentSourceTypeInput | null,
  predecessor_id?: ModelIDInput | null,
  result_id?: ModelIDInput | null,
  source_id?: ModelIDInput | null,
  completed_at?: ModelStringInput | null,
  started_at?: ModelStringInput | null,
  due_at?: ModelStringInput | null,
  created_by_client_id?: ModelStringInput | null,
  repetitions_done?: ModelIntInput | null,
  deleted_at?: ModelStringInput | null,
  and?: Array< ModelUserAssignmentFilterInput | null > | null,
  or?: Array< ModelUserAssignmentFilterInput | null > | null,
  not?: ModelUserAssignmentFilterInput | null,
};

export type ModelUserMovementAssignmentFilterInput = {
  id?: ModelIDInput | null,
  identity_sub?: ModelIDInput | null,
  source_type?: ModelAssignmentSourceTypeInput | null,
  predecessor_id?: ModelIDInput | null,
  source_id?: ModelIDInput | null,
  completed_at?: ModelStringInput | null,
  started_at?: ModelStringInput | null,
  due_at?: ModelStringInput | null,
  deleted_at?: ModelStringInput | null,
  and?: Array< ModelUserMovementAssignmentFilterInput | null > | null,
  or?: Array< ModelUserMovementAssignmentFilterInput | null > | null,
  not?: ModelUserMovementAssignmentFilterInput | null,
};

export type ModelMovementTaskRatingFilterInput = {
  id?: ModelIDInput | null,
  identity_sub?: ModelIDInput | null,
  movement_task_id?: ModelIDInput | null,
  rating?: ModelFloatInput | null,
  and?: Array< ModelMovementTaskRatingFilterInput | null > | null,
  or?: Array< ModelMovementTaskRatingFilterInput | null > | null,
  not?: ModelMovementTaskRatingFilterInput | null,
};

export type ModelMovementTaskRatingConnection = {
  __typename: "ModelMovementTaskRatingConnection",
  items:  Array<MovementTaskRating | null >,
  nextToken?: string | null,
};

export type ModelLeadFilterInput = {
  id?: ModelIDInput | null,
  firstname?: ModelStringInput | null,
  lastname?: ModelStringInput | null,
  company?: ModelStringInput | null,
  email_address?: ModelStringInput | null,
  tenant_id?: ModelIDInput | null,
  code?: ModelStringInput | null,
  code_send_at?: ModelStringInput | null,
  code_used_at?: ModelStringInput | null,
  user_id?: ModelIDInput | null,
  consent_conditions_at?: ModelStringInput | null,
  and?: Array< ModelLeadFilterInput | null > | null,
  or?: Array< ModelLeadFilterInput | null > | null,
  not?: ModelLeadFilterInput | null,
};

export type ModelLeadConnection = {
  __typename: "ModelLeadConnection",
  items:  Array<Lead | null >,
  nextToken?: string | null,
};

export type ModelDeletedUserFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelDeletedUserTypeInput | null,
  email?: ModelStringInput | null,
  original_user_id?: ModelStringInput | null,
  tenant_id?: ModelStringInput | null,
  license_id?: ModelStringInput | null,
  username?: ModelStringInput | null,
  request_received_at?: ModelStringInput | null,
  identity_deleted_at?: ModelStringInput | null,
  braze_profile_deleted_at?: ModelStringInput | null,
  aws_pinpoint_profile_deleted_at?: ModelStringInput | null,
  crisp_profile_deleted_at?: ModelStringInput | null,
  intercom_profile_deleted_at?: ModelStringInput | null,
  completed_by?: ModelIDInput | null,
  completed_at?: ModelStringInput | null,
  and?: Array< ModelDeletedUserFilterInput | null > | null,
  or?: Array< ModelDeletedUserFilterInput | null > | null,
  not?: ModelDeletedUserFilterInput | null,
};

export type ModelDeletedUserConnection = {
  __typename: "ModelDeletedUserConnection",
  items:  Array<DeletedUser | null >,
  nextToken?: string | null,
};

export type ModelManagementFilterInput = {
  id?: ModelStringInput | null,
  index?: ModelIntInput | null,
  and?: Array< ModelManagementFilterInput | null > | null,
  or?: Array< ModelManagementFilterInput | null > | null,
  not?: ModelManagementFilterInput | null,
};

export type ModelManagementConnection = {
  __typename: "ModelManagementConnection",
  items:  Array<Management | null >,
  nextToken?: string | null,
};

export type ModelAuditLogFilterInput = {
  id?: ModelIDInput | null,
  time?: ModelStringInput | null,
  typeName?: ModelStringInput | null,
  fieldName?: ModelStringInput | null,
  arguments?: ModelStringInput | null,
  userSub?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  clientId?: ModelIDInput | null,
  ip?: ModelStringInput | null,
  sourceDevice?: ModelStringInput | null,
  origin?: ModelStringInput | null,
  and?: Array< ModelAuditLogFilterInput | null > | null,
  or?: Array< ModelAuditLogFilterInput | null > | null,
  not?: ModelAuditLogFilterInput | null,
};

export type ModelAuditLogConnection = {
  __typename: "ModelAuditLogConnection",
  items:  Array<AuditLog | null >,
  nextToken?: string | null,
};

export type ModelWatchedItemByCustomUserIdAndTypeCompositeKeyConditionInput = {
  eq?: ModelWatchedItemByCustomUserIdAndTypeCompositeKeyInput | null,
  le?: ModelWatchedItemByCustomUserIdAndTypeCompositeKeyInput | null,
  lt?: ModelWatchedItemByCustomUserIdAndTypeCompositeKeyInput | null,
  ge?: ModelWatchedItemByCustomUserIdAndTypeCompositeKeyInput | null,
  gt?: ModelWatchedItemByCustomUserIdAndTypeCompositeKeyInput | null,
  between?: Array< ModelWatchedItemByCustomUserIdAndTypeCompositeKeyInput | null > | null,
  beginsWith?: ModelWatchedItemByCustomUserIdAndTypeCompositeKeyInput | null,
};

export type ModelWatchedItemByCustomUserIdAndTypeCompositeKeyInput = {
  type?: WatchedItemType | null,
  start_date?: string | null,
};

export type ModelLicenseByTenantCompositeKeyConditionInput = {
  eq?: ModelLicenseByTenantCompositeKeyInput | null,
  le?: ModelLicenseByTenantCompositeKeyInput | null,
  lt?: ModelLicenseByTenantCompositeKeyInput | null,
  ge?: ModelLicenseByTenantCompositeKeyInput | null,
  gt?: ModelLicenseByTenantCompositeKeyInput | null,
  between?: Array< ModelLicenseByTenantCompositeKeyInput | null > | null,
  beginsWith?: ModelLicenseByTenantCompositeKeyInput | null,
};

export type ModelLicenseByTenantCompositeKeyInput = {
  validTo?: string | null,
  identity_sub?: string | null,
};

export type ModelUserMappingFilterInput = {
  custom_user_id?: ModelIDInput | null,
  identity_sub?: ModelIDInput | null,
  and?: Array< ModelUserMappingFilterInput | null > | null,
  or?: Array< ModelUserMappingFilterInput | null > | null,
  not?: ModelUserMappingFilterInput | null,
};

export type ModelUserMappingConnection = {
  __typename: "ModelUserMappingConnection",
  items:  Array<UserMapping | null >,
  nextToken?: string | null,
};

export type ModelJobFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelJobTypeInput | null,
  and?: Array< ModelJobFilterInput | null > | null,
  or?: Array< ModelJobFilterInput | null > | null,
  not?: ModelJobFilterInput | null,
};

export type ModelJobConnection = {
  __typename: "ModelJobConnection",
  items:  Array<Job | null >,
  nextToken?: string | null,
};

export type ModelClientConfigurationFilterInput = {
  id?: ModelIDInput | null,
  minimum_version?: ModelStringInput | null,
  valid_from?: ModelStringInput | null,
  valid_to?: ModelStringInput | null,
  configured_by?: ModelStringInput | null,
  and?: Array< ModelClientConfigurationFilterInput | null > | null,
  or?: Array< ModelClientConfigurationFilterInput | null > | null,
  not?: ModelClientConfigurationFilterInput | null,
};

export type ModelClientConfigurationConnection = {
  __typename: "ModelClientConfigurationConnection",
  items:  Array<ClientConfiguration | null >,
  nextToken?: string | null,
};

export type listTenantsWithLicensesQueryVariables = {
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  licensesLimit?: number | null,
  nextToken?: string | null,
};

export type listTenantsWithLicensesQuery = {
  listTenants?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      name: string,
      email_domains?: Array< string > | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        items:  Array< {
          __typename: "License",
          id: string,
        } | null >,
      } | null,
    } | null >,
  } | null,
};

export type listTenantsByNameQueryVariables = {
  type?: TenantType | null,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  licensesLimit?: number | null,
  nextToken?: string | null,
};

export type listTenantsByNameQuery = {
  listTenantsByName?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      name: string,
      createdAt: string,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        items:  Array< {
          __typename: "License",
          id: string,
        } | null >,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTenantWithLicensesQueryVariables = {
  id: string,
  limit?: number | null,
};

export type GetTenantWithLicensesQuery = {
  getTenant?:  {
    __typename: "Tenant",
    id: string,
    type: TenantType,
    name: string,
    email_domains?: Array< string > | null,
    licenses?:  {
      __typename: "ModelLicenseConnection",
      items:  Array< {
        __typename: "License",
        id: string,
        identity_sub?: string | null,
        validTo: string,
      } | null >,
    } | null,
  } | null,
};

export type ListTenantsByLicenseInvoiceQueryVariables = {
  invoice_id?: string | null,
  limit?: number | null,
};

export type ListTenantsByLicenseInvoiceQuery = {
  listLicensesByInvoice?:  {
    __typename: "ModelLicenseConnection",
    items:  Array< {
      __typename: "License",
      tenant?:  {
        __typename: "Tenant",
        id: string,
        type: TenantType,
        name: string,
        createdAt: string,
        licenses?:  {
          __typename: "ModelLicenseConnection",
          items:  Array< {
            __typename: "License",
            id: string,
          } | null >,
        } | null,
      } | null,
    } | null >,
  } | null,
};

export type CreateLicensesBulkWithoutInvoicesMutationVariables = {
  validTo?: string | null,
  numberOfLicenses?: number | null,
  tenantId?: string | null,
  origin: LicenseOrigin,
};

export type CreateLicensesBulkWithoutInvoicesMutation = {
  createLicensesBulk?: string | null,
};

export type getTenantWithLicensesAndJobsQueryVariables = {
  id: string,
  limit?: number | null,
};

export type getTenantWithLicensesAndJobsQuery = {
  getTenant?:  {
    __typename: "Tenant",
    id: string,
    type: TenantType,
    name: string,
    email_domains?: Array< string > | null,
    category?: TenantCategory | null,
    jobs?:  {
      __typename: "ModelJobTenantConnection",
      items:  Array< {
        __typename: "JobTenant",
        id: string,
        job:  {
          __typename: "Job",
          id: string,
          name: string,
        },
      } | null >,
    } | null,
    licenses?:  {
      __typename: "ModelLicenseConnection",
      items:  Array< {
        __typename: "License",
        id: string,
        identity_sub?: string | null,
        validTo: string,
        invoice_id?: string | null,
        origin?: LicenseOrigin | null,
      } | null >,
    } | null,
  } | null,
};

export type getJobWithTenantsQueryVariables = {
  id: string,
};

export type getJobWithTenantsQuery = {
  getJob?:  {
    __typename: "Job",
    id: string,
    name: string,
    type: JobType,
    tenants?:  {
      __typename: "ModelJobTenantConnection",
      items:  Array< {
        __typename: "JobTenant",
        tenant:  {
          __typename: "Tenant",
          id: string,
          name: string,
          licenses?:  {
            __typename: "ModelLicenseConnection",
            items:  Array< {
              __typename: "License",
              id: string,
            } | null >,
          } | null,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type movementAnalysisResultByUserWithAssetsQueryVariables = {
  identity_sub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserMovementAnalysisResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type movementAnalysisResultByUserWithAssetsQuery = {
  movementAnalysisResultByUser?:  {
    __typename: "ModelUserMovementAnalysisResultConnection",
    items:  Array< {
      __typename: "UserMovementAnalysisResult",
      id: string,
      identity_sub: string,
      followup?: string | null,
      internal_comment?: string | null,
      user_assignments?:  {
        __typename: "ModelUserAssignmentConnection",
        items:  Array< {
          __typename: "UserAssignment",
          source_id: string,
          predecessor_assignment?:  {
            __typename: "UserAssignment",
            source_type: AssignmentSourceType,
            source_id: string,
            analysis_assets?:  {
              __typename: "ModelUserMovementAnalysisAssetConnection",
              items:  Array< {
                __typename: "UserMovementAnalysisAsset",
                src_file?: string | null,
              } | null >,
            } | null,
          } | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      evaluation_score_total?: number | null,
      user_screenshot?: string | null,
      evaluation_measurements?: string | null,
      feedback_texts?: string | null,
      status?: AnalysisResultStatus | null,
      approved_at?: string | null,
      approved_by?: string | null,
      draft_approved_at?: string | null,
      draft_approved_by?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type movementAnalysisAssetByUserWithSuccessorQueryVariables = {
  identity_sub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserMovementAnalysisAssetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type movementAnalysisAssetByUserWithSuccessorQuery = {
  movementAnalysisAssetByUser?:  {
    __typename: "ModelUserMovementAnalysisAssetConnection",
    items:  Array< {
      __typename: "UserMovementAnalysisAsset",
      id: string,
      identity_sub: string,
      user_assignment_id: string,
      user_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        successor_assignment?:  {
          __typename: "ModelUserAssignmentConnection",
          items:  Array< {
            __typename: "UserAssignment",
            id: string,
            result_id?: string | null,
            result?:  {
              __typename: "UserMovementAnalysisResult",
              id: string,
              identity_sub: string,
              followup?: string | null,
              internal_comment?: string | null,
              evaluation_score_total?: number | null,
              user_screenshot?: string | null,
              evaluation_measurements?: string | null,
              feedback_texts?: string | null,
              status?: AnalysisResultStatus | null,
              approved_at?: string | null,
              approved_by?: string | null,
              createdAt: string,
              updatedAt: string,
            } | null,
          } | null >,
        } | null,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      type?: AnalysisAssetType | null,
      src_file?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type listUserMovementAnalysisAssetsWithSuccessorQueryVariables = {
  filter?: ModelUserMovementAnalysisAssetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type listUserMovementAnalysisAssetsWithSuccessorQuery = {
  listUserMovementAnalysisAssets?:  {
    __typename: "ModelUserMovementAnalysisAssetConnection",
    items:  Array< {
      __typename: "UserMovementAnalysisAsset",
      id: string,
      identity_sub: string,
      user_assignment_id: string,
      user_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        successor_assignment?:  {
          __typename: "ModelUserAssignmentConnection",
          items:  Array< {
            __typename: "UserAssignment",
            id: string,
            result_id?: string | null,
            result?:  {
              __typename: "UserMovementAnalysisResult",
              id: string,
              approved_at?: string | null,
              approved_by?: string | null,
              draft_approved_by?: string | null,
              draft_approved_at?: string | null,
              status?: AnalysisResultStatus | null,
              identity_sub: string,
              createdAt: string,
            } | null,
          } | null >,
        } | null,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      type?: AnalysisAssetType | null,
      src_file?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type listRegistrationCodesByTenantUsedAtQueryVariables = {
  tenant_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegistrationCodeFilterInput | null,
  nextToken?: string | null,
};

export type listRegistrationCodesByTenantUsedAtQuery = {
  listRegistrationCodesByTenantUsedAt?:  {
    __typename: "ModelRegistrationCodeConnection",
    nextToken?: string | null,
    items:  Array< {
      __typename: "RegistrationCode",
      code: string,
      used_at?: string | null,
      createdAt: string,
      license_id?: string | null,
    } | null >,
  } | null,
};

export type listRegistrationFreeCodesQueryVariables = {
  tenant_id?: string | null,
  nextToken?: string | null,
  filter?: ModelRegistrationCodeFilterInput | null,
};

export type listRegistrationFreeCodesQuery = {
  listRegistrationCodesByTenant?:  {
    __typename: "ModelRegistrationCodeConnection",
    nextToken?: string | null,
    items:  Array< {
      __typename: "RegistrationCode",
      id: string,
      code: string,
      valid_to: string,
      license_id?: string | null,
      createdAt: string,
      reserved_at?: string | null,
    } | null >,
  } | null,
};

export type ListTenantsNameAndIdQueryVariables = {
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenantsNameAndIdQuery = {
  listTenants?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      name: string,
      id: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListLicensesTestQueryVariables = {
  type?: LicenseType | null,
  validTo?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLicenseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLicensesTestQuery = {
  listLicensesByValidTo?:  {
    __typename: "ModelLicenseConnection",
    items:  Array< {
      __typename: "License",
      id: string,
      type: LicenseType,
      tenant_id: string,
      identity_sub?: string | null,
      user?: string | null,
      validTo: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        type: TenantType,
        category?: TenantCategory | null,
        name: string,
        createdAt: string,
      } | null,
      createdAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ChangeEmailExternalProvidersMutationVariables = {
  user_id: string,
  new_email: string,
};

export type ChangeEmailExternalProvidersMutation = {
  changeEmailExternalProviders?: string | null,
};

export type ChangeTenantExternalProvidersMutationVariables = {
  user_id: string,
  new_tenant: ChangeTenantInput,
  old_tenant: ChangeTenantInput,
};

export type ChangeTenantExternalProvidersMutation = {
  changeTenantExternalProviders?: string | null,
};

export type SendRegistrationCodeMailMutationVariables = {
  payload?: SendRegistrationCodeMailInput | null,
};

export type SendRegistrationCodeMailMutation = {
  sendRegistrationCodeMail?: string | null,
};

export type CreateLicensesBulkMutationVariables = {
  validTo?: string | null,
  numberOfLicenses?: number | null,
  tenantId?: string | null,
  invoiceId?: string | null,
  origin?: LicenseOrigin | null,
};

export type CreateLicensesBulkMutation = {
  createLicensesBulk?: string | null,
};

export type CreateRegistrationCodesBulkMutationVariables = {
  validTo?: string | null,
  numberOfCodes?: number | null,
  tenantId?: string | null,
};

export type CreateRegistrationCodesBulkMutation = {
  createRegistrationCodesBulk?: string | null,
};

export type DisableUserMutation = {
  disableUser?: string | null,
};

export type DeleteBrazeUserMutationVariables = {
  externalId?: string | null,
};

export type DeleteBrazeUserMutation = {
  deleteBrazeUser?: string | null,
};

export type SyncTenantJobsMutationVariables = {
  jobIds: Array< string >,
  tenantId: string,
};

export type SyncTenantJobsMutation = {
  syncTenantJobs?: string | null,
};

export type SyncTenantUsersMutationVariables = {
  tenantId: string,
};

export type SyncTenantUsersMutation = {
  syncTenantUsers?: string | null,
};

export type SendMailMutation = {
  sendMail?: string | null,
};

export type MapCognitoUsersMutation = {
  mapCognitoUsers?: string | null,
};

export type CreateTenantWithCodeMutationVariables = {
  name?: string | null,
  category?: TenantCategory | null,
};

export type CreateTenantWithCodeMutation = {
  createTenantWithCode?: string | null,
};

export type VerifyCognitoUserMutation = {
  verifyCognitoUser?:  {
    __typename: "VerifyCognitoUserResult",
    success: boolean,
    message: string,
  } | null,
};

export type CreateUserMappingMutationVariables = {
  input: CreateUserMappingInput,
  condition?: ModelUserMappingConditionInput | null,
};

export type CreateUserMappingMutation = {
  createUserMapping?:  {
    __typename: "UserMapping",
    id: string,
    custom_user_id: string,
    identity_sub: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMappingMutationVariables = {
  input: UpdateUserMappingInput,
  condition?: ModelUserMappingConditionInput | null,
};

export type UpdateUserMappingMutation = {
  updateUserMapping?:  {
    __typename: "UserMapping",
    id: string,
    custom_user_id: string,
    identity_sub: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMappingMutationVariables = {
  input: DeleteUserMappingInput,
  condition?: ModelUserMappingConditionInput | null,
};

export type DeleteUserMappingMutation = {
  deleteUserMapping?:  {
    __typename: "UserMapping",
    id: string,
    custom_user_id: string,
    identity_sub: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateWatchedItemMutationVariables = {
  input: CreateWatchedItemInput,
  condition?: ModelWatchedItemConditionInput | null,
};

export type CreateWatchedItemMutation = {
  createWatchedItem?:  {
    __typename: "WatchedItem",
    id: string,
    name: string,
    contentful_id: string,
    contentful_version: string,
    start_date: string,
    end_date?: string | null,
    length_in_seconds: number,
    closed_at_seconds?: number | null,
    type: WatchedItemType,
    tenant_id: string,
    custom_user_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateWatchedItemMutationVariables = {
  input: UpdateWatchedItemInput,
  condition?: ModelWatchedItemConditionInput | null,
};

export type UpdateWatchedItemMutation = {
  updateWatchedItem?:  {
    __typename: "WatchedItem",
    id: string,
    name: string,
    contentful_id: string,
    contentful_version: string,
    start_date: string,
    end_date?: string | null,
    length_in_seconds: number,
    closed_at_seconds?: number | null,
    type: WatchedItemType,
    tenant_id: string,
    custom_user_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteWatchedItemMutationVariables = {
  input: DeleteWatchedItemInput,
  condition?: ModelWatchedItemConditionInput | null,
};

export type DeleteWatchedItemMutation = {
  deleteWatchedItem?:  {
    __typename: "WatchedItem",
    id: string,
    name: string,
    contentful_id: string,
    contentful_version: string,
    start_date: string,
    end_date?: string | null,
    length_in_seconds: number,
    closed_at_seconds?: number | null,
    type: WatchedItemType,
    tenant_id: string,
    custom_user_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTenantMutationVariables = {
  input: CreateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type CreateTenantMutation = {
  createTenant?:  {
    __typename: "Tenant",
    id: string,
    type: TenantType,
    category?: TenantCategory | null,
    name: string,
    email_domains?: Array< string > | null,
    code?: string | null,
    licenses?:  {
      __typename: "ModelLicenseConnection",
      items:  Array< {
        __typename: "License",
        id: string,
        type: LicenseType,
        tenant_id: string,
        invoice_id?: string | null,
        origin?: LicenseOrigin | null,
        identity_sub?: string | null,
        user?: string | null,
        validTo: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    jobs?:  {
      __typename: "ModelJobTenantConnection",
      items:  Array< {
        __typename: "JobTenant",
        id: string,
        job_id: string,
        tenant_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTenantMutationVariables = {
  input: UpdateTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type UpdateTenantMutation = {
  updateTenant?:  {
    __typename: "Tenant",
    id: string,
    type: TenantType,
    category?: TenantCategory | null,
    name: string,
    email_domains?: Array< string > | null,
    code?: string | null,
    licenses?:  {
      __typename: "ModelLicenseConnection",
      items:  Array< {
        __typename: "License",
        id: string,
        type: LicenseType,
        tenant_id: string,
        invoice_id?: string | null,
        origin?: LicenseOrigin | null,
        identity_sub?: string | null,
        user?: string | null,
        validTo: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    jobs?:  {
      __typename: "ModelJobTenantConnection",
      items:  Array< {
        __typename: "JobTenant",
        id: string,
        job_id: string,
        tenant_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTenantMutationVariables = {
  input: DeleteTenantInput,
  condition?: ModelTenantConditionInput | null,
};

export type DeleteTenantMutation = {
  deleteTenant?:  {
    __typename: "Tenant",
    id: string,
    type: TenantType,
    category?: TenantCategory | null,
    name: string,
    email_domains?: Array< string > | null,
    code?: string | null,
    licenses?:  {
      __typename: "ModelLicenseConnection",
      items:  Array< {
        __typename: "License",
        id: string,
        type: LicenseType,
        tenant_id: string,
        invoice_id?: string | null,
        origin?: LicenseOrigin | null,
        identity_sub?: string | null,
        user?: string | null,
        validTo: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    jobs?:  {
      __typename: "ModelJobTenantConnection",
      items:  Array< {
        __typename: "JobTenant",
        id: string,
        job_id: string,
        tenant_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTenantBookedMindPackageMutationVariables = {
  input: CreateTenantBookedMindPackageInput,
  condition?: ModelTenantBookedMindPackageConditionInput | null,
};

export type CreateTenantBookedMindPackageMutation = {
  createTenantBookedMindPackage?:  {
    __typename: "TenantBookedMindPackage",
    id: string,
    tenant_id: string,
    package_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTenantBookedMindPackageMutationVariables = {
  input: UpdateTenantBookedMindPackageInput,
  condition?: ModelTenantBookedMindPackageConditionInput | null,
};

export type UpdateTenantBookedMindPackageMutation = {
  updateTenantBookedMindPackage?:  {
    __typename: "TenantBookedMindPackage",
    id: string,
    tenant_id: string,
    package_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTenantBookedMindPackageMutationVariables = {
  input: DeleteTenantBookedMindPackageInput,
  condition?: ModelTenantBookedMindPackageConditionInput | null,
};

export type DeleteTenantBookedMindPackageMutation = {
  deleteTenantBookedMindPackage?:  {
    __typename: "TenantBookedMindPackage",
    id: string,
    tenant_id: string,
    package_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTenantBookedTrainingModuleMutationVariables = {
  input: CreateTenantBookedTrainingModuleInput,
  condition?: ModelTenantBookedTrainingModuleConditionInput | null,
};

export type CreateTenantBookedTrainingModuleMutation = {
  createTenantBookedTrainingModule?:  {
    __typename: "TenantBookedTrainingModule",
    id: string,
    tenant_id: string,
    module_id: string,
    with_analyse?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTenantBookedTrainingModuleMutationVariables = {
  input: UpdateTenantBookedTrainingModuleInput,
  condition?: ModelTenantBookedTrainingModuleConditionInput | null,
};

export type UpdateTenantBookedTrainingModuleMutation = {
  updateTenantBookedTrainingModule?:  {
    __typename: "TenantBookedTrainingModule",
    id: string,
    tenant_id: string,
    module_id: string,
    with_analyse?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTenantBookedTrainingModuleMutationVariables = {
  input: DeleteTenantBookedTrainingModuleInput,
  condition?: ModelTenantBookedTrainingModuleConditionInput | null,
};

export type DeleteTenantBookedTrainingModuleMutation = {
  deleteTenantBookedTrainingModule?:  {
    __typename: "TenantBookedTrainingModule",
    id: string,
    tenant_id: string,
    module_id: string,
    with_analyse?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLicenseMutationVariables = {
  input: CreateLicenseInput,
  condition?: ModelLicenseConditionInput | null,
};

export type CreateLicenseMutation = {
  createLicense?:  {
    __typename: "License",
    id: string,
    type: LicenseType,
    tenant_id: string,
    invoice_id?: string | null,
    origin?: LicenseOrigin | null,
    identity_sub?: string | null,
    user?: string | null,
    validTo: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLicenseMutationVariables = {
  input: UpdateLicenseInput,
  condition?: ModelLicenseConditionInput | null,
};

export type UpdateLicenseMutation = {
  updateLicense?:  {
    __typename: "License",
    id: string,
    type: LicenseType,
    tenant_id: string,
    invoice_id?: string | null,
    origin?: LicenseOrigin | null,
    identity_sub?: string | null,
    user?: string | null,
    validTo: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLicenseMutationVariables = {
  input: DeleteLicenseInput,
  condition?: ModelLicenseConditionInput | null,
};

export type DeleteLicenseMutation = {
  deleteLicense?:  {
    __typename: "License",
    id: string,
    type: LicenseType,
    tenant_id: string,
    invoice_id?: string | null,
    origin?: LicenseOrigin | null,
    identity_sub?: string | null,
    user?: string | null,
    validTo: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateRegistrationCodeMutationVariables = {
  input: CreateRegistrationCodeInput,
  condition?: ModelRegistrationCodeConditionInput | null,
};

export type CreateRegistrationCodeMutation = {
  createRegistrationCode?:  {
    __typename: "RegistrationCode",
    id: string,
    code: string,
    tenant_id: string,
    identity_sub?: string | null,
    license_id?: string | null,
    used_at?: string | null,
    valid_to: string,
    reserved_at?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRegistrationCodeMutationVariables = {
  input: UpdateRegistrationCodeInput,
  condition?: ModelRegistrationCodeConditionInput | null,
};

export type UpdateRegistrationCodeMutation = {
  updateRegistrationCode?:  {
    __typename: "RegistrationCode",
    id: string,
    code: string,
    tenant_id: string,
    identity_sub?: string | null,
    license_id?: string | null,
    used_at?: string | null,
    valid_to: string,
    reserved_at?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRegistrationCodeMutationVariables = {
  input: DeleteRegistrationCodeInput,
  condition?: ModelRegistrationCodeConditionInput | null,
};

export type DeleteRegistrationCodeMutation = {
  deleteRegistrationCode?:  {
    __typename: "RegistrationCode",
    id: string,
    code: string,
    tenant_id: string,
    identity_sub?: string | null,
    license_id?: string | null,
    used_at?: string | null,
    valid_to: string,
    reserved_at?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateJobTenantMutationVariables = {
  input: CreateJobTenantInput,
  condition?: ModelJobTenantConditionInput | null,
};

export type CreateJobTenantMutation = {
  createJobTenant?:  {
    __typename: "JobTenant",
    id: string,
    job_id: string,
    tenant_id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    job:  {
      __typename: "Job",
      id: string,
      name: string,
      type: JobType,
      tenants?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type UpdateJobTenantMutationVariables = {
  input: UpdateJobTenantInput,
  condition?: ModelJobTenantConditionInput | null,
};

export type UpdateJobTenantMutation = {
  updateJobTenant?:  {
    __typename: "JobTenant",
    id: string,
    job_id: string,
    tenant_id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    job:  {
      __typename: "Job",
      id: string,
      name: string,
      type: JobType,
      tenants?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type DeleteJobTenantMutationVariables = {
  input: DeleteJobTenantInput,
  condition?: ModelJobTenantConditionInput | null,
};

export type DeleteJobTenantMutation = {
  deleteJobTenant?:  {
    __typename: "JobTenant",
    id: string,
    job_id: string,
    tenant_id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    job:  {
      __typename: "Job",
      id: string,
      name: string,
      type: JobType,
      tenants?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type CreateJobMutationVariables = {
  input: CreateJobInput,
  condition?: ModelJobConditionInput | null,
};

export type CreateJobMutation = {
  createJob?:  {
    __typename: "Job",
    id: string,
    name: string,
    type: JobType,
    tenants?:  {
      __typename: "ModelJobTenantConnection",
      items:  Array< {
        __typename: "JobTenant",
        id: string,
        job_id: string,
        tenant_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateJobMutationVariables = {
  input: UpdateJobInput,
  condition?: ModelJobConditionInput | null,
};

export type UpdateJobMutation = {
  updateJob?:  {
    __typename: "Job",
    id: string,
    name: string,
    type: JobType,
    tenants?:  {
      __typename: "ModelJobTenantConnection",
      items:  Array< {
        __typename: "JobTenant",
        id: string,
        job_id: string,
        tenant_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteJobMutationVariables = {
  input: DeleteJobInput,
  condition?: ModelJobConditionInput | null,
};

export type DeleteJobMutation = {
  deleteJob?:  {
    __typename: "Job",
    id: string,
    name: string,
    type: JobType,
    tenants?:  {
      __typename: "ModelJobTenantConnection",
      items:  Array< {
        __typename: "JobTenant",
        id: string,
        job_id: string,
        tenant_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAnamneseMutationVariables = {
  input: CreateAnamneseInput,
  condition?: ModelAnamneseConditionInput | null,
};

export type CreateAnamneseMutation = {
  createAnamnese?:  {
    __typename: "Anamnese",
    id: string,
    identity_sub: string,
    anamnese_type: AnamneseType,
    processed_at?: string | null,
    gender: Gender,
    body_size?: number | null,
    body_weight?: number | null,
    birthdate?: string | null,
    is_performing_job?: boolean | null,
    is_pregnant?: boolean | null,
    has_contraindications?: boolean | null,
    pain_medication?: PainMedication | null,
    body_part_pain:  Array< {
      __typename: "BodyPartPain",
      body_part?: BodyPart | null,
      severity?: number | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAnamneseMutationVariables = {
  input: UpdateAnamneseInput,
  condition?: ModelAnamneseConditionInput | null,
};

export type UpdateAnamneseMutation = {
  updateAnamnese?:  {
    __typename: "Anamnese",
    id: string,
    identity_sub: string,
    anamnese_type: AnamneseType,
    processed_at?: string | null,
    gender: Gender,
    body_size?: number | null,
    body_weight?: number | null,
    birthdate?: string | null,
    is_performing_job?: boolean | null,
    is_pregnant?: boolean | null,
    has_contraindications?: boolean | null,
    pain_medication?: PainMedication | null,
    body_part_pain:  Array< {
      __typename: "BodyPartPain",
      body_part?: BodyPart | null,
      severity?: number | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAnamneseMutationVariables = {
  input: DeleteAnamneseInput,
  condition?: ModelAnamneseConditionInput | null,
};

export type DeleteAnamneseMutation = {
  deleteAnamnese?:  {
    __typename: "Anamnese",
    id: string,
    identity_sub: string,
    anamnese_type: AnamneseType,
    processed_at?: string | null,
    gender: Gender,
    body_size?: number | null,
    body_weight?: number | null,
    birthdate?: string | null,
    is_performing_job?: boolean | null,
    is_pregnant?: boolean | null,
    has_contraindications?: boolean | null,
    pain_medication?: PainMedication | null,
    body_part_pain:  Array< {
      __typename: "BodyPartPain",
      body_part?: BodyPart | null,
      severity?: number | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMindAnamneseMutationVariables = {
  input: CreateMindAnamneseInput,
  condition?: ModelMindAnamneseConditionInput | null,
};

export type CreateMindAnamneseMutation = {
  createMindAnamnese?:  {
    __typename: "MindAnamnese",
    id: string,
    identity_sub: string,
    processed_at?: string | null,
    has_contraindications?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMindAnamneseMutationVariables = {
  input: UpdateMindAnamneseInput,
  condition?: ModelMindAnamneseConditionInput | null,
};

export type UpdateMindAnamneseMutation = {
  updateMindAnamnese?:  {
    __typename: "MindAnamnese",
    id: string,
    identity_sub: string,
    processed_at?: string | null,
    has_contraindications?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMindAnamneseMutationVariables = {
  input: DeleteMindAnamneseInput,
  condition?: ModelMindAnamneseConditionInput | null,
};

export type DeleteMindAnamneseMutation = {
  deleteMindAnamnese?:  {
    __typename: "MindAnamnese",
    id: string,
    identity_sub: string,
    processed_at?: string | null,
    has_contraindications?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMindProgressMutationVariables = {
  input: CreateUserMindProgressInput,
  condition?: ModelUserMindProgressConditionInput | null,
};

export type CreateUserMindProgressMutation = {
  createUserMindProgress?:  {
    __typename: "UserMindProgress",
    id: string,
    identity_sub: string,
    source_id: string,
    source_type: MindTaskType,
    result_id?: string | null,
    result?:  {
      __typename: "UserMindEvaluationResult",
      id: string,
      identity_sub: string,
      mind_task_id: string,
      state: MindState,
      createdAt: string,
      updatedAt: string,
    } | null,
    completed_at?: string | null,
    started_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMindProgressMutationVariables = {
  input: UpdateUserMindProgressInput,
  condition?: ModelUserMindProgressConditionInput | null,
};

export type UpdateUserMindProgressMutation = {
  updateUserMindProgress?:  {
    __typename: "UserMindProgress",
    id: string,
    identity_sub: string,
    source_id: string,
    source_type: MindTaskType,
    result_id?: string | null,
    result?:  {
      __typename: "UserMindEvaluationResult",
      id: string,
      identity_sub: string,
      mind_task_id: string,
      state: MindState,
      createdAt: string,
      updatedAt: string,
    } | null,
    completed_at?: string | null,
    started_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMindProgressMutationVariables = {
  input: DeleteUserMindProgressInput,
  condition?: ModelUserMindProgressConditionInput | null,
};

export type DeleteUserMindProgressMutation = {
  deleteUserMindProgress?:  {
    __typename: "UserMindProgress",
    id: string,
    identity_sub: string,
    source_id: string,
    source_type: MindTaskType,
    result_id?: string | null,
    result?:  {
      __typename: "UserMindEvaluationResult",
      id: string,
      identity_sub: string,
      mind_task_id: string,
      state: MindState,
      createdAt: string,
      updatedAt: string,
    } | null,
    completed_at?: string | null,
    started_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMindEvaluationResultMutationVariables = {
  input: CreateUserMindEvaluationResultInput,
  condition?: ModelUserMindEvaluationResultConditionInput | null,
};

export type CreateUserMindEvaluationResultMutation = {
  createUserMindEvaluationResult?:  {
    __typename: "UserMindEvaluationResult",
    id: string,
    identity_sub: string,
    mind_task_id: string,
    state: MindState,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMindEvaluationResultMutationVariables = {
  input: UpdateUserMindEvaluationResultInput,
  condition?: ModelUserMindEvaluationResultConditionInput | null,
};

export type UpdateUserMindEvaluationResultMutation = {
  updateUserMindEvaluationResult?:  {
    __typename: "UserMindEvaluationResult",
    id: string,
    identity_sub: string,
    mind_task_id: string,
    state: MindState,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMindEvaluationResultMutationVariables = {
  input: DeleteUserMindEvaluationResultInput,
  condition?: ModelUserMindEvaluationResultConditionInput | null,
};

export type DeleteUserMindEvaluationResultMutation = {
  deleteUserMindEvaluationResult?:  {
    __typename: "UserMindEvaluationResult",
    id: string,
    identity_sub: string,
    mind_task_id: string,
    state: MindState,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMindPackageFeedbackMutationVariables = {
  input: CreateUserMindPackageFeedbackInput,
  condition?: ModelUserMindPackageFeedbackConditionInput | null,
};

export type CreateUserMindPackageFeedbackMutation = {
  createUserMindPackageFeedback?:  {
    __typename: "UserMindPackageFeedback",
    id: string,
    identity_sub: string,
    package_id: string,
    rating?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMindPackageFeedbackMutationVariables = {
  input: UpdateUserMindPackageFeedbackInput,
  condition?: ModelUserMindPackageFeedbackConditionInput | null,
};

export type UpdateUserMindPackageFeedbackMutation = {
  updateUserMindPackageFeedback?:  {
    __typename: "UserMindPackageFeedback",
    id: string,
    identity_sub: string,
    package_id: string,
    rating?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMindPackageFeedbackMutationVariables = {
  input: DeleteUserMindPackageFeedbackInput,
  condition?: ModelUserMindPackageFeedbackConditionInput | null,
};

export type DeleteUserMindPackageFeedbackMutation = {
  deleteUserMindPackageFeedback?:  {
    __typename: "UserMindPackageFeedback",
    id: string,
    identity_sub: string,
    package_id: string,
    rating?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserAssignmentMutationVariables = {
  input: CreateUserAssignmentInput,
  condition?: ModelUserAssignmentConditionInput | null,
};

export type CreateUserAssignmentMutation = {
  createUserAssignment?:  {
    __typename: "UserAssignment",
    id: string,
    successor_assignment?:  {
      __typename: "ModelUserAssignmentConnection",
      items:  Array< {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    identity_sub: string,
    source_type: AssignmentSourceType,
    predecessor_id?: string | null,
    predecessor_assignment?:  {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    result_id?: string | null,
    result?:  {
      __typename: "UserMovementAnalysisResult",
      id: string,
      identity_sub: string,
      followup?: string | null,
      internal_comment?: string | null,
      user_assignments?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      evaluation_score_total?: number | null,
      user_screenshot?: string | null,
      evaluation_measurements?: string | null,
      feedback_texts?: string | null,
      status?: AnalysisResultStatus | null,
      draft_approved_at?: string | null,
      draft_approved_by?: string | null,
      approved_at?: string | null,
      approved_by?: string | null,
      draft_at?: string | null,
      draft_by?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    analysis_assets?:  {
      __typename: "ModelUserMovementAnalysisAssetConnection",
      items:  Array< {
        __typename: "UserMovementAnalysisAsset",
        id: string,
        identity_sub: string,
        user_assignment_id: string,
        type?: AnalysisAssetType | null,
        src_file?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    source_id: string,
    completed_at?: string | null,
    started_at?: string | null,
    due_at?: string | null,
    created_by_client_id?: string | null,
    repetitions_done?: number | null,
    deleted_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserAssignmentMutationVariables = {
  input: UpdateUserAssignmentInput,
  condition?: ModelUserAssignmentConditionInput | null,
};

export type UpdateUserAssignmentMutation = {
  updateUserAssignment?:  {
    __typename: "UserAssignment",
    id: string,
    successor_assignment?:  {
      __typename: "ModelUserAssignmentConnection",
      items:  Array< {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    identity_sub: string,
    source_type: AssignmentSourceType,
    predecessor_id?: string | null,
    predecessor_assignment?:  {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    result_id?: string | null,
    result?:  {
      __typename: "UserMovementAnalysisResult",
      id: string,
      identity_sub: string,
      followup?: string | null,
      internal_comment?: string | null,
      user_assignments?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      evaluation_score_total?: number | null,
      user_screenshot?: string | null,
      evaluation_measurements?: string | null,
      feedback_texts?: string | null,
      status?: AnalysisResultStatus | null,
      draft_approved_at?: string | null,
      draft_approved_by?: string | null,
      approved_at?: string | null,
      approved_by?: string | null,
      draft_at?: string | null,
      draft_by?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    analysis_assets?:  {
      __typename: "ModelUserMovementAnalysisAssetConnection",
      items:  Array< {
        __typename: "UserMovementAnalysisAsset",
        id: string,
        identity_sub: string,
        user_assignment_id: string,
        type?: AnalysisAssetType | null,
        src_file?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    source_id: string,
    completed_at?: string | null,
    started_at?: string | null,
    due_at?: string | null,
    created_by_client_id?: string | null,
    repetitions_done?: number | null,
    deleted_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserAssignmentMutationVariables = {
  input: DeleteUserAssignmentInput,
  condition?: ModelUserAssignmentConditionInput | null,
};

export type DeleteUserAssignmentMutation = {
  deleteUserAssignment?:  {
    __typename: "UserAssignment",
    id: string,
    successor_assignment?:  {
      __typename: "ModelUserAssignmentConnection",
      items:  Array< {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    identity_sub: string,
    source_type: AssignmentSourceType,
    predecessor_id?: string | null,
    predecessor_assignment?:  {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    result_id?: string | null,
    result?:  {
      __typename: "UserMovementAnalysisResult",
      id: string,
      identity_sub: string,
      followup?: string | null,
      internal_comment?: string | null,
      user_assignments?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      evaluation_score_total?: number | null,
      user_screenshot?: string | null,
      evaluation_measurements?: string | null,
      feedback_texts?: string | null,
      status?: AnalysisResultStatus | null,
      draft_approved_at?: string | null,
      draft_approved_by?: string | null,
      approved_at?: string | null,
      approved_by?: string | null,
      draft_at?: string | null,
      draft_by?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    analysis_assets?:  {
      __typename: "ModelUserMovementAnalysisAssetConnection",
      items:  Array< {
        __typename: "UserMovementAnalysisAsset",
        id: string,
        identity_sub: string,
        user_assignment_id: string,
        type?: AnalysisAssetType | null,
        src_file?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    source_id: string,
    completed_at?: string | null,
    started_at?: string | null,
    due_at?: string | null,
    created_by_client_id?: string | null,
    repetitions_done?: number | null,
    deleted_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMovementAssignmentMutationVariables = {
  input: CreateUserMovementAssignmentInput,
  condition?: ModelUserMovementAssignmentConditionInput | null,
};

export type CreateUserMovementAssignmentMutation = {
  createUserMovementAssignment?:  {
    __typename: "UserMovementAssignment",
    id: string,
    successor_assignment?:  {
      __typename: "ModelUserMovementAssignmentConnection",
      items:  Array< {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    identity_sub: string,
    source_type: AssignmentSourceType,
    predecessor_id?: string | null,
    predecessor_assignment?:  {
      __typename: "UserMovementAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserMovementAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    source_id: string,
    completed_at?: string | null,
    started_at?: string | null,
    due_at?: string | null,
    deleted_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMovementAssignmentMutationVariables = {
  input: UpdateUserMovementAssignmentInput,
  condition?: ModelUserMovementAssignmentConditionInput | null,
};

export type UpdateUserMovementAssignmentMutation = {
  updateUserMovementAssignment?:  {
    __typename: "UserMovementAssignment",
    id: string,
    successor_assignment?:  {
      __typename: "ModelUserMovementAssignmentConnection",
      items:  Array< {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    identity_sub: string,
    source_type: AssignmentSourceType,
    predecessor_id?: string | null,
    predecessor_assignment?:  {
      __typename: "UserMovementAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserMovementAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    source_id: string,
    completed_at?: string | null,
    started_at?: string | null,
    due_at?: string | null,
    deleted_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMovementAssignmentMutationVariables = {
  input: DeleteUserMovementAssignmentInput,
  condition?: ModelUserMovementAssignmentConditionInput | null,
};

export type DeleteUserMovementAssignmentMutation = {
  deleteUserMovementAssignment?:  {
    __typename: "UserMovementAssignment",
    id: string,
    successor_assignment?:  {
      __typename: "ModelUserMovementAssignmentConnection",
      items:  Array< {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    identity_sub: string,
    source_type: AssignmentSourceType,
    predecessor_id?: string | null,
    predecessor_assignment?:  {
      __typename: "UserMovementAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserMovementAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    source_id: string,
    completed_at?: string | null,
    started_at?: string | null,
    due_at?: string | null,
    deleted_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateMovementTaskRatingMutationVariables = {
  input: CreateMovementTaskRatingInput,
  condition?: ModelMovementTaskRatingConditionInput | null,
};

export type CreateMovementTaskRatingMutation = {
  createMovementTaskRating?:  {
    __typename: "MovementTaskRating",
    id: string,
    identity_sub: string,
    movement_task_id: string,
    rating: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateMovementTaskRatingMutationVariables = {
  input: UpdateMovementTaskRatingInput,
  condition?: ModelMovementTaskRatingConditionInput | null,
};

export type UpdateMovementTaskRatingMutation = {
  updateMovementTaskRating?:  {
    __typename: "MovementTaskRating",
    id: string,
    identity_sub: string,
    movement_task_id: string,
    rating: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteMovementTaskRatingMutationVariables = {
  input: DeleteMovementTaskRatingInput,
  condition?: ModelMovementTaskRatingConditionInput | null,
};

export type DeleteMovementTaskRatingMutation = {
  deleteMovementTaskRating?:  {
    __typename: "MovementTaskRating",
    id: string,
    identity_sub: string,
    movement_task_id: string,
    rating: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMovementAnalysisResultMutationVariables = {
  input: CreateUserMovementAnalysisResultInput,
  condition?: ModelUserMovementAnalysisResultConditionInput | null,
};

export type CreateUserMovementAnalysisResultMutation = {
  createUserMovementAnalysisResult?:  {
    __typename: "UserMovementAnalysisResult",
    id: string,
    identity_sub: string,
    followup?: string | null,
    internal_comment?: string | null,
    user_assignments?:  {
      __typename: "ModelUserAssignmentConnection",
      items:  Array< {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    evaluation_score_total?: number | null,
    user_screenshot?: string | null,
    evaluation_measurements?: string | null,
    feedback_texts?: string | null,
    status?: AnalysisResultStatus | null,
    draft_approved_at?: string | null,
    draft_approved_by?: string | null,
    approved_at?: string | null,
    approved_by?: string | null,
    draft_at?: string | null,
    draft_by?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMovementAnalysisResultMutationVariables = {
  input: UpdateUserMovementAnalysisResultInput,
  condition?: ModelUserMovementAnalysisResultConditionInput | null,
};

export type UpdateUserMovementAnalysisResultMutation = {
  updateUserMovementAnalysisResult?:  {
    __typename: "UserMovementAnalysisResult",
    id: string,
    identity_sub: string,
    followup?: string | null,
    internal_comment?: string | null,
    user_assignments?:  {
      __typename: "ModelUserAssignmentConnection",
      items:  Array< {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    evaluation_score_total?: number | null,
    user_screenshot?: string | null,
    evaluation_measurements?: string | null,
    feedback_texts?: string | null,
    status?: AnalysisResultStatus | null,
    draft_approved_at?: string | null,
    draft_approved_by?: string | null,
    approved_at?: string | null,
    approved_by?: string | null,
    draft_at?: string | null,
    draft_by?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMovementAnalysisResultMutationVariables = {
  input: DeleteUserMovementAnalysisResultInput,
  condition?: ModelUserMovementAnalysisResultConditionInput | null,
};

export type DeleteUserMovementAnalysisResultMutation = {
  deleteUserMovementAnalysisResult?:  {
    __typename: "UserMovementAnalysisResult",
    id: string,
    identity_sub: string,
    followup?: string | null,
    internal_comment?: string | null,
    user_assignments?:  {
      __typename: "ModelUserAssignmentConnection",
      items:  Array< {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    evaluation_score_total?: number | null,
    user_screenshot?: string | null,
    evaluation_measurements?: string | null,
    feedback_texts?: string | null,
    status?: AnalysisResultStatus | null,
    draft_approved_at?: string | null,
    draft_approved_by?: string | null,
    approved_at?: string | null,
    approved_by?: string | null,
    draft_at?: string | null,
    draft_by?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateLeadMutationVariables = {
  input: CreateLeadInput,
  condition?: ModelLeadConditionInput | null,
};

export type CreateLeadMutation = {
  createLead?:  {
    __typename: "Lead",
    id: string,
    firstname: string,
    lastname: string,
    company: string,
    email_address: string,
    tenant_id?: string | null,
    code?: string | null,
    code_send_at?: string | null,
    code_used_at?: string | null,
    user_id?: string | null,
    consent_conditions_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateLeadMutationVariables = {
  input: UpdateLeadInput,
  condition?: ModelLeadConditionInput | null,
};

export type UpdateLeadMutation = {
  updateLead?:  {
    __typename: "Lead",
    id: string,
    firstname: string,
    lastname: string,
    company: string,
    email_address: string,
    tenant_id?: string | null,
    code?: string | null,
    code_send_at?: string | null,
    code_used_at?: string | null,
    user_id?: string | null,
    consent_conditions_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteLeadMutationVariables = {
  input: DeleteLeadInput,
  condition?: ModelLeadConditionInput | null,
};

export type DeleteLeadMutation = {
  deleteLead?:  {
    __typename: "Lead",
    id: string,
    firstname: string,
    lastname: string,
    company: string,
    email_address: string,
    tenant_id?: string | null,
    code?: string | null,
    code_send_at?: string | null,
    code_used_at?: string | null,
    user_id?: string | null,
    consent_conditions_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserMovementAnalysisAssetMutationVariables = {
  input: CreateUserMovementAnalysisAssetInput,
  condition?: ModelUserMovementAnalysisAssetConditionInput | null,
};

export type CreateUserMovementAnalysisAssetMutation = {
  createUserMovementAnalysisAsset?:  {
    __typename: "UserMovementAnalysisAsset",
    id: string,
    identity_sub: string,
    user_assignment_id: string,
    user_assignment?:  {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    type?: AnalysisAssetType | null,
    src_file?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMovementAnalysisAssetMutationVariables = {
  input: UpdateUserMovementAnalysisAssetInput,
  condition?: ModelUserMovementAnalysisAssetConditionInput | null,
};

export type UpdateUserMovementAnalysisAssetMutation = {
  updateUserMovementAnalysisAsset?:  {
    __typename: "UserMovementAnalysisAsset",
    id: string,
    identity_sub: string,
    user_assignment_id: string,
    user_assignment?:  {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    type?: AnalysisAssetType | null,
    src_file?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMovementAnalysisAssetMutationVariables = {
  input: DeleteUserMovementAnalysisAssetInput,
  condition?: ModelUserMovementAnalysisAssetConditionInput | null,
};

export type DeleteUserMovementAnalysisAssetMutation = {
  deleteUserMovementAnalysisAsset?:  {
    __typename: "UserMovementAnalysisAsset",
    id: string,
    identity_sub: string,
    user_assignment_id: string,
    user_assignment?:  {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    type?: AnalysisAssetType | null,
    src_file?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDeletedUserMutationVariables = {
  input: CreateDeletedUserInput,
  condition?: ModelDeletedUserConditionInput | null,
};

export type CreateDeletedUserMutation = {
  createDeletedUser?:  {
    __typename: "DeletedUser",
    id: string,
    type: DeletedUserType,
    email: string,
    original_user_id: string,
    tenant_id?: string | null,
    license_id?: string | null,
    username?: string | null,
    request_received_at?: string | null,
    identity_deleted_at?: string | null,
    braze_profile_deleted_at?: string | null,
    aws_pinpoint_profile_deleted_at?: string | null,
    crisp_profile_deleted_at?: string | null,
    intercom_profile_deleted_at?: string | null,
    completed_by?: string | null,
    completed_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDeletedUserMutationVariables = {
  input: UpdateDeletedUserInput,
  condition?: ModelDeletedUserConditionInput | null,
};

export type UpdateDeletedUserMutation = {
  updateDeletedUser?:  {
    __typename: "DeletedUser",
    id: string,
    type: DeletedUserType,
    email: string,
    original_user_id: string,
    tenant_id?: string | null,
    license_id?: string | null,
    username?: string | null,
    request_received_at?: string | null,
    identity_deleted_at?: string | null,
    braze_profile_deleted_at?: string | null,
    aws_pinpoint_profile_deleted_at?: string | null,
    crisp_profile_deleted_at?: string | null,
    intercom_profile_deleted_at?: string | null,
    completed_by?: string | null,
    completed_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDeletedUserMutationVariables = {
  input: DeleteDeletedUserInput,
  condition?: ModelDeletedUserConditionInput | null,
};

export type DeleteDeletedUserMutation = {
  deleteDeletedUser?:  {
    __typename: "DeletedUser",
    id: string,
    type: DeletedUserType,
    email: string,
    original_user_id: string,
    tenant_id?: string | null,
    license_id?: string | null,
    username?: string | null,
    request_received_at?: string | null,
    identity_deleted_at?: string | null,
    braze_profile_deleted_at?: string | null,
    aws_pinpoint_profile_deleted_at?: string | null,
    crisp_profile_deleted_at?: string | null,
    intercom_profile_deleted_at?: string | null,
    completed_by?: string | null,
    completed_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateManagementMutationVariables = {
  input: CreateManagementInput,
  condition?: ModelManagementConditionInput | null,
};

export type CreateManagementMutation = {
  createManagement?:  {
    __typename: "Management",
    id: string,
    index?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateManagementMutationVariables = {
  input: UpdateManagementInput,
  condition?: ModelManagementConditionInput | null,
};

export type UpdateManagementMutation = {
  updateManagement?:  {
    __typename: "Management",
    id: string,
    index?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteManagementMutationVariables = {
  input: DeleteManagementInput,
  condition?: ModelManagementConditionInput | null,
};

export type DeleteManagementMutation = {
  deleteManagement?:  {
    __typename: "Management",
    id: string,
    index?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAuditLogMutationVariables = {
  input: CreateAuditLogInput,
  condition?: ModelAuditLogConditionInput | null,
};

export type CreateAuditLogMutation = {
  createAuditLog?:  {
    __typename: "AuditLog",
    id: string,
    time: string,
    typeName?: string | null,
    fieldName?: string | null,
    arguments?: string | null,
    userSub: string,
    userId: string,
    clientId?: string | null,
    ip?: string | null,
    sourceDevice?: string | null,
    origin?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAuditLogMutationVariables = {
  input: UpdateAuditLogInput,
  condition?: ModelAuditLogConditionInput | null,
};

export type UpdateAuditLogMutation = {
  updateAuditLog?:  {
    __typename: "AuditLog",
    id: string,
    time: string,
    typeName?: string | null,
    fieldName?: string | null,
    arguments?: string | null,
    userSub: string,
    userId: string,
    clientId?: string | null,
    ip?: string | null,
    sourceDevice?: string | null,
    origin?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAuditLogMutationVariables = {
  input: DeleteAuditLogInput,
  condition?: ModelAuditLogConditionInput | null,
};

export type DeleteAuditLogMutation = {
  deleteAuditLog?:  {
    __typename: "AuditLog",
    id: string,
    time: string,
    typeName?: string | null,
    fieldName?: string | null,
    arguments?: string | null,
    userSub: string,
    userId: string,
    clientId?: string | null,
    ip?: string | null,
    sourceDevice?: string | null,
    origin?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClientConfigurationMutationVariables = {
  input: CreateClientConfigurationInput,
  condition?: ModelClientConfigurationConditionInput | null,
};

export type CreateClientConfigurationMutation = {
  createClientConfiguration?:  {
    __typename: "ClientConfiguration",
    id: string,
    minimum_version: string,
    valid_from?: string | null,
    valid_to?: string | null,
    configured_by?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateClientConfigurationMutationVariables = {
  input: UpdateClientConfigurationInput,
  condition?: ModelClientConfigurationConditionInput | null,
};

export type UpdateClientConfigurationMutation = {
  updateClientConfiguration?:  {
    __typename: "ClientConfiguration",
    id: string,
    minimum_version: string,
    valid_from?: string | null,
    valid_to?: string | null,
    configured_by?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteClientConfigurationMutationVariables = {
  input: DeleteClientConfigurationInput,
  condition?: ModelClientConfigurationConditionInput | null,
};

export type DeleteClientConfigurationMutation = {
  deleteClientConfiguration?:  {
    __typename: "ClientConfiguration",
    id: string,
    minimum_version: string,
    valid_from?: string | null,
    valid_to?: string | null,
    configured_by?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type SubmitRegisterFormMutationVariables = {
  payload: SubmitRegisterFormInput,
};

export type SubmitRegisterFormMutation = {
  submitRegisterForm?: string | null,
};

export type GetUserMovementAssignmentsQueryVariables = {
  userId?: string | null,
  userSub?: string | null,
  locale?: string | null,
  isAdminApp?: boolean | null,
};

export type GetUserMovementAssignmentsQuery = {
  getUserMovementAssignments?: string | null,
};

export type GetUserMindProgressFnQueryVariables = {
  userId?: string | null,
  userSub?: string | null,
  locale?: string | null,
};

export type GetUserMindProgressFnQuery = {
  getUserMindProgressFn?: string | null,
};

export type PresignedFileUrlQueryVariables = {
  path: string,
};

export type PresignedFileUrlQuery = {
  presignedFileUrl?: string | null,
};

export type GetIntercomHashQueryVariables = {
  operatingSystem: OperatingSystem,
};

export type GetIntercomHashQuery = {
  getIntercomHash?: string | null,
};

export type GetWatchedItemQueryVariables = {
  id: string,
};

export type GetWatchedItemQuery = {
  getWatchedItem?:  {
    __typename: "WatchedItem",
    id: string,
    name: string,
    contentful_id: string,
    contentful_version: string,
    start_date: string,
    end_date?: string | null,
    length_in_seconds: number,
    closed_at_seconds?: number | null,
    type: WatchedItemType,
    tenant_id: string,
    custom_user_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListWatchedItemsQueryVariables = {
  filter?: ModelWatchedItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWatchedItemsQuery = {
  listWatchedItems?:  {
    __typename: "ModelWatchedItemConnection",
    items:  Array< {
      __typename: "WatchedItem",
      id: string,
      name: string,
      contentful_id: string,
      contentful_version: string,
      start_date: string,
      end_date?: string | null,
      length_in_seconds: number,
      closed_at_seconds?: number | null,
      type: WatchedItemType,
      tenant_id: string,
      custom_user_id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTenantQueryVariables = {
  id: string,
};

export type GetTenantQuery = {
  getTenant?:  {
    __typename: "Tenant",
    id: string,
    type: TenantType,
    category?: TenantCategory | null,
    name: string,
    email_domains?: Array< string > | null,
    code?: string | null,
    licenses?:  {
      __typename: "ModelLicenseConnection",
      items:  Array< {
        __typename: "License",
        id: string,
        type: LicenseType,
        tenant_id: string,
        invoice_id?: string | null,
        origin?: LicenseOrigin | null,
        identity_sub?: string | null,
        user?: string | null,
        validTo: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    jobs?:  {
      __typename: "ModelJobTenantConnection",
      items:  Array< {
        __typename: "JobTenant",
        id: string,
        job_id: string,
        tenant_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTenantsQueryVariables = {
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenantsQuery = {
  listTenants?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTenantBookedMindPackageQueryVariables = {
  id: string,
};

export type GetTenantBookedMindPackageQuery = {
  getTenantBookedMindPackage?:  {
    __typename: "TenantBookedMindPackage",
    id: string,
    tenant_id: string,
    package_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTenantBookedMindPackagesQueryVariables = {
  filter?: ModelTenantBookedMindPackageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenantBookedMindPackagesQuery = {
  listTenantBookedMindPackages?:  {
    __typename: "ModelTenantBookedMindPackageConnection",
    items:  Array< {
      __typename: "TenantBookedMindPackage",
      id: string,
      tenant_id: string,
      package_id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTenantBookedTrainingModuleQueryVariables = {
  id: string,
};

export type GetTenantBookedTrainingModuleQuery = {
  getTenantBookedTrainingModule?:  {
    __typename: "TenantBookedTrainingModule",
    id: string,
    tenant_id: string,
    module_id: string,
    with_analyse?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTenantBookedTrainingModulesQueryVariables = {
  filter?: ModelTenantBookedTrainingModuleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenantBookedTrainingModulesQuery = {
  listTenantBookedTrainingModules?:  {
    __typename: "ModelTenantBookedTrainingModuleConnection",
    items:  Array< {
      __typename: "TenantBookedTrainingModule",
      id: string,
      tenant_id: string,
      module_id: string,
      with_analyse?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLicenseQueryVariables = {
  id: string,
};

export type GetLicenseQuery = {
  getLicense?:  {
    __typename: "License",
    id: string,
    type: LicenseType,
    tenant_id: string,
    invoice_id?: string | null,
    origin?: LicenseOrigin | null,
    identity_sub?: string | null,
    user?: string | null,
    validTo: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLicensesQueryVariables = {
  filter?: ModelLicenseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLicensesQuery = {
  listLicenses?:  {
    __typename: "ModelLicenseConnection",
    items:  Array< {
      __typename: "License",
      id: string,
      type: LicenseType,
      tenant_id: string,
      invoice_id?: string | null,
      origin?: LicenseOrigin | null,
      identity_sub?: string | null,
      user?: string | null,
      validTo: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        type: TenantType,
        category?: TenantCategory | null,
        name: string,
        email_domains?: Array< string > | null,
        code?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetRegistrationCodeQueryVariables = {
  id: string,
};

export type GetRegistrationCodeQuery = {
  getRegistrationCode?:  {
    __typename: "RegistrationCode",
    id: string,
    code: string,
    tenant_id: string,
    identity_sub?: string | null,
    license_id?: string | null,
    used_at?: string | null,
    valid_to: string,
    reserved_at?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRegistrationCodesQueryVariables = {
  filter?: ModelRegistrationCodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRegistrationCodesQuery = {
  listRegistrationCodes?:  {
    __typename: "ModelRegistrationCodeConnection",
    items:  Array< {
      __typename: "RegistrationCode",
      id: string,
      code: string,
      tenant_id: string,
      identity_sub?: string | null,
      license_id?: string | null,
      used_at?: string | null,
      valid_to: string,
      reserved_at?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        type: TenantType,
        category?: TenantCategory | null,
        name: string,
        email_domains?: Array< string > | null,
        code?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJobTenantQueryVariables = {
  id: string,
};

export type GetJobTenantQuery = {
  getJobTenant?:  {
    __typename: "JobTenant",
    id: string,
    job_id: string,
    tenant_id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    job:  {
      __typename: "Job",
      id: string,
      name: string,
      type: JobType,
      tenants?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type ListJobTenantsQueryVariables = {
  filter?: ModelJobTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobTenantsQuery = {
  listJobTenants?:  {
    __typename: "ModelJobTenantConnection",
    items:  Array< {
      __typename: "JobTenant",
      id: string,
      job_id: string,
      tenant_id: string,
      tenant:  {
        __typename: "Tenant",
        id: string,
        type: TenantType,
        category?: TenantCategory | null,
        name: string,
        email_domains?: Array< string > | null,
        code?: string | null,
        createdAt: string,
        updatedAt: string,
      },
      createdAt: string,
      updatedAt: string,
      job:  {
        __typename: "Job",
        id: string,
        name: string,
        type: JobType,
        createdAt: string,
        updatedAt: string,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAnamneseQueryVariables = {
  id: string,
};

export type GetAnamneseQuery = {
  getAnamnese?:  {
    __typename: "Anamnese",
    id: string,
    identity_sub: string,
    anamnese_type: AnamneseType,
    processed_at?: string | null,
    gender: Gender,
    body_size?: number | null,
    body_weight?: number | null,
    birthdate?: string | null,
    is_performing_job?: boolean | null,
    is_pregnant?: boolean | null,
    has_contraindications?: boolean | null,
    pain_medication?: PainMedication | null,
    body_part_pain:  Array< {
      __typename: "BodyPartPain",
      body_part?: BodyPart | null,
      severity?: number | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAnamnesesQueryVariables = {
  filter?: ModelAnamneseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnamnesesQuery = {
  listAnamneses?:  {
    __typename: "ModelAnamneseConnection",
    items:  Array< {
      __typename: "Anamnese",
      id: string,
      identity_sub: string,
      anamnese_type: AnamneseType,
      processed_at?: string | null,
      gender: Gender,
      body_size?: number | null,
      body_weight?: number | null,
      birthdate?: string | null,
      is_performing_job?: boolean | null,
      is_pregnant?: boolean | null,
      has_contraindications?: boolean | null,
      pain_medication?: PainMedication | null,
      body_part_pain:  Array< {
        __typename: "BodyPartPain",
        body_part?: BodyPart | null,
        severity?: number | null,
      } | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMindAnamneseQueryVariables = {
  id: string,
};

export type GetMindAnamneseQuery = {
  getMindAnamnese?:  {
    __typename: "MindAnamnese",
    id: string,
    identity_sub: string,
    processed_at?: string | null,
    has_contraindications?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMindAnamnesesQueryVariables = {
  filter?: ModelMindAnamneseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMindAnamnesesQuery = {
  listMindAnamneses?:  {
    __typename: "ModelMindAnamneseConnection",
    items:  Array< {
      __typename: "MindAnamnese",
      id: string,
      identity_sub: string,
      processed_at?: string | null,
      has_contraindications?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserMindProgressQueryVariables = {
  id: string,
};

export type GetUserMindProgressQuery = {
  getUserMindProgress?:  {
    __typename: "UserMindProgress",
    id: string,
    identity_sub: string,
    source_id: string,
    source_type: MindTaskType,
    result_id?: string | null,
    result?:  {
      __typename: "UserMindEvaluationResult",
      id: string,
      identity_sub: string,
      mind_task_id: string,
      state: MindState,
      createdAt: string,
      updatedAt: string,
    } | null,
    completed_at?: string | null,
    started_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserMindProgresssQueryVariables = {
  filter?: ModelUserMindProgressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserMindProgresssQuery = {
  listUserMindProgresss?:  {
    __typename: "ModelUserMindProgressConnection",
    items:  Array< {
      __typename: "UserMindProgress",
      id: string,
      identity_sub: string,
      source_id: string,
      source_type: MindTaskType,
      result_id?: string | null,
      result?:  {
        __typename: "UserMindEvaluationResult",
        id: string,
        identity_sub: string,
        mind_task_id: string,
        state: MindState,
        createdAt: string,
        updatedAt: string,
      } | null,
      completed_at?: string | null,
      started_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserMindEvaluationResultQueryVariables = {
  id: string,
};

export type GetUserMindEvaluationResultQuery = {
  getUserMindEvaluationResult?:  {
    __typename: "UserMindEvaluationResult",
    id: string,
    identity_sub: string,
    mind_task_id: string,
    state: MindState,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserMindEvaluationResultsQueryVariables = {
  filter?: ModelUserMindEvaluationResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserMindEvaluationResultsQuery = {
  listUserMindEvaluationResults?:  {
    __typename: "ModelUserMindEvaluationResultConnection",
    items:  Array< {
      __typename: "UserMindEvaluationResult",
      id: string,
      identity_sub: string,
      mind_task_id: string,
      state: MindState,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserMindPackageFeedbackQueryVariables = {
  id: string,
};

export type GetUserMindPackageFeedbackQuery = {
  getUserMindPackageFeedback?:  {
    __typename: "UserMindPackageFeedback",
    id: string,
    identity_sub: string,
    package_id: string,
    rating?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserMindPackageFeedbacksQueryVariables = {
  filter?: ModelUserMindPackageFeedbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserMindPackageFeedbacksQuery = {
  listUserMindPackageFeedbacks?:  {
    __typename: "ModelUserMindPackageFeedbackConnection",
    items:  Array< {
      __typename: "UserMindPackageFeedback",
      id: string,
      identity_sub: string,
      package_id: string,
      rating?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserAssignmentQueryVariables = {
  id: string,
};

export type GetUserAssignmentQuery = {
  getUserAssignment?:  {
    __typename: "UserAssignment",
    id: string,
    successor_assignment?:  {
      __typename: "ModelUserAssignmentConnection",
      items:  Array< {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    identity_sub: string,
    source_type: AssignmentSourceType,
    predecessor_id?: string | null,
    predecessor_assignment?:  {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    result_id?: string | null,
    result?:  {
      __typename: "UserMovementAnalysisResult",
      id: string,
      identity_sub: string,
      followup?: string | null,
      internal_comment?: string | null,
      user_assignments?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      evaluation_score_total?: number | null,
      user_screenshot?: string | null,
      evaluation_measurements?: string | null,
      feedback_texts?: string | null,
      status?: AnalysisResultStatus | null,
      draft_approved_at?: string | null,
      draft_approved_by?: string | null,
      approved_at?: string | null,
      approved_by?: string | null,
      draft_at?: string | null,
      draft_by?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    analysis_assets?:  {
      __typename: "ModelUserMovementAnalysisAssetConnection",
      items:  Array< {
        __typename: "UserMovementAnalysisAsset",
        id: string,
        identity_sub: string,
        user_assignment_id: string,
        type?: AnalysisAssetType | null,
        src_file?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    source_id: string,
    completed_at?: string | null,
    started_at?: string | null,
    due_at?: string | null,
    created_by_client_id?: string | null,
    repetitions_done?: number | null,
    deleted_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserAssignmentsQueryVariables = {
  filter?: ModelUserAssignmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserAssignmentsQuery = {
  listUserAssignments?:  {
    __typename: "ModelUserAssignmentConnection",
    items:  Array< {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserMovementAssignmentQueryVariables = {
  id: string,
};

export type GetUserMovementAssignmentQuery = {
  getUserMovementAssignment?:  {
    __typename: "UserMovementAssignment",
    id: string,
    successor_assignment?:  {
      __typename: "ModelUserMovementAssignmentConnection",
      items:  Array< {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    identity_sub: string,
    source_type: AssignmentSourceType,
    predecessor_id?: string | null,
    predecessor_assignment?:  {
      __typename: "UserMovementAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserMovementAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    source_id: string,
    completed_at?: string | null,
    started_at?: string | null,
    due_at?: string | null,
    deleted_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserMovementAssignmentsQueryVariables = {
  filter?: ModelUserMovementAssignmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserMovementAssignmentsQuery = {
  listUserMovementAssignments?:  {
    __typename: "ModelUserMovementAssignmentConnection",
    items:  Array< {
      __typename: "UserMovementAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserMovementAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetMovementTaskRatingQueryVariables = {
  id: string,
};

export type GetMovementTaskRatingQuery = {
  getMovementTaskRating?:  {
    __typename: "MovementTaskRating",
    id: string,
    identity_sub: string,
    movement_task_id: string,
    rating: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListMovementTaskRatingsQueryVariables = {
  filter?: ModelMovementTaskRatingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMovementTaskRatingsQuery = {
  listMovementTaskRatings?:  {
    __typename: "ModelMovementTaskRatingConnection",
    items:  Array< {
      __typename: "MovementTaskRating",
      id: string,
      identity_sub: string,
      movement_task_id: string,
      rating: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserMovementAnalysisResultQueryVariables = {
  id: string,
};

export type GetUserMovementAnalysisResultQuery = {
  getUserMovementAnalysisResult?:  {
    __typename: "UserMovementAnalysisResult",
    id: string,
    identity_sub: string,
    followup?: string | null,
    internal_comment?: string | null,
    user_assignments?:  {
      __typename: "ModelUserAssignmentConnection",
      items:  Array< {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    evaluation_score_total?: number | null,
    user_screenshot?: string | null,
    evaluation_measurements?: string | null,
    feedback_texts?: string | null,
    status?: AnalysisResultStatus | null,
    draft_approved_at?: string | null,
    draft_approved_by?: string | null,
    approved_at?: string | null,
    approved_by?: string | null,
    draft_at?: string | null,
    draft_by?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserMovementAnalysisResultsQueryVariables = {
  filter?: ModelUserMovementAnalysisResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserMovementAnalysisResultsQuery = {
  listUserMovementAnalysisResults?:  {
    __typename: "ModelUserMovementAnalysisResultConnection",
    items:  Array< {
      __typename: "UserMovementAnalysisResult",
      id: string,
      identity_sub: string,
      followup?: string | null,
      internal_comment?: string | null,
      user_assignments?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      evaluation_score_total?: number | null,
      user_screenshot?: string | null,
      evaluation_measurements?: string | null,
      feedback_texts?: string | null,
      status?: AnalysisResultStatus | null,
      draft_approved_at?: string | null,
      draft_approved_by?: string | null,
      approved_at?: string | null,
      approved_by?: string | null,
      draft_at?: string | null,
      draft_by?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetLeadQueryVariables = {
  id: string,
};

export type GetLeadQuery = {
  getLead?:  {
    __typename: "Lead",
    id: string,
    firstname: string,
    lastname: string,
    company: string,
    email_address: string,
    tenant_id?: string | null,
    code?: string | null,
    code_send_at?: string | null,
    code_used_at?: string | null,
    user_id?: string | null,
    consent_conditions_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListLeadsQueryVariables = {
  filter?: ModelLeadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLeadsQuery = {
  listLeads?:  {
    __typename: "ModelLeadConnection",
    items:  Array< {
      __typename: "Lead",
      id: string,
      firstname: string,
      lastname: string,
      company: string,
      email_address: string,
      tenant_id?: string | null,
      code?: string | null,
      code_send_at?: string | null,
      code_used_at?: string | null,
      user_id?: string | null,
      consent_conditions_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserMovementAnalysisAssetQueryVariables = {
  id: string,
};

export type GetUserMovementAnalysisAssetQuery = {
  getUserMovementAnalysisAsset?:  {
    __typename: "UserMovementAnalysisAsset",
    id: string,
    identity_sub: string,
    user_assignment_id: string,
    user_assignment?:  {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    type?: AnalysisAssetType | null,
    src_file?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserMovementAnalysisAssetsQueryVariables = {
  filter?: ModelUserMovementAnalysisAssetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserMovementAnalysisAssetsQuery = {
  listUserMovementAnalysisAssets?:  {
    __typename: "ModelUserMovementAnalysisAssetConnection",
    items:  Array< {
      __typename: "UserMovementAnalysisAsset",
      id: string,
      identity_sub: string,
      user_assignment_id: string,
      user_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      type?: AnalysisAssetType | null,
      src_file?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDeletedUserQueryVariables = {
  id: string,
};

export type GetDeletedUserQuery = {
  getDeletedUser?:  {
    __typename: "DeletedUser",
    id: string,
    type: DeletedUserType,
    email: string,
    original_user_id: string,
    tenant_id?: string | null,
    license_id?: string | null,
    username?: string | null,
    request_received_at?: string | null,
    identity_deleted_at?: string | null,
    braze_profile_deleted_at?: string | null,
    aws_pinpoint_profile_deleted_at?: string | null,
    crisp_profile_deleted_at?: string | null,
    intercom_profile_deleted_at?: string | null,
    completed_by?: string | null,
    completed_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDeletedUsersQueryVariables = {
  filter?: ModelDeletedUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeletedUsersQuery = {
  listDeletedUsers?:  {
    __typename: "ModelDeletedUserConnection",
    items:  Array< {
      __typename: "DeletedUser",
      id: string,
      type: DeletedUserType,
      email: string,
      original_user_id: string,
      tenant_id?: string | null,
      license_id?: string | null,
      username?: string | null,
      request_received_at?: string | null,
      identity_deleted_at?: string | null,
      braze_profile_deleted_at?: string | null,
      aws_pinpoint_profile_deleted_at?: string | null,
      crisp_profile_deleted_at?: string | null,
      intercom_profile_deleted_at?: string | null,
      completed_by?: string | null,
      completed_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetManagementQueryVariables = {
  id: string,
};

export type GetManagementQuery = {
  getManagement?:  {
    __typename: "Management",
    id: string,
    index?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListManagementsQueryVariables = {
  filter?: ModelManagementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListManagementsQuery = {
  listManagements?:  {
    __typename: "ModelManagementConnection",
    items:  Array< {
      __typename: "Management",
      id: string,
      index?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAuditLogQueryVariables = {
  id: string,
};

export type GetAuditLogQuery = {
  getAuditLog?:  {
    __typename: "AuditLog",
    id: string,
    time: string,
    typeName?: string | null,
    fieldName?: string | null,
    arguments?: string | null,
    userSub: string,
    userId: string,
    clientId?: string | null,
    ip?: string | null,
    sourceDevice?: string | null,
    origin?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAuditLogsQueryVariables = {
  filter?: ModelAuditLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAuditLogsQuery = {
  listAuditLogs?:  {
    __typename: "ModelAuditLogConnection",
    items:  Array< {
      __typename: "AuditLog",
      id: string,
      time: string,
      typeName?: string | null,
      fieldName?: string | null,
      arguments?: string | null,
      userSub: string,
      userId: string,
      clientId?: string | null,
      ip?: string | null,
      sourceDevice?: string | null,
      origin?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListWatchedItemsByCustomUserIdQueryVariables = {
  custom_user_id?: string | null,
  start_date?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWatchedItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWatchedItemsByCustomUserIdQuery = {
  listWatchedItemsByCustomUserId?:  {
    __typename: "ModelWatchedItemConnection",
    items:  Array< {
      __typename: "WatchedItem",
      id: string,
      name: string,
      contentful_id: string,
      contentful_version: string,
      start_date: string,
      end_date?: string | null,
      length_in_seconds: number,
      closed_at_seconds?: number | null,
      type: WatchedItemType,
      tenant_id: string,
      custom_user_id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListWatchedItemsByCustomUserIdAndTypeQueryVariables = {
  custom_user_id?: string | null,
  typeStart_date?: ModelWatchedItemByCustomUserIdAndTypeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWatchedItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWatchedItemsByCustomUserIdAndTypeQuery = {
  listWatchedItemsByCustomUserIdAndType?:  {
    __typename: "ModelWatchedItemConnection",
    items:  Array< {
      __typename: "WatchedItem",
      id: string,
      name: string,
      contentful_id: string,
      contentful_version: string,
      start_date: string,
      end_date?: string | null,
      length_in_seconds: number,
      closed_at_seconds?: number | null,
      type: WatchedItemType,
      tenant_id: string,
      custom_user_id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTenantsByNameQueryVariables = {
  type?: TenantType | null,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTenantsByNameQuery = {
  listTenantsByName?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TenantByCodeQueryVariables = {
  code?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTenantFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TenantByCodeQuery = {
  tenantByCode?:  {
    __typename: "ModelTenantConnection",
    items:  Array< {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TenantBookedMindPackageByTenantQueryVariables = {
  tenant_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTenantBookedMindPackageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TenantBookedMindPackageByTenantQuery = {
  tenantBookedMindPackageByTenant?:  {
    __typename: "ModelTenantBookedMindPackageConnection",
    items:  Array< {
      __typename: "TenantBookedMindPackage",
      id: string,
      tenant_id: string,
      package_id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TenantBookedTrainingModuleByTenantQueryVariables = {
  tenant_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTenantBookedTrainingModuleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TenantBookedTrainingModuleByTenantQuery = {
  tenantBookedTrainingModuleByTenant?:  {
    __typename: "ModelTenantBookedTrainingModuleConnection",
    items:  Array< {
      __typename: "TenantBookedTrainingModule",
      id: string,
      tenant_id: string,
      module_id: string,
      with_analyse?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListLicensesByTenantQueryVariables = {
  tenant_id?: string | null,
  validToIdentity_sub?: ModelLicenseByTenantCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLicenseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLicensesByTenantQuery = {
  listLicensesByTenant?:  {
    __typename: "ModelLicenseConnection",
    items:  Array< {
      __typename: "License",
      id: string,
      type: LicenseType,
      tenant_id: string,
      invoice_id?: string | null,
      origin?: LicenseOrigin | null,
      identity_sub?: string | null,
      user?: string | null,
      validTo: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        type: TenantType,
        category?: TenantCategory | null,
        name: string,
        email_domains?: Array< string > | null,
        code?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListLicensesByByUserQueryVariables = {
  identity_sub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLicenseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLicensesByByUserQuery = {
  listLicensesByByUser?:  {
    __typename: "ModelLicenseConnection",
    items:  Array< {
      __typename: "License",
      id: string,
      type: LicenseType,
      tenant_id: string,
      invoice_id?: string | null,
      origin?: LicenseOrigin | null,
      identity_sub?: string | null,
      user?: string | null,
      validTo: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        type: TenantType,
        category?: TenantCategory | null,
        name: string,
        email_domains?: Array< string > | null,
        code?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListLicensesByValidToQueryVariables = {
  type?: LicenseType | null,
  validTo?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLicenseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLicensesByValidToQuery = {
  listLicensesByValidTo?:  {
    __typename: "ModelLicenseConnection",
    items:  Array< {
      __typename: "License",
      id: string,
      type: LicenseType,
      tenant_id: string,
      invoice_id?: string | null,
      origin?: LicenseOrigin | null,
      identity_sub?: string | null,
      user?: string | null,
      validTo: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        type: TenantType,
        category?: TenantCategory | null,
        name: string,
        email_domains?: Array< string > | null,
        code?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListLicensesByInvoiceQueryVariables = {
  invoice_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLicenseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLicensesByInvoiceQuery = {
  listLicensesByInvoice?:  {
    __typename: "ModelLicenseConnection",
    items:  Array< {
      __typename: "License",
      id: string,
      type: LicenseType,
      tenant_id: string,
      invoice_id?: string | null,
      origin?: LicenseOrigin | null,
      identity_sub?: string | null,
      user?: string | null,
      validTo: string,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        type: TenantType,
        category?: TenantCategory | null,
        name: string,
        email_domains?: Array< string > | null,
        code?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListRegistrationCodesByTenantQueryVariables = {
  tenant_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegistrationCodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRegistrationCodesByTenantQuery = {
  listRegistrationCodesByTenant?:  {
    __typename: "ModelRegistrationCodeConnection",
    items:  Array< {
      __typename: "RegistrationCode",
      id: string,
      code: string,
      tenant_id: string,
      identity_sub?: string | null,
      license_id?: string | null,
      used_at?: string | null,
      valid_to: string,
      reserved_at?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        type: TenantType,
        category?: TenantCategory | null,
        name: string,
        email_domains?: Array< string > | null,
        code?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListRegistrationCodesByCodeQueryVariables = {
  code?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegistrationCodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRegistrationCodesByCodeQuery = {
  listRegistrationCodesByCode?:  {
    __typename: "ModelRegistrationCodeConnection",
    items:  Array< {
      __typename: "RegistrationCode",
      id: string,
      code: string,
      tenant_id: string,
      identity_sub?: string | null,
      license_id?: string | null,
      used_at?: string | null,
      valid_to: string,
      reserved_at?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        type: TenantType,
        category?: TenantCategory | null,
        name: string,
        email_domains?: Array< string > | null,
        code?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListRegistrationCodesByTenantUsedAtQueryVariables = {
  tenant_id?: string | null,
  used_at?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelRegistrationCodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRegistrationCodesByTenantUsedAtQuery = {
  listRegistrationCodesByTenantUsedAt?:  {
    __typename: "ModelRegistrationCodeConnection",
    items:  Array< {
      __typename: "RegistrationCode",
      id: string,
      code: string,
      tenant_id: string,
      identity_sub?: string | null,
      license_id?: string | null,
      used_at?: string | null,
      valid_to: string,
      reserved_at?: string | null,
      tenant?:  {
        __typename: "Tenant",
        id: string,
        type: TenantType,
        category?: TenantCategory | null,
        name: string,
        email_domains?: Array< string > | null,
        code?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AnamneseByUserQueryVariables = {
  identity_sub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAnamneseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AnamneseByUserQuery = {
  anamneseByUser?:  {
    __typename: "ModelAnamneseConnection",
    items:  Array< {
      __typename: "Anamnese",
      id: string,
      identity_sub: string,
      anamnese_type: AnamneseType,
      processed_at?: string | null,
      gender: Gender,
      body_size?: number | null,
      body_weight?: number | null,
      birthdate?: string | null,
      is_performing_job?: boolean | null,
      is_pregnant?: boolean | null,
      has_contraindications?: boolean | null,
      pain_medication?: PainMedication | null,
      body_part_pain:  Array< {
        __typename: "BodyPartPain",
        body_part?: BodyPart | null,
        severity?: number | null,
      } | null >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MindAnamneseByUserQueryVariables = {
  identity_sub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMindAnamneseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MindAnamneseByUserQuery = {
  mindAnamneseByUser?:  {
    __typename: "ModelMindAnamneseConnection",
    items:  Array< {
      __typename: "MindAnamnese",
      id: string,
      identity_sub: string,
      processed_at?: string | null,
      has_contraindications?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MindProgressByUserQueryVariables = {
  identity_sub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserMindProgressFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MindProgressByUserQuery = {
  mindProgressByUser?:  {
    __typename: "ModelUserMindProgressConnection",
    items:  Array< {
      __typename: "UserMindProgress",
      id: string,
      identity_sub: string,
      source_id: string,
      source_type: MindTaskType,
      result_id?: string | null,
      result?:  {
        __typename: "UserMindEvaluationResult",
        id: string,
        identity_sub: string,
        mind_task_id: string,
        state: MindState,
        createdAt: string,
        updatedAt: string,
      } | null,
      completed_at?: string | null,
      started_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type EvaluationResultByMindTaskQueryVariables = {
  mind_task_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserMindEvaluationResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EvaluationResultByMindTaskQuery = {
  evaluationResultByMindTask?:  {
    __typename: "ModelUserMindEvaluationResultConnection",
    items:  Array< {
      __typename: "UserMindEvaluationResult",
      id: string,
      identity_sub: string,
      mind_task_id: string,
      state: MindState,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type FeedbackByPackageQueryVariables = {
  package_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserMindPackageFeedbackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FeedbackByPackageQuery = {
  feedbackByPackage?:  {
    __typename: "ModelUserMindPackageFeedbackConnection",
    items:  Array< {
      __typename: "UserMindPackageFeedback",
      id: string,
      identity_sub: string,
      package_id: string,
      rating?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AssignmentByUserQueryVariables = {
  identity_sub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserAssignmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AssignmentByUserQuery = {
  assignmentByUser?:  {
    __typename: "ModelUserAssignmentConnection",
    items:  Array< {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AssignmentByPredecessorQueryVariables = {
  predecessor_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserAssignmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AssignmentByPredecessorQuery = {
  assignmentByPredecessor?:  {
    __typename: "ModelUserAssignmentConnection",
    items:  Array< {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MovementAssignmentByUserQueryVariables = {
  identity_sub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserMovementAssignmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MovementAssignmentByUserQuery = {
  movementAssignmentByUser?:  {
    __typename: "ModelUserMovementAssignmentConnection",
    items:  Array< {
      __typename: "UserMovementAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserMovementAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MovementAssignmentByPredecessorQueryVariables = {
  predecessor_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserMovementAssignmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MovementAssignmentByPredecessorQuery = {
  movementAssignmentByPredecessor?:  {
    __typename: "ModelUserMovementAssignmentConnection",
    items:  Array< {
      __typename: "UserMovementAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserMovementAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RatingByUserQueryVariables = {
  identity_sub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMovementTaskRatingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RatingByUserQuery = {
  ratingByUser?:  {
    __typename: "ModelMovementTaskRatingConnection",
    items:  Array< {
      __typename: "MovementTaskRating",
      id: string,
      identity_sub: string,
      movement_task_id: string,
      rating: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RatingByTaskQueryVariables = {
  movement_task_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMovementTaskRatingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RatingByTaskQuery = {
  ratingByTask?:  {
    __typename: "ModelMovementTaskRatingConnection",
    items:  Array< {
      __typename: "MovementTaskRating",
      id: string,
      identity_sub: string,
      movement_task_id: string,
      rating: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RatingByRateQueryVariables = {
  rating?: number | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMovementTaskRatingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RatingByRateQuery = {
  ratingByRate?:  {
    __typename: "ModelMovementTaskRatingConnection",
    items:  Array< {
      __typename: "MovementTaskRating",
      id: string,
      identity_sub: string,
      movement_task_id: string,
      rating: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MovementAnalysisResultByUserQueryVariables = {
  identity_sub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserMovementAnalysisResultFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MovementAnalysisResultByUserQuery = {
  movementAnalysisResultByUser?:  {
    __typename: "ModelUserMovementAnalysisResultConnection",
    items:  Array< {
      __typename: "UserMovementAnalysisResult",
      id: string,
      identity_sub: string,
      followup?: string | null,
      internal_comment?: string | null,
      user_assignments?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      evaluation_score_total?: number | null,
      user_screenshot?: string | null,
      evaluation_measurements?: string | null,
      feedback_texts?: string | null,
      status?: AnalysisResultStatus | null,
      draft_approved_at?: string | null,
      draft_approved_by?: string | null,
      approved_at?: string | null,
      approved_by?: string | null,
      draft_at?: string | null,
      draft_by?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LeadByEmailQueryVariables = {
  email_address?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLeadFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LeadByEmailQuery = {
  leadByEmail?:  {
    __typename: "ModelLeadConnection",
    items:  Array< {
      __typename: "Lead",
      id: string,
      firstname: string,
      lastname: string,
      company: string,
      email_address: string,
      tenant_id?: string | null,
      code?: string | null,
      code_send_at?: string | null,
      code_used_at?: string | null,
      user_id?: string | null,
      consent_conditions_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MovementAnalysisAssetByUserQueryVariables = {
  identity_sub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserMovementAnalysisAssetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MovementAnalysisAssetByUserQuery = {
  movementAnalysisAssetByUser?:  {
    __typename: "ModelUserMovementAnalysisAssetConnection",
    items:  Array< {
      __typename: "UserMovementAnalysisAsset",
      id: string,
      identity_sub: string,
      user_assignment_id: string,
      user_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      type?: AnalysisAssetType | null,
      src_file?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type MovementAnalysisAssetByAssignmentQueryVariables = {
  user_assignment_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserMovementAnalysisAssetFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MovementAnalysisAssetByAssignmentQuery = {
  movementAnalysisAssetByAssignment?:  {
    __typename: "ModelUserMovementAnalysisAssetConnection",
    items:  Array< {
      __typename: "UserMovementAnalysisAsset",
      id: string,
      identity_sub: string,
      user_assignment_id: string,
      user_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      type?: AnalysisAssetType | null,
      src_file?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListDeletedUsersByRequestReceivedAtQueryVariables = {
  type?: DeletedUserType | null,
  request_received_at?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDeletedUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeletedUsersByRequestReceivedAtQuery = {
  listDeletedUsersByRequestReceivedAt?:  {
    __typename: "ModelDeletedUserConnection",
    items:  Array< {
      __typename: "DeletedUser",
      id: string,
      type: DeletedUserType,
      email: string,
      original_user_id: string,
      tenant_id?: string | null,
      license_id?: string | null,
      username?: string | null,
      request_received_at?: string | null,
      identity_deleted_at?: string | null,
      braze_profile_deleted_at?: string | null,
      aws_pinpoint_profile_deleted_at?: string | null,
      crisp_profile_deleted_at?: string | null,
      intercom_profile_deleted_at?: string | null,
      completed_by?: string | null,
      completed_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListDeletedUsersByTenantQueryVariables = {
  tenant_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDeletedUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeletedUsersByTenantQuery = {
  listDeletedUsersByTenant?:  {
    __typename: "ModelDeletedUserConnection",
    items:  Array< {
      __typename: "DeletedUser",
      id: string,
      type: DeletedUserType,
      email: string,
      original_user_id: string,
      tenant_id?: string | null,
      license_id?: string | null,
      username?: string | null,
      request_received_at?: string | null,
      identity_deleted_at?: string | null,
      braze_profile_deleted_at?: string | null,
      aws_pinpoint_profile_deleted_at?: string | null,
      crisp_profile_deleted_at?: string | null,
      intercom_profile_deleted_at?: string | null,
      completed_by?: string | null,
      completed_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserMappingQueryVariables = {
  id: string,
};

export type GetUserMappingQuery = {
  getUserMapping?:  {
    __typename: "UserMapping",
    id: string,
    custom_user_id: string,
    identity_sub: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserMappingsQueryVariables = {
  filter?: ModelUserMappingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserMappingsQuery = {
  listUserMappings?:  {
    __typename: "ModelUserMappingConnection",
    items:  Array< {
      __typename: "UserMapping",
      id: string,
      custom_user_id: string,
      identity_sub: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserMappingsByCustomUserIdQueryVariables = {
  custom_user_id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserMappingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserMappingsByCustomUserIdQuery = {
  listUserMappingsByCustomUserId?:  {
    __typename: "ModelUserMappingConnection",
    items:  Array< {
      __typename: "UserMapping",
      id: string,
      custom_user_id: string,
      identity_sub: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserMappingsByIdentitySubQueryVariables = {
  identity_sub?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserMappingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserMappingsByIdentitySubQuery = {
  listUserMappingsByIdentitySub?:  {
    __typename: "ModelUserMappingConnection",
    items:  Array< {
      __typename: "UserMapping",
      id: string,
      custom_user_id: string,
      identity_sub: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetJobQueryVariables = {
  id: string,
};

export type GetJobQuery = {
  getJob?:  {
    __typename: "Job",
    id: string,
    name: string,
    type: JobType,
    tenants?:  {
      __typename: "ModelJobTenantConnection",
      items:  Array< {
        __typename: "JobTenant",
        id: string,
        job_id: string,
        tenant_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListJobsQueryVariables = {
  filter?: ModelJobFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListJobsQuery = {
  listJobs?:  {
    __typename: "ModelJobConnection",
    items:  Array< {
      __typename: "Job",
      id: string,
      name: string,
      type: JobType,
      tenants?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClientConfigurationQueryVariables = {
  id: string,
};

export type GetClientConfigurationQuery = {
  getClientConfiguration?:  {
    __typename: "ClientConfiguration",
    id: string,
    minimum_version: string,
    valid_from?: string | null,
    valid_to?: string | null,
    configured_by?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListClientConfigurationsQueryVariables = {
  filter?: ModelClientConfigurationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientConfigurationsQuery = {
  listClientConfigurations?:  {
    __typename: "ModelClientConfigurationConnection",
    items:  Array< {
      __typename: "ClientConfiguration",
      id: string,
      minimum_version: string,
      valid_from?: string | null,
      valid_to?: string | null,
      configured_by?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VerifyRegistrationCodeQueryVariables = {
  registrationCode?: string | null,
};

export type VerifyRegistrationCodeQuery = {
  verifyRegistrationCode?: string | null,
};

export type SendEmailVerificationQueryVariables = {
  userId: string,
  email: string,
  userSub: string,
};

export type SendEmailVerificationQuery = {
  sendEmailVerification?: string | null,
};

export type SendDeleteUserRequestQueryVariables = {
  userId: string,
  email: string,
  userSub: string,
  serviceEmail: string,
};

export type SendDeleteUserRequestQuery = {
  sendDeleteUserRequest?: string | null,
};

export type GetCompaniesQueryVariables = {
  searchQuery?: string | null,
};

export type GetCompaniesQuery = {
  getCompanies?: string | null,
};

export type OnCreateWatchedItemSubscriptionVariables = {
  custom_user_id?: string | null,
};

export type OnCreateWatchedItemSubscription = {
  onCreateWatchedItem?:  {
    __typename: "WatchedItem",
    id: string,
    name: string,
    contentful_id: string,
    contentful_version: string,
    start_date: string,
    end_date?: string | null,
    length_in_seconds: number,
    closed_at_seconds?: number | null,
    type: WatchedItemType,
    tenant_id: string,
    custom_user_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateWatchedItemSubscriptionVariables = {
  custom_user_id?: string | null,
};

export type OnUpdateWatchedItemSubscription = {
  onUpdateWatchedItem?:  {
    __typename: "WatchedItem",
    id: string,
    name: string,
    contentful_id: string,
    contentful_version: string,
    start_date: string,
    end_date?: string | null,
    length_in_seconds: number,
    closed_at_seconds?: number | null,
    type: WatchedItemType,
    tenant_id: string,
    custom_user_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteWatchedItemSubscriptionVariables = {
  custom_user_id?: string | null,
};

export type OnDeleteWatchedItemSubscription = {
  onDeleteWatchedItem?:  {
    __typename: "WatchedItem",
    id: string,
    name: string,
    contentful_id: string,
    contentful_version: string,
    start_date: string,
    end_date?: string | null,
    length_in_seconds: number,
    closed_at_seconds?: number | null,
    type: WatchedItemType,
    tenant_id: string,
    custom_user_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTenantSubscription = {
  onCreateTenant?:  {
    __typename: "Tenant",
    id: string,
    type: TenantType,
    category?: TenantCategory | null,
    name: string,
    email_domains?: Array< string > | null,
    code?: string | null,
    licenses?:  {
      __typename: "ModelLicenseConnection",
      items:  Array< {
        __typename: "License",
        id: string,
        type: LicenseType,
        tenant_id: string,
        invoice_id?: string | null,
        origin?: LicenseOrigin | null,
        identity_sub?: string | null,
        user?: string | null,
        validTo: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    jobs?:  {
      __typename: "ModelJobTenantConnection",
      items:  Array< {
        __typename: "JobTenant",
        id: string,
        job_id: string,
        tenant_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTenantSubscription = {
  onUpdateTenant?:  {
    __typename: "Tenant",
    id: string,
    type: TenantType,
    category?: TenantCategory | null,
    name: string,
    email_domains?: Array< string > | null,
    code?: string | null,
    licenses?:  {
      __typename: "ModelLicenseConnection",
      items:  Array< {
        __typename: "License",
        id: string,
        type: LicenseType,
        tenant_id: string,
        invoice_id?: string | null,
        origin?: LicenseOrigin | null,
        identity_sub?: string | null,
        user?: string | null,
        validTo: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    jobs?:  {
      __typename: "ModelJobTenantConnection",
      items:  Array< {
        __typename: "JobTenant",
        id: string,
        job_id: string,
        tenant_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTenantSubscription = {
  onDeleteTenant?:  {
    __typename: "Tenant",
    id: string,
    type: TenantType,
    category?: TenantCategory | null,
    name: string,
    email_domains?: Array< string > | null,
    code?: string | null,
    licenses?:  {
      __typename: "ModelLicenseConnection",
      items:  Array< {
        __typename: "License",
        id: string,
        type: LicenseType,
        tenant_id: string,
        invoice_id?: string | null,
        origin?: LicenseOrigin | null,
        identity_sub?: string | null,
        user?: string | null,
        validTo: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    jobs?:  {
      __typename: "ModelJobTenantConnection",
      items:  Array< {
        __typename: "JobTenant",
        id: string,
        job_id: string,
        tenant_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTenantBookedMindPackageSubscription = {
  onCreateTenantBookedMindPackage?:  {
    __typename: "TenantBookedMindPackage",
    id: string,
    tenant_id: string,
    package_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTenantBookedMindPackageSubscription = {
  onUpdateTenantBookedMindPackage?:  {
    __typename: "TenantBookedMindPackage",
    id: string,
    tenant_id: string,
    package_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTenantBookedMindPackageSubscription = {
  onDeleteTenantBookedMindPackage?:  {
    __typename: "TenantBookedMindPackage",
    id: string,
    tenant_id: string,
    package_id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTenantBookedTrainingModuleSubscription = {
  onCreateTenantBookedTrainingModule?:  {
    __typename: "TenantBookedTrainingModule",
    id: string,
    tenant_id: string,
    module_id: string,
    with_analyse?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTenantBookedTrainingModuleSubscription = {
  onUpdateTenantBookedTrainingModule?:  {
    __typename: "TenantBookedTrainingModule",
    id: string,
    tenant_id: string,
    module_id: string,
    with_analyse?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTenantBookedTrainingModuleSubscription = {
  onDeleteTenantBookedTrainingModule?:  {
    __typename: "TenantBookedTrainingModule",
    id: string,
    tenant_id: string,
    module_id: string,
    with_analyse?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLicenseSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnCreateLicenseSubscription = {
  onCreateLicense?:  {
    __typename: "License",
    id: string,
    type: LicenseType,
    tenant_id: string,
    invoice_id?: string | null,
    origin?: LicenseOrigin | null,
    identity_sub?: string | null,
    user?: string | null,
    validTo: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLicenseSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnUpdateLicenseSubscription = {
  onUpdateLicense?:  {
    __typename: "License",
    id: string,
    type: LicenseType,
    tenant_id: string,
    invoice_id?: string | null,
    origin?: LicenseOrigin | null,
    identity_sub?: string | null,
    user?: string | null,
    validTo: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLicenseSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnDeleteLicenseSubscription = {
  onDeleteLicense?:  {
    __typename: "License",
    id: string,
    type: LicenseType,
    tenant_id: string,
    invoice_id?: string | null,
    origin?: LicenseOrigin | null,
    identity_sub?: string | null,
    user?: string | null,
    validTo: string,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateRegistrationCodeSubscription = {
  onCreateRegistrationCode?:  {
    __typename: "RegistrationCode",
    id: string,
    code: string,
    tenant_id: string,
    identity_sub?: string | null,
    license_id?: string | null,
    used_at?: string | null,
    valid_to: string,
    reserved_at?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRegistrationCodeSubscription = {
  onUpdateRegistrationCode?:  {
    __typename: "RegistrationCode",
    id: string,
    code: string,
    tenant_id: string,
    identity_sub?: string | null,
    license_id?: string | null,
    used_at?: string | null,
    valid_to: string,
    reserved_at?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRegistrationCodeSubscription = {
  onDeleteRegistrationCode?:  {
    __typename: "RegistrationCode",
    id: string,
    code: string,
    tenant_id: string,
    identity_sub?: string | null,
    license_id?: string | null,
    used_at?: string | null,
    valid_to: string,
    reserved_at?: string | null,
    tenant?:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateJobTenantSubscription = {
  onCreateJobTenant?:  {
    __typename: "JobTenant",
    id: string,
    job_id: string,
    tenant_id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    job:  {
      __typename: "Job",
      id: string,
      name: string,
      type: JobType,
      tenants?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type OnUpdateJobTenantSubscription = {
  onUpdateJobTenant?:  {
    __typename: "JobTenant",
    id: string,
    job_id: string,
    tenant_id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    job:  {
      __typename: "Job",
      id: string,
      name: string,
      type: JobType,
      tenants?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type OnDeleteJobTenantSubscription = {
  onDeleteJobTenant?:  {
    __typename: "JobTenant",
    id: string,
    job_id: string,
    tenant_id: string,
    tenant:  {
      __typename: "Tenant",
      id: string,
      type: TenantType,
      category?: TenantCategory | null,
      name: string,
      email_domains?: Array< string > | null,
      code?: string | null,
      licenses?:  {
        __typename: "ModelLicenseConnection",
        nextToken?: string | null,
      } | null,
      jobs?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
    job:  {
      __typename: "Job",
      id: string,
      name: string,
      type: JobType,
      tenants?:  {
        __typename: "ModelJobTenantConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
  } | null,
};

export type OnCreateAnamneseSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnCreateAnamneseSubscription = {
  onCreateAnamnese?:  {
    __typename: "Anamnese",
    id: string,
    identity_sub: string,
    anamnese_type: AnamneseType,
    processed_at?: string | null,
    gender: Gender,
    body_size?: number | null,
    body_weight?: number | null,
    birthdate?: string | null,
    is_performing_job?: boolean | null,
    is_pregnant?: boolean | null,
    has_contraindications?: boolean | null,
    pain_medication?: PainMedication | null,
    body_part_pain:  Array< {
      __typename: "BodyPartPain",
      body_part?: BodyPart | null,
      severity?: number | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAnamneseSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnUpdateAnamneseSubscription = {
  onUpdateAnamnese?:  {
    __typename: "Anamnese",
    id: string,
    identity_sub: string,
    anamnese_type: AnamneseType,
    processed_at?: string | null,
    gender: Gender,
    body_size?: number | null,
    body_weight?: number | null,
    birthdate?: string | null,
    is_performing_job?: boolean | null,
    is_pregnant?: boolean | null,
    has_contraindications?: boolean | null,
    pain_medication?: PainMedication | null,
    body_part_pain:  Array< {
      __typename: "BodyPartPain",
      body_part?: BodyPart | null,
      severity?: number | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAnamneseSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnDeleteAnamneseSubscription = {
  onDeleteAnamnese?:  {
    __typename: "Anamnese",
    id: string,
    identity_sub: string,
    anamnese_type: AnamneseType,
    processed_at?: string | null,
    gender: Gender,
    body_size?: number | null,
    body_weight?: number | null,
    birthdate?: string | null,
    is_performing_job?: boolean | null,
    is_pregnant?: boolean | null,
    has_contraindications?: boolean | null,
    pain_medication?: PainMedication | null,
    body_part_pain:  Array< {
      __typename: "BodyPartPain",
      body_part?: BodyPart | null,
      severity?: number | null,
    } | null >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMindAnamneseSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnCreateMindAnamneseSubscription = {
  onCreateMindAnamnese?:  {
    __typename: "MindAnamnese",
    id: string,
    identity_sub: string,
    processed_at?: string | null,
    has_contraindications?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMindAnamneseSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnUpdateMindAnamneseSubscription = {
  onUpdateMindAnamnese?:  {
    __typename: "MindAnamnese",
    id: string,
    identity_sub: string,
    processed_at?: string | null,
    has_contraindications?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMindAnamneseSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnDeleteMindAnamneseSubscription = {
  onDeleteMindAnamnese?:  {
    __typename: "MindAnamnese",
    id: string,
    identity_sub: string,
    processed_at?: string | null,
    has_contraindications?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserMindProgressSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnCreateUserMindProgressSubscription = {
  onCreateUserMindProgress?:  {
    __typename: "UserMindProgress",
    id: string,
    identity_sub: string,
    source_id: string,
    source_type: MindTaskType,
    result_id?: string | null,
    result?:  {
      __typename: "UserMindEvaluationResult",
      id: string,
      identity_sub: string,
      mind_task_id: string,
      state: MindState,
      createdAt: string,
      updatedAt: string,
    } | null,
    completed_at?: string | null,
    started_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserMindProgressSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnUpdateUserMindProgressSubscription = {
  onUpdateUserMindProgress?:  {
    __typename: "UserMindProgress",
    id: string,
    identity_sub: string,
    source_id: string,
    source_type: MindTaskType,
    result_id?: string | null,
    result?:  {
      __typename: "UserMindEvaluationResult",
      id: string,
      identity_sub: string,
      mind_task_id: string,
      state: MindState,
      createdAt: string,
      updatedAt: string,
    } | null,
    completed_at?: string | null,
    started_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserMindProgressSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnDeleteUserMindProgressSubscription = {
  onDeleteUserMindProgress?:  {
    __typename: "UserMindProgress",
    id: string,
    identity_sub: string,
    source_id: string,
    source_type: MindTaskType,
    result_id?: string | null,
    result?:  {
      __typename: "UserMindEvaluationResult",
      id: string,
      identity_sub: string,
      mind_task_id: string,
      state: MindState,
      createdAt: string,
      updatedAt: string,
    } | null,
    completed_at?: string | null,
    started_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserMindEvaluationResultSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnCreateUserMindEvaluationResultSubscription = {
  onCreateUserMindEvaluationResult?:  {
    __typename: "UserMindEvaluationResult",
    id: string,
    identity_sub: string,
    mind_task_id: string,
    state: MindState,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserMindEvaluationResultSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnUpdateUserMindEvaluationResultSubscription = {
  onUpdateUserMindEvaluationResult?:  {
    __typename: "UserMindEvaluationResult",
    id: string,
    identity_sub: string,
    mind_task_id: string,
    state: MindState,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserMindEvaluationResultSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnDeleteUserMindEvaluationResultSubscription = {
  onDeleteUserMindEvaluationResult?:  {
    __typename: "UserMindEvaluationResult",
    id: string,
    identity_sub: string,
    mind_task_id: string,
    state: MindState,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserMindPackageFeedbackSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnCreateUserMindPackageFeedbackSubscription = {
  onCreateUserMindPackageFeedback?:  {
    __typename: "UserMindPackageFeedback",
    id: string,
    identity_sub: string,
    package_id: string,
    rating?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserMindPackageFeedbackSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnUpdateUserMindPackageFeedbackSubscription = {
  onUpdateUserMindPackageFeedback?:  {
    __typename: "UserMindPackageFeedback",
    id: string,
    identity_sub: string,
    package_id: string,
    rating?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserMindPackageFeedbackSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnDeleteUserMindPackageFeedbackSubscription = {
  onDeleteUserMindPackageFeedback?:  {
    __typename: "UserMindPackageFeedback",
    id: string,
    identity_sub: string,
    package_id: string,
    rating?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserAssignmentSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnCreateUserAssignmentSubscription = {
  onCreateUserAssignment?:  {
    __typename: "UserAssignment",
    id: string,
    successor_assignment?:  {
      __typename: "ModelUserAssignmentConnection",
      items:  Array< {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    identity_sub: string,
    source_type: AssignmentSourceType,
    predecessor_id?: string | null,
    predecessor_assignment?:  {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    result_id?: string | null,
    result?:  {
      __typename: "UserMovementAnalysisResult",
      id: string,
      identity_sub: string,
      followup?: string | null,
      internal_comment?: string | null,
      user_assignments?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      evaluation_score_total?: number | null,
      user_screenshot?: string | null,
      evaluation_measurements?: string | null,
      feedback_texts?: string | null,
      status?: AnalysisResultStatus | null,
      draft_approved_at?: string | null,
      draft_approved_by?: string | null,
      approved_at?: string | null,
      approved_by?: string | null,
      draft_at?: string | null,
      draft_by?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    analysis_assets?:  {
      __typename: "ModelUserMovementAnalysisAssetConnection",
      items:  Array< {
        __typename: "UserMovementAnalysisAsset",
        id: string,
        identity_sub: string,
        user_assignment_id: string,
        type?: AnalysisAssetType | null,
        src_file?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    source_id: string,
    completed_at?: string | null,
    started_at?: string | null,
    due_at?: string | null,
    created_by_client_id?: string | null,
    repetitions_done?: number | null,
    deleted_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserAssignmentSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnUpdateUserAssignmentSubscription = {
  onUpdateUserAssignment?:  {
    __typename: "UserAssignment",
    id: string,
    successor_assignment?:  {
      __typename: "ModelUserAssignmentConnection",
      items:  Array< {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    identity_sub: string,
    source_type: AssignmentSourceType,
    predecessor_id?: string | null,
    predecessor_assignment?:  {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    result_id?: string | null,
    result?:  {
      __typename: "UserMovementAnalysisResult",
      id: string,
      identity_sub: string,
      followup?: string | null,
      internal_comment?: string | null,
      user_assignments?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      evaluation_score_total?: number | null,
      user_screenshot?: string | null,
      evaluation_measurements?: string | null,
      feedback_texts?: string | null,
      status?: AnalysisResultStatus | null,
      draft_approved_at?: string | null,
      draft_approved_by?: string | null,
      approved_at?: string | null,
      approved_by?: string | null,
      draft_at?: string | null,
      draft_by?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    analysis_assets?:  {
      __typename: "ModelUserMovementAnalysisAssetConnection",
      items:  Array< {
        __typename: "UserMovementAnalysisAsset",
        id: string,
        identity_sub: string,
        user_assignment_id: string,
        type?: AnalysisAssetType | null,
        src_file?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    source_id: string,
    completed_at?: string | null,
    started_at?: string | null,
    due_at?: string | null,
    created_by_client_id?: string | null,
    repetitions_done?: number | null,
    deleted_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserAssignmentSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnDeleteUserAssignmentSubscription = {
  onDeleteUserAssignment?:  {
    __typename: "UserAssignment",
    id: string,
    successor_assignment?:  {
      __typename: "ModelUserAssignmentConnection",
      items:  Array< {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    identity_sub: string,
    source_type: AssignmentSourceType,
    predecessor_id?: string | null,
    predecessor_assignment?:  {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    result_id?: string | null,
    result?:  {
      __typename: "UserMovementAnalysisResult",
      id: string,
      identity_sub: string,
      followup?: string | null,
      internal_comment?: string | null,
      user_assignments?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      evaluation_score_total?: number | null,
      user_screenshot?: string | null,
      evaluation_measurements?: string | null,
      feedback_texts?: string | null,
      status?: AnalysisResultStatus | null,
      draft_approved_at?: string | null,
      draft_approved_by?: string | null,
      approved_at?: string | null,
      approved_by?: string | null,
      draft_at?: string | null,
      draft_by?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    analysis_assets?:  {
      __typename: "ModelUserMovementAnalysisAssetConnection",
      items:  Array< {
        __typename: "UserMovementAnalysisAsset",
        id: string,
        identity_sub: string,
        user_assignment_id: string,
        type?: AnalysisAssetType | null,
        src_file?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    source_id: string,
    completed_at?: string | null,
    started_at?: string | null,
    due_at?: string | null,
    created_by_client_id?: string | null,
    repetitions_done?: number | null,
    deleted_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserMovementAssignmentSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnCreateUserMovementAssignmentSubscription = {
  onCreateUserMovementAssignment?:  {
    __typename: "UserMovementAssignment",
    id: string,
    successor_assignment?:  {
      __typename: "ModelUserMovementAssignmentConnection",
      items:  Array< {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    identity_sub: string,
    source_type: AssignmentSourceType,
    predecessor_id?: string | null,
    predecessor_assignment?:  {
      __typename: "UserMovementAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserMovementAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    source_id: string,
    completed_at?: string | null,
    started_at?: string | null,
    due_at?: string | null,
    deleted_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserMovementAssignmentSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnUpdateUserMovementAssignmentSubscription = {
  onUpdateUserMovementAssignment?:  {
    __typename: "UserMovementAssignment",
    id: string,
    successor_assignment?:  {
      __typename: "ModelUserMovementAssignmentConnection",
      items:  Array< {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    identity_sub: string,
    source_type: AssignmentSourceType,
    predecessor_id?: string | null,
    predecessor_assignment?:  {
      __typename: "UserMovementAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserMovementAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    source_id: string,
    completed_at?: string | null,
    started_at?: string | null,
    due_at?: string | null,
    deleted_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserMovementAssignmentSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnDeleteUserMovementAssignmentSubscription = {
  onDeleteUserMovementAssignment?:  {
    __typename: "UserMovementAssignment",
    id: string,
    successor_assignment?:  {
      __typename: "ModelUserMovementAssignmentConnection",
      items:  Array< {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    identity_sub: string,
    source_type: AssignmentSourceType,
    predecessor_id?: string | null,
    predecessor_assignment?:  {
      __typename: "UserMovementAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserMovementAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserMovementAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    source_id: string,
    completed_at?: string | null,
    started_at?: string | null,
    due_at?: string | null,
    deleted_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateMovementTaskRatingSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnCreateMovementTaskRatingSubscription = {
  onCreateMovementTaskRating?:  {
    __typename: "MovementTaskRating",
    id: string,
    identity_sub: string,
    movement_task_id: string,
    rating: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateMovementTaskRatingSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnUpdateMovementTaskRatingSubscription = {
  onUpdateMovementTaskRating?:  {
    __typename: "MovementTaskRating",
    id: string,
    identity_sub: string,
    movement_task_id: string,
    rating: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteMovementTaskRatingSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnDeleteMovementTaskRatingSubscription = {
  onDeleteMovementTaskRating?:  {
    __typename: "MovementTaskRating",
    id: string,
    identity_sub: string,
    movement_task_id: string,
    rating: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserMovementAnalysisResultSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnCreateUserMovementAnalysisResultSubscription = {
  onCreateUserMovementAnalysisResult?:  {
    __typename: "UserMovementAnalysisResult",
    id: string,
    identity_sub: string,
    followup?: string | null,
    internal_comment?: string | null,
    user_assignments?:  {
      __typename: "ModelUserAssignmentConnection",
      items:  Array< {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    evaluation_score_total?: number | null,
    user_screenshot?: string | null,
    evaluation_measurements?: string | null,
    feedback_texts?: string | null,
    status?: AnalysisResultStatus | null,
    draft_approved_at?: string | null,
    draft_approved_by?: string | null,
    approved_at?: string | null,
    approved_by?: string | null,
    draft_at?: string | null,
    draft_by?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserMovementAnalysisResultSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnUpdateUserMovementAnalysisResultSubscription = {
  onUpdateUserMovementAnalysisResult?:  {
    __typename: "UserMovementAnalysisResult",
    id: string,
    identity_sub: string,
    followup?: string | null,
    internal_comment?: string | null,
    user_assignments?:  {
      __typename: "ModelUserAssignmentConnection",
      items:  Array< {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    evaluation_score_total?: number | null,
    user_screenshot?: string | null,
    evaluation_measurements?: string | null,
    feedback_texts?: string | null,
    status?: AnalysisResultStatus | null,
    draft_approved_at?: string | null,
    draft_approved_by?: string | null,
    approved_at?: string | null,
    approved_by?: string | null,
    draft_at?: string | null,
    draft_by?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserMovementAnalysisResultSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnDeleteUserMovementAnalysisResultSubscription = {
  onDeleteUserMovementAnalysisResult?:  {
    __typename: "UserMovementAnalysisResult",
    id: string,
    identity_sub: string,
    followup?: string | null,
    internal_comment?: string | null,
    user_assignments?:  {
      __typename: "ModelUserAssignmentConnection",
      items:  Array< {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    evaluation_score_total?: number | null,
    user_screenshot?: string | null,
    evaluation_measurements?: string | null,
    feedback_texts?: string | null,
    status?: AnalysisResultStatus | null,
    draft_approved_at?: string | null,
    draft_approved_by?: string | null,
    approved_at?: string | null,
    approved_by?: string | null,
    draft_at?: string | null,
    draft_by?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateLeadSubscription = {
  onCreateLead?:  {
    __typename: "Lead",
    id: string,
    firstname: string,
    lastname: string,
    company: string,
    email_address: string,
    tenant_id?: string | null,
    code?: string | null,
    code_send_at?: string | null,
    code_used_at?: string | null,
    user_id?: string | null,
    consent_conditions_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateLeadSubscription = {
  onUpdateLead?:  {
    __typename: "Lead",
    id: string,
    firstname: string,
    lastname: string,
    company: string,
    email_address: string,
    tenant_id?: string | null,
    code?: string | null,
    code_send_at?: string | null,
    code_used_at?: string | null,
    user_id?: string | null,
    consent_conditions_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteLeadSubscription = {
  onDeleteLead?:  {
    __typename: "Lead",
    id: string,
    firstname: string,
    lastname: string,
    company: string,
    email_address: string,
    tenant_id?: string | null,
    code?: string | null,
    code_send_at?: string | null,
    code_used_at?: string | null,
    user_id?: string | null,
    consent_conditions_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserMovementAnalysisAssetSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnCreateUserMovementAnalysisAssetSubscription = {
  onCreateUserMovementAnalysisAsset?:  {
    __typename: "UserMovementAnalysisAsset",
    id: string,
    identity_sub: string,
    user_assignment_id: string,
    user_assignment?:  {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    type?: AnalysisAssetType | null,
    src_file?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserMovementAnalysisAssetSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnUpdateUserMovementAnalysisAssetSubscription = {
  onUpdateUserMovementAnalysisAsset?:  {
    __typename: "UserMovementAnalysisAsset",
    id: string,
    identity_sub: string,
    user_assignment_id: string,
    user_assignment?:  {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    type?: AnalysisAssetType | null,
    src_file?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserMovementAnalysisAssetSubscriptionVariables = {
  identity_sub?: string | null,
};

export type OnDeleteUserMovementAnalysisAssetSubscription = {
  onDeleteUserMovementAnalysisAsset?:  {
    __typename: "UserMovementAnalysisAsset",
    id: string,
    identity_sub: string,
    user_assignment_id: string,
    user_assignment?:  {
      __typename: "UserAssignment",
      id: string,
      successor_assignment?:  {
        __typename: "ModelUserAssignmentConnection",
        nextToken?: string | null,
      } | null,
      identity_sub: string,
      source_type: AssignmentSourceType,
      predecessor_id?: string | null,
      predecessor_assignment?:  {
        __typename: "UserAssignment",
        id: string,
        identity_sub: string,
        source_type: AssignmentSourceType,
        predecessor_id?: string | null,
        result_id?: string | null,
        source_id: string,
        completed_at?: string | null,
        started_at?: string | null,
        due_at?: string | null,
        created_by_client_id?: string | null,
        repetitions_done?: number | null,
        deleted_at?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result_id?: string | null,
      result?:  {
        __typename: "UserMovementAnalysisResult",
        id: string,
        identity_sub: string,
        followup?: string | null,
        internal_comment?: string | null,
        evaluation_score_total?: number | null,
        user_screenshot?: string | null,
        evaluation_measurements?: string | null,
        feedback_texts?: string | null,
        status?: AnalysisResultStatus | null,
        draft_approved_at?: string | null,
        draft_approved_by?: string | null,
        approved_at?: string | null,
        approved_by?: string | null,
        draft_at?: string | null,
        draft_by?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      analysis_assets?:  {
        __typename: "ModelUserMovementAnalysisAssetConnection",
        nextToken?: string | null,
      } | null,
      source_id: string,
      completed_at?: string | null,
      started_at?: string | null,
      due_at?: string | null,
      created_by_client_id?: string | null,
      repetitions_done?: number | null,
      deleted_at?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    type?: AnalysisAssetType | null,
    src_file?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDeletedUserSubscription = {
  onCreateDeletedUser?:  {
    __typename: "DeletedUser",
    id: string,
    type: DeletedUserType,
    email: string,
    original_user_id: string,
    tenant_id?: string | null,
    license_id?: string | null,
    username?: string | null,
    request_received_at?: string | null,
    identity_deleted_at?: string | null,
    braze_profile_deleted_at?: string | null,
    aws_pinpoint_profile_deleted_at?: string | null,
    crisp_profile_deleted_at?: string | null,
    intercom_profile_deleted_at?: string | null,
    completed_by?: string | null,
    completed_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDeletedUserSubscription = {
  onUpdateDeletedUser?:  {
    __typename: "DeletedUser",
    id: string,
    type: DeletedUserType,
    email: string,
    original_user_id: string,
    tenant_id?: string | null,
    license_id?: string | null,
    username?: string | null,
    request_received_at?: string | null,
    identity_deleted_at?: string | null,
    braze_profile_deleted_at?: string | null,
    aws_pinpoint_profile_deleted_at?: string | null,
    crisp_profile_deleted_at?: string | null,
    intercom_profile_deleted_at?: string | null,
    completed_by?: string | null,
    completed_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDeletedUserSubscription = {
  onDeleteDeletedUser?:  {
    __typename: "DeletedUser",
    id: string,
    type: DeletedUserType,
    email: string,
    original_user_id: string,
    tenant_id?: string | null,
    license_id?: string | null,
    username?: string | null,
    request_received_at?: string | null,
    identity_deleted_at?: string | null,
    braze_profile_deleted_at?: string | null,
    aws_pinpoint_profile_deleted_at?: string | null,
    crisp_profile_deleted_at?: string | null,
    intercom_profile_deleted_at?: string | null,
    completed_by?: string | null,
    completed_at?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateManagementSubscription = {
  onCreateManagement?:  {
    __typename: "Management",
    id: string,
    index?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateManagementSubscription = {
  onUpdateManagement?:  {
    __typename: "Management",
    id: string,
    index?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteManagementSubscription = {
  onDeleteManagement?:  {
    __typename: "Management",
    id: string,
    index?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAuditLogSubscription = {
  onCreateAuditLog?:  {
    __typename: "AuditLog",
    id: string,
    time: string,
    typeName?: string | null,
    fieldName?: string | null,
    arguments?: string | null,
    userSub: string,
    userId: string,
    clientId?: string | null,
    ip?: string | null,
    sourceDevice?: string | null,
    origin?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAuditLogSubscription = {
  onUpdateAuditLog?:  {
    __typename: "AuditLog",
    id: string,
    time: string,
    typeName?: string | null,
    fieldName?: string | null,
    arguments?: string | null,
    userSub: string,
    userId: string,
    clientId?: string | null,
    ip?: string | null,
    sourceDevice?: string | null,
    origin?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAuditLogSubscription = {
  onDeleteAuditLog?:  {
    __typename: "AuditLog",
    id: string,
    time: string,
    typeName?: string | null,
    fieldName?: string | null,
    arguments?: string | null,
    userSub: string,
    userId: string,
    clientId?: string | null,
    ip?: string | null,
    sourceDevice?: string | null,
    origin?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserMappingSubscriptionVariables = {
  custom_user_id?: string | null,
};

export type OnCreateUserMappingSubscription = {
  onCreateUserMapping?:  {
    __typename: "UserMapping",
    id: string,
    custom_user_id: string,
    identity_sub: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserMappingSubscriptionVariables = {
  custom_user_id?: string | null,
};

export type OnUpdateUserMappingSubscription = {
  onUpdateUserMapping?:  {
    __typename: "UserMapping",
    id: string,
    custom_user_id: string,
    identity_sub: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserMappingSubscriptionVariables = {
  custom_user_id?: string | null,
};

export type OnDeleteUserMappingSubscription = {
  onDeleteUserMapping?:  {
    __typename: "UserMapping",
    id: string,
    custom_user_id: string,
    identity_sub: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateJobSubscription = {
  onCreateJob?:  {
    __typename: "Job",
    id: string,
    name: string,
    type: JobType,
    tenants?:  {
      __typename: "ModelJobTenantConnection",
      items:  Array< {
        __typename: "JobTenant",
        id: string,
        job_id: string,
        tenant_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateJobSubscription = {
  onUpdateJob?:  {
    __typename: "Job",
    id: string,
    name: string,
    type: JobType,
    tenants?:  {
      __typename: "ModelJobTenantConnection",
      items:  Array< {
        __typename: "JobTenant",
        id: string,
        job_id: string,
        tenant_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteJobSubscription = {
  onDeleteJob?:  {
    __typename: "Job",
    id: string,
    name: string,
    type: JobType,
    tenants?:  {
      __typename: "ModelJobTenantConnection",
      items:  Array< {
        __typename: "JobTenant",
        id: string,
        job_id: string,
        tenant_id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateClientConfigurationSubscription = {
  onCreateClientConfiguration?:  {
    __typename: "ClientConfiguration",
    id: string,
    minimum_version: string,
    valid_from?: string | null,
    valid_to?: string | null,
    configured_by?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateClientConfigurationSubscription = {
  onUpdateClientConfiguration?:  {
    __typename: "ClientConfiguration",
    id: string,
    minimum_version: string,
    valid_from?: string | null,
    valid_to?: string | null,
    configured_by?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteClientConfigurationSubscription = {
  onDeleteClientConfiguration?:  {
    __typename: "ClientConfiguration",
    id: string,
    minimum_version: string,
    valid_from?: string | null,
    valid_to?: string | null,
    configured_by?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ModelTeamAccessFilterInput = {
  tenant_access_id?: ModelIDInput | null,
  access_code?: ModelStringInput | null,
  tenant_id?: ModelIDInput | null,
  and?: Array< ModelTeamAccessFilterInput | null > | null,
  or?: Array< ModelTeamAccessFilterInput | null > | null,
  not?: ModelTeamAccessFilterInput | null,
}

export type CreateTeamAccessInput = {
  access_code: ModelStringInput,
  tenant_access_id: ModelIDInput,
  tenant_id: ModelIDInput,
  validity: string,
}

export type UpdateTeamAccessInput = {
  access_code: ModelStringInput,
  validity: string,
  id: string,
}

export type TeamAccessFields = {
  validity: string;
  access_code: string;
  tenant_access_id: string;
  team_id?: string;
}