import { BodyPart } from './anamnesis.model';
import { MovementTaskAnalysisSetup } from './movement-analysis-setup.model';

export enum MovementTrainingStructureType {
  MODULE = 'modules',
  BLOCK = 'blocks',
  UNIT = 'units',
  TASK = 'tasks',
  USER_ASSIGNMENT = 'userAssignments',
}

export enum AssignmentSourceType {
  MOVEMENT_TASK = 'MOVEMENT_TASK',
  ANAMNESE = 'ANAMNESE',
}

export enum AnalysisResultStatus {
  DRAFT = 'DRAFT',
  COMPLETE = 'COMPLETE',
}

export enum MovementTaskType {
  CONTENT = 'CONTENT',
  WARMUP_CONTENT = 'WARMUP_CONTENT',
  CONTENT_WITH_VARIATION = 'CONTENT_WITH_VARIATION',
  ANAMNESE = 'ANAMNESE',
  PAIN_FEEDBACK = 'PAIN_FEEDBACK',
  ANALYSIS_T0 = 'ANALYSIS_T0',
  ANALYSIS_T1 = 'ANALYSIS_T1',
  ANALYSIS_FEEDBACK = 'ANALYSIS_FEEDBACK',
}

export enum SegmentType {
  THEORY = 'THEORY',
  PRACTICE = 'PRACTICE',
  ANALYSIS = 'ANALYSIS',
}

export enum ContraindicationType {
  ALL = 'ALL',
  NONE = 'NONE',
}

export enum JobType {
  CAREGIVER = 'CAREGIVER',
}

export type UserAssignment = {
  identitySub: string;
  id: string;
  sourceType: AssignmentSourceType;
  resultId?: string;
  result?: UserMovementAnalysisResult;
  sourceId: string;
  source: MovementTask;
  completedAt?: string;
  startedAt?: string;
  dueAt?: string;
  updatedByClientId?: string;
  disabled?: boolean;
  repetitionsDone: number;
  predecessorId?: string | null;
};

export type UserMovementAnalysisResult = {
  id: string;
  identitySub: string;
  followup: string | null;
  status?: AnalysisResultStatus | null;
  followupTask?: ExpandedTask;
  evaluation_measurements: string | null;
  feedback_texts: string | null;
};

export type DBUserAssignment = {
  id: string;
  identity_sub: string;
  source_type: AssignmentSourceType;
  source_id: string;
  result_id?: string;
  completed_at?: string;
  started_at?: string;
  due_at?: string;
  updated_by_client_id?: string;
  predecessor_id: string | null;
  disabled: boolean;
  repetitions_done: number;
};

export interface UserAssignmentWithIds extends UserAssignment {
  moduleId: string;
  blockId: string;
  unitId: string;
  segmentId: string;
}

export type MovementTaskRating = {
  identitySub: string;
  id: string;
  movementTaskId: string;
  rating: number;
};

export type BodyPartPain = {
  bodyPart: BodyPart;
  unacceptableSeverity: number;
};

export interface MovementTaskContent {
  title: string;
  body: string;
  variationsQuestion: string;
  variations?: {
    title: string;
    videoSrc: string;
    time?: number;
  }[];
  imgSrc: string;
  videoSrc: string;
  time?: number;
  content: {
    content: object;
  };
}

export type MovementTask = {
  parentBlockId: string;
  block: MovementBlock;
  taskType: MovementTaskType;
  taskPredecessor: string | null;
  id: string;
  unitPredecessor: string | null;
  unitId: Unit['id'];
  unit: Unit;
  segmentPredecessor: string | null;
  segmentId: Segment['id'];
  segment: Segment;
  segmentType: SegmentType;
  durationInSeconds: number;
  repetition: number;
  hasTaskRating: boolean;
  unacceptableContraindications: ContraindicationType[];
  bodyPartPains: BodyPartPain[];
  content?: MovementTaskContent;
  analysisSetup?: MovementTaskAnalysisSetup;
};

export type MovementBlock = {
  id: string;
  jobType: JobType;
  isModuleEntryPoint: boolean;
  modulePredecessor: string | null;
  moduleId: string;
  blockPredecessor: string | null;
  content?: {
    title: string;
    imgSrc: string;
  };
};

export type Module = {
  id: string;
  content?: {
    title: string;
  };
};

export type Unit = {
  id: string;
  content?: {
    title: string;
    imgSrc: string;
    tag: string;
  };
};

export type Segment = {
  id: string;
  content?: {
    title: string;
  };
};

export interface ExpandedTask extends MovementTask {
  userAssignment: UserAssignment;
}

export interface ExpandedSegment extends Segment {
  tasks: ExpandedTask[];
  segmentPredecessor?: string | null;
}

export interface ExpandedUnit extends Unit {
  segments: ExpandedSegment[];
  unitPredecessor?: string | null;
}

export interface ExpandedBlock extends MovementBlock {
  units: ExpandedUnit[];
}

export interface ExpandedModule extends Partial<Module> {
  blocks: ExpandedBlock[];
  modulePredecessor?: string | null;
}

export interface ParsedTrainingStructure {
  modules?: ExpandedModule[];
}

export interface UserAssignmentV2 {
  id: string;
  identitySub: string;
  predecessorId?: string | null;
  sourceId: string;
  sourceType: AssignmentSourceType;
  startedAt?: string;
  completedAt?: string;
}

export type DBUserAssignmentV2 = {
  id: string;
  identity_sub: string;
  source_type: AssignmentSourceType;
  source_id: string;
  source_task: Task;
  result_id?: string;
  completed_at?: string;
  started_at?: string;
  due_at?: string;
  updated_by_client_id?: string;
  predecessor_id: string | null;
  disabled: boolean;
};

export enum MovementTaskTypeV2 {
  CONTENT = 'CONTENT',
  ANAMNESE = 'ANAMNESE',
  CONTENT_WITH_VARIATION = 'CONTENT_WITH_VARIATION',
  ANALYSIS = 'ANALYSIS',
}

export interface Tool {
  title: string;
  content: string; // reason for using the tool in case of variations.
}

export interface MovementTaskContentV2 {
  id: string;
  title: string;
  body: string;
  variationsQuestion?: string;
  variations?: {
    tool: Tool;
    videoUrl: string;
  };
  animationSrc: string;
  audioSrc: string;
  imgSrc: string;
  videoSrc: string;
  durationSeconds: number;
  sys?: {
    id: string;
    revision: number;
  };
}

export interface MovementBlockContent {
  id: string;
  title: string;
  description: string;
  imgSrc: string;
  videoSrc: string;
  audioSrc: string;
  animationSrc: string;
}

export interface Task {
  id: string;
  userAssignment?: UserAssignmentV2;
  disabled?: boolean;
  parentBlockId: string;
  parentModuleId: string;
  taskType: MovementTaskTypeV2;
  taskPredecessor: string | null;
  unitPredecessor: string | null;
  unitId: UnitV2['id'];
  unacceptableContraindications: ContraindicationType[];
  content?: MovementTaskContentV2;
}

export interface UnitV2 {
  id: string;
  blockId: string;
  moduleId: string;
  tasks: Task[];
  locked?: boolean;
}

export interface Block {
  id: string;
  moduleId: string;
  jobType: JobType;
  isModuleEntryPoint: boolean;
  blockPredecessor: string | null;
  units: UnitV2[];
  content?: MovementBlockContent;
  locked?: boolean;
}

export interface ModuleV2 {
  id: string;
  blocks: Block[];
}
