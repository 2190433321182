import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { WatchedItemType } from 'api/API';
import { UserRoute } from '../config';
import { UserComponentBaseProps } from '../model';
import './subpages.scss';
import { UserWatchedItemHistory } from './UserWatchedItemHistory.screen';


export const UserCounting = (props: UserComponentBaseProps) => {
  const { user } = props;
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(UserRoute.BODY_EXCERCISES_COUNTING);

  const switchTab = (tab: string | null) => {
    setSelectedTab(tab as UserRoute);
  };

  return (
    <div>
      <Tabs defaultActiveKey={selectedTab} onSelect={switchTab}>
        <Tab eventKey={UserRoute.BODY_EXCERCISES_COUNTING} title={t('@T_UserHistory_BodyExercisesHistoryTab')} >
          <UserWatchedItemHistory user={user} label={t('@T_UserHistory_BodyExercisesHistoryTab')} type={WatchedItemType.BODY_EXERCISE} />
        </Tab>
        <Tab eventKey={UserRoute.MIND_EXCERCISES_COUNTING} title={t('@T_UserHistory_MindExercisesHistoryTab')} >
          <UserWatchedItemHistory user={user} label={t('@T_UserMindExercisesHistory_Title')} type={WatchedItemType.MIND_EXERCISE} />
        </Tab>
        <Tab eventKey={UserRoute.MIND_CALMING_SOUNDS_COUNTING} title={t('@T_UserMindCalmingSounds_Title')} >
          <UserWatchedItemHistory user={user} label={t('@T_UserMindCalmingSounds_Title')} type={WatchedItemType.MIND_CALMING_SOUNDS} />
        </Tab>
        <Tab eventKey={UserRoute.BODY_TRAINING_COUNTING} title={t('@T_UserHistory_BodyTrainingHistoryTab')}>
          <UserWatchedItemHistory user={user} label={t('@T_UserHistory_BodyTrainingHistoryTab')} type={WatchedItemType.BODY_TRAINING} />
        </Tab>
        <Tab eventKey={UserRoute.MIND_TRAINING_COUNTING} title={t('@T_UserHistory_MindTrainingHistoryTab')}>
          <UserWatchedItemHistory user={user} label={t('@T_UserHistory_MindTrainingHistoryTab')} type={WatchedItemType.MIND_TRAINING} />
        </Tab>
      </Tabs>
    </div>
  );
};