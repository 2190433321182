import React, { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useMutation, gql } from '@apollo/client';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import { LicenseOrigin } from 'api/API';

import { route } from 'utils';
import { Modal } from 'components/shared/modal/Modal.component';
import { useModal } from 'hooks/shared/useModal';
import { WithTooltip } from 'components/shared/with-tooltip';
import { useIsAdmin } from 'hooks';
import { Button, ButtonType } from 'components/shared/button/Button.component';
import { DATE_FORMAT_SECONDARY, LICENSES_LIMIT } from 'config/app.config';
import { Routes } from 'config/routes.config';
import { useFields } from 'hooks/shared/useFields';
import { createLicensesBulk as CREATE_LICENSES_BULK, createRegistrationCodesBulk as CREATE_REGISTRATION_CODES_BULK } from 'api/graphql/mutations';
import { getTenantWithLicenses, createLicensesBulkWithoutInvoices as CREATE_LICENSES_BULK_WITHOUT_INVOICES } from 'api/graphql/custom';
import { listRegistrationCodesByTenant } from '../../api/graphql/queries';

export const AddLicenses = (): JSX.Element => {
  const [leavePage, toggleLeavePage] = useState<boolean>(false);
  const { modalOpened, handleToggle, handleSecondaryButton } = useModal<void>(false, () => { toggleLeavePage(!leavePage); });
  const { t } = useTranslation();
  const { tenantId } = useParams<{ tenantId: string }>();
  const isAdmin = useIsAdmin();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fields, setFields, onUpdateField] = useFields<{ validTo: string; numberOfLicenses: number; invoiceId?: string }>({
    validTo: dayjs().add(1, 'year').format(DATE_FORMAT_SECONDARY),
    numberOfLicenses: 1,
    invoiceId: undefined,
  });

  const refetchQueries = [{
    query: gql`${getTenantWithLicenses}`,
    variables: {
      limit: LICENSES_LIMIT,
      id: tenantId,
    },
  }];

  const [createLicensesBulk] = useMutation(gql`${CREATE_LICENSES_BULK}`, { refetchQueries });
  const [createLicensesBulkWithoutInvoice] = useMutation(gql`${CREATE_LICENSES_BULK_WITHOUT_INVOICES}`, { refetchQueries });

  const [createRegistrationCodesBulk] = useMutation(gql`${CREATE_REGISTRATION_CODES_BULK}`, {
    refetchQueries: [
      {
        query: gql`${listRegistrationCodesByTenant}`,
        variables: {
          tenant_id: tenantId,
        },
      },
    ],
  });

  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  if (leavePage) return <Navigate to={route(Routes.TENANT_MANAGEMENT_OVERVIEW, { id: tenantId! })} />;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {

      // TODO refactor DRY SD-941
      // create licenses in chunks because AWS batch limit is 25.
      const chunks = _.chunk(Array(Number(fields.numberOfLicenses)).fill(null), 25);
      const createLicensesFn = fields.invoiceId ? createLicensesBulk : createLicensesBulkWithoutInvoice;
      const createLicenseRequest = chunks.map(chunk => createLicensesFn({
        variables: {
          tenantId,
          validTo: fields.validTo,
          numberOfLicenses: chunk.length,
          invoiceId: fields.invoiceId,
          origin: LicenseOrigin.ADMIN_APP,
        },
      }));
      await Promise.all(createLicenseRequest);

      // TODO refactor DRY SD-941
      // create registration in chunks because AWS batch limit is 25.
      const createRegistrationCodeRequest = chunks.map(chunk => createRegistrationCodesBulk({
        variables: {
          tenantId,
          validTo: fields.validTo,
          numberOfCodes: chunk.length,
        },
      }));
      await Promise.all(createRegistrationCodeRequest);

      navigate(route(Routes.TENANT_MANAGEMENT_OVERVIEW, { id: tenantId! }));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div data-testid='add-tenant'>
      <h2 className='page-title'>
        {t('@T_License_AddLicenses')}
      </h2>

      <div>
        <form className='form' onSubmit={handleSubmit}>
          <div className='form__field-container'>

            <div>
              <label className='required' htmlFor='numberoflicenses'>{t('@T_License_AmountOfLicenses')}</label>
              <input type='number' min='1' max='1000' id='numberoflicenses' value={fields.numberOfLicenses} onChange={onUpdateField('numberOfLicenses')} required />
            </div>

            <div>
              <label className='required' htmlFor='validto'>{t('@T_License_ValidTo')}</label>
              <input type='date' id='validto' value={fields.validTo} onChange={onUpdateField('validTo')} required />
            </div>

            <div>
              <label htmlFor='invoiceId'>{t('@T_License_Invoice')}</label>
              <input type='text' id='invoiceId' placeholder={t('@T_License_Invoice')} value={fields.invoiceId} onChange={onUpdateField('invoiceId')} name='invoiceId' />
            </div>
          </div>

          <div className='btn-group text-right'>
            <Button type='button' disabled={loading} buttonType={ButtonType.DANGER} onClick={handleToggle}>{t('@T_General_CancelLabel')}</Button>

            <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
              <Button type='submit' disabled={loading || !isAdmin} >{ loading ? t('@T_General_LoadingIndicator') : t('@T_General_SaveLabel') }</Button>
            </WithTooltip>
          </div>

        </form>
      </div>
      <Modal
        opened={modalOpened}
        onDanger={handleToggle}
        onPrimary={handleSecondaryButton}
        title={t('@T_General_DiscardChanges')}
        dangerButtonText={t('@T_General_CancelLabel')}
        primaryButtonText={t('@T_General_YesLeave')}
      />

    </div>
  );
};
