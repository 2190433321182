import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../utils';
import './Home.screen.scss';
import { DownloadLead } from './DownloadLead.component';

export const Home = () => {
  const { t } = useTranslation();
  const userData = useTypedSelector(it => it.auth.signIn.userData);
  const name = userData ? `${userData.givenName || ''} ${userData.familyName || ''}` : '';

  return (
    <div className='container pb-4'>
      {userData && (
        <h3 className='welcome-msg mt-3'>{t('@T_General_WelcomeMessage', { name, email: userData.email })}</h3>
      )}

      <h4 className='text-center'>{t('@T_Home_Under_Construction')}</h4>

      <DownloadLead />
    </div>
  );
};
