import React, { Fragment, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

import { useTypedSelector } from '../../utils';
import { Routes } from '../../config/routes.config';
import { Nav } from './Nav.component';

export const WithNav = () => {
  const { userData, isLoading } = useTypedSelector(it => it.auth.signIn);
  const navigate = useNavigate();

  useEffect(
    () => {
      if (!userData && !isLoading) {
        navigate(Routes.SIGN_IN);
      }
    },
    [userData, isLoading, navigate],
  );

  return (
    <Fragment>
      <div className='side-nav'>
        <Nav />
        <div className='container pt-4 px-5'>
          {!isLoading ? <Outlet /> : <div className='full-size-centered white-opacity'><Spinner animation='border' variant='primary' /></div>}
        </div>
      </div>
    </Fragment>
  );
};
