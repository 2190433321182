import { AuthActionTypes, AuthActions } from './actions';

export enum Group {
  admin = 'admin',
  lbCoach = 'lbcoach',
}

export interface UserData {
  givenName: string;
  familyName: string;
  email: string;
  subId: string;
  groups: Group[];
  'custom:user_id': string;
}

interface State {
  errorMessage: string;
  isLoading: boolean;
  userData: UserData | undefined;
}

const initialState: State = {
  errorMessage: '',
  isLoading: false,
  userData: undefined,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export function reducer(state = initialState, action: AuthActions): State {

  switch (action.type) {

    case AuthActionTypes.REHYDRATE_USER:
      return { ...state, isLoading: true };

    case AuthActionTypes.SIGN_IN:
      return { ...state, isLoading: true, errorMessage: '', userData: undefined };

    case AuthActionTypes.SIGN_IN_SUCCESS:
      return { ...state, isLoading: false, userData: action.payload };

    case AuthActionTypes.SIGN_IN_FAIL:
      return { ...state, isLoading: false, errorMessage: action.payload!.message };

    case AuthActionTypes.SIGN_OUT:
      return { ...state, userData: undefined };

    default:
      return state;
  }
}
