import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, gql } from '@apollo/client';
import dayjs from 'dayjs';

import { getTenantWithLicensesAndJobs, listTeamAccessByTenantId } from 'api/graphql/custom';
import { tenantBookedMindPackageByTenant, tenantBookedTrainingModuleByTenant, listDeletedUsersByTenant } from 'api/graphql/queries';
import { TenantBookedMindPackageByTenantQuery, TenantBookedTrainingModuleByTenantQuery, TenantCategory, ListDeletedUsersByTenantQuery, License, TeamAccessMapping, TeamAccessFields } from 'api/API';
import { teamAccessUrlDomain } from 'config/team-access-domain.config';
import { useFields } from 'hooks/shared/useFields';
import { LicenseList } from 'components/license/LicenseList.component';
import { EditTenant } from 'components/tenant/EditTenant.component';
import { TenantDetails } from 'components/tenant/TenantDetails.component';
import { RegistrationCodeList } from 'components/registrationCode/RegistrationCodeList.component';
import { CopyTeamAccessUrl } from 'components/tenant/CopyTeamAccessUrl';
import { LICENSES_LIMIT, PIE_CHART_COLORS_VARIANT_1, PIE_CHART_COLORS_VARIANT_2 } from 'config/app.config';
import { sortStringDates } from 'utils';
import { SelectedJobOption } from 'hooks/tenant/useAddTenant';
import { JobTenant } from 'global/models';
import { PieChartComponent } from 'components/piechart/PieChart.component';
import { LicenseListWithCheckboxes } from 'components/license/LicenseListWithCheckboxes.component';


export interface TenantFields {
  name: string;
  jobs: SelectedJobOption[];
  job_tenant_ids: string[];
  category?: TenantCategory;
}

interface PieChartData {
  label: string;
  value: number;
}

const EXPIRATION_TIME_IN_DAYS = 32;

export const TenantOverview = (): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const { loading, error, data, refetch } = useQuery(gql`${getTenantWithLicensesAndJobs}`, {
    variables: {
      id,
      limit: LICENSES_LIMIT,
    },
  });

  const { loading: loadingBookedMindPackages, data: bookedMindPackages } = useQuery<TenantBookedMindPackageByTenantQuery>(gql`${tenantBookedMindPackageByTenant}`, {
    variables: { tenant_id: id },
  });

  const { loading: loadingBookedTrainingModules, data: bookedTrainingModules } =
    useQuery<TenantBookedTrainingModuleByTenantQuery>(
      gql`
        ${tenantBookedTrainingModuleByTenant}
      `,
      {
        variables: { tenant_id: id },
      },
    );

  const { loading: loadingDeletedUsers, data: deletedUsersByTenant } = useQuery<ListDeletedUsersByTenantQuery>(gql`${listDeletedUsersByTenant}`, {
    variables: { tenant_id: id, limit: LICENSES_LIMIT },
  });

  const { loading: loadingTeamAccess, data: listTeamAccessData, refetch: refetchTeamAccess } = useQuery(
    gql`
      ${listTeamAccessByTenantId}
    `,
    {
      variables: { tenant_id: id },
    },
  );

  const teamAccessData: TeamAccessMapping  = !loadingTeamAccess && listTeamAccessData?.listTeamAccessByTenantId?.items[0];

  const [isEditMode, setEditMode] = useState<boolean>(false);

  const [fields, setFields, onUpdateField, onUpdateFieldWithValue] = useFields<TenantFields & TeamAccessFields>({
    name: '',
    jobs: [],
    job_tenant_ids: [],
    validity: '',
    access_code: '',
    tenant_access_id: '',
    team_id: '',
  });

  const toggleEdit = (cancelled = false) => {
    if (cancelled) {
      setFields({
        ...data.getTenant,
        jobs: data.getTenant.jobs.items.map((job_tenant: JobTenant) => ({
          value: job_tenant.job.id,
          label: job_tenant.job.name,
        })),
        job_tenant_ids: data.getTenant.jobs.items.map((job_tenant: JobTenant) => job_tenant.id),
        validity: teamAccessData?.validity || '',
        access_code: teamAccessData?.access_code || '',
        tenant_access_id: teamAccessData?.tenant_access_id,
        team_id: teamAccessData?.id,
      });
    }
    setEditMode(!isEditMode);
  };

  useEffect(() => {
    refetch();
    refetchTeamAccess();
    if (data && data.getTenant) {
      setFields({
        ...data.getTenant,
        jobs: data.getTenant.jobs.items.map((job_tenant: JobTenant) => ({
          value: job_tenant.job.id,
          label: job_tenant.job.name,
        })),
        validity: teamAccessData?.validity,
        access_code: teamAccessData?.access_code,
        tenant_access_id: teamAccessData?.tenant_access_id,
        team_id: teamAccessData?.id,
      });
    }
  }, [data, setFields, refetch, isEditMode]);

  const today = dayjs();
  const expiringDate = dayjs().add(EXPIRATION_TIME_IN_DAYS, 'day');

  const deletedUsers = deletedUsersByTenant?.listDeletedUsersByTenant?.items || [];
  const licenses: License[] = data?.getTenant?.licenses?.items || [];
  const assignedLicenses = licenses.filter(license => !!license.identity_sub).sort((a, b) => sortStringDates(a.validTo, b.validTo));
  const unassignedLicenses = licenses.filter(license => !license.identity_sub).sort((a, b) => sortStringDates(a.validTo, b.validTo));
  const unassignedValidLicenses = unassignedLicenses.filter(license => dayjs().isBefore(license.validTo));
  const unassignedExpiredLicenses = unassignedLicenses.filter(license => dayjs().isAfter(license.validTo));
  const assignedExpiredLicenses = assignedLicenses.filter(license => dayjs().isAfter(license.validTo));
  const allExpiredLicenses = assignedExpiredLicenses.concat(unassignedExpiredLicenses); 
  const expiringLicenses = licenses.filter(license => dayjs(license.validTo).isAfter(today) && dayjs(license.validTo).isBefore(expiringDate));

  const licensesChartData: PieChartData[] = [
    {
      label: t('@T_License_Assigned'),
      value: assignedLicenses.length,
    },
    {
      label: t('@T_License_Unassigned'),
      value: unassignedLicenses.length,
    },
  ];

  const expiredLicensesData: PieChartData[] = [
    {
      label: t('@T_License_Unassinged_Expired_Licenses'),
      value: unassignedExpiredLicenses.length,
    },
    {
      label: t('@T_License_Assinged_Expired_Licenses'),
      value: assignedExpiredLicenses.length,
    },
  ];

  const teamAcessInputClass = teamAccessData ? 'border-0 bg-transparent' : 'border';

  return (
    <div data-testid='tenant-overview'>

      {error && <div>{t('@T_General_Error')}</div>}

      {(loading || loadingBookedMindPackages || loadingBookedTrainingModules || loadingDeletedUsers) ?
        <div>{t('@T_General_LoadingIndicator')}</div>
        : (
          <div>
            { isEditMode ?
              <EditTenant {...{ fields, setFields, onUpdateField, toggleEdit, onUpdateFieldWithValue }} /> : (
                <div>
                  <TenantDetails
                    {
                    ...{
                      fields,
                      toggleEdit,
                      activeUsersCount: assignedLicenses.length - assignedExpiredLicenses.length,
                      deletedUsersCount: deletedUsers.length,
                      licensesCount: licenses.length,
                      bookedMindPackages: bookedMindPackages?.tenantBookedMindPackageByTenant,
                      bookedTrainingModules: bookedTrainingModules?.tenantBookedTrainingModuleByTenant,
                      availableLicensesCount: licenses.length - assignedLicenses.length - unassignedExpiredLicenses.length,
                    }
                  }
                  />
              <div className='col-5'>
                <h5 className='text-danger'>{t('@T_Team_Access')}</h5>
                <div>
                  <label className='card-header' htmlFor='validity'>{t('@T_Validity')}</label>
                  <input
                    className={teamAcessInputClass}
                    type='text'
                    id='validity'
                    placeholder={t('@T_Validity')}
                    value={teamAccessData?.validity}
                    disabled
                  />
                </div>
                <div>
                  <label className='card-header' htmlFor='access_code'>{t('@T_Access_Code')}</label>
                  <input
                    className={teamAcessInputClass}
                    type='text'
                    id='access_code'
                    placeholder={t('@T_Access_Code')}
                    value={teamAccessData?.access_code}
                    disabled
                  />
                </div>
                <div>
                  <label className='card-header' htmlFor='tenant_access_id'>
                    {t('@T_Tenant_Access_Url')}
                  </label>
                  <div className='d-flex align-items-baseline'>
                    {teamAccessData ? 
                      <p className='border-0 bg-transparent'>{teamAccessUrlDomain + teamAccessData.tenant_access_id}</p> 
                      : 
                      <input
                        className='border border-3 disabled'
                        type='text'
                        placeholder={t('@T_Tenant_Access_Url')}
                        value={''}
                        name='tenant_access_id'
                        disabled
                      />
                    }
                    {teamAccessData && <CopyTeamAccessUrl tenant_access_id={teamAccessData?.tenant_access_id} />}
                  </div>
                </div>
              </div>
                  <h2>{t('@T_License_Chart_Title')}</h2>
                    <div className='piecharts'>
                        <PieChartComponent
                        data={licensesChartData}
                        color={PIE_CHART_COLORS_VARIANT_1}
                        error={t('@T_License_Chart_No_Licenses')}
                      />
                      <PieChartComponent
                        data={expiredLicensesData}
                        color={PIE_CHART_COLORS_VARIANT_2}
                        error={t('@T_License_Chart_No_Expired_Licenses')}
                      />
                    </div>
                  <Tabs defaultActiveKey='licenses'>
                    <Tab eventKey='licenses' title={t('@T_License_Licenses_And_Users')}>
                      <LicenseList licenses={assignedLicenses.concat(unassignedValidLicenses)} />
                    </Tab>
                    <Tab eventKey='codes' title={t('@T_License_Codes')}>
                      <RegistrationCodeList tenantName={fields.name} />
                    </Tab>
                    <Tab eventKey='expiredLicenses' title={t('@T_License_Expired')}>
                      <LicenseListWithCheckboxes licenses={allExpiredLicenses} title={t('@T_License_Expired')} />
                    </Tab>
                    <Tab eventKey='expiringLicenses' title={t('@T_License_Expiring')}>
                      <LicenseListWithCheckboxes licenses={expiringLicenses} title= {t('@T_License_Expiring')}/>
                    </Tab>
                  </Tabs>
                </div>
              )}
          </div>
        )}
    </div>
  );
};
