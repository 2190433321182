import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation, gql } from '@apollo/client';
import { toast } from 'react-toastify';

import { WithTooltip } from 'components/shared/with-tooltip';
import { CreateJobMutation, CreateJobMutationVariables, JobType } from 'api/API';
import { Modal, Button, ButtonType } from 'components/shared';
import { useFields, useModal, useIsAdmin } from 'hooks';
import { listJobs } from 'api/graphql/queries';
import { createJob as CREATE_JOB } from 'api/graphql/mutations';
import { Routes } from 'config';

export interface JobFields {
  name: string;
  type?: JobType;
}

export const AddJob = (): JSX.Element => {
  const [leavePage, toggleLeavePage] = useState(false);
  const { modalOpened, handleToggle, handleSecondaryButton } = useModal(false, () => { toggleLeavePage(!leavePage); });
  const { t } = useTranslation();
  const isAdmin = useIsAdmin();
  const [fields, , onUpdateField] = useFields<JobFields>({
    name: '',
  });
  const [createJob] = useMutation<CreateJobMutation, CreateJobMutationVariables>(gql`${CREATE_JOB}`, {
    refetchQueries: [{
      query: gql`${listJobs}`,
    }],
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const formValid = fields.name && fields.type;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();

    try {
      await createJob({
        variables: {
          input: {
            name: fields.name,
            type: fields.type as JobType,
          },
        },
      });

      navigate(Routes.JOB_MANAGEMENT);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (leavePage) return <Navigate to={Routes.JOB_MANAGEMENT}/>;

  return (
    <div data-testid='add-job'>
      <h2 className='page-title'>
        {t('@T_Job_AddJob')}
      </h2>

      <div>
        <form className='form' onSubmit={handleSubmit}>
          <div className='form__field-container'>
            <div>
              <label htmlFor='name'>{t('@T_General_Name')}</label>
              <input type='text' required id='name' placeholder={t('@T_General_Name')} value={fields.name} onChange={onUpdateField('name')} name='name' maxLength={50}/>
            </div>

            <div className='form__field-container'>
              <div>
                <label htmlFor='job-type'>
                  {t('@T_Job_JobType')}
                  <select id='job-type' onChange={onUpdateField('type')}>
                    <option value=''>{t('@T_General_MakeAChoice')}</option>
                    {Object.values(JobType).map(jobType => (
                      <option key={jobType} value={jobType}>{jobType}</option>
                    ))}
                  </select>
                </label>
              </div>
            </div>
          </div>

          <div className='btn-group text-right'>
            <Button type='button' disabled={loading} buttonType={ButtonType.DANGER} onClick={handleToggle}>{t('@T_General_CancelLabel')}</Button>
            <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
              <Button type='submit' disabled={loading || !formValid || !isAdmin} >{ loading ? t('@T_General_LoadingIndicator') : t('@T_General_SaveLabel') }</Button>
            </WithTooltip>
          </div>

        </form>
      </div>
      <Modal
        opened={modalOpened}
        onDanger={handleToggle}
        onPrimary={handleSecondaryButton}
        title={t('@T_General_DiscardChanges')}
        dangerButtonText={t('@T_General_CancelLabel')}
        primaryButtonText={t('@T_General_YesLeave')}
      />

    </div>
  );
};
