import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GraphQLOptions } from '@aws-amplify/api-graphql';

export const useGraphQLApi = <GraphQLResultType>(graphQLOptions: GraphQLOptions) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<GraphQLResultType | null>(null);

  // This is to avoid the useEffect complaining about exhaustive-depts.
  const stringifiedOptions = JSON.stringify(graphQLOptions);
  useEffect(
    () => {
      const fetch = async () => {
        try {
          const options = JSON.parse(stringifiedOptions);
          setLoading(true);

          const result = await API.graphql(options) as GraphQLResultType;

          setData(result);

          setLoading(false);
        } catch (err) {
          setError(err as Error);
        }
      };

      fetch();
    },
    [stringifiedOptions],
  );

  return {
    data,
    loading,
    error,
  };
};
