import { ModelTeamAccessFilterInput, TeamAccessMapping } from 'api/API';
import { listTeamAccesss } from 'api/graphql/custom';
import { API } from 'aws-amplify';
import { QueryListResult } from 'global/models';
import cryptoRandomString from 'crypto-random-string';
import { toast } from 'react-toastify';

export const enum TeamAccessCreateUpdate {
  Create = 'create',
  Update = 'update',
}

export const fetchTeamAccess = async (code: string, filter: ModelTeamAccessFilterInput) => {
  const result = await API.graphql({
    query: listTeamAccesss,
    variables: {
      filter: filter,
    },
  }) as QueryListResult<'listTeamAccesss', TeamAccessMapping>;
  return result.data.listTeamAccesss.items;
};

export const generateTenantAccessId = async (pattern, length, setValue) => {
  try {
    const value = cryptoRandomString({ length: 4, type: pattern });
    const filter = { tenant_access_id: { eq: value } };
    const items = await fetchTeamAccess(value, filter);

    if (items.length > 0) {
      generateTenantAccessId(pattern, length, setValue);
    } else {
      setValue(value);
    }
  } catch (err) {
    const error = await err.message;
    setValue('');
    toast.error(error);
  }
};

