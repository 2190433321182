/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    'aws_project_region': 'eu-central-1',
    'aws_cognito_identity_pool_id': 'eu-central-1:9e6b9394-fbe5-4e82-bbdb-306fb3ab924a',
    'aws_cognito_region': 'eu-central-1',
    'aws_user_pools_id': 'eu-central-1_ayGJQkekc',
    'aws_user_pools_web_client_id': '3qvf9l01of242rc7bmh2btsb4i',
    'oauth': {},
    'aws_cognito_username_attributes': [
        'EMAIL'
    ],
    'aws_cognito_social_providers': [],
    'aws_cognito_signup_attributes': [
        'EMAIL'
    ],
    'aws_cognito_mfa_configuration': 'OFF',
    'aws_cognito_mfa_types': [
        'SMS'
    ],
    'aws_cognito_password_protection_settings': {
        'passwordPolicyMinLength': 8,
        'passwordPolicyCharacters': []
    },
    'aws_cognito_verification_mechanisms': [
        'EMAIL'
    ],
    'aws_appsync_graphqlEndpoint': 'https://cjs6amtk6febdgza3cqaeupiwa.appsync-api.eu-central-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-central-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
    'aws_appsync_apiKey': 'da2-4jlenuhskjeg3nok5qhtwkmxsa',
    'aws_cloud_logic_custom': [
        {
            'name': 'AdminQueries',
            'endpoint': 'https://c2gzehq008.execute-api.eu-central-1.amazonaws.com/prod',
            'region': 'eu-central-1'
        },
        {
            'name': 'chatHook',
            'endpoint': 'https://7xlvwmglj7.execute-api.eu-central-1.amazonaws.com/prod',
            'region': 'eu-central-1'
        },
        {
            'name': 'verifyEmailAddress',
            'endpoint': 'https://x7yq6i5fc7.execute-api.eu-central-1.amazonaws.com/prod',
            'region': 'eu-central-1'
        }
    ],
    'aws_mobile_analytics_app_id': '7739291a974f4b5db301f22e2f6d0f2e',
    'aws_mobile_analytics_app_region': 'eu-central-1',
    'aws_user_files_s3_bucket': 'lifebonusprod-prod',
    'aws_user_files_s3_bucket_region': 'eu-central-1'
};


export default awsmobile;

