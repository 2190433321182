import React, { ReactNode } from 'react';
import { ListBody } from './ListBody.component';
import { ListHeader } from './ListHeader.component';

interface ListProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const List = ({ children, ...restProps } : ListProps) => (
  <div {...restProps}>
    {children}
  </div>
);

List.Body = ListBody;
List.Header = ListHeader;
