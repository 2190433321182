import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Card } from 'react-bootstrap';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { AccordionToggle } from 'components/shared';
import { UserComponentBaseProps } from '../model';
import { DATE_TIME_FORMAT } from '../../../config/app.config';
import { SingleAnamnesis } from '../components/SingleAnamnesis.component';

export const UserHealthDataMind = ({ mindAnamnesisResults }: UserComponentBaseProps) => {
  const newestReport = mindAnamnesisResults?.[0];
  const olderReports = mindAnamnesisResults?.slice(1);

  const [activeIds, setActiveIds] = useState<string[]>([]);
  const { t } = useTranslation();

  const onToggle = (id: string) => () => {
    if (activeIds.includes(id)) {
      setActiveIds(activeIds.filter(activeId => id !== activeId));
    } else {
      setActiveIds([...activeIds, id]);
    }
  };

  if (!newestReport) {
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <h3>{t('@T_Anamnesis_NoReportsInfo')}</h3>
      </div>
    );
  }

  return (
    <div className='pb-5'>
      <Card>
        <Card.Header>
          {t('@T_Anamnesis_AnamnesisDate', { date: dayjs(newestReport.processed_at).format(DATE_TIME_FORMAT) })}
        </Card.Header>
        <Card.Body>
          <SingleAnamnesis anamnesis={newestReport} />
        </Card.Body>
      </Card>
      {olderReports?.map(anamnesis => (
        <Accordion key={anamnesis.id}>
          <Card>
            <AccordionToggle eventKey={anamnesis.id} onClick={onToggle(anamnesis.id)}>
              <div className='d-flex justify-content-between align-items-center'>
                {t('@T_Anamnesis_AnamnesisDate', { date: dayjs(anamnesis.processed_at).format(DATE_TIME_FORMAT) })}
                <FontAwesomeIcon icon={activeIds.includes(anamnesis.id) ? faChevronUp : faChevronDown} />
              </div>

            </AccordionToggle>
            <Accordion.Collapse eventKey={anamnesis.id}>
              <Card.Body>
                <SingleAnamnesis anamnesis={anamnesis} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ))}

    </div>
  );
};
