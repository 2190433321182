import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { API, graphqlOperation } from 'aws-amplify';

import { WithTooltip, List, ListBody, ListHeader } from 'components/shared';
import { useErrorToast, useIsAdmin, usePromise } from '../../hooks';
import { route } from '../../utils';
import { Routes } from '../../config';
import { ClientConfiguration } from '../../global/models/client-configuration.model';
import { QueryResult } from '../../global/models/helper.model';
import { getClientConfiguration } from '../../api/graphql/queries';

export const ClientManagement = () => {

  const [clientConfiguration, setClientConfiguration] = useState<ClientConfiguration | undefined>(undefined);
  const isAdmin = useIsAdmin();
  const { t } = useTranslation();

  const onLoadClientConfiguration = () => (
    API.graphql(graphqlOperation(getClientConfiguration, { id: '1' })) as Promise<QueryResult<'getClientConfiguration', ClientConfiguration>>)
    .then(result => {
      const configuration = result.data.getClientConfiguration;
      setClientConfiguration(configuration);
    })
    .catch(e => {
      toast.error(e.message);
    });

  const [loadClientConfiguration, { isLoading, error: loadingError }] = usePromise<void>(onLoadClientConfiguration);

  useEffect(() => {
    loadClientConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useErrorToast(loadingError);

  return (
    <div className='container pb-4'>

      <List>
        <ListHeader title={t('@T_ClientManagement_Head_Title')} />

        <ListBody
          isLoading={isLoading}
          errorMessage={loadingError?.message}
        >
          <table>
            <thead>
              <tr>
                <th>
                {t('@T_ClientManagement_Minimum_Version')}
                </th>
                <th>
                {t('@T_ClientManagement_Actions')}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr key={clientConfiguration?.id}>
                <td>{clientConfiguration?.minimum_version}</td>
                <td>
                  <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
                    <Link to={route(Routes.CLIENT_MANAGEMENT_EDIT, { id: clientConfiguration?.id! })} className={`btn btn-secondary text-light ${!isAdmin ? 'disabled' : ''}`}>
                      {t('@T_ClientManagement_Edit')}
                    </Link>
                  </WithTooltip>
                </td>
              </tr>
            </tbody>
          </table>
        </ListBody>
      </List>
    </div>
  );
};
