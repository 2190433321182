import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { API, graphqlOperation } from 'aws-amplify';

import { Routes } from 'config/routes.config';
import { RegistrationCode, ModelAttributeTypes } from 'api/API';
import { prepareFilename, route } from 'utils';
import { listRegistrationFreeCodes } from 'api/graphql/custom';
import { QueryListResult } from 'global/models/helper.model';
import { List, ListHeader } from 'components/shared';
import { UsedRegistrationCodeList } from './UsedRegistrationCodeList.component';
import { FreeRegistrationCodeList } from './FreeRegistrationCodeList.component';
import './RegistrationCodeList.component.scss';

export interface RegistrationCodeListProps {
  tenantName: string;
}

type Result = QueryListResult<'listRegistrationCodesByTenant', RegistrationCode>;
type NextTokenUnion = string | null;

export const RegistrationCodeList = ({ tenantName }: RegistrationCodeListProps): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [CSVData, setCSVData] = useState<RegistrationCode[]>([]);

  const fetchCSVData = async (nextToken?: NextTokenUnion) => {
    const result = await API.graphql(graphqlOperation(listRegistrationFreeCodes, {
      tenant_id: id,
      filter: {
        not:
        {
          license_id: {
            attributeType: ModelAttributeTypes.string,
          },
        },
      },
      nextToken,
    })) as Result;

    setCSVData(prevData => [...prevData, ...result?.data?.listRegistrationCodesByTenant?.items]);
    if (result?.data?.listRegistrationCodesByTenant?.nextToken) {
      fetchCSVData(result?.data?.listRegistrationCodesByTenant?.nextToken);
    }
  };

  useEffect(
    () => {
      fetchCSVData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div>
      <List>
        <ListHeader title={`${t('@T_License_Codes')}`}>
          <Link to={route(Routes.TENANT_MANAGEMENT_ADD_CODES, { tenantId: id! })} className='btn btn-secondary text-light' style={{ marginRight: 10 }}>
            {t('@T_License_GenerateCodes')}
          </Link>

          <CSVLink
            data={CSVData}
            headers={[
              { label: t('@T_License_Code'), key: 'code' },
              { label: t('@T_General_CreatedAt'), key: 'createdAt' },
              { label: t('@T_RegistrationCode_Reserved'), key: 'reserved_at' },
            ]}
            filename={prepareFilename(`${tenantName}_codes`, 'csv')}
            className='btn btn-secondary text-light'
            target='_blank'
            rel='noreferrer'
          >
            {t('@T_License_ExportCodes')}
          </CSVLink>

        </ListHeader>

        <div className='registration-code-lists-container'>
          <FreeRegistrationCodeList tenant_id={id!} />
          <UsedRegistrationCodeList tenant_id={id!} />
        </div>
      </List>
    </div>
  );
};
