import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Select, { OnChangeValue } from 'react-select';

import { TenantFields } from 'screens/tenant-management';
import { useModal } from 'hooks/shared/useModal';
import { useUpdateTenant } from 'hooks/tenant/useUpdateTenant';
import { useCreateOrUpdateTeamAccess } from 'hooks/tenant/useCreateOrUpdateTeamAccess';
import { useQuery, gql } from '@apollo/client';
import { listJobs } from 'api/graphql/queries';
import { teamAccessUrlDomain } from 'config/team-access-domain.config';
import { SelectedJobOption } from 'hooks/tenant/useAddTenant';
import { generateTenantAccessId, TeamAccessCreateUpdate } from 'screens/tenant-management/utils';
import { TeamAccessFields } from 'api/API';
import { Job } from '../../global/models';
import { Modal, Button, ButtonType, WithTooltip } from '../shared';
import { CopyTeamAccessUrl } from './CopyTeamAccessUrl';
interface ListJobsQuery {
  listJobs: {
    items: Job[];
  };
}

interface EditTenantProps {
  fields: TenantFields & TeamAccessFields;
  setFields: React.Dispatch<React.SetStateAction<TenantFields & TeamAccessFields>>;
  onUpdateField: (field: string) => (e: React.ChangeEvent<any>) => void;
  toggleEdit: (cancelled?: boolean) => void;
  onUpdateFieldWithValue: (field: string) => (e: any) => void;
}

export const EditTenant = ({ fields, setFields, onUpdateField, toggleEdit, onUpdateFieldWithValue }: EditTenantProps): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data: jobs, loading: loadingJobs, error: errorJobs } = useQuery<ListJobsQuery>(gql`${listJobs}`);
  const [initialTenantData, setInitialTenantData] = useState<TenantFields & TeamAccessFields>();

  useEffect(
    () => {
      if (fields.name) {
        setInitialTenantData(fields);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [!!fields.name],
  );

  const handleCreateOrUpdateTeamAccess = () => {
    if (initialTenantData?.tenant_access_id !== fields?.tenant_access_id && fields?.tenant_access_id) {
      return TeamAccessCreateUpdate.Create;
    } else if (initialTenantData?.access_code !== fields.access_code || initialTenantData?.validity !== fields.validity) {
      return TeamAccessCreateUpdate.Update;
    }
    return null;
  };

  const [updateTenant, loadingUpdate] = useUpdateTenant(fields, id!, initialTenantData?.name !== fields.name);
  const [createOrUpdateTeamAccess] = useCreateOrUpdateTeamAccess(fields, id!, handleCreateOrUpdateTeamAccess());

  const handleUpdateTenant = async (e: React.FormEvent<HTMLFormElement>) => {
    await createOrUpdateTeamAccess(e);
    await updateTenant(e);
    toggleEdit();
  };

  const handleChangeSelect = (selectedOption: SelectedJobOption[]) => {
    setFields({ ...fields, jobs: selectedOption });
  };

  const { modalOpened, handleToggle, handleSecondaryButton } = useModal<void>(false, () => {
    toggleEdit(true);
  });

  const teamAccessRequired = !!fields.access_code || !!fields.tenant_access_id || !!fields.validity;

  const generateTenantAccessIdCode = () => {
    generateTenantAccessId('url-safe', 4, (value: string) => {
      onUpdateFieldWithValue('tenant_access_id')(value);
    });
  };

  return (
    <div data-testid='edit-tenant'>
      <h2 className='page-title'>
        {t('@T_Customer_EditCustomer')}
      </h2>
      <form className='form' onSubmit={handleUpdateTenant}>

        <div className='form__field-container'>
          <div>
            <label className='required' htmlFor='name'>{t('@T_General_Name')}</label>
            <input
              type='text'
              id='name'
              placeholder={t('@T_General_Name')}
              value={fields.name.trim()}
              onChange={onUpdateField('name')}
              name='name'
              maxLength={50}
              required
            />
          </div>

          <div>
            <label htmlFor='job_id'>{t('@T_Job_SelectJob')}</label>
            { (!loadingJobs && !errorJobs) && (
            <Select
              isMulti
              id='tenant_id'
              isSearchable
              onChange={(selectedValue: OnChangeValue<SelectedJobOption, true>) => handleChangeSelect((selectedValue as SelectedJobOption[]))}
              value={fields.jobs}
              options={jobs!.listJobs.items.map((job: Job) => ({
                label: job.name,
                value: job.id,
              }))}
            />
            )}

            {
            (!loadingJobs && errorJobs) &&
            <div>{errorJobs.message}</div>
            }
          </div>
        </div>

        <div className='col-6 pt-4'>
          <h3>{t('@T_Team_Access')}</h3>
          <p className='fst-italic'>{t('@T_Team_Access_Form_Heading')}</p>
          <div>
            <label className={`${teamAccessRequired && 'required'}`} htmlFor='validity'>
              {t('@T_Validity')}
            </label>
            <input
              className='border'
              type='date'
              id='validity'
              value={fields.validity}
              onChange={onUpdateField('validity')}
              required={teamAccessRequired}
            />
            <p className='alert alert-danger text-black border border-2 border-danger'>{t('@T_Team_Access_Edit_Warning')}</p>
          </div>
          <div>
            <label
              className={`${teamAccessRequired && 'required'}`}
              htmlFor='access_code'
            >
              {t('@T_Access_Code')}
            </label>
            <input
              className='border'
              type='text'
              required={teamAccessRequired}
              id='access_code'
              placeholder={t('@T_Access_Code')}
              value={fields?.access_code?.trim()}
              onChange={onUpdateField('access_code')}
              name='access_code'
              minLength={8}
              maxLength={8}
            />
            <p>{t('@T_Access_Code_Validation_Msg')}</p>
          </div>
          <div>
            <label
              className={`${teamAccessRequired && 'required'}`}
              htmlFor='tenant_access_id'
            >
              {t('@T_Tenant_Access_Id')}
            </label>
            <div className='d-flex align-items-baseline'>
              <WithTooltip text={!initialTenantData?.tenant_access_id ? t('@T_Tenant_Access_Id_Tooltip') : ''} show={!initialTenantData?.tenant_access_id}>
                <input
                  className={`${
                    initialTenantData?.tenant_access_id
                      ? 'border-0 bg-transparent'
                      : 'border border-3 disabled'
                  }`}
                  type='text'
                  required={teamAccessRequired}
                  id='tenant_access_id'
                  placeholder={t('@T_Tenant_Access_Id')}
                  defaultValue={
                    initialTenantData?.tenant_access_id ? teamAccessUrlDomain + initialTenantData?.tenant_access_id : fields.tenant_access_id
                  }
                  name='tenant_access_id'
                  minLength={4}
                  maxLength={4}
                  autoComplete='off'
                  disabled={!!initialTenantData?.tenant_access_id}
                />
              </WithTooltip>
              {initialTenantData?.tenant_access_id ? (
                <CopyTeamAccessUrl tenant_access_id={initialTenantData?.tenant_access_id} />
              ) : (
                <Button
                  className='ms-3'
                  type='button'
                  disabled={!fields?.validity || fields?.access_code?.length !== 8}
                  onClick={generateTenantAccessIdCode}
                  buttonType={ButtonType.SECONDARY}
                >
                  {t('@T_Generate')}
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className='btn-group text-right'>
          <Button
            disabled={loadingUpdate}
            buttonType={ButtonType.DANGER}
            type='button'
            onClick={handleToggle}
          >{t('@T_General_CancelLabel')}
          </Button>
          <Button type='submit' disabled={loadingUpdate}>{t('@T_General_SaveLabel')}</Button>
        </div>

      </form>
      <Modal
        opened={modalOpened}
        onDanger={handleToggle}
        onPrimary={handleSecondaryButton}
        title={t('@T_General_DiscardChanges')}
        dangerButtonText={t('@T_General_CancelLabel')}
        primaryButtonText={t('@T_General_YesLeave')}
      />
    </div>
  );
};
