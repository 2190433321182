import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.scss';
import { SignIn } from './components/logIn/SignIn.component';
import './i18n';
import { Home } from './screens/home/Home.screen';
import { WithNav } from './components/nav/WithNav';
import { AddLicenses, AddTenant, TenantManagement, TenantOverview } from './screens/tenant-management';
import { AddJob, JobManagement, JobOverview } from './screens/job-management';
import { Routes as ConfigRoutes } from './config/routes.config';
import { UsersManagement } from './screens/users-management/UsersManagement.screen';
import { UserDetails, UserChangeTenant, UserChangeEmail } from './screens/users-management';
import { DeletedUsersManagement } from './screens/deleted-users-management/DeletedUsersManagement.screen';
import { AddRegistrationCodes } from './screens/tenant-management/AddRegistrationCodes.screen';
import { LicenseManagementOverview, LicenseManagementEdit } from './screens/license-management';
import { ClientManagement, ClientManagementEdit } from './screens/client-management';

function App() {
  return (
    <Router>
      <div className='App'>
        <Routes>
          <Route path={ConfigRoutes.SIGN_IN} element={<SignIn />} />
          <Route path='/' element={<WithNav />}>
            <Route path={ConfigRoutes.HOME} element={<Home />} />
            <Route path={ConfigRoutes.TENANT_MANAGEMENT} element={<TenantManagement />} />
            <Route path={ConfigRoutes.TENANT_MANAGEMENT_ADD} element={<AddTenant />} />
            <Route path={ConfigRoutes.TENANT_MANAGEMENT_OVERVIEW} element={<TenantOverview />} />
            <Route path={ConfigRoutes.LICENSE_MANAGEMENT} element={<LicenseManagementOverview />} />
            <Route path={ConfigRoutes.LICENSE_MANAGEMENT_EDIT} element={<LicenseManagementEdit />} />
            <Route path={ConfigRoutes.JOB_MANAGEMENT} element={<JobManagement />} />
            <Route path={ConfigRoutes.JOB_MANAGEMENT_ADD} element={<AddJob />} />
            <Route path={ConfigRoutes.JOB_MANAGEMENT_OVERVIEW} element={<JobOverview />} />
            <Route path={ConfigRoutes.USERS_MANAGEMENT} element={<UsersManagement />} />
            <Route path={ConfigRoutes.DELETED_USERS_MANAGEMENT} element={<DeletedUsersManagement />} />
            <Route path={ConfigRoutes.CLIENT_MANAGEMENT} element={<ClientManagement />} />
            <Route path={ConfigRoutes.CLIENT_MANAGEMENT_EDIT} element={<ClientManagementEdit />} />
            <Route path={ConfigRoutes.USER_DETAILS} element={<UserDetails />} />
            <Route path={ConfigRoutes.USER_CHANGE_TENANT} element={<UserChangeTenant />} />
            <Route path={ConfigRoutes.USER_CHANGE_EMAIL} element={<UserChangeEmail />} />
            <Route path={ConfigRoutes.TENANT_MANAGEMENT_ADD_LICENSES} element={<AddLicenses />} />
            <Route path={ConfigRoutes.TENANT_MANAGEMENT_ADD_CODES} element={<AddRegistrationCodes />} />
          </Route>
        </Routes>
      </div>
      <ToastContainer theme='colored' />
    </Router>
  );
}

export default App;
