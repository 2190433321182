import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { WithTooltip, List, ListBody, ListHeader } from 'components/shared';
import { useIsAdmin } from 'hooks';
import { Routes } from '../../config/routes.config';
import { route } from '../../utils';
import { Tenant } from '../../global/models';

interface TenantListProps {
  tenants: Tenant[];
}

export const TenantList = ({ tenants } : TenantListProps) => {
  const { t } = useTranslation();
  const isAdmin = useIsAdmin();

  return (
    <List>
      <ListHeader title={t('@T_Customer_ListOfCustomers')}>
        <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
          <Link to={Routes.TENANT_MANAGEMENT_ADD} className={`btn btn-secondary text-light ${!isAdmin ? 'disabled' : ''}`} >
            {t('@T_Customer_AddCustomer')}
          </Link>
        </WithTooltip>
      </ListHeader>

      <ListBody>
        <table>
          <thead>
            <tr>
              <th>{t('@T_General_Name')}</th>
              <th>{t('@T_Customer_LicensesBought')}</th>
            </tr>
          </thead>

          <tbody>
            {tenants.sort((a, b) => a.name.localeCompare(b.name)).map((tenant: Tenant) => (
              <tr key={tenant.id}>
                <td><Link to={route(Routes.TENANT_MANAGEMENT_OVERVIEW, { id: tenant.id as string })}>{tenant.name}</Link></td>
                <td>{tenant.licenses?.items?.length}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ListBody>
    </List>
  );
};
