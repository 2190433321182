import React, { useState, ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationCode } from 'api/API';
import { Modal } from 'components/shared';
import { isValidEmail } from 'utils';

export interface RegistrationMailHandlerData {
  code: string;
  name: string;
  email: string;
}

export interface RegistrationMailHandlerProps {
  code: RegistrationCode;
  onConfirm: (data: RegistrationMailHandlerData) => void;
  onCancel: () => void;
}

export const RegistrationMailHandler = ({ code, onConfirm, onCancel }: RegistrationMailHandlerProps) => {
  const { t } = useTranslation();
  const [email, setCurrentMail] = useState<string>('');
  const [name, setCurrentName] = useState<string>('');

  const confirm = () => {
    onConfirm({ name, email, code: code?.code! });
  };

  const setFieldValue = (setter: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    setter(event.target.value);
  };

  useEffect(
    () => {
      setCurrentMail('');
      setCurrentName('');
    },
    [code?.code],
  );

  return (
    <Modal
      opened={!!code}
      onDanger={onCancel}
      onPrimary={confirm}
      title={t('@T_RegistrationCode_SendCode')}
      dangerButtonText={t('@T_General_CancelLabel')}
      primaryButtonText={t('@T_RegistrationCode_SendCode')}
      disabled={!isValidEmail(email)}
    >
      <form>
        <div>
          <label className='required' htmlFor='email'>{t('@T_General_EmailLabel')}</label>
          <input
            type='email'
            id='email'
            placeholder={t('@T_General_EmailLabel')}
            value={email}
            onChange={setFieldValue(setCurrentMail)}
            name='email'
            required
          />
        </div>
        <div>
          <label htmlFor='name'>{t('@T_General_Name')}</label>
          <input
            type='text'
            id='name'
            placeholder={t('@T_General_Name')}
            value={name}
            onChange={setFieldValue(setCurrentName)}
            name='name'
          />
        </div>
      </form>
    </Modal>
  );
};
