import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Select, { OnChangeValue } from 'react-select';
import Form from 'react-bootstrap/Form';
import { useMutation, useQuery, gql } from '@apollo/client';
import { flatten, groupBy, chunk } from 'lodash';
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';

import { Job, MindPackageOrderGroup } from 'global/models';
import { listJobs } from 'api/graphql/queries';
import {
  SyncTenantJobsMutation,
  SyncTenantJobsMutationVariables,
  CreateTenantBookedTrainingModuleMutation,
  CreateTenantBookedMindPackageMutation,
  LicenseOrigin,
  TenantCategory,
  TeamAccessFields,
} from 'api/API';
import { Modal } from 'components/shared/modal/Modal.component';
import { useModal } from 'hooks/shared/useModal';
import { Button, ButtonType } from 'components/shared/button/Button.component';
import { DATE_FORMAT_SECONDARY, TENANTS_LIMIT, LICENSES_LIMIT } from 'config/app.config';
import { useFields } from 'hooks/shared/useFields';
import { TenantInput, SelectedJobOption } from 'hooks/tenant/useAddTenant';
import {
  createLicensesBulk as CREATE_LICENSES_BULK,
  createRegistrationCodesBulk as CREATE_REGISTRATION_CODES_BULK,
  createTenantWithCode as CREATE_TENANT,
  syncTenantJobs as SYNC_TENANT_JOBS,
  createTenantBookedTrainingModule as CREATE_TENANT_BOOKED_TRAINING_MODULE,
  createTenantBookedMindPackage as CREATE_TENANT_BOOKED_MIND_PACKAGE,
} from 'api/graphql/mutations';

import {
  listTenantsWithLicenses,
  createLicensesBulkWithoutInvoices as CREATE_LICENSES_BULK_WITHOUT_INVOICES,
  createTeamAccess as CREATE_TEAM_ACCESS,
} from 'api/graphql/custom';

import { Routes } from 'config/routes.config';
import { ContentfulContentType } from 'shared/contentful';
import { ContentfulContext } from 'providers';
import { mindPackagesItems, movementModules } from 'config/modules.config';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { WithTooltip } from 'components/shared/with-tooltip/WithTooltip.component';
import { generateTenantAccessId } from './utils';

interface ListJobsQuery {
  listJobs: {
    items: Job[];
  };
}

interface PaidMovementModule {
  id: string;
  withAnalysis: boolean;
  selected: boolean;
}

interface PaidModules {
  movement_modules: PaidMovementModule[];
  mind_groups: PaidMindPackageGroup[];
}
interface PaidMindPackage {
  name: string;
  id: string;
  group?: MindPackageOrderGroup;
}

interface PaidMindPackageGroup {
  id: string;
  selected: boolean;
  packages?: PaidMindPackage[];
}

export const AddTenant = (): JSX.Element => {
  const [leavePage, toggleLeavePage] = useState<boolean>(false);
  const { modalOpened, handleToggle, handleSecondaryButton } = useModal<void>(false, () => { toggleLeavePage(!leavePage); });
  const { t } = useTranslation();
  const { [ContentfulContentType.MIND_PACKAGES]: contentFulPackages } = useContext(ContentfulContext);
  const [fields, setFields, onUpdateField, onUpdateFieldWithValue] = useFields<TenantInput & PaidModules & TeamAccessFields>({
    name: '',
    identity_sub: '',
    tenant_id: '',
    invoiceId: '',
    tenantCategory: null,
    validTo: dayjs().add(1, 'year').format(DATE_FORMAT_SECONDARY),
    numberOfLicenses: 1,
    email_domains: [],
    job_ids: [],
    movement_modules: movementModules.map(({ id }) => ({ id, selected: false, withAnalysis: false })),
    mind_groups: [],
    validity: '',
    access_code: '',
    tenant_access_id: '',
  });
  const [createTenantWithCode] = useMutation(gql`${CREATE_TENANT}`);

  const refetchQueries = [{
    query: gql`${listTenantsWithLicenses}`,
    variables: {
      limit: TENANTS_LIMIT,
      licensesLimit: LICENSES_LIMIT,
    },
  }];

  const [createLicensesBulk] = useMutation(gql`${CREATE_LICENSES_BULK}`, { refetchQueries });
  const [createLicensesBulkWithoutInvoice] = useMutation(gql`${CREATE_LICENSES_BULK_WITHOUT_INVOICES}`, { refetchQueries });
  const [createRegistrationCodesBulk] = useMutation(gql`${CREATE_REGISTRATION_CODES_BULK}`);
  const [syncTenantJobs] = useMutation<SyncTenantJobsMutation, SyncTenantJobsMutationVariables>(gql`${SYNC_TENANT_JOBS}`);
  const [createTeamAccess] = useMutation(gql`${CREATE_TEAM_ACCESS}`);
  const { data: jobs, loading: loadingJobs, error: errorJobs } = useQuery<ListJobsQuery>(gql`${listJobs}`);
  const [loading, setLoading] = useState<boolean>(false);
  const [mindPackagesGroups, setMindPackagesGroups] = useState<PaidMindPackageGroup[]>([]);
  const navigate = useNavigate();
  const [isCheckAll, setIsCheckAll] = useState(false);

  useEffect(
    () => {
      const mappedPackages = mindPackagesItems.map(({ id, group }) => {
        const foundItem = contentFulPackages?.items?.find(item => item.fields.id === id);
        return { id: foundItem?.fields.id ?? id, name: foundItem?.fields.title ?? id, group };
      });

      const groupedPackages = groupBy(mappedPackages, (mindPackage: PaidMindPackage) => mindPackage.group || mindPackage.id);
      const groups: PaidMindPackageGroup[] = Object.entries(groupedPackages)
        .map(([id, packages]) => ({
          id,
          selected: false,
          packages,
        }));

      setMindPackagesGroups(groups);
      onUpdateFieldWithValue('mind_groups')(groups);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentFulPackages],
  );

  if (leavePage) return <Navigate to={Routes.TENANT_MANAGEMENT} />;

  const teamAccessRequired = !!fields.access_code || !!fields.tenant_access_id || !!fields.validity;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {

      const response = await createTenantWithCode({
        variables: {
          name: fields.name,
          category: fields.tenantCategory,
        },
      });

      const tenant = JSON.parse(response.data.createTenantWithCode);

      const trainingModulesPromises = fields.movement_modules
        .filter(({ selected }) => selected)
        .map(module => {
          const input = {
            tenant_id: tenant.id,
            module_id: module.id,
            with_analyse: module.withAnalysis,
          };

          return API.graphql({
            query: CREATE_TENANT_BOOKED_TRAINING_MODULE,
            variables: { input },
          });
        }) as Promise<CreateTenantBookedTrainingModuleMutation>[];

      const selectedPackages = fields.mind_groups
        .filter(({ selected }) => selected)
        .map(({ packages }) => packages)
        .filter(item => !!item) as PaidMindPackage[][];

      const mindPackagesPromises = flatten(selectedPackages)
        .map(({ id }) => {
          const input = {
            tenant_id: tenant.id,
            package_id: id,
          };

          return API.graphql({
            query: CREATE_TENANT_BOOKED_MIND_PACKAGE,
            variables: { input },
          });
        }) as Promise<CreateTenantBookedMindPackageMutation>[];

      // TODO refactor DRY SD-941
      // create licenses in chunks because AWS batch limit is 25.
      const chunks = chunk(Array(Number(fields.numberOfLicenses)).fill(null), 25);
      const createLicensesFn = fields.invoiceId ? createLicensesBulk : createLicensesBulkWithoutInvoice;
      const createLicenseRequests = chunks.map(items => createLicensesFn({
        variables: {
          tenantId: tenant.id,
          validTo: fields.validTo,
          numberOfLicenses: items.length,
          origin: LicenseOrigin.ADMIN_APP,
          invoiceId: fields.invoiceId,
        },
      }));

      // TODO refactor DRY SD-941
      // create registration in chunks because AWS batch limit is 25.
      const createRegistrationCodeRequest = chunks.map(items => createRegistrationCodesBulk({
        variables: {
          tenantId: tenant.id,
          validTo: fields.validTo,
          numberOfCodes: items.length,
        },
      }));

      await syncTenantJobs({
        variables: {
          tenantId: tenant.id,
          jobIds: fields.job_ids.map(job => job.value),
        },
      });

      if (teamAccessRequired) {
        await createTeamAccess({
          variables: {
            input: {
              access_code: fields.access_code,
              tenant_access_id: fields.tenant_access_id,
              tenant_id: tenant.id,
              validity: fields.validity,
            },
          },
        });
      }

      await Promise.all(trainingModulesPromises);
      await Promise.all(mindPackagesPromises);
      await Promise.all(createLicenseRequests);
      await Promise.all(createRegistrationCodeRequest);

      navigate(Routes.TENANT_MANAGEMENT);
    } catch (error) {

      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeSelect = (selectedOption: SelectedJobOption[]) => {
    setFields({ ...fields, job_ids: selectedOption });
  };

  const toggleMovementModuleField = (id: string, selected: boolean, key: keyof PaidMovementModule) => {
    const foundIndex = fields.movement_modules.findIndex(module => module.id === id);
    const value: PaidMovementModule[] = [...fields.movement_modules];
    value[foundIndex] = { ...value[foundIndex], [key]: selected };

    onUpdateFieldWithValue<PaidMovementModule[]>('movement_modules')(value);
  };

  const toggleMovementModule = (id: string, selected: boolean) => {
    toggleMovementModuleField(id, selected, 'selected');
  };

  const toggleAnalysis = (id: string, withAnalysis: boolean) => {
    toggleMovementModuleField(id, withAnalysis, 'withAnalysis');
  };

  const toggleMindPackage = (id: string, selected: boolean) => {
    const foundIndex = fields.mind_groups.findIndex(group => group.id === id);
    const value: PaidMindPackageGroup[] = [...fields.mind_groups];
    value[foundIndex] = { ...value[foundIndex], selected };

    onUpdateFieldWithValue('mind_groups')(value);
  };

  const handleSelectAll = () => {
    const updatedGroups = mindPackagesGroups.map(group => {
      return {
        ...group,
        selected: !isCheckAll,
      };
    });

    setIsCheckAll(!isCheckAll);
    onUpdateFieldWithValue('mind_groups')(updatedGroups);
  };

  const generateTenantAccessIdCode = () => {
    generateTenantAccessId('url-safe', 4, (value: string) => {
      onUpdateFieldWithValue('tenant_access_id')(value);
    });
  };

  return (
    <div data-testid='add-tenant'>
      <h2 className='page-title'>
        {t('@T_Customer_AddCustomer')}
      </h2>

      <div>
        <form className='form' onSubmit={handleSubmit}>
          <div className='form__field-container'>
            <div>
              <label className='required' htmlFor='name'>{t('@T_General_Name')}</label>
              <input type='text' required id='name' placeholder={t('@T_General_Name')} value={fields.name.trim()} onChange={onUpdateField('name')} name='name' maxLength={50}/>
            </div>

            <div>
              <label className='required' htmlFor='numberoflicenses'>{t('@T_Customer_NumberOfActiveLicenses')}</label>
              <input type='number' min='1' max='1000' id='numberoflicenses' value={fields.numberOfLicenses} onChange={onUpdateField('numberOfLicenses')} required />
            </div>

            <div>
              <label className='required' htmlFor='validto'>{t('@T_License_ValidTo')}</label>
              <input type='date' id='validto' value={fields.validTo} onChange={onUpdateField('validTo')} required />
            </div>

            <div>
              <label htmlFor='invoiceId'>{t('@T_License_Invoice')}</label>
              <input type='text' id='invoiceId' placeholder={t('@T_License_Invoice')} value={fields.invoiceId} onChange={onUpdateField('invoiceId')} name='invoiceId' />
            </div>

            <div style={{ marginBottom: 10 }}>
              <label htmlFor='tenant_category'>{t('@T_Tenant_CategoryLabel')}</label>
                <select required id='tenant_category' onChange={onUpdateField('tenantCategory')}>
                  <option value=''>{t('@T_General_MakeAChoice')}</option>
                  { [TenantCategory.TENANT, TenantCategory.TENANT_INTERNAL].map(type => <option key={type} value={type}>{type}</option>) }
                </select>
            </div>

            <div>
              <label htmlFor='job_id'>Select job</label>
              { (!loadingJobs && !errorJobs) && (
              <Select
                isMulti
                id='tenant_id'
                isSearchable
                onChange={(selectedValue: OnChangeValue<SelectedJobOption, true>) => handleChangeSelect((selectedValue as SelectedJobOption[]))}
                value={fields.job_ids}
                options={jobs?.listJobs.items.map((tenant: Job) => ({
                  label: tenant.name,
                  value: tenant.id as string,
                }))}
              />
              )}

              {
              (!loadingJobs && errorJobs) &&
              <div>{errorJobs.message}</div>
              }
            </div>

            <div className='row mt-3'>
              <div className='col-6'>
                <div className='mb-2'>{t('@T_Tenant_Movement_Modules')}</div>
                {movementModules.map(module => {
                  const moduleField = fields.movement_modules?.find(({ id }) => module.id === id);
                  return (
                    <div key={module.id} className='row ml-1 mb-2'>
                      <Form.Check
                        id={`${module.id}-selected`}
                        className='mr-4'
                        type='checkbox'
                        label={module.name}
                        checked={moduleField?.selected}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => toggleMovementModule(module.id, event.target.checked)}
                      />

                      <Form.Check
                        id={`${module.id}-withAnalysis`}
                        type='switch'
                        label={t('@T_Tenant_Movement_Modules_With_Analysis')}
                        checked={moduleField?.withAnalysis}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => toggleAnalysis(module.id, event.target.checked)}
                      />
                    </div>
                  );
                })}
              </div>

              <div className='col-6'>
                <div className='mb-2'>{t('@T_Tenant_Mind_Packages')}</div>
                <div className='mb-2'>
                  <Checkbox
                    id='selectAll'
                    handleClick={handleSelectAll}
                    isChecked={isCheckAll}
                  />
                  <span className='ml-1'>{t('@T_General_SelectAll')}</span>
                </div>
                {mindPackagesGroups.map(group => {
                  const mindPackage = fields.mind_groups.find(({ id }) => group.id === id);
                  return (
                    <div key={group.id} className='row ml-1 mb-2'>
                      <Form.Check
                        className='d-flex align-items-center'
                        id={`${group.id}-mindSelected`}
                        type='checkbox'
                        label={<div>{group?.packages?.map(item => <div key={item.id}>{item.id}: {item.name}</div>)}</div>}
                        checked={mindPackage?.selected}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => toggleMindPackage(mindPackage?.id!, event.target.checked)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

          </div>

          <div className='col-4'>
            <h5>{t('@T_Team_Access')}</h5>
            <p className='fst-italic'>{t('@T_Team_Access_Form_Heading')}</p>
            <div>
              <label className={`${teamAccessRequired && 'required'}`} htmlFor='validity'>
                {t('@T_Validity')}
              </label>
              <input
                className='border'
                type='date'
                id='validity'
                value={fields.validity}
                onChange={onUpdateField('validity')}
                required={teamAccessRequired}
              />
            </div>
            <div>
              <label
                className={`${teamAccessRequired && 'required'}`}
                htmlFor='access_code'
              >
                {t('@T_Access_Code')}
              </label>
              <input
                className='border'
                type='text'
                required={teamAccessRequired}
                id='access_code'
                placeholder={t('@T_Access_Code')}
                value={fields.access_code.trim()}
                onChange={onUpdateField('access_code')}
                name='access_code'
                minLength={8}
                maxLength={8}
              />
              <p>{t('@T_Access_Code_Validation_Msg')}</p>
            </div>
            <div>
              <label
                className={`${teamAccessRequired && 'required'}`}
                htmlFor='tenant_access_id'
              >
                {t('@T_Tenant_Access_Id')}
              </label>
              <div className='d-flex align-items-baseline'>
                <WithTooltip text={t('@T_Tenant_Access_Id_Tooltip')} show>
                  <input
                    className='border border-3 disabled'
                    type='text'
                    required={teamAccessRequired}
                    id='tenant_access_id'
                    placeholder={t('@T_Tenant_Access_Id')}
                    value={fields.tenant_access_id}
                    name='tenant_access_id'
                    minLength={4}
                    maxLength={4}
                    autoComplete='off'
                  />
                </WithTooltip>
                <Button
                  className='ms-3'
                  type='button'
                  disabled={!fields.validity || fields.access_code.length !== 8}
                  onClick={generateTenantAccessIdCode}
                  buttonType={ButtonType.SECONDARY}
                >
                  {t('@T_Generate')}
                </Button>
              </div>
            </div>
          </div>

          <div className='btn-group text-right mb-3 col-4 offset-8'>
            <Button type='button' disabled={loading} buttonType={ButtonType.DANGER} onClick={handleToggle}>{t('@T_General_CancelLabel')}</Button>
            <Button type='submit' disabled={loading} >{ loading ? t('@T_General_LoadingIndicator') : t('@T_General_SaveLabel') }</Button>
          </div>
        </form>
      </div>
      <Modal
        opened={modalOpened}
        onDanger={handleToggle}
        onPrimary={handleSecondaryButton}
        title={t('@T_General_DiscardChanges')}
        dangerButtonText={t('@T_General_CancelLabel')}
        primaryButtonText={t('@T_General_YesLeave')}
      />

    </div>
  );
};
