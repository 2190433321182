import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation, gql } from '@apollo/client';
import { toast } from 'react-toastify';

import { WithTooltip } from 'components/shared/with-tooltip';
import { GetJobQuery, UpdateJobTenantMutation, UpdateJobMutationVariables, CreateJobInput, JobType } from 'api/API';
import { getJob as GET_JOB } from 'api/graphql/queries';
import { updateJob as UPDATE_JOB } from 'api/graphql/mutations';
import { useModal, useIsAdmin } from 'hooks';
import { Button, ButtonType, Modal } from 'components/shared';
import { JobFields } from 'screens/job-management';

interface EditJobProps {
  fields: JobFields;
  setFields: React.Dispatch<React.SetStateAction<CreateJobInput>>;
  onUpdateField: (field: string) => (e: React.ChangeEvent<any>) => void;
  toggleEdit: (cancelled?: boolean) => void;
}

export const EditJob = ({ fields, setFields, onUpdateField, toggleEdit }: EditJobProps): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const isAdmin = useIsAdmin();
  const { loading, error, data } = useQuery<GetJobQuery>(gql`${GET_JOB}`, {
    variables: {
      id,
    },
  });
  const [leavePage, toggleLeavePage] = useState<boolean>(false);
  const { modalOpened, handleToggle, handleSecondaryButton } = useModal<void>(false, () => {
    toggleLeavePage(!leavePage);
  });
  const [updateJob, { loading: loadingUpdateJob, called }] = useMutation<UpdateJobTenantMutation, UpdateJobMutationVariables>(gql`${UPDATE_JOB}`);
  const [errorUpdateJob, setErrorUpdateJob] = useState('');

  const formValid = fields?.name && fields?.type;

  useEffect(() => {
    if (data && data.getJob) {
      setFields({
        name: data.getJob.name,
        id: data.getJob.id,
        type: data.getJob.type,
      });
    }
  }, [data, setFields]);

  if (leavePage || (called && !errorUpdateJob)) {
    toggleEdit();
  }

  const handleUpdateJob = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await updateJob({
        variables: {
          input: {
            id: id!,
            name: fields.name,
            type: fields.type,
          },
        },
      });

      toggleEdit();
    } catch (err: any) {
      setErrorUpdateJob(err.message);
      toast.error(err.message);
    }
  };

  return (
    <div data-testid='edit-job'>
      <h2 className='page-title'>
        {t('@T_Job_EditJob')}
      </h2>

      {(loading && !error) ?
        <div>{t('@T_General_LoadingIndicator')}</div>
        : (
          <div>
            <form className='form' onSubmit={handleUpdateJob}>
              <div className='form__field-container'>
                <div>
                  <label htmlFor='name'>{t('@T_General_Name')}</label>
                  <input type='text' required id='name' placeholder={t('@T_General_Name')} value={fields.name} onChange={onUpdateField('name')} name='name' maxLength={50}/>
                </div>
              </div>

              <div className='form__field-container'>
                <div>
                  <label htmlFor='type'>
                    {t('@T_Job_JobType')}
                    <select id='type' onChange={onUpdateField('type')} value={fields.type}>
                      <option value=''>{t('@T_General_MakeAChoice')}</option>
                      {Object.values(JobType).map(jobType => (
                        <option key={jobType} value={jobType} selected={jobType === fields.type}>{jobType}</option>
                      ))}
                    </select>
                  </label>
                </div>
              </div>

              <div className='btn-group text-right'>
                <Button type='button' disabled={loadingUpdateJob} buttonType={ButtonType.DANGER} onClick={handleToggle}>{t('@T_General_CancelLabel')}</Button>
                <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
                  <Button type='submit' disabled={loading || !formValid || !isAdmin} >{ loading ? t('@T_General_LoadingIndicator') : t('@T_General_SaveLabel') }</Button>
                </WithTooltip>
              </div>

            </form>
          </div>
        )}

      {(!loading && error) &&
      <div>{error.message}</div>}
      <Modal
        opened={modalOpened}
        onDanger={handleToggle}
        onPrimary={handleSecondaryButton}
        title={t('@T_General_DiscardChanges')}
        dangerButtonText={t('@T_General_CancelLabel')}
        primaryButtonText={t('@T_General_YesLeave')}
      />

    </div>
  );
};
