import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { License } from 'api/API';
import { getLicense } from 'api/graphql/queries';
import { usePromise, useFields } from 'hooks';
import { Button, ButtonType, List, ListBody, ListHeader } from 'components/shared';
import { User } from 'global/models';
import { getLocalTime, DATE_FORMAT_SECONDARY } from 'config/app.config';
import { updateLicense } from 'api/graphql/mutations';
import { useGraphQLApi } from 'hooks/useGraphQLApi';
import { QueryResult } from 'global/models/helper.model';
import { PartialLicense } from './LicenseManagementOverview.screen';

interface UpdateLicenseInput {
  id: string;
  user?: string | null;
  identity_sub?: string | null;
  validTo?: string;
}

export const LicenseManagementEdit = () => {
  const [batchLicense, setBatchLicense] = useState<PartialLicense[]>([]);
  const { state: licenseIds } = useLocation();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fields, setFields, onUpdateField] = useFields<{ validTo: string | undefined }>({
    validTo: '',
  });
  const { data, loading, error } = useGraphQLApi<QueryResult<'getLicense', License>>({
    query: getLicense,
    variables: {
      id,
    },
  });

  const license = data?.data?.getLicense;

  const updateLicenseQuery = (inputData: UpdateLicenseInput) => {
    return API.graphql({
      query: updateLicense,
      variables: {
        input: inputData,
      },
    });
  };

  const onSubmit = async () => {
    const inputData = {
      id: id as string,
      user: license?.user,
      identity_sub: license?.identity_sub,
      validTo: fields.validTo,
    };
    await updateLicenseQuery(inputData);

    navigate(-1);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [submit, { result: _result, isLoading: isSaving, error: errorSubmit }] = usePromise(onSubmit);
  const user: { UserAttributes: User } = license?.user ? JSON.parse(license.user) : null;
  const errorMessage = error?.message || errorSubmit?.errors?.[0]?.message;

  useEffect(
    () => {
      setFields({
        validTo: license?.validTo && getLocalTime(license.validTo!, DATE_FORMAT_SECONDARY),
      });
      setBatchLicense(JSON.parse(JSON.stringify(licenseIds)));

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [license],
  );
  const multiSubmit = async () => {
    if (!fields.validTo) {
      toast.warn('Please Select a date');
      return;
    }

    try {
      const promises = batchLicense?.map(element => {
        const inputData:UpdateLicenseInput = {
          id: element.id!,
          identity_sub: element?.identity_sub,
          validTo: fields.validTo,
        };
        return updateLicenseQuery(inputData);
      });
      await Promise.all(promises);
      navigate(-1);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <List>
      <ListHeader title='Renew license' />

      <ListBody
        isLoading={loading}
        errorMessage={errorMessage}
      >
        <p>User: {user?.UserAttributes?.email}</p>
        <p>User token: {user?.UserAttributes?.['custom:user_id']}</p>
        <p>Tenant: {license?.tenant?.name}</p>

        <div>
          <label className='required' htmlFor='validto'>{t('@T_License_ValidTo')}</label>
          <input type='date' id='validto' value={fields.validTo} onChange={onUpdateField('validTo')} required />
        </div>

        <div className='btn-group'>
          <Button buttonType={ButtonType.DANGER} onClick={() => navigate(-1)} >
            Cancel
          </Button>
          <Button onClick={licenseIds ? multiSubmit : submit} disabled={isSaving}>
            {isSaving ? 'Saving' : 'Save'}
          </Button>
        </div>
      </ListBody>
    </List>
  );
};
