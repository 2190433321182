import { useTranslation } from 'react-i18next';
import { API } from 'aws-amplify';
import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { MovementTaskTypeV2, Task, Block } from 'global/models/movement-training.model';
import {
  flattenModulesV2 as flattenModules,
  parseMovementsV2 as parseMovements,
  sortCompletedTasksV2 as sortCompletedTasks,
  getFlattenBlocks,
  getFutureTasks,
  getCompletedTasks,
  getBlocksIndexedById,
  getClusteredFutureBlocks,
} from 'global/services/movement-parser.service';

import { ButtonType, IconButton, List, ListBody, ListHeader, WithTooltip } from 'components/shared';
import { getUserMovementAssignments } from 'api/graphql/queries';
import { updateUserMovementAssignment } from 'api/graphql/mutations';
import { useModal, useIsAdmin } from 'hooks';
import { getLocalTime, DATE_TIME_FORMAT } from 'config';
import { UserComponentBaseProps } from '../model';
import { ResetModal } from '../components/ResetModal.component';

const REFETCH_INTERVAL = 5 * 60 * 1000; // 5 minutes

interface UserAssignmentList {
  data: {
    getUserMovementAssignments: string;
  };
}

export const UserMovementProgress = ({ user }: UserComponentBaseProps) => {
  const [futureTasks, setFutureTasks] = useState<Task[]>([]);
  const [clusteredBlocks, setClusteredBlocks] = useState<Record<string, Block>>({});
  const [futureBlocks, setFutureBlocks] = useState<Record<string, Task[]>>({});
  const [completedTasks, setCompletedTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const { t } = useTranslation();
  const firstTask = futureTasks?.[0];
  const isAdmin = useIsAdmin();

  const modifyFutureBlocks = (updatedFutureBlocks: Record<string, Task[]>, blockId: string) => {
    if (updatedFutureBlocks[blockId].length === 0) {
      _.unset(updatedFutureBlocks, blockId);
    }
  };

  const {
    modalOpened: completeAssignmentOpen,
    handleToggle: toggleCompleteAssignmentModal,
    handleSecondaryButton: confirmAssignmentCompletion,
  } = useModal(
    false,
    async () => {
      setIsLoading(true);
      const completedAt = new Date().toISOString();

      const input = {
        id: firstTask.userAssignment?.id,
        completed_at: completedAt,
      };
      try {
        await API.graphql({
          query: updateUserMovementAssignment,
          variables: { input },
        });
        const updatedFutureBlocks = {
          ...futureBlocks,
          [firstTask.parentBlockId]: futureBlocks[firstTask.parentBlockId].slice(1),
        };

        modifyFutureBlocks(updatedFutureBlocks, firstTask.parentBlockId);
        setFutureBlocks(updatedFutureBlocks);
        setFutureTasks(futureTasks.filter(task => task !== firstTask));
        setCompletedTasks([{ ...firstTask, userAssignment: { ...firstTask.userAssignment!, completedAt } }, ...completedTasks]);
        toggleCompleteAssignmentModal();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
  );

  const {
    modalOpened: resetAssignmentOpen,
    handleToggle: toggleResetAssignmentModal,
    handleSecondaryButton: confirmResetAssignmentCompletion,
  } = useModal(
    false,
    async () => {
      setIsLoading(true);

      const revertedTasks = completedTasks.map(task => {
        return { ...task, userAssignment: { ...task.userAssignment!, completedAt: '' } };
      }).reverse();

      const updateUserMovementAssignmentPromises = completedTasks.map(task => {
        const input = {
          id: task.userAssignment?.id,
          completed_at: '',
        };

        return API.graphql({
          query: updateUserMovementAssignment,
          variables: { input },
        });
      });
      try {
        await Promise.all(updateUserMovementAssignmentPromises);
        setFutureBlocks(getClusteredFutureBlocks([...revertedTasks, ...futureTasks]));
        setFutureTasks([...revertedTasks, ...futureTasks]);
        setCompletedTasks([]);
        toggleResetAssignmentModal();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
  );

  const fetchUserAssignments = useCallback(async () => {
    if (!user?.Attributes?.['custom:user_id']) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await API.graphql({
        query: getUserMovementAssignments,
        variables: {
          userId: user?.Attributes?.['custom:user_id'],
          userSub: user?.Username,
          isAdminApp: true,
        },
      }) as UserAssignmentList;
      const assignments = result.data.getUserMovementAssignments;
      const modules = parseMovements(assignments);
      const flattenTasks = flattenModules(modules);
      const blocks = getFlattenBlocks(modules);
      const flattenFutureTasks = getFutureTasks(flattenTasks);
      setClusteredBlocks(getBlocksIndexedById(blocks));
      setFutureBlocks(getClusteredFutureBlocks(flattenFutureTasks));
      setFutureTasks(flattenFutureTasks);
      setCompletedTasks(getCompletedTasks(flattenTasks).sort(sortCompletedTasks).reverse());
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
    // locally we are using different lint config then when using react-script build due to problem
    // https://github.com/facebook/create-react-app/issues/8683
    // to fix properly we would need to bump deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.Attributes?.['custom:user_id']]);

  useEffect(() => {
    fetchUserAssignments();

    const fetchInterval = setInterval(fetchUserAssignments, REFETCH_INTERVAL);

    return () => clearInterval(fetchInterval);
  }, [fetchUserAssignments]);

  const getTaskTitle = (task: Task) => {
    return task.content?.title || task.taskType === MovementTaskTypeV2.ANALYSIS && t('@T_Assignments_FeedbackTitle');
  };

  return (
    <div>

      {isLoading && (
        <div className='full-size-centered white-opacity'>
          <Spinner animation='border' variant='primary' />
        </div>
      )}
      {!isLoading && (
        <div>
          <List>
            <ListHeader title={t('@T_UserHistory_UserAssignments')}>
              <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
                <Button disabled={!isAdmin} className='mt-2 mb-2' variant='secondary' onClick={() => setEditMode(!isEditMode)}>
                  {t(isEditMode ? '@T_General_DoneLabel' : '@T_General_EditLabel')}
                </Button>
              </WithTooltip>
              <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
                <Button disabled={!isAdmin} className='mt-2 mb-2 mx-3' variant='secondary' onClick={toggleResetAssignmentModal}>
                  {t('@T_General_ResetLabel')}
                </Button>
              </WithTooltip>
            </ListHeader>
            <ListBody>
              <table>
                <thead>
                  <tr>
                    <th>{t('@T_General_ID')}</th>
                    <th>{t('@T_UserHistory_UserAssignments')}</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(futureBlocks).map(([key, values], blockIndex) => {
                    const blockTasks = values.map((task, taskIndex) => (
                      <tr key={task.id}>
                        <td>{task.id}</td>
                        <td>{getTaskTitle(task)}</td>
                        <td>
                          {blockIndex === 0 && taskIndex === 0 && isEditMode ? (
                            <IconButton buttonType={ButtonType.PRIMARY} icon={faCheck} action={toggleCompleteAssignmentModal} style={{ marginTop: '-8px', marginBottom: '-8px' }} />
                          ) : <div style={{ width: '40px' }} />}
                        </td>
                      </tr>
                    ));
                    return [...(!(blockTasks.length === 0) ?
                      [<tr key={key}><td> </td><td><h4>{clusteredBlocks && clusteredBlocks[key].content?.title}</h4></td></tr>] : []),
                    ...blockTasks];
                  })}
                </tbody>
              </table>
            </ListBody>
          </List>
          <List className='mb-4'>
            <ListHeader title={t('@T_UserHistory_CompletedTasks')} />
            <ListBody>
              <table>
                <thead>
                  <tr>
                    <th>{t('@T_General_ID')}</th>
                    <th>{t('@T_Assignments_Block')}</th>
                    <th>{t('@T_Assignments_Unit')}</th>
                    <th>{t('@T_UserHistory_CompletedAt')}</th>
                  </tr>
                </thead>
                <tbody>
                  {completedTasks.map(task => (
                    <tr key={task.id}>
                      <td>{task.id}</td>
                      <td>{clusteredBlocks[task.parentBlockId].content?.title}</td>
                      <td>{getTaskTitle(task)}</td>
                      <td>{getLocalTime(task.userAssignment?.completedAt!, DATE_TIME_FORMAT)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ListBody>
          </List>
        </div>
      )}
      <Modal show={completeAssignmentOpen} onHide={toggleCompleteAssignmentModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('@T_UserHistory_CompleteTaskHeader')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{t('@T_UserHistory_CompleteTaskDescription', { name: firstTask && firstTask.content?.title })}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' onClick={toggleCompleteAssignmentModal}>{t('@T_UserHistory_CompleteTaskCancellation')}</Button>
          <Button variant='primary' onClick={confirmAssignmentCompletion}>{t('@T_UserHistory_CompleteTaskConfirmation')}</Button>
        </Modal.Footer>
      </Modal>

      <ResetModal
        title={t('@T_General_ResetLabel')}
        body={t('@T_UserHistory_ResetMovementTrainingDescription')}
        isVisible={resetAssignmentOpen}
        onPressPrimary={confirmResetAssignmentCompletion}
        onPressSecondary={toggleResetAssignmentModal}
        primaryButtonLabel={t('@T_UserHistory_CompleteTaskConfirmation')}
        secondaryButtonLabel={t('@T_UserHistory_CompleteTaskCancellation')}
      />
    </div>
  );
};
