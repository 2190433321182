import { MindPackageOrderGroup } from 'global/models';

export const movementModules = [{ name: 'Module 1', id: '1' }];
export const mindPackagesItems = [
  { id: 'p1', group: MindPackageOrderGroup.MANDATORY_STRESS },
  { id: 'p2', group: MindPackageOrderGroup.MANDATORY_STRESS },
  { id: 'p3', group: MindPackageOrderGroup.MANDATORY_STRESS },
  { id: 'p4' },
  { id: 'p5' },
  { id: 'p6' },
  { id: 'p7' },
  { id: 'p8' },
];
