import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { WithTooltip, List, ListBody, ListHeader } from 'components/shared';
import { useIsAdmin } from 'hooks';
import { Routes } from 'config/routes.config';
import { getLocalTime, DATE_FORMAT_SECONDARY } from 'config/app.config';
import { route } from 'utils';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { includes } from 'lodash';
import { PartialLicense } from 'screens/license-management';
import { toast } from 'react-toastify';
import { License } from 'api/API';

export interface LicenseListProps {
  title: string;
  licenses: License[];
}

export const LicenseListWithCheckboxes = ({ licenses, title }: LicenseListProps): JSX.Element => {

  const { t } = useTranslation();
  const isAdmin = useIsAdmin();
  const navigate = useNavigate();

  const [selectedLicenses, setSelectedLicenses] = useState<PartialLicense[]>([]);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const handleClick = (license: License, e: ChangeEvent<HTMLInputElement>) => {
    const { id, identity_sub } = license;
    const { checked } = e.target;

    setSelectedLicenses([...selectedLicenses, { id, identity_sub }]);
    if (!checked) {
      setSelectedLicenses(selectedLicenses.filter(item => item.id !== id));
    }
  };

  const handleMultiSelect = () => {
    if (selectedLicenses.length > 0) {
      navigate(Routes.LICENSE_MANAGEMENT_EDIT, { state: selectedLicenses });
    } else {
      toast.warn('No Licenses Selected');
    }
  };

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setSelectedLicenses(isCheckAll ? [] : licenses.map(li => {
      return {
        id: li.id,
        identity_sub: li.identity_sub,
      };
    }));
  };

  return (
    <div>
      <List>
        <ListHeader title={title} actionButton={{ label: t('@T_License_Renew'), onClick: handleMultiSelect, show: isAdmin }}/>
        <ListBody>
          <table>
            <thead>
              <tr>
              {isAdmin && <th>
                    <Checkbox
                      id='selectAll'
                      handleClick={handleSelectAll}
                      isChecked={isCheckAll}
                    />
                  </th>}
                <th>{t('@T_General_Name')}</th>
                <th>{t('@T_LicenseList_Validity')}</th>
                <th>{t('@T_License_Date')}</th>
                <th>{t('@T_License_Action')}</th>
              </tr>
            </thead>

            <tbody>
              {
                licenses.map(license => (
                  <tr key={license.id}>
                    {isAdmin && <td>
                        <Checkbox
                          id={license?.id as string}
                          handleClick={e => handleClick(license, e)}
                          isChecked={selectedLicenses.some(item => includes(item, license?.id))}
                        />
                      </td>}
                    <td>{license.id}</td>
                    <td>{getLocalTime(license.validTo, DATE_FORMAT_SECONDARY)}</td>
                    <td>
                      <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
                        <Link to={route(Routes.LICENSE_MANAGEMENT_EDIT, { id: license.id! })} className={`btn btn-secondary text-light ${!isAdmin ? 'disabled' : ''}`}>
                        {t('@T_License_Renew')}
                        </Link>
                      </WithTooltip>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </ListBody>
      </List>
    </div>
  );
};
