import { useTranslation } from 'react-i18next';
import { API } from 'aws-amplify';
import React, { useCallback, useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { UserMindProgress as UserMindProgressfn, MindTaskType } from 'global/models/mind-training.model';
import { flattenMindProgress, parseMindProgress, isTaskOfType, isMindTaskCompleted } from 'global/services/mind-parser.service';
import { getUserMindProgressFn } from 'api/graphql/queries';
import { updateUserMindProgress } from 'api/graphql/mutations';
import { ButtonType, IconButton, List, ListBody, ListHeader, WithTooltip } from 'components/shared';
import { DATE_TIME_FORMAT, getLocalTime } from 'config';
import { useModal, useIsAdmin } from 'hooks';
import { UserComponentBaseProps } from '../model';
import { ResetModal } from '../components/ResetModal.component';



const REFETCH_INTERVAL = 5 * 60 * 1000; // 5 minutes
const TASKS_TO_DISPLAY = 15;

interface UserMindProgressList {
  data: {
    getUserMindProgressFn: string;
  };
}

export const UserMindProgress = ({ user }: UserComponentBaseProps) => {
  const [futureSessionTasks, setFutureSessionTasks] = useState<UserMindProgressfn[]>([]);
  const [completedSessionTasks, setCompletedSessionTasks] = useState<UserMindProgressfn[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const { t } = useTranslation();
  const firstTask = futureSessionTasks?.[0];
  const isAdmin = useIsAdmin();

  const sortCompletedSessionTasks = (sessionTaskA: UserMindProgressfn, sessionTaskB: UserMindProgressfn) =>
    new Date(sessionTaskB.completedAt as string).getTime() - new Date(sessionTaskA.completedAt as string).getTime();

  const fetchUserMindProgress = useCallback(async () => {
    if (!user?.Attributes?.['custom:user_id']) {
      return;
    }

    setIsLoading(true);

    try {
      const result = await API.graphql({
        query: getUserMindProgressFn,
        variables: {
          userId: user?.Attributes?.['custom:user_id'],
          userSub: user?.Username,
        },
      }) as UserMindProgressList;
      const userMindProgress: UserMindProgressfn[] = JSON.parse(result.data.getUserMindProgressFn);
      const unorderedFutureSessionTasks = userMindProgress
        .filter(mindTask =>
          (isTaskOfType(MindTaskType.SESSION)(mindTask) || isTaskOfType(MindTaskType.HEALTH_QUESTIONNAIRE)(mindTask)) &&
          !isMindTaskCompleted(mindTask));
      const orderedFutureSessionTasks = flattenMindProgress(parseMindProgress(unorderedFutureSessionTasks));
      setFutureSessionTasks(orderedFutureSessionTasks);
      setCompletedSessionTasks(userMindProgress.filter(mindTask =>
        (isTaskOfType(MindTaskType.SESSION)(mindTask) || isTaskOfType(MindTaskType.HEALTH_QUESTIONNAIRE)(mindTask)) &&
        isMindTaskCompleted(mindTask))
        .sort(sortCompletedSessionTasks));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
    // locally we are using different lint config then when using react-script build due to problem
    // https://github.com/facebook/create-react-app/issues/8683
    // to fix properly we would need to bump deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.Attributes?.['custom:user_id']]);

  const getSessionTaskTitle = (sessionTask: UserMindProgressfn) => sessionTask?.source?.content?.title;

  const {
    modalOpened: resetMindProgressOpen,
    handleToggle: toggleResetMindProgressModal,
    handleSecondaryButton: confirmResetMindProgressCompletion,
  } = useModal(
    false,
    async () => {
      setIsLoading(true);
      const revertedTasks = completedSessionTasks.map(task => {
        return { ...task, completedAt: '' };
      }).reverse();
      const updateUserMindProgressPromises = completedSessionTasks.map(task => {
        const input = {
          id: task.id,
          completed_at: '',
        };

        return API.graphql({
          query: updateUserMindProgress,
          variables: { input },
        });
      });
      try {
        await Promise.all(updateUserMindProgressPromises);
        setFutureSessionTasks([...revertedTasks, ...futureSessionTasks]);
        setCompletedSessionTasks([]);
        toggleResetMindProgressModal();
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
  );

  const {
    modalOpened: completeSingleMindTask,
    handleToggle: toggleCompleteSingleMindTaskModal,
    handleSecondaryButton: confirmSingleMindTask,
  } = useModal(
    false,
    async () => {
      try {
        const completedAt = new Date().toISOString();

        setIsLoading(true);
        const input = {
          id: firstTask.id,
          completed_at: completedAt,
        };

        await API.graphql({
          query: updateUserMindProgress,
          variables: { input },
        });
        setFutureSessionTasks(futureSessionTasks.filter(task => task !== firstTask));
        setCompletedSessionTasks([
          { ...firstTask, completedAt: completedAt },
          ...completedSessionTasks,
        ]);
        toggleCompleteSingleMindTaskModal();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
  );

  useEffect(() => {
    fetchUserMindProgress();

    const fetchInterval = setInterval(fetchUserMindProgress, REFETCH_INTERVAL);

    return () => clearInterval(fetchInterval);
  }, [fetchUserMindProgress]);

  return (
    <div>
      {isLoading && (
        <div className='full-size-centered white-opacity'>
          <Spinner animation='border' variant='primary' />
        </div>
      )}
      {!isLoading && (
        <div>
          <List>
            <ListHeader title={t('@T_UserHistory_UserAssignments')}>
              <WithTooltip
                text={t('@T_General_AdminGroupRequired')}
                show={!isAdmin}
              >
                <Button
                  disabled={!isAdmin}
                  className='mt-2 mb-2 me-3'
                  variant='secondary'
                  onClick={() => setEditMode(!isEditMode)}
                >
                  {t(
                    isEditMode ? '@T_General_DoneLabel' : '@T_General_EditLabel',
                  )}
                </Button>
              </WithTooltip>
              <WithTooltip
                text={t('@T_General_AdminGroupRequired')}
                show={!isAdmin}
              >
                <Button
                  disabled={!isAdmin}
                  className='mt-2 mb-2'
                  variant='secondary'
                  onClick={toggleResetMindProgressModal}
                >
                  {t('@T_General_ResetLabel')}
                </Button>
              </WithTooltip>
            </ListHeader>
            <ListBody>
              <table>
                <thead>
                  <tr>
                    <th>{t('@T_General_ID')}</th>
                    <th>{t('@T_UserHistory_UserAssignments')}</th>
                    <th> </th>
                  </tr>
                </thead>

                <tbody>
                  {futureSessionTasks
                    .slice(0, TASKS_TO_DISPLAY)
                    .filter(session => session)
                    .map((sessionTask, taskIndex) => (
                      <tr key={sessionTask.id}>
                        <td>{sessionTask.id}</td>
                        <td>{getSessionTaskTitle(sessionTask)}</td>
                        <td>
                          {!taskIndex && isEditMode && (
                            <IconButton
                              buttonType={ButtonType.PRIMARY}
                              icon={faCheck}
                              action={toggleCompleteSingleMindTaskModal}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </ListBody>
          </List>
          <List className='mb-4'>
            <ListHeader title={t('@T_UserHistory_CompletedTasks')} />
            <ListBody>
              <table>
                <thead>
                  <tr>
                    <th>{t('@T_General_ID')}</th>
                    <th>{t('@T_UserHistory_UserAssignments')}</th>
                    <th>{t('@T_UserHistory_CompletedAt')}</th>
                  </tr>
                </thead>

                <tbody>
                  {completedSessionTasks.map(sessionTask => (
                    <tr key={sessionTask.id}>
                      <td>{sessionTask.id}</td>
                      <td>{getSessionTaskTitle(sessionTask)}</td>
                      <td>
                        {getLocalTime(
                          sessionTask.completedAt!,
                          DATE_TIME_FORMAT,
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ListBody>
          </List>
        </div>
      )}
      <Modal
        show={completeSingleMindTask}
        onHide={toggleCompleteSingleMindTaskModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('@T_UserHistory_CompleteTaskHeader')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {t('@T_UserHistory_CompleteTaskDescription', {
              name: firstTask && firstTask.source?.content?.title,
            })}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={toggleCompleteSingleMindTaskModal}
          >
            {t('@T_UserHistory_CompleteTaskCancellation')}
          </Button>
          <Button variant='primary' onClick={confirmSingleMindTask}>
            {t('@T_UserHistory_CompleteTaskConfirmation')}
          </Button>
        </Modal.Footer>
      </Modal>
      <ResetModal
        title={t('@T_General_ResetLabel')}
        body={t('@T_UserHistory_ResetMindTrainingDescription')}
        isVisible={resetMindProgressOpen}
        onPressPrimary={confirmResetMindProgressCompletion}
        onPressSecondary={toggleResetMindProgressModal}
        primaryButtonLabel={t('@T_UserHistory_CompleteTaskConfirmation')}
        secondaryButtonLabel={t('@T_UserHistory_CompleteTaskCancellation')}
      />
    </div>
  );
};
