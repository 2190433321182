import { EntryCollection } from 'contentful';

// import i18n from 'i18next';
import { contentfulClient } from './contentful-client';
import { ContentfulContentType, GetContentfulEntriesQuery } from './contentful-model';

// use locale of admin app
// const locale = i18n.language.startsWith('en') ? 'en-US' : 'de-DE';
const locale = 'de-DE'; // use german locale for now

export const getContentfulEntries = <T = any>(query?: GetContentfulEntriesQuery): Promise<EntryCollection<T> | null> => {
  const queryWithLocale = { locale, select: 'fields', ...(query || {}) };

  return contentfulClient.getEntries<T>(queryWithLocale)
    .then(data => data)
    .catch(err => {
      throw err;
    });
};
