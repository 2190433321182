import React, { useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';

import { baseContentfulQueries, getContentfulEntries, ContentfulProviderValue } from 'shared/contentful';
import { store } from '../redux/store';
import { client } from '../api/graphql/client';

export const ContentfulContext = React.createContext<ContentfulProviderValue>({});

export const WithProviders = ({ children } : { children: React.ReactNode }) => {

  const [contentfulData, setContentfulData] = useState<ContentfulProviderValue>({});

  useEffect(
    () => {
      const fetchQueries = async () => {
        const allItems = await Promise.all(baseContentfulQueries.map(getContentfulEntries));
        const content = baseContentfulQueries.reduce(
          (all, query, index) => ({
            ...all,
            [query.content_type]: allItems[index],
          }),
          {} as ContentfulProviderValue,
        );

        setContentfulData(content);
      };

      fetchQueries();
    },
    [],
  );

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ContentfulContext.Provider value={contentfulData}>
          {children}
        </ContentfulContext.Provider>
      </Provider>
    </ApolloProvider>
  );

};
