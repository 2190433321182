import dayjs from 'dayjs';
import 'dayjs/locale/de';

export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm';
export const DATE_FORMAT_SECONDARY = 'DD-MM-YYYY';
export const DATE_FORMAT_TERTIARY = 'YYYY-MM-DD'; 
export const DATE_FORMAT_YEAR = dayjs().locale('de').format('YYYY'); 
export const LICENSES_LIMIT = 9000;
export const TENANTS_LIMIT = 100;
export const PIE_CHART_COLORS_VARIANT_1 = ['#f95047', '#999999'];
export const PIE_CHART_COLORS_VARIANT_2 = ['#FFBB28', '#606060'];

export const getLocalTime = (date: string, dateFormat: string) => {
  return dayjs(date).locale('de').format(dateFormat); 
}; 