import React, { useEffect } from 'react';
import { useParams, Route, Routes } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { useQuery, gql } from '@apollo/client';

import VideoPlayerComponent from 'components/video/VideoPlayer.component';
import { sortAnamnese } from 'global/services/anamnese.service';
import { getMappedIdentitySub } from 'utils';
import { anamneseByUser, mindAnamneseByUser } from '../../api/graphql/queries';
import { adminQuery } from '../../services/adminQuery.service';
import { usePromise } from '../../hooks/usePromise';
import { AnamnesisResult } from '../../global/models';
import { UserOverview, UserHistory, UserMedical, UserCounting, UploadedVideos } from './subpages';
import { MenuItem } from './components/MenuItem.component';
import { UserRoute } from './config';

import './UserDetails.screen.scss'; 


export const UserDetails = () => {
  const { id } = useParams<{ id: string; }>();

  const onLoadUser = async () => {
    const identity_sub = await getMappedIdentitySub(id!);
    return adminQuery.findByIdentitySub(identity_sub!);
  };
  const [loadUser, { isLoading, result: user }] = usePromise(onLoadUser);

  const { loading: anamnesisLoading, data: movementData } = useQuery(gql`${anamneseByUser}`, {
    variables: {
      identity_sub: id,
    },
  });

  const { loading: mindAnamneseLoading, data: mindData } = useQuery(gql`${mindAnamneseByUser}`, {
    variables: {
      identity_sub: id,
    },
  }); 

  const movementAnamnesisResults: AnamnesisResult[] = movementData?.anamneseByUser?.items;
  const mindAnamnesisResults: AnamnesisResult[] = mindData?.mindAnamneseByUser?.items;
  const sortedMovementAnamnesis = sortAnamnese(movementAnamnesisResults || []);
  const sortedMindAnamnesis = sortAnamnese(mindAnamnesisResults || []);

  useEffect(() => {
    loadUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='row border border-grey bg-white mt-3'>
      <div className='col-12 p-0'>
        <div>
          <Navbar id='user-overview-nav'>
            <Nav className='justify-content-around w-100'>
              <MenuItem route={UserRoute.OVERVIEW} label='@T_UserDetails_Overview' />
              <MenuItem route={UserRoute.UPLOADED_VIDEOS} label='@T_UserDetails_UploadedVideos' />
              <MenuItem route={UserRoute.MEDICAL} label='@T_UserDetails_Medical' />
              <MenuItem route={UserRoute.PROGRESS} label='@T_UserDetails_Progress' />
              <MenuItem route={UserRoute.COUNTING} label='@T_UserDetails_Counting' />
            </Nav>
          </Navbar>
        </div>
        {(isLoading || anamnesisLoading || mindAnamneseLoading) ? <div className='full-size-centered white-opacity'><Spinner animation='border' variant='primary' /></div> : null}

        <div className='p-3'>
          <Routes>
            <Route 
              path='/*'
              element={<UserOverview user={user} 
              movementAnamnesisResults={sortedMovementAnamnesis} 
              mindAnamnesisResults={sortedMindAnamnesis} />} 
              />
            <Route path={UserRoute.UPLOADED_VIDEOS} element={<UploadedVideos />}/>
            <Route path={UserRoute.MEDICAL} 
              element={<UserMedical user={user} 
              movementAnamnesisResults={sortedMovementAnamnesis} 
              mindAnamnesisResults={sortedMindAnamnesis} />} 
              />
            <Route path={UserRoute.PROGRESS} element={<UserHistory user={user} />} />
            <Route path={UserRoute.COUNTING} element={<UserCounting user={user}/> }/>
            <Route path={`${UserRoute.UPLOADED_VIDEOS}/${UserRoute.VIDEO_PLAYER}`} element={<VideoPlayerComponent />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};
