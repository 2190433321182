import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';

import { useIsAdmin } from 'hooks';
import { List, ListBody, ListHeader, WithTooltip } from 'components/shared';
import { Routes } from '../../config/routes.config';
import { Job } from '../../global/models';
import { listJobs } from '../../api/graphql/queries';
import { route } from '../../utils';

export const JobManagement = () => {
  const { loading, error, data } = useQuery(gql`${listJobs}`);
  const { t } = useTranslation();
  const isAdmin = useIsAdmin();

  return (
    <List className='container pb-4'>
      <ListHeader title={t('@T_Job_ListOfJobs')}>
        <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
          <Link to={Routes.JOB_MANAGEMENT_ADD} className={`btn btn-secondary text-light ${!isAdmin ? 'disabled' : ''}`} >
            {t('@T_Job_AddJob')}
          </Link>
        </WithTooltip>
      </ListHeader>

      <ListBody>
        {error && <div>{t('@T_General_Error')}</div>}

        {loading && <div>{t('@T_General_LoadingIndicator')}</div>}

        {(!error && !loading) && (
          <table>
            <thead>
              <tr>
                <th>{t('@T_General_Name')}</th>
                <th>{t('@T_Job_JobType')}</th>
              </tr>
            </thead>

            <tbody>
              {data?.listJobs?.items?.map((job: Job) => (
                <tr key={job.id}>
                  <td><Link to={route(Routes.JOB_MANAGEMENT_OVERVIEW, { id: job.id })}>{job.name}</Link></td>
                  <td>{job.type}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </ListBody>
    </List>
  );
};
