import React, { useState } from 'react';

export const useFields = <S = any>(initialFields: S) : [
  S,
  React.Dispatch<React.SetStateAction<S>>,
  (field: string) => (e: React.ChangeEvent<any>) => void,
  <Value = any>(field: string) => (e: Value) => void,
] => {
  const [fields, setFields] = useState<S>(initialFields);

  const onUpdateField = (field: string) => (e: React.ChangeEvent<any>) => {
    setFields({ ...fields, [field]: e.target.value });
  };

  const onUpdateFieldWithValue = <Value = any>(field: string) => (value: Value) => {
    setFields({ ...fields, [field]: value });
  };

  return [fields, setFields, onUpdateField, onUpdateFieldWithValue];
};
