import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Group } from 'redux/modules/auth/sign-in/reducer';
import { ActionCreators } from 'redux/modules/auth/sign-in/actions';
import { useTypedSelector } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faHome, faBuilding, faIdCard, faUsers, faTrash, faTag, faPeopleArrows, faBars } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IconButton, ButtonType, Button } from 'components/shared';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Routes } from '../../config/routes.config';
import lb_logo from '../../resources/images/lb_logo.png';

import './Nav.component.scss';

interface NavLinkTypes {
  route: string;
  icon: IconProp;
  label: string;
}

const UserDetails = ({ mobile }: { mobile?: boolean }) => {
  const userData = useTypedSelector(it => it.auth.signIn.userData);
  const group = userData?.groups?.includes(Group.admin) ? 'Admin' : 'LB Coach';

  return (
    <div className={mobile ? 'pt-2 pb-2 d-flex justify-content-center' : 'navigation__user-details pt-2'}>
      {userData?.email}<br />
      {group}
    </div>
  );
};

export const Nav = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

  const navLinks: NavLinkTypes[] = [
    {
      route: Routes.HOME,
      icon: faHome,
      label: t('@T_General_HomeLabel'),
    },
    {
      route: Routes.TENANT_MANAGEMENT,
      icon: faBuilding,
      label: t('@T_General_CustomerManagementLabel'),
    },
    {
      route: Routes.LICENSE_MANAGEMENT,
      icon: faIdCard,
      label: t('@T_License_Management'),
    },
    {
      route: Routes.JOB_MANAGEMENT,
      icon: faPeopleArrows,
      label: t('@T_Job_JobManagement'),
    },
    {
      route: Routes.USERS_MANAGEMENT,
      icon: faUsers,
      label: t('@T_Navigation_UserManagement'),
    },
    {
      route: Routes.DELETED_USERS_MANAGEMENT,
      icon: faTrash,
      label: t('@T_Navigation_DeletedUsersManagement'),
    },
    {
      route: Routes.CLIENT_MANAGEMENT,
      icon: faTag,
      label: t('@T_Navigation_ClientManagement'),
    },
  ];

  const logout = () => {
    dispatch(ActionCreators.signOut());
  };

  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  const showNavLinks = () => {
    return navLinks.map(navLink => <NavLink key={navLink.label} to={navLink.route} className='my-4 my-1'><FontAwesomeIcon className='me-2' icon={navLink.icon} />{navLink.label}</NavLink>);
  };

  return (
    <div>
      <div className='navigationbar__menu'>
        <div className='navigation__logo-container pb-4' >
          <img src={lb_logo} className='navigation__logo' alt='Lifebonus Logo' />
        </div>
        <div >
          {showNavLinks()}
        </div>
        <div className='navigation_bottom_div text-center fw-bold'>
          <UserDetails />
          <IconButton buttonType={ButtonType.DANGER} icon={faPowerOff} action={logout} className='navigation__logout' />
        </div>
      </div>
      <div>
        <Button className='navigation__menu-button' onClick={openMenu}>
          <FontAwesomeIcon icon={faBars} />
        </Button>
        {(isMenuOpened) && (
          <div className='navigation__menu'>
            {showNavLinks()}
            <div className='navigation_bottom_div text-center fw-bold'>
              <UserDetails mobile />
              <Button buttonType={ButtonType.DANGER} className='navigation__logout' onClick={logout}>
                <FontAwesomeIcon icon={faPowerOff} />
                {t('@T_Navigation_Logout')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};