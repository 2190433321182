import React, { ReactChild } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Col, Row, Button } from 'react-bootstrap'; 

import { AnamnesisResult, Gender } from '../../../global/models';

export interface SingleAnamnesisProps {
  anamnesis: AnamnesisResult;
  showButton?: boolean;
}

const Field = ({ label, children }: { label: string; children: ReactChild }) => (
  <Row className='align-items-center mb-2'>
    <Col>
      <Form.Label className='mb-0'>{label}</Form.Label>
    </Col>
    <Col xs='auto'>
      {children}
    </Col>
  </Row>
);

const BooleanCheckboxes = ({ selected }: { selected: boolean }) => {
  const { t } = useTranslation();

  return (
    <div className='d-flex mt-2 mb-2'>
      <Form.Check className='mr-3' disabled type='checkbox' label={t('@T_General_Yes')} checked={selected} />
      <Form.Check disabled type='checkbox' label={t('@T_General_No')} checked={!selected} />
    </div>
  );
};

export const SingleAnamnesis = ({ anamnesis, showButton = false }: SingleAnamnesisProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className='d-flex justify-content-end'>
        {/* temporary */}
        {showButton ? <Button className='mt-2 mb-2' variant='secondary'>{t('@T_General_EditLabel')}</Button> : null}
      </div>
      {anamnesis.__typename === 'Anamnese' ? (
            <div>
            <div className='mb-3'>
              <h5 className='mb-4 font-weight-bold'>{t('@T_Anamnesis_HealthAndWellbeingLabel')}</h5>
              <div>
                <Field label={t('@T_Anamnesis_PerformingJobQuestion')}>
                  <BooleanCheckboxes selected={anamnesis?.is_performing_job} />
                </Field>
    
                {anamnesis.gender !== Gender.MALE ? (
                  <Field label={t('@T_Anamnesis_PregnancyQuestion')}>
                    <BooleanCheckboxes selected={anamnesis.is_pregnant} />
                  </Field>
                ) : null}
                  <Field label={t('@T_Anamnesis_ContradictionsQuestion')}>
                    <BooleanCheckboxes selected={anamnesis.has_contraindications} />
                  </Field>
              </div>
            </div>
          </div>
      ) : (
        <div>
        <div className='mb-3'>
          <h5 className='mb-4 font-weight-bold'>{t('@T_Anamnesis_HealthAndWellbeingLabel')}</h5>
          <div>
            <Field label={t('@T_Anamnesis_ContradictionsQuestion')}>
              <BooleanCheckboxes selected={anamnesis?.has_contraindications} />
            </Field>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};
