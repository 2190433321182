import { TypedUseSelectorHook, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { API } from 'aws-amplify';
import { toast } from 'react-toastify';

import { RootState } from 'redux/store';
import { listUserMappingsByCustomUserId, listUserMappingsByIdentitySub } from 'api/graphql/queries';
import { QueryListResult } from 'global/models/helper.model';
import { UserMapping } from 'api/API';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import { adminQuery, UserWithAdminProps } from '../services/adminQuery.service';

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const route = (url: string, params?: {
  [key: string]: string;
}) : string => {
  let newUrl = url;

  if (params) {
    Object.keys(params).forEach(param => {
      if (!url.includes(`:${param}`)) {
        throw Error(`Parameter ${param} is not found in ${url}`);
      }

      newUrl = newUrl.replace(`:${param}`, params[param]);
    });
  }

  return newUrl;
};

// eslint-disable-next-line arrow-body-style
export const sortStringDates = (date1: string, date2: string) => {
  return dayjs(date1).isAfter(dayjs(date2)) ? 1 : -1;
};

export const kilogramsToPounds = (kg: number) => kg * 2.20462262185;
export const centimetersToInches = (cm: number) => cm * 0.3937007874;
export const getBmi = (kg: number, centimeters: number) => (
  ((kilogramsToPounds(kg) / (centimetersToInches(centimeters) * centimetersToInches(centimeters))) * 703).toFixed(2)
);

export const formatDate = (dateStr: string, keepTimeComponent = false) => {
  const format = keepTimeComponent ? 'DD.MM.YY HH:mm' : 'DD.MM.YY';
  const date = dayjs(dateStr);
  return date.format(format);
};

export const clusterDateOfBirth = (dateStr: string | null | undefined, clusterSize = 10) => {
  if (!dateStr) {
    return null;
  }

  const yearOfDate = dayjs(dateStr).year();
  const cluster = Math.floor(yearOfDate / clusterSize) * clusterSize;
  return `${cluster}-01-01T00:00:00.000Z`;
};

export const prepareFilename = (filename: string, ext: string, timestamp = true) => {
  const preparedFilename = filename.replace(/ /g, '-').toLowerCase();

  if (timestamp) {
    return `${preparedFilename}-${dayjs().format('YYYY-MM-DD-HHMM')}.${ext}`;
  }

  return `${preparedFilename}.${ext}`;
};

export const getMappedIdentitySub = async (customUserId: string) => {
  const userMappings = await API.graphql({
    query: listUserMappingsByCustomUserId,
    variables: {
      custom_user_id: customUserId,
    },
  }) as QueryListResult<'listUserMappingsByCustomUserId', UserMapping>;

  const userMapping = userMappings?.data?.listUserMappingsByCustomUserId?.items?.[0];

  return userMapping?.identity_sub || null;
};

export const getMappedCustomUserId = async (identitySub: string) => {
  const userMappings = await API.graphql({
    query: listUserMappingsByIdentitySub,
    variables: {
      identity_sub: identitySub,
    },
  }) as QueryListResult<'listUserMappingsByIdentitySub', UserMapping>;

  const userMapping = userMappings?.data?.listUserMappingsByIdentitySub?.items?.[0];

  return userMapping?.custom_user_id || null;
};

export const AmplifyApi = <TVariables>(query: GraphQLOptions['query'], variables?: Record<string, TVariables>, options?: Omit<GraphQLOptions, 'query' | 'variables'>) => API.graphql({
  query,
  variables,
  ...options,
});

export const getUserByCustomId = async (customUserId: string) => {
  const identity_sub = await getMappedIdentitySub(customUserId);

  return adminQuery.findByIdentitySub(identity_sub as string);
};

export const fetchAllUsers = async (token: string | null, allUsers: UserWithAdminProps[]):Promise<UserWithAdminProps[]> => {
  const response = await adminQuery.listUsers({ token, search: '', searchAttribute: 'email' });
  const newUsers = [...allUsers, ...response.users];
  if (response.nextToken) {
    return fetchAllUsers(response.nextToken, newUsers);
  }
  return newUsers;
};

export const processAdminQuerySequentially = (arr: UserWithAdminProps[], adminQueryFn: (user: UserWithAdminProps) => Promise<void>) => (
  arr.reduce((promise, user) => (promise
    .then(() => adminQueryFn(user).then(() => console.debug(`[DEBUG] ${adminQueryFn.name} - ${user.Attributes.email}`)))
    .catch(e => {
      toast.error(e.message);
    })),
  Promise.resolve()));

export const isValidEmail = (email: string) => {
  const regex = /\S[^,]+@\S[^,]+\.\S[^,]+/;
  return regex.test(email);
};

export const secondsToTime = (timeInSeconds: number) => {
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  const pad = (num: number) => num.toString().padStart(2, '0');

  return hours
    ? `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
    : `${pad(minutes)}:${pad(seconds)}`;
};

export const aTagStyle = { display: 'table-caption' };