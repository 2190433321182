import { useState } from 'react';

export const useModal = <T = any, T2 = any>(initialModalOpened: boolean, callback?: (data?: T2) => T) => {
  const [modalOpened, toggleModal] = useState<boolean>(initialModalOpened);
  const [data, setModalData] = useState<any>(null);

  const handleSecondaryButton = () => {
    if (callback) {
      callback(data);
    }
  };

  const handleToggle = (input?: T2) => {
    setModalData(input);
    toggleModal(!modalOpened);
  };

  return { modalOpened, handleToggle, handleSecondaryButton };
};
