import React from 'react';
import { useLocation, NavLink } from 'react-router-dom';

import Nav from 'react-bootstrap/Nav';
import { useTranslation } from 'react-i18next';

export const MenuItem = ({ route, label }: { route: string; label: string }) => {
  const { pathname, search } = useLocation();
  const { t } = useTranslation();
  const pathAndParams = `${pathname}${search}`.replace(/[^a-zA-Z ]/g, '');
  const isActive = (navigationRoute: string) => pathAndParams?.includes(navigationRoute);

  return (
    <Nav.Link as={NavLink} active={isActive(route.replace(/[^a-zA-Z ]/g, ''))} to={route}>{t(label)}</Nav.Link>
  );
};
