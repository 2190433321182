export const listTenantsWithLicenses = /* GraphQL */ `
  query listTenantsWithLicenses(
    $filter: ModelTenantFilterInput
    $limit: Int
    $licensesLimit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        email_domains
        licenses(limit: $licensesLimit) {
          items {
            id
          }
        }
      }
    }
  }
`;

export const listTenantsWithLicensesByName = /* GraphQL */ `
    query listTenantsByName(
        $type: TenantType
        $name: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelTenantFilterInput
        $limit: Int
        $licensesLimit: Int
        $nextToken: String
    ) {
        listTenantsByName(
            type: $type
            name: $name
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                type
                name
                createdAt,
                licenses(limit: $licensesLimit) {
                    items {
                        id
                    }
                }
            }
            nextToken
        }
    }
`;

export const getTenantWithLicenses = /* GraphQL */ `
  query GetTenantWithLicenses(
    $id: ID!
    $limit: Int
    ) {
      getTenant(id: $id) {
        id
        type
        name
        email_domains
        licenses(limit: $limit) {
          items {
            id
            identity_sub
            validTo
          }
        }
      }
  }
`;

export const listTenantsByLicenseInvoice = /* GraphQL */ `
  query ListTenantsByLicenseInvoice(
    $invoice_id: String
    $limit: Int
  ) {
    listLicensesByInvoice(
      invoice_id: $invoice_id
      limit: 1
    ) {
      items {
        tenant {
          id
          type
          name
          createdAt,
          licenses(limit: $limit) {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

export const createLicensesBulkWithoutInvoices = /* GraphQL */ `
  mutation CreateLicensesBulkWithoutInvoices(
    $validTo: String
    $numberOfLicenses: Int
    $tenantId: String
    $origin: LicenseOrigin!
  ) {
    createLicensesBulk(
      validTo: $validTo
      numberOfLicenses: $numberOfLicenses
      tenantId: $tenantId
      origin: $origin
    )
  }
`;

export const getTenantWithLicensesAndJobs = /* GraphQL */ `
  query getTenantWithLicensesAndJobs(
    $id: ID!
    $limit: Int
    ) {
      getTenant(id: $id) {
        id
        type
        name
        email_domains
        category
        jobs {
          items {
            id
            job {
              id
              name
            }
          }
        }
        licenses(limit: $limit) {
          items {
            id
            identity_sub
            validTo
            invoice_id
            origin
          }
        }
      }
  }
`;

export const getJobWithTenants = /* GraphQL */ `
  query getJobWithTenants($id: ID!) {
    getJob(id: $id) {
      id
      name
      type
      tenants {
        items {
          tenant {
            id
            name
            licenses {
              items {
                id
              }
            }
          }
        }
        nextToken
      }
    }
  }
`;

export const movementAnalysisResultByUserWithAssets = /* GraphQL */ `
  query movementAnalysisResultByUserWithAssets(
    $identity_sub: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserMovementAnalysisResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    movementAnalysisResultByUser(
      identity_sub: $identity_sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        identity_sub
        followup
        internal_comment
        user_assignments {
          items {
            source_id
            predecessor_assignment {
              source_type
              source_id
              analysis_assets {
                items {
                  src_file
                }
              }
            }
          }
          nextToken
        }
        evaluation_score_total
        user_screenshot
        evaluation_measurements
        feedback_texts
        status
        approved_at
        approved_by
        draft_approved_at
        draft_approved_by
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const movementAnalysisAssetByUserId = /* GraphQL */ `
  query movementAnalysisAssetByUserId(
    $identity_sub: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserMovementAnalysisAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    movementAnalysisAssetByUser(
      identity_sub: $identity_sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        identity_sub
        user_assignment_id
        type
        src_file
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const movementAnalysisAssetByUserWithSuccessor = /* GraphQL */ `
  query movementAnalysisAssetByUserWithSuccessor(
    $identity_sub: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserMovementAnalysisAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    movementAnalysisAssetByUser(
      identity_sub: $identity_sub
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        identity_sub
        user_assignment_id
        user_assignment {
          id
          successor_assignment {
            items {
              id
              result_id
              result {
                id
                identity_sub
                followup
                internal_comment
                evaluation_score_total
                user_screenshot
                evaluation_measurements
                feedback_texts
                status
                approved_at
                approved_by
                createdAt
                updatedAt
              }
            }
          }
          identity_sub
          source_type
          predecessor_id
          result_id
          source_id
          completed_at
          started_at
          due_at
          created_by_client_id
          repetitions_done
          deleted_at
          createdAt
          updatedAt
        }
        type
        src_file
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listUserMovementAnalysisAssetsWithSuccessor = /* GraphQL */ `
  query listUserMovementAnalysisAssetsWithSuccessor(
    $filter: ModelUserMovementAnalysisAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMovementAnalysisAssets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        identity_sub
        user_assignment_id
        user_assignment {
          id
          successor_assignment {
            items {
              id
              result_id
              result {
                id
                approved_at
                approved_by
                draft_approved_by
                draft_approved_at
                status
                identity_sub
                createdAt
              }
            }
          }
          identity_sub
          source_type
          predecessor_id
          result_id
          source_id
          completed_at
          started_at
          due_at
          created_by_client_id
          repetitions_done
          deleted_at
          createdAt
          updatedAt
        }
        type
        src_file
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listRegistrationCodesByTenantUsedAt = /* GraphQL */ `
  query listRegistrationCodesByTenantUsedAt(
    $tenant_id: ID,
    $sortDirection: ModelSortDirection,
    $filter: ModelRegistrationCodeFilterInput
    $nextToken: String
  ) {
    listRegistrationCodesByTenantUsedAt(
      tenant_id: $tenant_id,
      sortDirection: $sortDirection,
      filter: $filter,
      nextToken: $nextToken
    ) {
      nextToken
      items {
        code
        used_at
        createdAt
        license_id
      }
    }
  }
`;

export const listRegistrationFreeCodes = /* GraphQL */ `
  query listRegistrationFreeCodes(
    $tenant_id: ID,
    $nextToken: String,
    $filter: ModelRegistrationCodeFilterInput
  ) {
    listRegistrationCodesByTenant(
      tenant_id: $tenant_id,
      nextToken: $nextToken,
      filter: $filter
    ) {
      nextToken
      items {
        id
        code
        valid_to
        license_id
        createdAt
        reserved_at
      }
    }
  }
`;

export const listTenantNameAndId = /* GraphQL */ `
  query ListTenantsNameAndId(
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        id
      }
      nextToken
    }
  }
`;

export const listLicensesForLicenseManagement = /* GraphQL */ `
  query ListLicensesTest(
    $type: LicenseType
    $validTo: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLicensesByValidTo(
      type: $type
      validTo: $validTo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        tenant_id
        identity_sub
        user
        validTo
        tenant {
          id
          type
          category
          name
          createdAt
        }
        createdAt
      }
      nextToken
    }
  }
`;

export const listTeamAccesss = /* GraphQL */ `
  query listTeamAccesss(
    $limit: Int
    $nextToken: String,
    $filter: ModelTeamAccessFilterInput
  ) {
    listTeamAccesss(
      limit: $limit,
      nextToken: $nextToken,
      filter: $filter
    ) {
      nextToken
      items {
        access_code
        validity
        updatedAt
        createdAt
        id
        tenant_access_id
        tenant_id
      }
    }
  }
`;

export const listTeamAccessByTenantId = /* GraphQL */ `
  query listTeamAccessByTenantId(
    $tenant_id: ID,
  ) {
    listTeamAccessByTenantId(
      tenant_id: $tenant_id,
    ) {
      items {
        access_code
        validity
        updatedAt
        createdAt
        id
        tenant_access_id
        tenant_id
      }
    }
  }
`;

export const createTeamAccess = /* GraphQL */ `
  mutation CreateTeamAccess(
    $input: CreateTeamAccessInput!
  ) {
    createTeamAccess(input: $input) {
      access_code
      validity
      updatedAt
      createdAt
      id
      tenant_access_id
      tenant_id
    }
  }
`;

export const updateTeamAccess = /* GraphQL */ `
  mutation UpdateTeamAccess(
    $input: UpdateTeamAccessInput!
  ) {
    updateTeamAccess(input: $input) {
      access_code
      validity
      updatedAt
      createdAt
      id
      tenant_access_id
      tenant_id
    }
  }
`;

export const deleteUserExternalProviders = /* GraphQL */ `
  mutation DeleteUserExternalProviders(
    $externalId: String
  ) {
    deleteUserExternalProviders(
      externalId: $externalId
    )
  }
`;