import { applyMiddleware, compose, createStore } from 'redux';
import { compact } from 'lodash';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { rootSaga } from './sagas';
import { rootReducer } from './reducers';

export function configureStore() {

  const dev = process.env.NODE_ENV === 'development';
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = compact([
    sagaMiddleware,
    dev ? createLogger() : null,
  ]);

  const composeEnhancers = (dev && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const store = createStore(
    rootReducer,
    {},
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  sagaMiddleware.run(rootSaga);

  return store;
}

export type RootState = ReturnType<typeof rootReducer>;
export const store = configureStore();
