import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Card } from 'react-bootstrap/';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { API } from 'aws-amplify';

import { UserProgress } from 'components/user/userprogress/UserProgress.component';
import { UserMindProgress as UserMindProgressfn } from 'global/models/mind-training.model';
import { isMindTaskCompleted } from 'global/services/mind-parser.service';
import {
  parseMovementsV2 as parseMovements,
  flattenModulesV2 as flattenModules,
  getCompletedTasks,
} from 'global/services/movement-parser.service';
import { Task } from 'global/models/movement-training.model';
import { getUserMindProgressFn, getUserMovementAssignments } from 'api/graphql/queries';
import { Routes } from 'config';
import { route } from 'utils';
import { ListBody, ListHeader, WithTooltip } from 'components/shared';
import { useIsAdmin } from 'hooks';
import { AnamnesisResult } from '../../../global/models';
import { UserComponentBaseProps } from '../model';
import { getLocalTime, DATE_FORMAT_SECONDARY, DATE_FORMAT_YEAR } from '../../../config/app.config';
import { UserRoute } from '../config';


interface UserMindProgressList {
  data: {
    getUserMindProgressFn: string;
  };
}

interface UserAssignmentList {
  data: {
    getUserMovementAssignments: string;
  };
}

export const UserOverview = (props: UserComponentBaseProps) => {
  const isAdmin = useIsAdmin();
  const { user, movementAnamnesisResults, mindAnamnesisResults } = props;
  const { t } = useTranslation();
  const customUserId = user?.Attributes?.['custom:user_id'];

  const [isLoadingMind, setIsLoadingMind] = useState<boolean>(false);
  const [isLoadingMovement, setIsLoadingMovement] = useState<boolean>(false);
  const [mindProgress, setMindProgress] = useState<UserMindProgressfn[]>([]);
  const [movementAssignments, setMovementAssignments] = useState<Task[]>([]);


  const fetchUserMindProgress = useCallback(async () => {
    if (!customUserId) {
      return;
    }

    setIsLoadingMind(true);

    try {
      const result = await API.graphql({
        query: getUserMindProgressFn,
        variables: {
          userId: customUserId,
          userSub: user?.Username,
        },
      }) as UserMindProgressList;
      const userMindProgress: UserMindProgressfn[] = JSON.parse(result.data.getUserMindProgressFn);
      setMindProgress(userMindProgress);

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingMind(false);
    }
  }, [customUserId]);


  const fetchUserAssignments = useCallback(async () => {
    if (!customUserId) {
      return;
    }

    setIsLoadingMovement(true);

    try {
      const result = await API.graphql({
        query: getUserMovementAssignments,
        variables: {
          userId: customUserId,
          userSub: user?.Username,
          isAdminApp: true,
        },
      }) as UserAssignmentList;
      const assignments = result.data.getUserMovementAssignments;
      const modules = parseMovements(assignments);
      const flattenTasks = flattenModules(modules);
      setMovementAssignments(flattenTasks);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingMovement(false);
    }
    // locally we are using different lint config then when using react-script build due to problem
    // https://github.com/facebook/create-react-app/issues/8683
    // to fix properly we would need to bump deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customUserId]);


  const calcProgressQuotient = (completedTask: Task[] | UserMindProgressfn[], blockLength: Task[] | UserMindProgressfn[]) =>
    Math.round((completedTask.length / blockLength.length) * 100);

  const movementAnamnesisRecentResult = movementAnamnesisResults?.[0] || {} as AnamnesisResult;
  const { birthdate, gender, is_pregnant, is_performing_job, has_contraindications } = movementAnamnesisRecentResult;
  const movementIsPassiveUser = movementAnamnesisResults?.[0] && (is_pregnant || has_contraindications || !is_performing_job);
  const movementPassiveUserReason = ''.concat(`${is_pregnant ? `${t('@T_User_PassiveUserReason_PregnancyLabel')}, ` : ''}`,
    `${has_contraindications ? `${t('@T_User_PassiveUserReason_ContraindicationsLabel')}, ` : ''}`,
    `${!is_performing_job ? `${t('@T_User_PassiveUserReason_NotPerformingJobLabel')}, ` : ''}`,
  ).slice(0, -2);

  const mindAnamnesisRecentResult = mindAnamnesisResults?.[0] || {} as AnamnesisResult;
  const { has_contraindications: mindContraindications } = mindAnamnesisRecentResult;
  const mindIsPassiveUser = mindAnamnesisResults?.[0] && mindContraindications;
  const mindPassiveUserReason = mindIsPassiveUser ? `${t('@T_User_PassiveUserReason_ContraindicationsLabel')} ` : '';
  interface userRelatedDataLabelsProps {
    label: string;
    value: string | undefined;
  }

  const userRelatedDataLabels: userRelatedDataLabelsProps[] = [
    {
      label: '@T_User_FullNameLabel',
      value: `${user?.Attributes.given_name || ''} ${user?.Attributes.family_name || ''}`,
    },
    {
      label: '@T_User_ID',
      value: `${user?.Attributes['custom:user_id']}`,
    },
    {
      label: '@T_General_EmailLabel',
      value: user?.Attributes?.email,
    },
    {
      label: '@T_User_TenantLabel',
      value: user?.Attributes?.['custom:tenant_name'],
    },
    {
      label: '@T_User_BirthyearLabel',
      value: birthdate ? getLocalTime(birthdate, DATE_FORMAT_YEAR) : undefined,
    },
    {
      label: '@T_User_GenderLabel',
      value: gender,
    },
    {
      label: '@T_User_CreationDateLabel',
      value: user?.UserCreateDate ? dayjs(user?.UserCreateDate).format(DATE_FORMAT_SECONDARY) : undefined,
    },
    {
      label: '@T_User_PassiveUserMovementLabel',
      value: movementIsPassiveUser ? t('@T_General_Yes') : t('@T_General_No'),
    },
    {
      label: '@T_User_PassiveUserMovementReasonLabel',
      value: movementIsPassiveUser ? movementPassiveUserReason : '-',
    },
    {
      label: '@T_User_PassiveUserMindLabel',
      value: mindIsPassiveUser ? t('@T_General_Yes') : t('@T_General_No'),
    },
    {
      label: '@T_User_PassiveUserMindReasonLabel',
      value: mindIsPassiveUser ? mindPassiveUserReason : '-',
    },
  ];
  const uniqueMovementBlocks: number[] = [];
  const uniqueMindPackages: string[] = [];

  const mappedMovementProgressData = movementAssignments.map(task => {
    const movementBlockId: string = task.parentBlockId;
    const blockLength: Task[] = movementAssignments.filter(ass => ass.parentBlockId === movementBlockId);
    const completedMovementAssignments: Task[] = getCompletedTasks(movementAssignments).filter(block => block.parentBlockId === movementBlockId);

    return {
      'id': +movementBlockId.slice(2),
      'title': `Block ${movementBlockId.slice(2)}`,
      'data': calcProgressQuotient(completedMovementAssignments, blockLength),
    };

  }).filter(element => {
    const duplicateTask = uniqueMovementBlocks.includes(element.id);

    if (!duplicateTask) {
      uniqueMovementBlocks.push(element.id);

      return true;
    }

    return false;
  }).sort((a, b) => (a.id > b.id ? - 1 : 1)).reverse();

  const mappedMindProgressData = mindProgress.map(units => {
    const packageId: string = units.package?.content?.id!;
    const packageTitle: string = units.package?.content?.title!;
    const themepackageLength: UserMindProgressfn[] = mindProgress.filter(ass => ass.source?.packageId === packageId);
    const completedUnits: UserMindProgressfn[] = themepackageLength.filter(ass => isMindTaskCompleted(ass));

    return {
      'id': packageId,
      'title': packageTitle,
      'data': calcProgressQuotient(completedUnits, themepackageLength),
    };
  }).filter(element => {
    const duplicateTask = uniqueMindPackages.includes(element.id);

    if (!duplicateTask) {
      uniqueMindPackages.push(element.id);
      return true;
    }

    return false;
  }).sort((a, b) => (a.id > b.id ? - 1 : 1)).reverse();

  useEffect(() => {
    fetchUserMindProgress();
    fetchUserAssignments();

  }, [fetchUserMindProgress, fetchUserAssignments]);

  const userProgress = [
    {
      headerTitle: t('@T_UserOverview_ProgressMovement'), 
      condition: mappedMovementProgressData.length === 0,
      linkRoute: `${Routes.USERS_MANAGEMENT}/user-details/${user?.Attributes['custom:user_id']}/${user?.Attributes.email}/${UserRoute.PROGRESS}`,
      loading: isLoadingMovement,
      error: t('@T_UserOverview_UserProgress_No_Movementtraining'), 
      progressData: mappedMovementProgressData,
    },
    {
      headerTitle: t('@T_UserOverview_ProgressMind'), 
      condition: mappedMindProgressData.length === 0,
      linkRoute: `${Routes.USERS_MANAGEMENT}/user-details/${user?.Attributes['custom:user_id']}/${user?.Attributes.email}/${UserRoute.PROGRESS}`,
      loading: isLoadingMind,
      error: t('@T_UserOverview_UserProgress_No_Mindtraining'), 
      progressData: mappedMindProgressData,
    },
  ];

  const showUserProgress = () => {
    return userProgress.map(progressTask => <UserProgress
      key={progressTask.headerTitle}
      headerTitle={progressTask.headerTitle}
      conditon={progressTask.condition}
      linkRoute={progressTask.linkRoute}
      loading={progressTask.loading}
      error={progressTask.error}
      progressData={progressTask.progressData} />);
  };

  const renderAdminstrationArea = () => (
    <React.Fragment>
      <ListHeader title={t('@T_UserOverview_Adminstration_Area')} />
      <ListBody>
        <table>
          <tbody >
            <tr>
              <td>
                <b>{t('@T_UserOverview_Change_Tenant')}</b>
              </td>
              <td>
                <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
                  <Link to={route(Routes.USER_CHANGE_TENANT, { id: user?.Attributes['custom:user_id'] as string })} className={`btn btn-danger text-light ${!isAdmin ? 'disabled' : ''}`}>
                  {t('@T_UserOverview_Change_Tenant')}
                  </Link>
                </WithTooltip>
              </td>
            </tr>
            <tr>
              <td>
                <b>{t('@T_UserList_ChangeEmailButton')}</b>
              </td>
              <td>
                <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
                  <Link to={route(Routes.USER_CHANGE_EMAIL, { id: user?.Attributes['custom:user_id'] as string })} className={`btn btn-danger text-light ${!isAdmin ? 'disabled' : ''}`}>
                  {t('@T_UserList_ChangeEmailButton')}
                  </Link>
                </WithTooltip>
              </td>
            </tr>
          </tbody>
        </table>
      </ListBody>
    </React.Fragment>
  );

  return (
    <div>
      <Card className='mb-4'>
        <Card.Header>
          {t('@T_UserOverview_UserDataHeader')}
        </Card.Header>
        <Card.Body>
          {userRelatedDataLabels.map(entry => (
            <div key={entry.label} className='d-flex align-items-center'>
              <p className='w-50'>{t(entry.label)}</p>
              <p className='text-black w-50 d-flex align-items-center'>
                {entry.value || '-'}
              </p>
            </div>
          ))
          }
        </Card.Body>
      </Card>
      <div className='d-flex flex-row'>
        {showUserProgress()}
      </div>
      <div className='adminstration-area-container'>
        {renderAdminstrationArea()}
      </div>   
    </div>
  );
};