import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { License } from 'api/API';

import { WithTooltip, List, ListBody, ListHeader } from 'components/shared';
import { useIsAdmin } from 'hooks';
import { Routes } from 'config/routes.config';
import { getLocalTime, DATE_FORMAT_SECONDARY } from 'config/app.config';
import { aTagStyle, route } from 'utils';

export interface LicenseListProps {
  licenses: License[];
}

export const LicenseList = ({ licenses }: LicenseListProps): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const isAdmin = useIsAdmin();

  return (
    <List>
      <ListHeader title={t('@T_License_Licenses')}>
        <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
          <Link to={route(Routes.TENANT_MANAGEMENT_ADD_LICENSES, { tenantId: id! })} className={`btn btn-secondary text-light ${!isAdmin ? 'disabled' : ''}`} >
            {t('@T_License_AddLicenses')}
          </Link>
        </WithTooltip>
      </ListHeader>
      <ListBody>
        <table>
          <thead>
            <tr>
              <th>{t('@T_LicenseList_License')}</th>
              <th>{t('@T_LicenseList_User')}</th>
              <th>{t('@T_LicenseList_Validity')}</th>
              <th>{t('@T_License_Invoice')}</th>
              <th>{t('@T_License_Origin')}</th>
            </tr>
          </thead>

          <tbody>
            {
              licenses.map(license => (
                <tr key={license.id}>
                  <td>{license.id}</td>
                  <td><Link style={aTagStyle} to={route(Routes.USER_DETAILS, { id: license.identity_sub! })}>{license.identity_sub}</Link></td>
                  <td>{getLocalTime(license.validTo, DATE_FORMAT_SECONDARY)}</td>
                  <td>{license.invoice_id}</td>
                  <td>{license.origin}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </ListBody>
    </List>
  );
};
