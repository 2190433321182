import React, { useState, useRef } from 'react';
import { API } from 'aws-amplify';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import { listLeads } from 'api/graphql/queries';
import { QueryListResult } from 'global/models/helper.model';

type Lead = {
  id: string;
  firstname: string;
  lastname: string;
  email_address: string;
  company: string;
  tenant_id: string;
  code: string;
  code_send_at: string;
  code_used_at: string;
  user_id: string;
};

type LeadResponse = QueryListResult<'listLeads', Lead>;

const HEADERS = [
  { label: 'Id', key: 'id' },
  { label: 'Firstname', key: 'firstname' },
  { label: 'Lastname', key: 'lastname' },
  { label: 'Email', key: 'email_address' },
  { label: 'Company', key: 'company' },
  { label: 'Tenant Id', key: 'tenant_id' },
  { label: 'Code', key: 'code' },
  { label: 'Code send at', key: 'code_send_at' },
  { label: 'Code used at', key: 'code_used_at' },
  { label: 'User Id', key: 'user_id' },
];

export const DownloadLead = () => {
  const { t } = useTranslation(); 
  const [isLoading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const csvRef = useRef<any>();

  const downloadLeadData = () => {
    if (isLoading) {
      return;
    }

    setLoading(true);

    const loadData = async () => {
      let currentData: Lead[] = [];

      const finish = () => {
        setData(currentData);
        setLoading(false);
        csvRef?.current?.link.click();
      };

      const getBatch = async (nextToken?: string): Promise<void> => {
        const variables = nextToken ? { limit: 1000, nextToken } : { limit: 1000 };
        const response = await API.graphql({ query: listLeads, variables }) as LeadResponse;
        currentData = [...currentData, ...(response?.data?.listLeads?.items || [])];
        const currentNextToken = response?.data?.listLeads?.nextToken;

        return currentNextToken ? getBatch(currentNextToken) : finish();
      };


      await getBatch();
    };

    loadData();
  };

  if (!data?.length) {
    return <button onClick={downloadLeadData} className='btn btn-secondary text-light' disabled={isLoading}>{t('@T_Home_Download_Lead')}</button>;
  }

  return (
    <CSVLink
      data={data!}
      headers={HEADERS}
      filename='leads.csv'
      className='btn btn-secondary text-light'
      ref={csvRef}
    >
      {t('@T_Home_Download_Lead')}
    </CSVLink>
  );
};
