import { flattenDeep, groupBy, orderBy } from 'lodash';
import { MindTaskType, ParsedMindPackage, UserMindProgress } from '../models';
import { mapSort } from './movement-parser.service';

export const parseMindProgress = (mindProgresses: UserMindProgress[]): ParsedMindPackage[] => {
  const packages: ParsedMindPackage[] = Object.values<UserMindProgress[]>(groupBy(mindProgresses, 'package.id'))
    .map((items: UserMindProgress[]) => ({
      ...items[0].package,
      progressItems: mapSort(items, 'source.task_predecessor', 'source.id'),
    }));

  const packagesGroups = Object.values<ParsedMindPackage[]>(groupBy(packages, (mindPackage: ParsedMindPackage) => mindPackage.packageGroup || mindPackage.id))
    .map(group => [...group].sort((a, b) => (a.packageGroupOrder ?? 0) - (b.packageGroupOrder ?? 0)));

  return ([] as ParsedMindPackage[]).concat(...orderBy(
    packagesGroups,
    [
      (group: ParsedMindPackage[]) => group[0].progressItems[0].createdAt,
      (group: ParsedMindPackage[]) => group[0].id,
    ],
  ));
};

export const flattenMindProgress = (packages: ParsedMindPackage[]): UserMindProgress[] => {
  return flattenDeep(packages.map(mindPackage => mindPackage.progressItems));
};

export const isMindTaskCompleted = (task: UserMindProgress) => !task || !!task.completedAt;
export const isMindTaskStarted = (task: UserMindProgress) => !task || !!task.startedAt;
export const isMindPackageStarted = (mindPackage: ParsedMindPackage) => mindPackage?.progressItems?.some(isMindTaskStarted);
export const isMindPackageCompleted = (mindPackage: ParsedMindPackage) => mindPackage?.progressItems?.every(isMindTaskCompleted);
export const isTaskOfType = (type: MindTaskType) => (task: UserMindProgress) => task.sourceType === type;

export const getTaskByIds = (packages: ParsedMindPackage[], packageId: string, taskId: string) => {
  return packages?.find(minPackage => minPackage.id === packageId)
    ?.progressItems
    ?.find(task => task.source?.id === taskId);
};

export const getPreviousTask = (mindPackage: ParsedMindPackage, taskId: string) => {
  const currentIndex = mindPackage.progressItems?.findIndex(task => task.source?.id === taskId);

  return currentIndex ? mindPackage.progressItems[currentIndex - 1] : null;
};

export const getNextTask = (mindPackage: ParsedMindPackage, taskId: string) => {
  const currentIndex = mindPackage.progressItems?.findIndex(task => task.source?.id === taskId);

  return mindPackage.progressItems[currentIndex + 1] || null;
};
