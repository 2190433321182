import { createAction } from 'redux-actions';
import { UserData } from './reducer';

type Action<T1, T2 = any> = { type: T1; payload?: T2 };

export enum AuthActionTypes {
  SIGN_IN = 'auth/SIGN_IN',
  SIGN_IN_SUCCESS = 'auth/SIGN_IN_SUCCESS',
  SIGN_IN_FAIL = 'auth/SIGN_IN_FAIL',
  SIGN_OUT = 'auth/SIGN_OUT',
  REHYDRATE_USER = 'auth/REHYDRATE_USER',
}

export type SignInForm = {
  email: string | undefined;
  password: string | undefined;
};

export type ErrorEnvelope = {
  code: string;
  message: string;
};

export type AuthActions =
  Action<AuthActionTypes.SIGN_IN, SignInForm>
  | Action<AuthActionTypes.SIGN_IN_SUCCESS, UserData>
  | Action<AuthActionTypes.SIGN_IN_FAIL, ErrorEnvelope>
  | Action<AuthActionTypes.SIGN_OUT>
  | Action<AuthActionTypes.REHYDRATE_USER>;

export const ActionCreators = {
  signIn: createAction<SignInForm>(AuthActionTypes.SIGN_IN),
  signInSuccess: createAction<UserData>(AuthActionTypes.SIGN_IN_SUCCESS),
  signInFail: createAction<ErrorEnvelope>(AuthActionTypes.SIGN_IN_FAIL),
  signOut: createAction(AuthActionTypes.SIGN_OUT),
  rehydrateUser: createAction(AuthActionTypes.REHYDRATE_USER),
};
