import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFields, useIsAdmin, usePromise } from 'hooks';
import { API } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

import { Button, ButtonType, List, ListBody, ListHeader, WithTooltip } from 'components/shared';
import { useGraphQLApi } from '../../hooks/useGraphQLApi';
import { QueryResult } from '../../global/models/helper.model';
import { getClientConfiguration } from '../../api/graphql/queries';
import { ClientConfiguration } from '../../global/models/client-configuration.model';
import { updateClientConfiguration } from '../../api/graphql/mutations';
import { useTypedSelector } from '../../utils';

export const ClientManagementEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const isAdmin = useIsAdmin();
  const userData = useTypedSelector(it => it.auth.signIn.userData);

  const navigate = useNavigate();

  const [fields, setFields, onUpdateField] = useFields<{ minimum_version: string | undefined }>({
    minimum_version: '',
  });

  const { data, loading, error } = useGraphQLApi<QueryResult<'getClientConfiguration', ClientConfiguration>>({
    query: getClientConfiguration,
    variables: {
      id,
    },
  });

  const configuration = data?.data?.getClientConfiguration;

  const onSubmit = async () => {
    await API.graphql({
      query: updateClientConfiguration,
      variables: {
        input: {
          id,
          minimum_version: fields.minimum_version,
          configured_by: userData?.email,
        },
      },
    });

    navigate(-1);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [submit, { result: _result, isLoading: isSaving, error: errorSubmit }] = usePromise(onSubmit);
  const errorMessage = error?.message || errorSubmit?.errors?.[0]?.message;

  useEffect(
    () => {
      setFields({
        minimum_version: configuration?.minimum_version,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configuration],
  );

  return (
    <List>
      <ListHeader title={t('@T_ClientManagement_Edit_Head_Title')} />

      <ListBody
        isLoading={loading}
        errorMessage={errorMessage}
      >
        <div>
          <input type='text' id='minimum-version' value={fields.minimum_version} onChange={onUpdateField('minimum_version')} required />
        </div>
        <div className='btn-group'>
          <Button buttonType={ButtonType.DANGER} onClick={() => navigate(-1)} >
            Cancel
          </Button>
          <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
            <Button onClick={submit} disabled={!isAdmin || isSaving}>
              {isSaving ? t('@T_ClientManagement_Edit_Save_New_Version_Loading') : t('@T_ClientManagement_Edit_Save_New_Version')}
            </Button>
          </WithTooltip>
        </div>
      </ListBody>
    </List>
  );
};
