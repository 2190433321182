import { DBBaseModel } from './base.model';

export enum JobType {
  CAREGIVER = 'CAREGIVER',
}

export enum MindPackageOrderGroup {
  MANDATORY_STRESS = 'MANDATORY_STRESS',
}

export enum MindTaskType {
  SESSION = 'SESSION',
  EVALUATION = 'EVALUATION',
  FEEDBACK = 'FEEDBACK',
  HEALTH_QUESTIONNAIRE = 'HEALTH_QUESTIONNAIRE',
}

export enum MindState {
  STRESSED = 'STRESSED',
  OKAY = 'OKAY',
  RELAXED = 'RELAXED',
}

export type PaidMindPackage = {
  id: string;
};

export type TenantsPaidMindPackages = {
  [id: string]: PaidMindPackage[];
};

export type MindPackageContentful = {
  title: string;
  body: string;
  imgSrc?: string;
  id?: string;
};

export type MindPackage = {
  id?: string;
  jobType?: JobType;
  packageGroupOrder?: number;
  packageGroup?: MindPackageOrderGroup;
  content?: MindPackageContentful;
};

export type MindTask = {
  id: string;
  packageId: string;
  task_predecessor?: string;
  type: MindTaskType;
  activeOnly: boolean;
  content?: {
    title: string;
    sys?: {
      revision?: number;
    };
    durationSeconds: number;
    body: string;
    imgSrc?: string;
    videoMediaVersion?: number;
    audioMediaVersion?: number;
    videoSrc?: string;
    audioSrc?: string;
  }
};

export type UserMindProgress = {
  id: string;
  identitySub: string;
  sourceId: string;
  sourceType: MindTaskType;
  resultId?: string;
  completedAt?: string;
  startedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  source?: MindTask;
  package?: MindPackage;
};

export type UserMindPackageFeedback = DBBaseModel & {
  package_id: string;
  rating: number;
};

export type UserMindEvaluationResult = DBBaseModel & {
  mind_task_id: string;
  state: MindState;
};

export type DBUserMindProgress = DBBaseModel & {
  completed_at: string;
  started_at: string;
  source_id: string;
  source_type: MindTaskType;
  result_id?: string;
};

export interface ParsedMindPackage extends MindPackage {
  progressItems: UserMindProgress[];
}
