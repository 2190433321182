import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { toast } from 'react-toastify';

import {
  createTeamAccess as CREATE_TEAM_ACCESS,
  updateTeamAccess as UPDATE_TEAM_ACCESS,
} from 'api/graphql/custom';
import { TeamAccessFields } from 'api/API';
import { TeamAccessCreateUpdate } from 'screens/tenant-management/utils';

type CreateOrUpdateTeamAccess = [
  (e: React.FormEvent<HTMLFormElement>) => Promise<any>,
  boolean,
  string,
];

export const useCreateOrUpdateTeamAccess = (
  fields: TeamAccessFields,
  tenant_id: string,
  createOrUpdateTeamAccess: TeamAccessCreateUpdate | null,
): CreateOrUpdateTeamAccess => {
  const [loading, setLoading] = useState<boolean>(false);
  const [createTeamAccess] = useMutation(
    gql`
      ${CREATE_TEAM_ACCESS}
    `,
  );
  const [updateTeamAccess] = useMutation(
    gql`
      ${UPDATE_TEAM_ACCESS}
    `,
  );
  const [error, setError] = useState<string>('');

  const handleCreateOrUpdateTeamAccess = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    setLoading(true);
    const { access_code, tenant_access_id, validity, team_id } = fields;

    try {
      if (createOrUpdateTeamAccess) {
        if (createOrUpdateTeamAccess === TeamAccessCreateUpdate.Create) {
          await createTeamAccess({
            variables: {
              input: {
                access_code: access_code,
                tenant_access_id: tenant_access_id,
                tenant_id: tenant_id,
                validity: validity,
              },
            },
          });
        } else {
          await updateTeamAccess({
            variables: {
              input: {
                access_code: access_code,
                tenant_id: tenant_id,
                validity: validity,
                id: team_id,
              },
            },
          });
        }
      }
    } catch (err) {
      toast.error(err.message);
      setError(err.message);
    }

    setLoading(false);
  };

  return [handleCreateOrUpdateTeamAccess, loading, error];
};
