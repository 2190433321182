import React, { useEffect, useState, useRef, KeyboardEvent } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Pagination from 'react-bootstrap/Pagination';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import { WithTooltip, List, ListBody, ListHeader } from 'components/shared';
import { useIsAdmin, useErrorToast } from 'hooks';
import { adminQuery, UserSearchField, UserSearchAttribute, UserWithAdminProps } from '../../services/adminQuery.service';
import { usePromise } from '../../hooks/usePromise';
import { Routes } from '../../config/routes.config';
import { route, fetchAllUsers, processAdminQuerySequentially, isValidEmail, aTagStyle } from '../../utils';
import { DATE_FORMAT_SECONDARY } from '../../config';

export const UsersManagement = () => {
  const { t } = useTranslation();
  const inputEl = useRef<HTMLInputElement>(null);
  const [users, setUsers] = useState<UserWithAdminProps[]>([]);
  const [pagesTokens, setPagesTokens] = useState<(string | null)[]>([null]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [search, setSearch] = useState<string>();
  const [dirty, setDirty] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<boolean>(false);
  const isAdmin = useIsAdmin();
  const [searchAttribute, setSearchAttribute] = useState<UserSearchAttribute>('given_name');

  const SEARCH_FIELDS: UserSearchField[] = [
    { attribute: 'given_name', label: t('@T_General_FirstName') },
    { attribute: 'email', label: t('@T_General_EmailLabel') },
    { attribute: 'family_name', label: t('@T_General_LastName') },
  ];

  const onToggleDisableUser = (user: UserWithAdminProps) => (
    (user.Enabled ? adminQuery.disableUser(user) : adminQuery.enableUser(user))
      .then(() => {
        const updatedUsers = users!.map((currentUser: UserWithAdminProps) => (
          (currentUser.Attributes['custom:user_id'] === user.Attributes['custom:user_id']) ? { ...currentUser, Enabled: !currentUser.Enabled } : currentUser));

        setUsers(updatedUsers);
      })
      .catch(e => {
        toast.error(e.message);
      })
  );
  
  const onDeleteUser = (user: UserWithAdminProps) => {
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm(t('@T_UserList_DeleteUserConfirmation'));

    if (!confirmed) {
      return Promise.resolve();
    }

    return adminQuery.createDeletedUser(user)
      .then(() => adminQuery.deleteUser(user)
        .then(() => setUsers(users!.filter(({ Attributes }) => Attributes['custom:user_id'] !== user.Attributes['custom:user_id'])))
        .catch(e => {
          toast.error(e.message);
        }));
  };

  const onVerifyEmailAddress = (user: UserWithAdminProps) => {
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm(t('@T_UserList_VerifyEmailAddressConfirmation'));

    if (!confirmed) {
      return Promise.resolve();
    }

    return adminQuery.verifyEmailAddress(user)
      .then(() => {
        setDirty(true);
      }).catch(e => {
        toast.error(e.message);
      });
  };

  const onLoadUsers = (page: number, searchText: string = '') => (
    adminQuery.listUsers({ token: pagesTokens[page], search: searchText, searchAttribute })
      .then(({ users: newUsers, nextToken }) => {
        if (nextToken) {
          const tokens = [...pagesTokens];
          tokens[page + 1] = nextToken;
          setPagesTokens(tokens);
        }

        setUsers(newUsers);
        setDirty(false);
      })
  );

  const handleFieldChange = (e: React.ChangeEvent<any>) => {
    setSearchAttribute(e.target.value);
    setPagesTokens([null]);
    setCurrentPage(0);
    setDirty(!!search);
  };

  const onVerifyAllEmailAddresses = async () => {

    const allUsers = await fetchAllUsers(null, []);

    // find users with unverified email addresses
    const usersWithUnverifiedEmailAddresses = allUsers.filter(({ Attributes }) => !Attributes['custom:email_verified_at']);

    // eslint-disable-next-line no-alert
    const confirmed = window.confirm(`This will verify ${usersWithUnverifiedEmailAddresses.length} of ${allUsers.length} users email addresses. Continue?`);

    if (!confirmed) {
      return Promise.resolve();
    }

    return processAdminQuerySequentially(usersWithUnverifiedEmailAddresses, adminQuery.verifyEmailAddress);
  };

  const onSetAllFeatureFlagsId = async () => {

    const allUsers = await fetchAllUsers(null, []);

    // find users without feature flag ids
    const usersWithoutFeatureFlagsId = allUsers.filter(({ Attributes }) => !Attributes['custom:feature_flags_id']);

    // eslint-disable-next-line no-alert
    const confirmed = window.confirm(`This will set up ${usersWithoutFeatureFlagsId.length} of ${allUsers.length} users feature flags id. Continue?`);

    if (!confirmed) {
      return Promise.resolve();
    }

    return processAdminQuerySequentially(usersWithoutFeatureFlagsId, adminQuery.setFeatureFlagsId);
  };

  const onSetConsentAnalyticsAt = async () => {

    const allUsers = await fetchAllUsers(null, []);

    // find users without consent_analytics_at
    const usersWithoutConsentAnalyticsAt = allUsers.filter(({ Attributes }) => !Attributes['custom:consent_analytics_at']);

    // eslint-disable-next-line no-alert
    const confirmed = window.confirm(`This will set up ${usersWithoutConsentAnalyticsAt.length} of ${allUsers.length} users consent analytics at. Continue?`);

    if (!confirmed) {
      return Promise.resolve();
    }

    return processAdminQuerySequentially(usersWithoutConsentAnalyticsAt, adminQuery.setConsentAnalyticsAt);
  };

  const [toggleDisableUser, { isLoading: isTogglingUser }] = usePromise<void, [UserWithAdminProps]>(onToggleDisableUser);
  const [deleteUser, { isLoading: isDeletingUser }] = usePromise<void, [UserWithAdminProps]>(onDeleteUser);
  const [verifyEmail, { isLoading: isVerifyingEmail }] = usePromise<void, [UserWithAdminProps]>(onVerifyEmailAddress);
  const [loadUsers, { isLoading, error: loadingError }] = usePromise<void, [number, string]>(onLoadUsers);
  const [verifyAllEmail, { isLoading: isVerifyingAllEmail }] = usePromise<void>(onVerifyAllEmailAddresses);
  const [setAllFeatureFlagsId, { isLoading: isSettingAllFeatureFlagsId }] = usePromise<void>(onSetAllFeatureFlagsId);
  const [setCustomConsentAnalyticsAt, { isLoading: isSettingCustomConsentAnalyticsAt }] = usePromise<void>(onSetConsentAnalyticsAt);

  const processing = isTogglingUser || isDeletingUser || isVerifyingEmail || isLoading || isVerifyingAllEmail || isSettingAllFeatureFlagsId
    || isSettingCustomConsentAnalyticsAt;

  useErrorToast(loadingError);

  const onSearchBlur = () => {
    if (searchData) {
      setPagesTokens([null]);
      setSearch(inputEl.current?.value?.toLowerCase().trim() || '');
      setCurrentPage(0);
      setSearchData(false);
    }
  };

  const onChange = () => {
    if (!searchData) {
      setSearchData(true);
    }
  };

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13 || event.key === 'Enter') {
      onSearchBlur();
    }
  };

  useEffect(() => {
    loadUsers(currentPage, search!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, search, dirty, searchAttribute]);

  const searchAttributeSelectorStyle = { height: 50, width: 'auto', paddingTop: 0, paddingBottom: 0 };

  const renderDangerZone = () => (
    <React.Fragment>
      <ListHeader title='Danger Zone' />
      <ListBody>
        <table>
          <tbody >
            <tr>
              <td>
                Verify Email Addresses for ALL users
              </td>
              <td>
                <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
                  <Button disabled={!isAdmin} onClick={verifyAllEmail} type='button' variant='danger'>Verify All Email Addresses</Button>
                </WithTooltip>
              </td>
            </tr>
            <tr>
              <td>
                Set Feature Flags for ALL users
              </td>
              <td>
                <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
                  <Button disabled={!isAdmin} onClick={setAllFeatureFlagsId} type='button' variant='danger'>Set All Feature Flags</Button>
                </WithTooltip>
              </td>
            </tr>
            <tr>
              <td>
                Set Consent Analytics for ALL users
              </td>
              <td>
                <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
                  <Button disabled={!isAdmin} onClick={setCustomConsentAnalyticsAt} type='button' variant='danger'>Set Consent Analytics at</Button>
                </WithTooltip>
              </td>
            </tr>
          </tbody>
        </table>
      </ListBody>
    </React.Fragment>
  );

  return (
    <List className='container pb-4' data-testid='user-management'>

      {processing ? <div className='full-size-centered white-opacity'><Spinner animation='border' variant='primary' /></div> : null}

      <div className='d-flex justify-content-between align-items-center'>
        <ListHeader title={t('@T_UserList_UserManagement')} />

        <InputGroup className='d-flex flex-nowrap w-50'>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
          <FormControl
            className='mb-0'
            ref={inputEl}
            placeholder={t('@T_General_Search')}
            aria-label={t('@T_General_Search')}
            onBlur={onSearchBlur}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />

          <select onChange={handleFieldChange} style={searchAttributeSelectorStyle} className='m-0'>
            {SEARCH_FIELDS.map(field => (
              <option key={field.attribute} value={field.attribute}>{field.label}</option>
            ))}
          </select>
        </InputGroup>

        <Pagination className='m-0'>
          <Pagination.Prev disabled={!currentPage} onClick={() => setCurrentPage(currentPage - 1)} />
          <Pagination.Item disabled>{currentPage + 1}</Pagination.Item>
          <Pagination.Next disabled={!pagesTokens[currentPage + 1]} onClick={() => setCurrentPage(currentPage + 1)} />
        </Pagination>
      </div>

      <ListBody>
        {isLoading && t('@T_General_LoadingIndicator')}

        {(!isLoading && !loadingError) && (
          <table>
            <thead>
              <tr>
                <th>{t('@T_General_Name')}</th>
                <th>{t('@T_General_EmailLabel')}</th>
                <th>{t('@T_License_Tenant_Name')}</th>
                <th>{t('@T_General_EmailVerified')}</th>
                <th>{t('@T_Delete_Or_Deactivate_Account')}</th>
              </tr>
            </thead>

            <tbody>
              {users?.map((user: UserWithAdminProps) => (
                <tr key={user.Attributes['custom:user_id']}>
                  <td>
                    <Link to={route(Routes.USER_DETAILS, { id: user.Attributes['custom:user_id'] })}>
                      {user.Attributes.given_name} {user.Attributes.family_name}
                    </Link>
                  </td>
                  <td><Link style={aTagStyle} to={route(Routes.USER_DETAILS, { id: user.Attributes['custom:user_id'] })}>{user.Attributes.email}</Link></td>
                  <td><Link style={aTagStyle} to={route(Routes.TENANT_MANAGEMENT_OVERVIEW, { id: user.Attributes['custom:tenant_id'] })}>{user.Attributes['custom:tenant_name']}</Link></td>
                  <td>
                    {user.Attributes['custom:email_verified_at'] ? dayjs(user.Attributes['custom:email_verified_at']).format(DATE_FORMAT_SECONDARY) : (
                      <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
                        <Button disabled={!isAdmin} onClick={() => verifyEmail(user)} type='button' variant='secondary'>{t('@T_UserList_VerifyEmailAddress')}</Button>
                      </WithTooltip>
                    )}
                  </td>
                  <td>
                    <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
                      <React.Fragment>
                        <Button disabled={!isAdmin} className='me-2' type='button' variant={user.Enabled ? 'danger' : 'primary'} onClick={() => toggleDisableUser(user)}>{!user.Enabled ? t('@T_UserList_EnableUser') : t('@T_UserList_DisableUser')}</Button>
                        {!user.Enabled && <Button className='me-2' disabled={!isAdmin} onClick={() => deleteUser(user)} type='button' variant='danger'>{t('@T_UserList_DeleteUser')}</Button>}
                      </React.Fragment>
                    </WithTooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </ListBody>
      {(!isLoading && !loadingError) && renderDangerZone()}
    </List>
  );
};
