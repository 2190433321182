import { BaseModel } from './base.model';

export enum CrispSessionPlatform {
  IOS = 'ios',
  ANDROID = 'android',
}

export interface CrispSession extends BaseModel {
  id: string;
  crisp_session_id: string;
  identity_sub: string;
  platform: CrispSessionPlatform;
  push_endpoint_id: string;
}
