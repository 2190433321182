import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

interface ResetModalProps {
  title: string;
  isVisible: boolean;
  body: string;
  onPressPrimary: () => void;
  onPressSecondary: () => void;
  primaryButtonLabel: string;
  secondaryButtonLabel: string;
}

export const ResetModal = ({
  title,
  body,
  isVisible,
  onPressPrimary,
  onPressSecondary,
  primaryButtonLabel,
  secondaryButtonLabel,
}: ResetModalProps) => {

  return (
    <Modal show={isVisible} onHide={onPressSecondary}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{body}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant='secondary' onClick={onPressSecondary}>{secondaryButtonLabel}</Button>
        <Button variant='secondary' onClick={onPressPrimary}>{primaryButtonLabel}</Button>
      </Modal.Footer>
    </Modal>
  );
};
