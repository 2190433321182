import React, { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useMutation, gql } from '@apollo/client';

import { route } from 'utils';
import { Modal } from 'components/shared/modal/Modal.component';
import { useModal } from 'hooks/shared/useModal';
import { Button, ButtonType } from 'components/shared/button/Button.component';
import { DATE_FORMAT_SECONDARY } from 'config/app.config';
import { useFields } from 'hooks/shared/useFields';
import { createRegistrationCodesBulk as CREATE_REGISTRATION_CODES_BULK } from 'api/graphql/mutations';
import { Routes } from 'config/routes.config';
import * as _ from 'lodash';
import { listRegistrationCodesByTenant } from '../../api/graphql/queries';

export const AddRegistrationCodes = (): JSX.Element => {
  const [leavePage, toggleLeavePage] = useState<boolean>(false);
  const { modalOpened, handleToggle, handleSecondaryButton } = useModal<void>(false, () => { toggleLeavePage(!leavePage); });
  const { t } = useTranslation();
  const { tenantId } = useParams<{ tenantId: string }>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fields, setFields, onUpdateField] = useFields<{ validTo: string; numberOfCodes: number }>({
    validTo: dayjs().add(1, 'year').format(DATE_FORMAT_SECONDARY),
    numberOfCodes: 1,
  });

  const [createRegistrationCodesBulk] = useMutation(gql`${CREATE_REGISTRATION_CODES_BULK}`, {
    refetchQueries: [
      {
        query: gql`${listRegistrationCodesByTenant}`,
        variables: {
          tenant_id: tenantId,
        },
      },
    ],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  if (leavePage) return <Navigate to={route(Routes.TENANT_MANAGEMENT_OVERVIEW, { id: tenantId! })} />;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      // create registration codes in chunks, because AWS batch limit is 25.
      const chunks = _.chunk(Array(Number(fields.numberOfCodes)).fill(null), 25);
      const promises = chunks.map(chunk => createRegistrationCodesBulk({
        variables: {
          tenantId,
          validTo: fields.validTo,
          numberOfCodes: chunk.length,
        },
      }));
      await Promise.all(promises);

      setLoading(false);

      navigate(route(Routes.TENANT_MANAGEMENT_OVERVIEW, { id: tenantId! }));
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div data-testid='add-tenant'>
      <h2 className='page-title'>
        {t('@T_License_GenerateCodes')}
      </h2>

      <div>
        <div>{`Tenant: ${tenantId}`}</div>
        <div>{`Generated at: ${dayjs().format(DATE_FORMAT_SECONDARY)}`}</div>

        <form className='form' onSubmit={handleSubmit}>
          <div className='form__field-container'>

            <div>
              <label className='required' htmlFor='numberOfCodes'>{t('@T_License_CodesCount')}
                <input type='number' min='1' max='1000' id='numberOfCodes' value={fields.numberOfCodes} onChange={onUpdateField('numberOfCodes')} required />
              </label>
            </div>

          </div>

          <div className='btn-group text-right'>
            <Button type='button' disabled={loading} buttonType={ButtonType.DANGER} onClick={handleToggle}>{t('@T_General_CancelLabel')}</Button>
            <Button type='submit' disabled={loading} >{ loading ? t('@T_General_LoadingIndicator') : t('@T_General_SaveLabel') }</Button>
          </div>
          {
            errorMessage && <div>{errorMessage}</div>
          }
        </form>
      </div>
      <Modal
        opened={modalOpened}
        onDanger={handleToggle}
        onPrimary={handleSecondaryButton}
        title={t('@T_General_DiscardChanges')}
        dangerButtonText={t('@T_General_CancelLabel')}
        primaryButtonText={t('@T_General_YesLeave')}
      />

    </div>
  );
};
