import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { useLocation, useNavigate } from 'react-router-dom';
import { type OnProgressProps } from 'react-player/base';
import { secondsToTime } from 'utils';
import { PlayerStatus, ProgressBarColors, keyboardKeys } from 'config/videoplayer.config';


import backImgSrc from '../../resources/svg/back.svg';
import playImgSrc from '../../resources/svg/play.svg';
import pauseImgSrc from '../../resources/svg/pause.svg';
import replayImgSrc from '../../resources/svg/replay.svg';

import './VideoPlayer.component.scss';


const Player = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { url, title } = location.state;

  const [isPlaying, setIsPlaying] = useState(true);
  const [showControls, setShowControls] = useState(true);
  const [percentagePlayed, setPercentagePlayed] = useState(0);
  const [percentageLoaded, setPercentageLoaded] = useState(0);
  const [muted, setMuted] = useState(true);

  const player = useRef<ReactPlayer>(null);
  const progressBar = useRef<HTMLInputElement>(null);
  const playerWrapper = useRef<HTMLDivElement>(null);

  const loadedInSeconds = player.current?.getSecondsLoaded();
  const isCompleted = +percentagePlayed === 1;

  const onLoad = () => {
    setIsPlaying(false);
    return window.removeEventListener('load', onLoad);
  };

  const replay = () => {
    setPercentagePlayed(0);
    setIsPlaying(true);
    player.current?.seekTo(0);
  };

  const showTools = () => {
    setShowControls(true);
  };

  const hideTools = () => {
    setShowControls(false);
  };

  const handleSeekChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const maxSeekValue = '0.9999';
    const seekValue =
      e.target.value > maxSeekValue ? maxSeekValue : e.target.value;
    setPercentagePlayed(parseFloat(seekValue));
    player.current?.seekTo(parseFloat(seekValue));
  };

  const handleProgress = (state: OnProgressProps) => {
    setPercentagePlayed(parseFloat(state.played.toString()));
  };

  const renderPlayerButtons = () => {
    if (isCompleted) {
      return (
        <img
          className='c-pointer center'
          onClick={replay}
          src={replayImgSrc}
          alt={PlayerStatus.Replay}
        />
      );
    } else {
      return (
        <img
          className='c-pointer center'
          onClick={() => {
            setIsPlaying(!isPlaying);
          }}
          src={isPlaying ? pauseImgSrc : playImgSrc}
          alt={isPlaying ? PlayerStatus.Pause : PlayerStatus.Play}
        />
      );
    }
  };

  const showHeader = () => {
    return (
      <header className='px-3 d-flex a-i-center w-100 mt-3'>
        <img
          src={backImgSrc}
          className='c-pointer back_button mr-1'
          height={37}
          width={37}
          alt={t('@T_VideoPlayer_Back')}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div>
          <h5 className='font-17 px-3 text-white mt-2'>{t('@T_VideoPlayer_Back')}</h5>
        </div>
      </header>
    );
  };

  const showProgressBar = () => {
    return (
      <div className='px-4 w-100 bottom_0'>
        <h5 className='font-24 text-white'>{title}</h5>
        <div className='d-flex py-5 mb-1'>
          <p className='font-17 text-white'>
            {secondsToTime(player.current?.getCurrentTime() ?? 0)}
          </p>
          <div className='mx-3 w-100'>
            <input
              ref={progressBar}
              className='w-100 bg-gray progress_bar'
              style={{
                background: `linear-gradient(
                  to right,
                  ${ProgressBarColors.playedSeconds} ${
                  percentagePlayed * 0
                }%,
                  ${ProgressBarColors.playedSeconds} ${
                  percentagePlayed * 100
                }%,
                  ${ProgressBarColors.loaded} ${percentagePlayed * 100}%,
                  ${ProgressBarColors.loaded} ${percentageLoaded}%,
                  ${ProgressBarColors.background} ${percentageLoaded}%,
                  ${ProgressBarColors.background} 100%
                )`,
              }}
              type='range'
              min={0}
              max={1}
              step='any'
              value={percentagePlayed}
              onChange={handleSeekChange}
              onKeyDown={e => {
                e.preventDefault();
              }}
            />
          </div>
          <p className='font-17 text-white'>
            {secondsToTime(player.current?.getDuration() ?? 0)}
          </p>
        </div>
      </div>
    );
  };

  useLayoutEffect(() => {
    window.addEventListener('load', onLoad);
  }, []);

  useEffect(() => {
    if (isCompleted) {
      setIsPlaying(false);
    }
  }, [isCompleted]);

  useEffect(() => {
    if (showControls) {
      setTimeout(() => {
        setShowControls(false);
      }, 3000);
    }
  }, [showControls]);

  useEffect(() => {
    setPercentageLoaded(
      ((player.current?.getSecondsLoaded() ?? 0) /
        (player.current?.getDuration() ?? 1)) *
        100,
    );
  }, [loadedInSeconds]);

  useEffect(() => {
    const handleHotKeys = (e: KeyboardEvent) => {
      switch (e.code) {
        case keyboardKeys.SPACE:
          setIsPlaying(!isPlaying);
          break;
        case keyboardKeys.ARROW_RIGHT:
          player.current?.seekTo(+player.current?.getCurrentTime() + 10);
          break;
        case keyboardKeys.ARROW_LEFT:
          player.current?.seekTo(player.current?.getCurrentTime() - 10);
          break;
      }
    };
    document.addEventListener('keyup', handleHotKeys);

    return () => {
      document.removeEventListener('keyup', handleHotKeys);
    };
  }, [percentagePlayed, isPlaying]);

  return (
    <div className='white main'>
      <div
        ref={playerWrapper}
        className='bg-black player-wrapper center'
        onMouseMove={showTools}
        onMouseDown={showTools}
        onMouseLeave={hideTools}
      >
        <ReactPlayer
          url={url}
          muted={muted}
          playing={isPlaying}
          className='player'
          ref={player}
          onProgress={handleProgress}
          playsinline
          onReady={() => setMuted(true)}
          width={'100%'}
          height={'100%'}
        />
        {showControls &&
          <div className={showControls ? 'showControls' : 'o-0'}>
            {showHeader()}
            {renderPlayerButtons()}
            {showProgressBar()}
         </div>
        }
      </div>
    </div>
  );
};

export default React.memo(Player);