import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useIsAdmin, usePromise } from 'hooks';
import { WithTooltip, List, ListBody, ListHeader } from 'components/shared';
import Pagination from 'react-bootstrap/Pagination';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import FormControl from 'react-bootstrap/FormControl';

import { adminQuery } from 'services/adminQuery.service';
import { Routes } from '../../config/routes.config';
import { route } from '../../utils';
import { Tenant } from '../../global/models';

export enum SearchAttribute {
  name = 'name',
  invoice = 'invoice',
}

export interface TenantSearchField {
  attribute: SearchAttribute;
  label: string;
}

const searchAttributeSelectorStyle = { height: 50, width: 'auto', paddingTop: 0, paddingBottom: 0 };

export const TenantManagement = () => {
  const { t } = useTranslation();
  const inputEl = useRef<HTMLInputElement>(null);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [pagesTokens, setPagesTokens] = useState<(string | null)[]>([null]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [search, setSearch] = useState<string>();
  const isAdmin = useIsAdmin();
  const [searchAttribute, setSearchAttribute] = useState<TenantSearchField['attribute']>(SearchAttribute.name);

  const SEARCH_FIELDS: TenantSearchField[] = [
    { attribute: SearchAttribute.name, label: t('@T_General_Name') },
    { attribute: SearchAttribute.invoice, label: t('@T_General_Invoice') },
  ];

  const onLoadTenants = (page: number, searchValue = '', searchBy = SearchAttribute.name) => {
    if (searchBy === SearchAttribute.invoice && !searchValue) {
      setTenants([]);
      return Promise.resolve();
    }

    if (searchBy === SearchAttribute.invoice) {
      return adminQuery.getTenantsByInvoice({ invoice_id: searchValue }).then(response => {
        setTenants(response ? [response] : []);
      });

    }

    return adminQuery.listTenantsWithLicensesByName({ token: pagesTokens[page], name: searchValue }).then(response => {

      const { items: fetchedTenants, nextToken } = response.data?.listTenantsByName;

      if (nextToken) {
        const tokens = [...pagesTokens];
        tokens[page + 1] = nextToken;
        setPagesTokens(tokens);
      }

      const sortedTenants = fetchedTenants.sort((a, b) => a.name.localeCompare(b.name));

      setTenants(sortedTenants);
    });
  };

  const [loadTenants, { isLoading: isLoadingTenants, error: loadingError }] = usePromise<void, [number, string, SearchAttribute]>(onLoadTenants);

  const onSearchBlur = () => {
    setSearch(inputEl.current?.value.trim() || '');
    setCurrentPage(0);
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchAttribute(e.target.value as SearchAttribute);
  };

  const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.charCode === 13 || event.key === 'Enter') {
      onSearchBlur();
    }
  };

  useEffect(() => {
    loadTenants(currentPage, search!, searchAttribute);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, search, searchAttribute]);

  return (
    <List className='container pb-4'>

      <div className='d-flex justify-content-between align-items-center'>
        <ListHeader title={t('@T_Customer_ListOfCustomers')} />

        <InputGroup className='d-flex flex-nowrap w-50'>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faSearch as IconProp} />
          </InputGroup.Text>
          <FormControl
            className='mb-0'
            ref={inputEl}
            placeholder={t('@T_General_Search')}
            aria-label={t('@T_General_Search')}
            onBlur={onSearchBlur}
            onKeyPress={onKeyPress}
            disabled={isLoadingTenants}
          />

          <select onChange={handleFieldChange} style={searchAttributeSelectorStyle} className='m-0' disabled={isLoadingTenants}>
            {SEARCH_FIELDS.map(field => (
              <option key={field.attribute} value={field.attribute}>{field.label}</option>
            ))}
          </select>
        </InputGroup>

        <Pagination className='m-0'>
          <Pagination.Prev disabled={!currentPage} onClick={() => setCurrentPage(currentPage - 1)} />
          <Pagination.Item disabled>{currentPage + 1}</Pagination.Item>
          <Pagination.Next disabled={!pagesTokens[currentPage + 1]} onClick={() => setCurrentPage(currentPage + 1)} />
        </Pagination>

        <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
          <Link to={Routes.TENANT_MANAGEMENT_ADD} className={`btn btn-secondary text-light ${!isAdmin ? 'disabled' : ''}`} >
            {t('@T_Customer_AddCustomer')}
          </Link>
        </WithTooltip>
      </div>

      <ListBody>
        {loadingError && <div>{loadingError.message}</div>}

        {isLoadingTenants && <div>{t('@T_General_LoadingIndicator')}</div>}

        {(!loadingError && !isLoadingTenants) && (
        <table>
          <thead>
            <tr>
              <th>{t('@T_General_Name')}</th>
              <th>{t('@T_Customer_LicensesBought')}</th>
            </tr>
          </thead>

          <tbody>
            {tenants.map((tenant: Tenant) => (
              <tr key={tenant.id}>
                <td><Link to={route(Routes.TENANT_MANAGEMENT_OVERVIEW, { id: tenant.id as string })}>{tenant.name}</Link></td>
                <td>{tenant.licenses?.items?.length}</td>
              </tr>
            ))}
          </tbody>
        </table>
        )}
      </ListBody>
    </List>
  );
};
