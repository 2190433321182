import React from 'react';
import { useTranslation } from 'react-i18next';

import { WithTooltip } from 'components/shared/with-tooltip';
import { useIsAdmin } from 'hooks';
import { JobFields } from 'screens/job-management';
import { Button } from '../shared/button/Button.component';
import './JobDetails.component.scss';

export interface JobDetailsProps {
  fields: JobFields;
  toggleEdit: () => void;
}

export const JobDetails = ({ fields, toggleEdit }: JobDetailsProps): JSX.Element => {
  const { t } = useTranslation();
  const isAdmin = useIsAdmin();

  return (
    <div className='job-overview' data-testid='job-details'>
      <h2 className='page-title'>
        {t('@T_Job_JobOverviewLabel')}
      </h2>
      <div className='job-overview__details'>
        <div>
          <div>
            <p>{t('@T_General_Name')}</p>
            <p>{fields.name}</p>
          </div>

          <div>
            <p>{t('@T_Job_JobType')}</p>
            <p>{fields.type}</p>
          </div>
        </div>

        <div>
          <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
            <Button type='submit' disabled={!isAdmin} onClick={toggleEdit}>{t('@T_General_EditLabel')}</Button>
          </WithTooltip>
        </div>
      </div>
    </div>
  );
};
