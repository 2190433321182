import awsExports from './aws-exports';

export interface AwsConfig {
  aws_appsync_dangerously_connect_to_http_endpoint_for_testing?: boolean;
  aws_appsync_graphqlEndpoint: string;
  aws_project_region: string;
  aws_cognito_identity_pool_id: string;
  aws_cognito_region: string;
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  oauth: {};
  aws_appsync_region: string;
  aws_appsync_authenticationType: string;
  aws_appsync_apiKey: string;
}

export const awsConfig : AwsConfig = {
  ...awsExports as AwsConfig,
  aws_appsync_graphqlEndpoint: awsExports.aws_appsync_graphqlEndpoint,
};
