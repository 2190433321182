import React, { ChangeEvent, useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import dayjs from 'dayjs';
import { Tab, Tabs } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { WithTooltip, List, ListBody, ListHeader } from 'components/shared';
import { listLicensesForLicenseManagement } from 'api/graphql/custom';
import { License, LicenseType, ModelSortDirection } from 'api/API';
import { QueryListResult } from 'global/models/helper.model';
import { DATE_FORMAT_TERTIARY, Routes } from 'config';
import { route } from 'utils';
import { useIsAdmin } from 'hooks/useHasGroup';
import Checkbox from 'components/shared/checkbox/Checkbox';

const { includes } = require('lodash');

enum LicenseTabKeys {
  EXPIRED = 'expired',
  EXPIRING = 'expiring',
}

export type PartialLicense = Pick<License, 'id' | 'identity_sub'>;

const EXPIRATION_TIME_IN_DAYS = 40;

const licenseQueryVariablesMapping = {
  [LicenseTabKeys.EXPIRED]: {
    sortDirection: ModelSortDirection.DESC,
    type: LicenseType.LICENSE,
    validTo: {
      lt: dayjs().format(DATE_FORMAT_TERTIARY),
    },
  },
  [LicenseTabKeys.EXPIRING]: {
    sortDirection: ModelSortDirection.ASC,
    type: LicenseType.LICENSE,
    validTo: {
      between: [dayjs().format(DATE_FORMAT_TERTIARY), dayjs().add(EXPIRATION_TIME_IN_DAYS, 'day').format(DATE_FORMAT_TERTIARY)],
    },
  },
};

export const LicenseManagementOverview = () => {
  const { t } = useTranslation();
  const isAdmin = useIsAdmin();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<LicenseTabKeys>(LicenseTabKeys.EXPIRING);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | null>(null);
  const [licenses, setLicenses] = useState<License[]>([]);
  const [nextToken, setNextToken] = useState<string | null>();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selectedLicenses, setSelectedLicenses] = useState<PartialLicense[]>([]);

  const licensesWithEmail: License[] = licenses.filter(license => !!license.user);
 
  const fetchLicenses = async () => {
    try {
      setLoading(true);

      const result = await API.graphql({
        query: listLicensesForLicenseManagement,
        variables: {
          ...licenseQueryVariablesMapping[selectedTab],
          nextToken,
        },
      }) as QueryListResult<'listLicensesByValidTo', License>;

      const newLicenses = result?.data?.listLicensesByValidTo?.items.filter(license => license.identity_sub);
      setNextToken(result?.data?.listLicensesByValidTo?.nextToken);
      setLicenses([...licenses, ...(newLicenses || [])]);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const switchTab = (tab: any) => {
    setLicenses([]);
    setNextToken(undefined);
    setSelectedTab(tab!);
  };

  useEffect(
    () => {
      fetchLicenses();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTab],
  );

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setSelectedLicenses(isCheckAll ? [] : licenses.map(li => {
      return {
        id: li.id,
        identity_sub: li.identity_sub,
      };
    }));
  };

  const handleClick = (license: License, e: ChangeEvent<HTMLInputElement>) => {
    const { id, identity_sub } = license;
    const { checked } = e.target;

    setSelectedLicenses([...selectedLicenses, { id, identity_sub }]);
    if (!checked) {
      setSelectedLicenses(selectedLicenses.filter(item => item.id !== id));
    }
  };

  const handleMultiSelect = () => {
    if (selectedLicenses.length > 0) {
      navigate(Routes.LICENSE_MANAGEMENT_EDIT, { state: selectedLicenses });
    } else {
      toast.warn('No Licenses Selected');
    }
  };
  return (
    <div className='container pb-4'>
      <Tabs defaultActiveKey={selectedTab} onSelect={switchTab}>
        <Tab eventKey='expiring' title={t('@T_License_Expiring')}/>
        <Tab eventKey='expired' title={t('@T_License_Expired')}/>
      </Tabs>

      <List>
        <ListHeader title={t('@T_License_Licenses')} actionButton={{ label: t('@T_License_Renew'), onClick: handleMultiSelect, show: isAdmin }} />
        <ListBody
          isLoading={loading}
          errorMessage={error?.message}
          loadMore={!!nextToken}
          onLoadMore={fetchLicenses}
        >
          <table>
            <thead>
              <tr>
              {isAdmin && <th>
                  <Checkbox
                    id='selectAll'
                    handleClick={handleSelectAll}
                    isChecked={isCheckAll}
                  />
                </th>}
                <th>
                  {t('@T_License_ValidTo')}
                </th>
                <th>
                  {t('@T_License_Tenant_Name')}
                </th>
                <th>
                  {t('@T_License_User_E-Mail')}
                </th>
                <th>
                  {t('@T_License_Action')}
                </th>
              </tr>
            </thead>

            <tbody>
              {licensesWithEmail?.length ? licensesWithEmail.map(license => {
                const user = license?.user ? JSON.parse(license?.user) : undefined;

                return (
                  <tr key={license?.id}>
                    {isAdmin && <td>
                      <Checkbox
                        id={license?.id as string}
                        handleClick={e => handleClick(license, e)}
                        isChecked={selectedLicenses.some(item => includes(item, license?.id))}
                      />
                    </td>}
                    <td>{license?.validTo}</td>
                    <td>{license?.tenant?.name}</td>
                    <td>{user?.UserAttributes?.email}</td>
                    <td>
                      <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
                        <Link to={route(Routes.LICENSE_MANAGEMENT_EDIT, { id: license.id! })} className={`btn btn-secondary text-light ${!isAdmin ? 'disabled' : ''}`}>
                          {t('@T_License_Renew')}
                        </Link>
                      </WithTooltip>
                    </td>
                  </tr>
                );
              }) : <tr><td>{`No licenses found that are ${selectedTab} within ${EXPIRATION_TIME_IN_DAYS} days`}</td></tr>}
            </tbody>
          </table>
        </ListBody>
      </List>
    </div>
  );
};
