import { useState } from 'react';

interface UsePromiseState<Result> {
  result: Result | null;
  isLoading: boolean;
  error: any;
}

export const usePromise = <Result = any, Args extends any[] = []>
  (promise: (...args: Args) => Promise<Result>): [(...args: Args) => void, UsePromiseState<Result>] => {
  const [result, setResult] = useState<Result | null>(null);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const execute = (...args: Args) => {
    setError(null);
    setLoading(true);

    promise(...args)
      .then(response => setResult(response))
      .catch(err => setError(err))
      .finally(() => setLoading(false));
  };

  return [
    execute,
    {
      result,
      isLoading,
      error,
    },
  ];
};
