import React, { MouseEvent } from 'react';
import './ListHeader.component.scss';

interface ListHeaderActionButton {
  label: string;
  onClick: (e: MouseEvent<HTMLAnchorElement>) => void;
  show: boolean | undefined;
}

interface ListHeaderProps {
  title: string;
  children?: React.ReactNode;
  actionButton?: ListHeaderActionButton;
}

export const ListHeader = ({ title, children, actionButton }: ListHeaderProps) => (
  <div className='list__header'>
    <h2 className='page-title'>
      {title}
    </h2>
    <div className='list__buttons'>
      {children}
    </div>
    {actionButton && actionButton.show &&
      <a className='btn app-btn-danger align-self-center text-white' onClick={actionButton.onClick} >
        {actionButton.label}
      </a>
    }
  </div>
);
