import { Entry, Asset, EntryCollection } from 'contentful';
import { MindPackageContentful } from 'global/models';

export interface GetContentfulEntriesQuery {
  locale?: string;
  content_type?: ContentfulContentType | string;
  select?: string;
  order?: string;
  limit?: number;
  skip?: number;
  // https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters
  [search: string]: string | number | undefined | Object;
}

export interface ContentfulStorageResults<T = any> {
  data: T | null;
  timestamp: number;
}

export enum ContentfulContentType {
  ONBOARDING_SCREEN = 'onboardingScreen',
  TRANSLATIONS = 'translations',
  POLICIES = 'policies',
  CONFIG = 'config',
  TASKS = 'tasks',
  MIND_PACKAGES = 'mindPackages',
}

const baseContentfulQuery = {
  select: 'fields,sys.id',
  limit: 100,
} as GetContentfulEntriesQuery;

const mindPackagesQuery = {
  ...baseContentfulQuery,
  content_type: ContentfulContentType.MIND_PACKAGES,
};

export const baseContentfulQueries = [mindPackagesQuery];

export interface ContentfulProviderValue {
  [ContentfulContentType.MIND_PACKAGES]?: EntryCollection<MindPackageContentful>;
}

export interface ContentfulOnboardingScreen {
  order: number;
  header: string[];
  description: string[];
  name: string;
  background: string[];
  color: string;
  buttonText?: string;
  buttonBackground?: string[];
  buttonColor?: string;
  image?: Asset;
  customContent?: boolean;
}

export interface AccordionItem {
  title: string;
  isOpen: boolean;
  text: string;
}

export interface Task {
  id: string;
  title: string;
}

export type ContentFulAccordionItemEntry = Entry<AccordionItem>;

export type ContentfulOnboardingScreenEntry = Entry<ContentfulOnboardingScreen>;

export interface ContentfulTranslation {
  key: string;
  value: string;
}

export interface ContentfulTranslations {
  locale: string;
  items: Record<string, string>;
}

export type ContentfulTranslationEntry = Entry<ContentfulTranslation>;
