import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { presignedFileUrl, getUserMovementAssignments } from 'api/graphql/queries';
import { movementAnalysisAssetByUserId } from 'api/graphql/custom';
import { UserMovementAnalysisAsset } from 'api/API';
import { Block, Task } from 'global/models/movement-training.model';
import { parseMovementsV2 as parseMovements, flattenModulesV2 as flattenModules } from 'global/services/movement-parser.service';
import { Button, ButtonType } from 'components/shared';
import { DATE_TIME_FORMAT } from 'config';
import { AmplifyApi } from 'utils';
import { UserRoute } from '../config';

interface UserData {
  assignments: Task[];
  blocks: Block[];
  assets: UserMovementAnalysisAsset[];
}

export const UploadedVideos = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [userData, setUserData] = useState<UserData>({ assignments: [], blocks: [], assets: [] });
  const [loadingVideoIndex, setLoadingVideoIndex] = useState(-1);
  const [isLoadingVideos, setIsLoadingVideos] = useState(false);

  const fetchUserData = async (customUserId: string) => {
    try {
      setIsLoadingVideos(true);
      const [assignmentsResponse, assetsResponse] = await Promise.all([
        AmplifyApi(getUserMovementAssignments, { userId: customUserId, isAdminApp: true }),
        AmplifyApi(movementAnalysisAssetByUserId, { identity_sub: customUserId }),
      ]);

      const assignmentsData = assignmentsResponse.data.getUserMovementAssignments;
      const modules = parseMovements(assignmentsData);
      const flattenTasks = flattenModules(modules);

      const assetsData: UserMovementAnalysisAsset[] = assetsResponse?.data?.movementAnalysisAssetByUser?.items || [];
      const sortedByCreateDate = assetsData.sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix());
      setIsLoadingVideos(false);
      setUserData({ assignments: flattenTasks, assets: sortedByCreateDate, blocks: modules[0].blocks });
    } catch (error) {
      setIsLoadingVideos(false);
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchUserData(id);
    }
  }, [id]);

  const getPresignedFileUrl = async (pathName: string) => {
    const result = await AmplifyApi(presignedFileUrl, { path: pathName });
    return result?.data?.presignedFileUrl as string | null | undefined;
  };

  const openVideo = async (title: string, assetSource: string | null | undefined) => {
    if (!assetSource) return;

    setLoadingVideoIndex(userData.assets.findIndex((asset: UserMovementAnalysisAsset) => asset.src_file === assetSource));
    const url = await getPresignedFileUrl(assetSource);
    setLoadingVideoIndex(-1);
    if (url) {
      navigate(UserRoute.VIDEO_PLAYER, { state: { url, title } });
    }
  };

  const calculateVideoTitle = (assignmentId: string) => {
    const relevantTask = userData.assignments.find((task: Task) => task.userAssignment?.id === assignmentId);
    if (!relevantTask) return '';
    const block = userData.blocks.find((it: Block) => it.id === relevantTask.parentBlockId);
    return `${block?.content?.title || t('@T_General_Unknown')} (${relevantTask.id})`;
  };

  const center = { textAlign: 'center' as const };

  return (
    <div>
      {isLoadingVideos ? <div className='full-size-centered white-opacity'><Spinner animation='border' variant='primary' /></div> : null}
      <Card className='mb-4'>
        <Card.Header>{t('@T_UserDetails_UploadedVideos')}</Card.Header>
        <Card.Body>
          <table>
            <thead>
              <tr>
                <th style={center}>{t('@T_UserDetails_UploadedVideos_Table_Header_1')}</th>
                <th style={center}>{t('@T_UserDetails_UploadedVideos_Table_Header_2')}</th>
                <th style={center}>{t('@T_General_Actions')}</th>
              </tr>
            </thead>
            <tbody>
              {userData.assets.map((asset, index) => (
                <tr key={asset.id}>
                  <td style={center}>{calculateVideoTitle(asset.user_assignment_id)}</td>
                  <td style={center}>{dayjs(asset.createdAt).format(DATE_TIME_FORMAT)}</td>
                  <td className='d-flex flex-column'>
                    <Button
                      style={{ paddingTop: 6, paddingBottom: 2 }}
                      buttonType={ButtonType.SECONDARY}
                      onClick={() => openVideo(calculateVideoTitle(asset.user_assignment_id), asset.src_file)}
                    >
                      {index === loadingVideoIndex ? t('@T_General_LoadingIndicator') : t('@T_UserDetails_UploadedVideos_Play_Button')}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    </div>
  );
};
