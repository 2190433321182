import React from 'react';
import { ProgressbarComponent } from 'components/progressbar/Progressbar.component';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface UserProgressProps {
  headerTitle: string; 
  conditon: boolean;
  linkRoute: string;
  loading: boolean;
  error: string; 
  progressData: {
    data: number;
    title: string;
    id: number | string;
  }[];
}
export const UserProgress = ({ headerTitle, conditon, linkRoute, loading, error, progressData }: UserProgressProps) => {
  const { t } = useTranslation();

  return (
    <div className='w-full mb-4'>
      {conditon ?
        <Card>
          <Card.Header>
            {headerTitle} - {' '}
          </Card.Header>

          <Card.Body>
            {error}
          </Card.Body>
        </Card>
        :
        <Card className='w-full mb-4'>
          <Card.Header>
            {headerTitle} - {' '}
            <Link to={linkRoute}>{t('@T_UserOverview_UserProgress_MoreDetails')}</Link>
          </Card.Header>

          <Card.Body>
            {!loading && progressData
              .map(task => <ProgressbarComponent key={task.id} title={task.title} data={task.data} />)}
          </Card.Body>
        </Card>
      }
    </div>
  );
}; 