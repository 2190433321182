import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { UserRoute } from '../config';
import { UserComponentBaseProps } from '../model';
import { UserMovementProgress } from './UserMovementProgress.screen';
import { UserMindProgress } from './UserMindProgress.screen';
import './subpages.scss';


export const UserHistory = (props: UserComponentBaseProps) => {
  const { user } = props;
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(UserRoute.MOVEMENT_HISTORY);

  const switchTab = (tab: string | null) => {
    setSelectedTab(tab as UserRoute);
  };

  return (
    <div>
      <Tabs defaultActiveKey={selectedTab} onSelect={switchTab}>
        <Tab eventKey={UserRoute.MOVEMENT_HISTORY} title={t('@T_UserDetails_Movement')}>
          <UserMovementProgress user={user} />
        </Tab>
        <Tab eventKey={UserRoute.MIND_HISTORY} title={t('@T_UserDetails_Mind')}>
          <UserMindProgress user={user} />
        </Tab>
      </Tabs>
    </div>
  );
};
