export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  DIVERSE = 'DIVERSE',
}

export interface User {
  email: string;
  email_verified: string;
  family_name: string;
  given_name: string;
  gender?: string;
  locale: string;
  sub: string;
  zoneinfo: string;

  ['custom:tenant_id']: string;
  ['custom:tenant_name']: string;
  ['custom:job_id']: string;
  ['custom:job_name']: string;
  ['custom:job_type']: string;
  ['custom:consent_privacy_at']: string;
  ['custom:consent_data_at']: string;
  ['custom:user_id']: string;
  ['custom:feature_flags_id']: string;
  ['custom:email_verified']: string;
  ['custom:email_verified_at']: string;
  ['custom:consent_analytics_at']: string;
}

export interface DeletedUser {
  id: string;
  username: string;
  original_user_id: string;
  tenant_id: string;
  license_id: string;
  email: string;
  request_received_at: string;
  identity_deleted_at?: string;
  braze_profile_deleted_at?: string;
  aws_pinpoint_profile_deleted_at?: string;
  crisp_profile_deleted_at?: string;
  intercom_profile_deleted_at?: string;
  completed_at?: string;
  completed_by?: string;
}
