import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { Routes } from 'config/routes.config';
import { TenantFields } from 'screens/tenant-management';
import { route } from 'utils';
import {
  updateTenant as UPDATE_TENANT,
  syncTenantJobs as SYNC_TENANT_JOBS,
  syncTenantUsers as SYNC_TENANT_USERS,
} from 'api/graphql/mutations';

import {
  SyncTenantJobsMutation,
  SyncTenantJobsMutationVariables,
  SyncTenantUsersMutation,
  SyncTenantUsersMutationVariables,
} from 'api/API';

type UpdateTenant = [
  ((e: React.FormEvent<HTMLFormElement>) => Promise<any>),
  boolean,
  string,
];

export const useUpdateTenant = (fields: TenantFields, id: string, syncName: boolean = false) : UpdateTenant => {
  const [loading, setLoading] = useState<boolean>(false);
  const [updateTenant] = useMutation(gql`${UPDATE_TENANT}`);
  const [syncTenantJobs] = useMutation<SyncTenantJobsMutation, SyncTenantJobsMutationVariables>(gql`${SYNC_TENANT_JOBS}`);
  const [syncTenantUsers] = useMutation<SyncTenantUsersMutation, SyncTenantUsersMutationVariables>(gql`${SYNC_TENANT_USERS}`);
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  const handleUpdateTenant = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const { name } = fields;

    try {
      await updateTenant({
        variables: {
          input: {
            id,
            name,
            email_domains: [], // TODO this is not required anymore but still in the data model
          },
        },
      });

      await syncTenantJobs({
        variables: {
          tenantId: id,
          jobIds: fields.jobs.map(job => job.value),
        },
      });

      if (syncName) {
        await syncTenantUsers({
          variables: {
            tenantId: id,
          },
        });
      }
    } catch (err) {
      toast.error(err.message);
      setError(err.message);
    }

    setLoading(false);

    navigate(route(Routes.TENANT_MANAGEMENT_OVERVIEW, { id }));
  };

  return [
    handleUpdateTenant,
    loading,
    error,
  ];

};
