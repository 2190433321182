import React from 'react';
import { Legend, Pie, PieChart, Cell } from 'recharts';

import './PieChart.component.scss'; 

interface PieChartProps {
  title?: string;
  data: Data[];
  color: string[]; 
  error: string;
  options?: PieChartOptions; 
}

interface PieChartOptions {
  width?: number; 
  height?: number; 
  dataKey: string; 
  nameKey?: string;  
  cx?: string; 
  cy?: string; 
  isAnimationActive?: boolean;  
  innerRadius?: number; 
  outerRadius?: number; 
  label?: boolean;
}

interface Data {
  label: string; 
  value: number; 
}

export function PieChartComponent({
  title, 
  data, 
  color, 
  error,
  options, 
}: PieChartProps) {

  const defaultOptions: PieChartOptions = {
    width: 700, 
    height: 400, 
    dataKey: 'value', 
    nameKey: 'label',  
    cx: '50%', 
    cy: '50%', 
    isAnimationActive: false,  
    innerRadius: 0, 
    outerRadius: 80, 
    label: true,
  }; 
  
  const currentOptions = { ...defaultOptions, ...options }; 
  const { width, height, dataKey, nameKey, cx, cy, isAnimationActive, innerRadius, outerRadius, label } = currentOptions; 

  return (
    <div >
      {data.every(license => license.value !== 0) ? 
        <div className='piechart-container'>
          <h2 className='page-title'>{title}</h2> 
          <PieChart 
              width={width} 
              height={height} 
            >
          <Pie 
            data={data}
            dataKey={dataKey} 
            nameKey={nameKey} 
            cx={cx} 
            cy={cy}
            isAnimationActive={isAnimationActive}
            innerRadius={innerRadius} 
            outerRadius={outerRadius}
            label={label}
            fill='#f95047' 
          >
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={color[index % color.length]} />
            ))}
          </Pie>
          <Legend></Legend>
        </PieChart>
        </div>
        : error}
    </div>
  ); 
}
