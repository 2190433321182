import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { ActionCreators, SignInForm } from '../../redux/modules/auth/sign-in/actions';
import './SignIn.component.scss';
import logo from '../../resources/images/lb_logo.png';
import { Button, ButtonType } from '../shared/button/Button.component';
import { useTypedSelector } from '../../utils';
import { Routes } from '../../config/routes.config';

export const SignIn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    email: '',
    password: '',
  } as SignInForm);

  const isLoading = useTypedSelector(it => it.auth.signIn.isLoading);
  const errorMessage = useTypedSelector(it => it.auth.signIn.errorMessage);
  const userData = useTypedSelector(it => it.auth.signIn.userData);
  const isValidForm = !!(form.email && form.password);

  const changeForm = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [field]: e.target.value });
  };

  const signIn = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(ActionCreators.signIn(form));
  };

  if (userData) {
    return <Navigate to={Routes.HOME}/>;
  }

  return (
    <div>
      <div>
        <header className='sign-in__header'>
          <img src={logo} alt=''/>
          <h1>{t('@T_General_ApplicationName')}</h1>
          <form onSubmit={signIn}>
            <p> {t('@T_Authorization_SignInMessage')} </p>

            <input
              placeholder={t('@T_General_EmailLabel')}
              onChange={changeForm('email')}
              value={form.email}
              disabled={isLoading}
            />

            <input
              onChange={changeForm('password')}
              value={form.password}
              disabled={isLoading}
              placeholder={t('@T_General_PasswordLabel')}
              type='password'
            />

            { errorMessage && <p>{t(errorMessage as string)}</p> }
            { isLoading && <p>{t('@T_General_LoadingIndicator')}</p> }

            <div>
              <Button disabled={!isValidForm} buttonType={ButtonType.PRIMARY} className='w-full'>{t('@T_General_SignInLabel')}</Button>
            </div>

          </form>
        </header>
      </div>

    </div>
  );
};
