import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API, graphqlOperation } from 'aws-amplify';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { faClipboardCheck, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { useMutation, gql } from '@apollo/client';
import { ModelAttributeTypes, RegistrationCode, SendRegistrationCodeMailMutation, UpdateRegistrationCodeMutation } from 'api/API';
import { QueryListResult } from 'global/models/helper.model';
import { ListBody } from 'components/shared';
import { getLocalTime, DATE_FORMAT_SECONDARY } from 'config';
import { ButtonType, IconButton } from 'components/shared/button';
import { sendRegistrationCodeMail, updateRegistrationCode } from 'api/graphql/mutations';
import { usePromise } from 'hooks/usePromise';
import { listRegistrationFreeCodes } from 'api/graphql/custom';
import { RegistrationMailHandler, RegistrationMailHandlerData } from './RegistrationMailHandler.component';

type Result = QueryListResult<'listRegistrationCodesByTenant', RegistrationCode>;
type NextTokenUnion = string | null;

export const FreeRegistrationCodeList = ({ tenant_id }: { tenant_id: string }) => {
  const { t } = useTranslation();
  const [updateError, setUpdateError] = useState<Error | null>(null);
  const [codes, setCodes] = useState<RegistrationCode[]>([]);
  const [currentCode, setCurrentCode] = useState<RegistrationCode | null>(null);
  const [updateRegistrationCodeFn] = useMutation<UpdateRegistrationCodeMutation>(gql`${updateRegistrationCode}`);
  const [sendRegistrationCodeMailFn] = useMutation<SendRegistrationCodeMailMutation>(gql`${sendRegistrationCodeMail}`);
  const [nextToken, setNextToken] = useState<NextTokenUnion>();
  const onFetchFreeFeeCodes = async () => {
    const fetchedResult = await API.graphql(graphqlOperation(listRegistrationFreeCodes, {
      tenant_id,
      filter: {
        not:
        {
          license_id: {
            attributeType: ModelAttributeTypes.string,
          },
        },
      },
      nextToken,
    })) as Result;

    return fetchedResult;
  };

  const [fetchFreeCodes, { result, isLoading, error }] = usePromise<Result, [NextTokenUnion?]>(onFetchFreeFeeCodes);
  const resultItems = result?.data?.listRegistrationCodesByTenant?.items;
  const fetchedCodes = useMemo(() => resultItems || [], [resultItems]);
  const token = result?.data?.listRegistrationCodesByTenant?.nextToken;
  const errorMessage = error?.message || updateError?.message;
  const filteredValidCodes = codes.filter(code => dayjs(code.valid_to) > dayjs()); 

  const reserveCode = (code: RegistrationCode) => {
    try {
      setUpdateError(null);
      const reservedAt = new Date().toISOString();
      const index = codes.findIndex(registrationCode => code.id === registrationCode.id);
      const newCodes = [...codes];
      newCodes[index].reserved_at = reservedAt;
      setCodes(newCodes);

      updateRegistrationCodeFn({
        variables: {
          input: {
            id: code?.id,
            reserved_at: new Date().toISOString(),
            // https://stackoverflow.com/questions/58032718/dynamodb-update-fails-with-error-the-update-expression-attempted-to-update-the
            used_at: null,
          },
        },
      });
    } catch (err) {
      setUpdateError(err as Error);
    }
  };

  const sendMail = async (data: RegistrationMailHandlerData) => {
    try {
      setCurrentCode(null);
      const payload = { ...data, is_admin_action: true };
      await sendRegistrationCodeMailFn({ variables: { payload } });

      if (!currentCode?.reserved_at) {
        reserveCode(currentCode!);
      }

      toast.success(t('@T_RegistrationCode_SendCodeSuccess'));
    } catch (err) {
      setUpdateError(err as Error);
    }
  };

  useEffect(
    () => {
      fetchFreeCodes();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      setNextToken(token);
      setCodes(prevCodes => [...prevCodes, ...fetchedCodes]);
      if (fetchedCodes.length === 0 && !!token) {
        fetchFreeCodes();
      }
    },
    [token, fetchedCodes],
  );

  
  

  return (
    <ListBody
      isLoading={!codes.length && isLoading}
      loadMore={!!nextToken}
      errorMessage={errorMessage}
      onLoadMore={() => fetchFreeCodes(nextToken)}
    >
      <table>
        <thead>
          <tr>
            <th>{t('@T_License_Code')}</th>
            <th>{t('@T_General_CreatedAt')}</th>
            <th>{t('@T_RegistrationCode_ValidTo')}</th>
            <th>{t('@T_RegistrationCode_Reserved')}</th>
            <th>{t('@T_RegistrationCode_SendCode')}</th>
          </tr>
        </thead>

        <tbody>
          {filteredValidCodes.map(code => (
            <tr key={code.id}>
              <td>{code.code}</td>
              <td>{getLocalTime(code.createdAt!, DATE_FORMAT_SECONDARY)}</td>
              <td>{getLocalTime(code.valid_to!, DATE_FORMAT_SECONDARY)}</td> 
              <td className='p-0 text-center'>
                {
                  code.reserved_at ?
                    getLocalTime(code.reserved_at!, DATE_FORMAT_SECONDARY) :
                    <IconButton buttonType={ButtonType.PRIMARY} icon={faClipboardCheck} action={() => reserveCode(code)} className='m-auto'/>
                }
              </td>
              <td className='p-0 text-center'>
                <IconButton buttonType={ButtonType.PRIMARY} icon={faMailBulk} action={() => setCurrentCode(code)} className='m-auto'/>
              </td>
            </tr>
          )) }
        </tbody>
      </table>
      <RegistrationMailHandler code={currentCode!} onConfirm={sendMail} onCancel={() => setCurrentCode(null)}/>
    </ListBody>
  );
};
