import { AUTH_TYPE } from 'aws-appsync-auth-link/lib/auth-link';
import { Auth } from 'aws-amplify';
import { createAuthLink } from 'aws-appsync-auth-link';
import { ApolloClient, InMemoryCache, ApolloLink, createHttpLink } from '@apollo/client';
import { awsConfig } from '../../aws.config';

const url = awsConfig.aws_appsync_graphqlEndpoint;
const region = awsConfig.aws_appsync_region;
const auth = {
  type: awsConfig.aws_appsync_authenticationType as AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken(),
};

export const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createHttpLink({ uri: url }),
]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
