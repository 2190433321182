import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { sortBy } from 'lodash';

import { TenantFields } from 'screens/tenant-management';
import { SelectedJobOption } from 'hooks/tenant/useAddTenant';
import { TenantBookedMindPackageByTenantQuery, TenantBookedTrainingModuleByTenantQuery } from 'api/API';
import { ContentfulContentType } from 'shared/contentful';
import { ContentfulContext } from 'providers';
import { useIsAdmin } from 'hooks';
import { WithTooltip } from 'components/shared/with-tooltip';

import { Button } from '../shared';
import './TenantDetails.component.scss';

export interface TenantDetailsProps {
  fields: TenantFields;
  toggleEdit: () => void;
  activeUsersCount: number;
  deletedUsersCount: number;
  licensesCount: number;
  bookedMindPackages: TenantBookedMindPackageByTenantQuery['tenantBookedMindPackageByTenant'];
  bookedTrainingModules: TenantBookedTrainingModuleByTenantQuery['tenantBookedTrainingModuleByTenant'];
  availableLicensesCount: number;
}

const LICENSES_LIMIT_WARNING = 5;

export const TenantDetails = ({
  fields,
  toggleEdit,
  activeUsersCount,
  deletedUsersCount,
  licensesCount,
  bookedMindPackages,
  bookedTrainingModules,
  availableLicensesCount,
}: TenantDetailsProps): JSX.Element => {
  const { t } = useTranslation();
  const { [ContentfulContentType.MIND_PACKAGES]: contentFulPackages } = useContext(ContentfulContext);
  const [mindPackages, setMindPackages] = useState<{ name?: string; id?: string }[]>([]);
  const isAdmin = useIsAdmin();

  const availableLicensesStyle = availableLicensesCount <= LICENSES_LIMIT_WARNING ? { color: 'red', fontWeight: 'bold' } : {};

  useEffect(
    () => {
      const mappedPackages = bookedMindPackages?.items?.map(mindPackage => {
        const foundItem = contentFulPackages?.items?.find(item => item.fields.id === mindPackage?.package_id);
        return { id: foundItem?.fields.id ?? mindPackage?.package_id, name: foundItem?.fields.title ?? mindPackage?.package_id };
      });

      setMindPackages(sortBy(mappedPackages!, 'id'));
    },
    [contentFulPackages, bookedMindPackages],
  );

  return (
    <div className='tenant-overview' data-testid='tenant-details'>
      <h2 className='page-title'>
        {t('@T_Tenant_TenantOverviewLabel')}
      </h2>
      <div className='tenant-overview__details'>
        <div>
          <div>
            <h6>{t('@T_General_Name')}</h6>
            <p>{fields.name}</p>
          </div>
          <div>
            <h6>{t('@T_License_LicensesBoughtCount')}</h6>
            <p>{licensesCount}</p>
          </div>
          <div>
            <h6>{t('@T_License_LicensesAvailableCount')}</h6>
            <p style={availableLicensesStyle}>{availableLicensesCount}</p>
          </div>
          <div>
            <h6>{t('@T_General_ActiveUsersCount')}</h6>
            <p>{activeUsersCount}</p>
          </div>
          <div>
            <h6>{t('@T_General_DeletedUsersCount')}</h6>
            <p>{deletedUsersCount}</p>
          </div>
          <div>
            <h6>{t('@T_General_Jobs')}</h6>
            <p>{fields.jobs.map((job: SelectedJobOption) => job.label).join(', ')}</p>
          </div>
          <div>
            <h6>{t('@T_Tenant_CategoryLabel')}</h6>
            <p>{fields.category}</p>
          </div>
        </div>

        <div>
          <h6>{t('@T_Tenant_Movement_Modules')}</h6>
          <div>
            {bookedTrainingModules?.items?.map(item => (
              <div key={item?.id} className='d-flex align-items-center mb-3'>
                <p className='mb-0 mr-3'>{t('@T_Tenant_Body_Coaching')}</p>
                <Form.Check disabled={true} type='switch' label={t('@T_Tenant_Movement_Modules_With_Analysis')} checked={!!item?.with_analyse}/>
              </div>
            ))}
            {!bookedTrainingModules?.items?.length && '-'}
          </div>

        </div>

        <div>
          <h6>{t('@T_Tenant_Mind_Packages')}</h6>
          <div>
            {mindPackages?.map(item => (
              <p key={item?.id}>{item?.id}: {item?.name}</p>
            ))}
            {!bookedMindPackages?.items?.length && '-'}
          </div>
        </div>

        <div>
          <WithTooltip text={t('@T_General_AdminGroupRequired')} show={!isAdmin}>
            <Button type='submit' disabled={!isAdmin} onClick={toggleEdit}>{t('@T_General_EditLabel')}</Button>
          </WithTooltip>
        </div>
      </div>

    </div>
  );
};
