import { API, Auth, graphqlOperation } from 'aws-amplify';
import { v4 as uuid } from 'uuid';

import { QueryResult, QueryListResult, DeletedUser, User, Tenant } from 'global/models';
import { LICENSES_LIMIT, TENANTS_LIMIT } from 'config/app.config';
import { anamneseByUser, leadByEmail, listDeletedUsersByRequestReceivedAt, listLicenses, listLicensesByByUser } from '../api/graphql/queries';
import { createDeletedUser, updateDeletedUser, updateAnamnese, changeEmailExternalProviders } from '../api/graphql/mutations';
import { Anamnese, DeletedUserType, License, ModelSortDirection, TenantType, Lead } from '../api/API';
import { listTenantsWithLicensesByName, listTenantsByLicenseInvoice, deleteUserExternalProviders } from '../api/graphql/custom';

const adminQueries = 'AdminQueries';

const getHeaders = async () => ({
  'Content-Type': 'application/json',
  Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
});

export interface RawUsersInterface {
  Users: UserWithAdminPropsResponse[];
  NextToken?: string;
}

export interface UsersInterface {
  users: UserWithAdminProps[];
  nextToken?: string;
}

export interface UserWithAdminProps extends UserAdminProps {
  Attributes: User;
}

export interface UserWithAdminPropsResponse extends UserAdminProps {
  Attributes: { Name: string; Value: string }[];
}

export interface UserAdminProps {
  Enabled: boolean;
  UserCreateDate: string;
  UserLastModifiedDate: string;
  UserStatus: string;
  Username: string;
}

export type UserSearchAttribute = 'email' | 'given_name' | 'family_name';

export interface UserSearchField {
  attribute: UserSearchAttribute;
  label: string;
}

interface AdminQueryHeaders {
  'Content-Type': string;
  Authorization: string;
}

interface AdminQueryStringParameters {
  limit: number;
  filter: string;
  pagination?: string;
}

interface AdminQueryOptions {
  headers: AdminQueryHeaders;
  queryStringParameters: AdminQueryStringParameters;
}

export const adminQuery = {
  async unsetDisabledAtTimeStamp(user: UserWithAdminProps) {
    await API.post(adminQueries, '/updateUserAttributes', {
      headers: await getHeaders(),
      body: {
        username: user.Attributes.sub,
        attributes: [
          {
            Name: 'custom:disabled_at',
            Value: '',
          },
        ],
      },
    });
  },

  async setDisabledAtTimeStamp(user: UserWithAdminProps) {
    await API.post(adminQueries, '/updateUserAttributes', {
      headers: await getHeaders(),
      body: {
        username: user.Attributes.sub,
        attributes: [
          {
            Name: 'custom:disabled_at',
            Value: new Date().toISOString(),
          },
        ],
      },
    });
  },

  async verifyEmailAddress(user: UserWithAdminProps) {

    const timestamp = new Date().toISOString();
    const userId = user.Attributes['custom:user_id'];

    return API.post(adminQueries, '/updateUserAttributes', {
      headers: await getHeaders(),
      body: {
        username: user.Attributes.sub,
        attributes: [
          {
            Name: 'custom:email_verified',
            Value: '1',
          },
          {
            Name: 'custom:email_verified_at',
            Value: timestamp,
          },
        ],
      },
    });
  },

  async changeEmailAddress(user: UserWithAdminProps, email: string) {
    const userId = user.Attributes['custom:user_id'];

    await API.graphql({
      query: changeEmailExternalProviders,
      variables: {
        user_id: userId,
        new_email: email,
      },
    });

    return API.post(adminQueries, '/updateUserAttributes', {
      headers: await getHeaders(),
      body: {
        username: user.Attributes.sub,
        attributes: [
          {
            Name: 'email',
            Value: email.toLowerCase(),
          },
          {
            Name: 'custom:email_verified',
            Value: '1',
          },
          {
            Name: 'custom:email_verified_at',
            Value: new Date().toISOString(),
          },
          {
            Name: 'email_verified',
            Value: 'true',
          },
        ],
      },
    });
  },

  async updateTenantId(user: UserWithAdminProps, tenant_id: string | undefined, tenant_name: string) {
    return API.post(adminQueries, '/updateUserAttributes', {
      headers: await getHeaders(),
      body: {
        username: user.Attributes.sub,
        attributes: [
          {
            Name: 'custom:tenant_id',
            Value: tenant_id,
          },
          {
            Name: 'custom:tenant_name',
            Value: tenant_name,
          },
        ],
      },
    });
  },

  async setFeatureFlagsId(user: UserWithAdminProps) {
    return API.post(adminQueries, '/updateUserAttributes', {
      headers: await getHeaders(),
      body: {
        username: user.Attributes.sub,
        attributes: [
          {
            Name: 'custom:feature_flags_id',
            Value: uuid(),
          },
        ],
      },
    });
  },

  async setConsentAnalyticsAt(user: UserWithAdminProps) {
    return API.post(adminQueries, '/updateUserAttributes', {
      headers: await getHeaders(),
      body: {
        username: user.Attributes.sub,
        attributes: [
          {
            Name: 'custom:consent_analytics_at',
            Value: new Date().toISOString(),
          },
        ],
      },
    });
  },

  async listUsers({ search = '', searchAttribute, token }: { search?: string; searchAttribute: UserSearchAttribute; token?: string | null }): Promise<UsersInterface> {
    const options = {
      headers: await getHeaders(),
      queryStringParameters: {
        limit: 10,
        pagination: token,
        filter: `${searchAttribute}^="${search}"`,
      },
    };

    return API.get(adminQueries, '/listUsers', options)
      .then(response => {
        const users = response.Users.map((user: UserWithAdminPropsResponse) => {
          const attributes = user.Attributes.reduce((newObject, currentAttribute) => {
            const attributeName = currentAttribute.Name as keyof User;
            return { ...newObject, [attributeName]: currentAttribute.Value };
          }, {} as { [key in keyof User]: string });

          return { ...user, Attributes: attributes };
        });

        return {
          users,
          nextToken: response.NextToken,
        };
      });
  },

  async enableUser(user: UserWithAdminProps) {
    await API.post(adminQueries, '/enableUser', {
      headers: await getHeaders(),
      body: {
        username: user.Attributes.sub,
        attributes: [
          {
            Name: 'custom:disabled_at',
            Value: '',
          },
        ],
      },
    });

    await this.unsetDisabledAtTimeStamp(user);
  },

  async disableUser(user: UserWithAdminProps) {
    await API.post(adminQueries, '/disableUser', {
      headers: await getHeaders(),
      body: {
        username: user.Attributes.sub,
        attributes: [
          {
            Name: 'custom:disabled_at',
            Value: new Date().toISOString(),
          },
        ],
      },
    });

    await this.setDisabledAtTimeStamp(user);
  },

  async deleteUser(user: UserWithAdminProps) {
    return API.post(adminQueries, '/adminDeleteUser', {
      headers: await getHeaders(),
      body: {
        username: user.Attributes.sub,
      },
    });
  },

  queryUser(options: AdminQueryOptions) {
    return API.get(adminQueries, '/listUsers', options)
      .then(({ Users }: RawUsersInterface) => {
        const user = Users[0];

        if (!user) {
          throw new Error('User not found');
        }

        const attributes = user.Attributes.reduce((newObject, currentAttribute) => {
          const attributeName = currentAttribute.Name as keyof User;
          return { ...newObject, [attributeName]: currentAttribute.Value };
        }, {} as { [key in keyof User]: string });

        return { ...user, Attributes: attributes };
      });
  },

  async findByIdentitySub(identity_sub: string) {
    const options = {
      headers: await getHeaders(),
      queryStringParameters: {
        limit: 1,
        filter: `sub^="${identity_sub}"`,
      },
    };

    return this.queryUser(options);
  },

  async findByEmail(email: string) {
    const options = {
      headers: await getHeaders(),
      queryStringParameters: {
        limit: 1,
        filter: `email^="${email}"`,
      },
    };

    return this.queryUser(options);
  },

  // @deprecated
  async getUser(email: string) {
    // Send to Sentry
    console.error('[deprecated] getUser function');

    return this.findByEmail(email);
  },

  async listTenantsWithLicensesByName({ token, name }: { token?: string | null; name?: string | null }): Promise<QueryListResult<'listTenantsByName', Tenant>> {
    return API.graphql({
      query: listTenantsWithLicensesByName,
      variables: {
        name: (name ? { beginsWith: name } : undefined),
        limit: TENANTS_LIMIT,
        licensesLimit: LICENSES_LIMIT,
        type: TenantType.TENANT,
        nextToken: token,
      },
    }) as Promise<QueryListResult<'listTenantsByName', Tenant>>;
  },

  async getTenantsByInvoice({ invoice_id }: { invoice_id: string }): Promise<Tenant | null> {
    if (!invoice_id) {
      return Promise.resolve(null);
    }

    const licenseResponse = await API.graphql({
      query: listTenantsByLicenseInvoice,
      variables: { invoice_id, limit: LICENSES_LIMIT },
    }) as QueryListResult<'listLicensesByInvoice', License>;

    return licenseResponse.data?.listLicensesByInvoice?.items?.[0]?.tenant as Tenant || null;
  },

  async listDeletedUsersByRequestReceivedAt({ token }: { token?: string | null }): Promise<QueryListResult<'listDeletedUsersByRequestReceivedAt', DeletedUser>> {
    return API.graphql({
      query: listDeletedUsersByRequestReceivedAt,
      variables: {
        nextToken: token,
        type: DeletedUserType.DELETED_USER,
        sortDirection: ModelSortDirection.DESC,
      },
    }) as Promise<QueryListResult<'listDeletedUsersByRequestReceivedAt', DeletedUser>>;
  },

  async createDeletedUser(user: UserWithAdminProps): Promise<QueryResult<'createDeletedUser', DeletedUser>> {

    const { email, given_name, family_name } = user.Attributes;
    // required to delete braze user data
    const original_user_id = user.Attributes['custom:user_id'];
    // required to restore connection to tenant
    const tenant_id = user.Attributes['custom:tenant_id'];

    const username = `${given_name || ''} ${family_name || ''}`;

    const request_received_at = new Date().toISOString();
    return API.graphql({
      query: createDeletedUser,
      variables: {
        input: {
          type: DeletedUserType.DELETED_USER,
          email,
          original_user_id,
          tenant_id,
          username,
          request_received_at,
        },
      },
    }) as Promise<QueryResult<'createDeletedUser', DeletedUser>>;
  },

  async deleteUserFromExternalProviders(originalUserId: string): Promise<QueryResult<'deleteUserExternalProviders', String>> {
    return API.graphql(graphqlOperation(deleteUserExternalProviders, { externalId: originalUserId })) as Promise<QueryResult<'deleteUserExternalProviders', String>>;
  },

  async updateDeletedUser(fields: any): Promise<QueryResult<'updateDeletedUser', DeletedUser>> {
    return API.graphql(graphqlOperation(
      updateDeletedUser,
      { input: fields },
    )) as Promise<QueryResult<'updateDeletedUser', DeletedUser>>;
  },

  async updateAnamnese(fields: any): Promise<QueryResult<'updateAnamnese', Anamnese>> {
    return API.graphql(graphqlOperation(
      updateAnamnese,
      { input: fields },
    )) as Promise<QueryResult<'updateAnamnese', Anamnese>>;
  },

  async listAnamnesisRecordsByUser(originalUserId: string): Promise<QueryListResult<'anamneseByUser', Anamnese>> {
    return API.graphql(
      graphqlOperation(anamneseByUser, {
        identity_sub: originalUserId,
      }),
    ) as Promise<QueryListResult<'anamneseByUser', Anamnese>>;
  },

  async listLicenses(originalUserId: string): Promise<QueryListResult<'listLicenses', License>> {
    return API.graphql(
      graphqlOperation(listLicenses, { filter: {
        identity_sub: { eq: originalUserId },
      } }),
    ) as Promise<QueryListResult<'listLicenses', License>>;
  },

  async getLicenseByUser(userId: string): Promise<QueryListResult<'listLicensesByByUser', License>> {
    return API.graphql(graphqlOperation(listLicensesByByUser, { identity_sub: userId })) as Promise<QueryListResult<'listLicensesByByUser', License>>;
  },

  async getLeadByEmail(userEmailAddress: string): Promise<QueryListResult<'leadByEmail', Lead>> {
    return API.graphql(graphqlOperation(leadByEmail, { email_address: userEmailAddress })) as Promise<QueryListResult<'leadByEmail', Lead>>;
  },
};
