import React, { CSSProperties } from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Button } from '../button';

interface ListBodyProps {
  children: React.ReactNode;
  isLoading?: boolean;
  errorMessage?: string;
  loadMore?: boolean;
  className?: string;
  style?: CSSProperties;
  onLoadMore?: () => void;
}

export const ListBody = ({ children, errorMessage, loadMore, onLoadMore, isLoading = false, className, style }: ListBodyProps) => {
  const { t } = useTranslation();
  const enableLoadMore = loadMore && onLoadMore;

  return (
    <div className={`card list-body ${className}`} style={style}>
      {isLoading && <div>{t('@T_General_LoadingIndicator')}</div>}
      {errorMessage && <Alert variant='danger'>{errorMessage}</Alert>}

      {!isLoading && children}

      {enableLoadMore && (
        <Button onClick={onLoadMore}>
          Load more
        </Button>
      )}
    </div>
  );
};
