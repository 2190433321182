import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export interface WithTooltipProps {
  show: boolean;
  children: JSX.Element;
  text: string;
}

export const WithTooltip = ({ show = true, text, children }: WithTooltipProps): JSX.Element | null => (
  show ? (
    <OverlayTrigger delay={300} overlay={<Tooltip id='tooltip-disabled'>{text}</Tooltip>}>
      <span className='d-inline-block'>
        {children}
      </span>
    </OverlayTrigger>
  ) : children
);
