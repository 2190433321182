import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { CSVLink } from 'react-csv';
import Spinner from 'react-bootstrap/Spinner';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from 'react-bootstrap/Pagination';
import { toast } from 'react-toastify';

import { List, ListBody, ListHeader } from 'components/shared';
import { DeletedUser, QueryResult } from '../../global/models';
import { adminQuery } from '../../services/adminQuery.service';
import { useErrorToast, useIsAdmin, usePromise } from '../../hooks';
import { useTypedSelector, formatDate, prepareFilename, clusterDateOfBirth } from '../../utils';
import './DeletedUsersManagement.screen.scss';
import { Anamnese } from '../../api/API';

const EMPTY_FIELD = '-';

export const DeletedUsersManagement = () => {
  const { t } = useTranslation();
  const userData = useTypedSelector(it => it.auth.signIn.userData);
  const [deletedUsers, setDeletedUsers] = useState<DeletedUser[]>([]);
  const [pagesTokens, setPagesTokens] = useState<(string | null)[]>([null]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const isAdmin = useIsAdmin();

  // the keys need to correspond to the DeletedUser interface
  const headers = [
    { label: t('@T_General_EmailLabel'), key: 'email' },
    { label: t('@T_General_Name'), key: 'username' },
    { label: t('@T_DeletedUserList_RequestReceived'), key: 'request_received_at' },
    { label: t('@T_DeletedUserList_DeleteData'), key: 'delete_data' },
    { label: t('@T_DeletedUserList_Identity'), key: 'identity_deleted_at' },
    { label: t('@T_DeletedUserList_UserEngagement'), key: 'aws_pinpoint_profile_deleted_at' },
    { label: t('@T_DeletedUserList_Messenger'), key: 'intercom_profile_deleted_at' },
    { label: t('@T_DeletedUserList_CompletedBy'), key: 'completed_by' },
  ];

  const onLoadDeletedUsers = async (page: number) => {

    const response = await adminQuery.listDeletedUsersByRequestReceivedAt({ token: pagesTokens[page] });

    const { items: fetchedDeletedUsers, nextToken } = response.data?.listDeletedUsersByRequestReceivedAt;

    if (nextToken) {
      const tokens = [...pagesTokens];
      tokens[page + 1] = nextToken;
      setPagesTokens(tokens);
    }

    setDeletedUsers(fetchedDeletedUsers);
  };

  const processUpdateDeletedUserResponse = (response: QueryResult<'updateDeletedUser', DeletedUser>) => {
    const updatedUser: DeletedUser = response.data.updateDeletedUser;
    const updatedUsers = [...deletedUsers];
    const index = updatedUsers.findIndex(u => u.id === updatedUser.id);
    updatedUsers[index] = updatedUser;

    setDeletedUsers(updatedUsers);
  };

  const deleteUserFromExternalProviders = (user: DeletedUser) => {
    return adminQuery.deleteUserFromExternalProviders(user.original_user_id)
      .catch(response => {
        throw new Error(response.errors?.[0]?.message || t('@T_General_Error'));
      });
  };

  const onUpdateDeletedUser = (update: any) => (
    adminQuery.updateDeletedUser(update).then(response => {
      processUpdateDeletedUserResponse(response);
    })
  );

  const [loadDeletedUsers, { isLoading: isLoadingDeletedUsers, error: loadingError }] = usePromise<void, [number]>(onLoadDeletedUsers);
  const [updateDeletedUser, { isLoading: isUpdatingDeletedUser, error: updateDeletedUserError }] = usePromise<void, [any]>(onUpdateDeletedUser);
  const [isDeletingUser, setIsDeletingUser] = useState<boolean>(false);

  useErrorToast(loadingError);
  useErrorToast(updateDeletedUserError);

  const deleteUserIdentity = async (user: DeletedUser) => {
    const { original_user_id } = user;

    const response = await adminQuery.listAnamnesisRecordsByUser(original_user_id);
    const { items: anamnesisRecords } = response.data?.anamneseByUser;

    // cluster birth dates
    const updatedRecords = anamnesisRecords.map((anamnese:Anamnese) => ({ id: anamnese.id, birthdate: clusterDateOfBirth(anamnese.birthdate) }));
    await Promise.all(updatedRecords.map(adminQuery.updateAnamnese));

    const licenseResponse = await adminQuery.listLicenses(original_user_id);
    const { items: licenses } = licenseResponse.data?.listLicenses;
    // For now, we assume a user has only one license attached
    // For now, we store the license id in the DeletedUser, but keep the license in use by this user
    const license_id = licenses.length > 0 ? licenses[0].id : null;

    updateDeletedUser({
      id: user.id,
      license_id,
      identity_deleted_at: new Date().toISOString(),
      aws_pinpoint_profile_deleted_at: user.aws_pinpoint_profile_deleted_at,
    });
  };

  const onRequestReceived = (user: DeletedUser, requestReceivedAt: Date) => {
    updateDeletedUser({
      id: user.id,
      request_received_at: requestReceivedAt.toISOString(),
    });
  };

  const onRequestCompleted = (user: DeletedUser) => {
    let completedByName = '';
    let completedByEmail = '';
    if (userData) {
      completedByName = `${userData.givenName || ''} ${userData.familyName || ''}`.trim();
      completedByEmail = userData.email ? ` (${userData.email})` : '';
    }

    const completed_by = `${completedByName}${completedByEmail}`;
    const original_user_id = 'id-removed-on-completion';

    updateDeletedUser({
      id: user.id,
      original_user_id,
      completed_at: new Date().toISOString(),
      completed_by,
    });
  };

  const renderRequestReceivedInputAndDate = (user: DeletedUser) => {

    if (user.completed_at) {
      return (<td>{formatDate(user.request_received_at)}</td>);
    }

    return (
      <td>
        <DatePicker
          disabled={!isAdmin}
          value={formatDate(user.request_received_at)}
          onChange={(date: Date) => {
            onRequestReceived(user, date);
          }}
        />
      </td>
    );
  };

  const renderCompletedByInfo = (user: DeletedUser) => <td>{user.completed_at ? user.completed_by : EMPTY_FIELD}</td>;

  const prepareDataExport = () => deletedUsers.map((user: DeletedUser) => (
    {
      ...user,
      request_received_at: user.request_received_at ? formatDate(user.request_received_at, true) : EMPTY_FIELD,
      identity_deleted_at: user.identity_deleted_at ? formatDate(user.identity_deleted_at, true) : EMPTY_FIELD,
      aws_pinpoint_profile_deleted_at: user.aws_pinpoint_profile_deleted_at ? formatDate(user.aws_pinpoint_profile_deleted_at, true) : EMPTY_FIELD,
      intercom_profile_deleted_at: user.intercom_profile_deleted_at ? formatDate(user.intercom_profile_deleted_at, true) : EMPTY_FIELD,
      completed_at: user.completed_at ? formatDate(user.completed_at, true) : EMPTY_FIELD,
      completed_by: user.completed_by ? user.completed_by : EMPTY_FIELD,
    }));

  const onDeleteUser = async (user: DeletedUser) => {
    // eslint-disable-next-line no-alert
    const confirmed = window.confirm(
      t('@T_DeletedUserList_Confirmation_DeleteUserProfile'),
    );
    if (!confirmed) {
      return;
    }
    setIsDeletingUser(true);
    await deleteUserFromExternalProviders(user)
      .then(() => (user.aws_pinpoint_profile_deleted_at = new Date().toISOString()))
      .catch(e => {
        if (e.message === 'Aws Pinpoint user doesn\'t exist') {
          user.aws_pinpoint_profile_deleted_at = new Date().toISOString();
        }
        toast.error(e.message || t('@T_General_Error'));
      });

    await deleteUserIdentity(user)
      .then(() => onRequestCompleted(user))
      .catch(e => toast.error(e.message || t('@T_General_Error')))
      .finally(() => setIsDeletingUser(false));
  };

  const renderRow = (user: DeletedUser) => (
    <tr key={user.id}>
      <td>{user.email}</td>
      <td>{user.username}</td>
      {renderRequestReceivedInputAndDate(user)}
      <td>{user.identity_deleted_at && user.aws_pinpoint_profile_deleted_at && user.intercom_profile_deleted_at ?
        <Button disabled={true} type='button' variant='success'>
          {t('@T_DeletedUserList_DataDeleted')}
        </Button> : 
        <Button disabled={!isAdmin} onClick={() => onDeleteUser(user)} type='button' variant='danger'>
          {t('@T_DeletedUserList_DeleteData')}
        </Button>} 
      </td>
      <td>{user.identity_deleted_at ? formatDate(user.identity_deleted_at) : ''}</td>
      <td>{user.aws_pinpoint_profile_deleted_at ? formatDate(user.aws_pinpoint_profile_deleted_at) : ''}</td>
      <td>{user.intercom_profile_deleted_at ? formatDate(user.intercom_profile_deleted_at) : ''}</td>
      {renderCompletedByInfo(user)}
    </tr>
  );

  useEffect(() => {
    loadDeletedUsers(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const isLoading = isLoadingDeletedUsers || isUpdatingDeletedUser || isDeletingUser;

  return (
    <List className='container pb-4' data-testid='deleted-users-management'>
      {isLoading ? <div className='full-size-centered white-opacity'><Spinner animation='border' variant='primary' /></div> : null}
      <div className='d-flex justify-content-between align-items-center'>
        <ListHeader title={`${t('@T_DeletedUserList_DeletedUsersManagement')} (${deletedUsers.length})`}/>

        <Pagination className='m-0'>
          <Pagination.Prev disabled={!currentPage} onClick={() => setCurrentPage(currentPage - 1)} />
          <Pagination.Item disabled>{currentPage + 1}</Pagination.Item>
          <Pagination.Next disabled={!pagesTokens[currentPage + 1]} onClick={() => setCurrentPage(currentPage + 1)} />
        </Pagination>

        <div>
          <CSVLink
            data={prepareDataExport()}
            headers={headers}
            filename={prepareFilename(t('@T_DeletedUserList_DeletedUsersManagement'), 'csv')}
            className='btn btn-secondary text-light'
            target='_blank'
            rel='noreferrer'
          >
            {t('@T_DeletedUserList_Export_CSV')}
          </CSVLink>
        </div>
      </div>

      <ListBody>
        <table className='deleted-users'>
          <thead>
            <tr>
              {headers.map(header => (<th key={header.key}>{header.label}</th>))}
            </tr>
          </thead>
          <tbody>
            {deletedUsers.map((user: DeletedUser) => (
              renderRow(user)
            ))}
          </tbody>
        </table>
      </ListBody>
    </List>
  );
};
