import { ContentfulClientApi, CreateClientParams } from 'contentful';

const { createClient } = require('contentful/dist/contentful.browser.min.js');

const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

export const contentfulClient = createClient({
  space: SPACE_ID || 'fallback',
  accessToken: ACCESS_TOKEN || 'fallback',
} as CreateClientParams) as ContentfulClientApi;
