import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';

import { adminQuery, UserWithAdminProps } from 'services/adminQuery.service';
import { usePromise } from 'hooks';
import { Button, ButtonType, List, ListBody, ListHeader, LoadingButton } from 'components/shared';
import { getUserByCustomId, isValidEmail } from 'utils';
import { toast } from 'react-toastify';
import { InputGroup, FormControl } from 'react-bootstrap';

export const UserChangeEmail = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<UserWithAdminProps>();
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const newEmailInputEl = useRef<HTMLInputElement>(null);

  const fetchingUser = async () => {
    try {
      const user = await getUserByCustomId(id!);

      setCurrentUser(user);
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    const newEmail = newEmailInputEl.current?.value;

    try {
      if (!newEmail || !isValidEmail(newEmail)) {
        toast.error(`${t('@T_UserList_IncorrectEmailError')} (${newEmail})`);
        return;
      }
  
      adminQuery.changeEmailAddress(currentUser!, newEmail).then(() => {
        navigate(-1);
      });


    } catch (err) {
      toast.error((err.message));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [submit, { result: _result, isLoading: isSaving, error: errorSubmit }] = usePromise(onSubmit);

  useEffect(
    () => {
      fetchingUser();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <List>
      <ListHeader title={t('@T_UserOverview_Change_E-Mail')} />

      {loading && <div className='full-size-centered white-opacity'><Spinner animation='border' variant='primary' /></div>}

      <ListBody
        isLoading={loading}
        errorMessage={error}
        className='p-3'
        style={{ height: '80vh' }}
      >
        <div>
          <h5 className='mb-3'><b>{t('@T_UserOverview_Change_E-Mail_Current_E-Mail')}:</b> {currentUser?.Attributes.email}</h5>
          <h5><b>{t('@T_UserOverview_Change_E-Mail_E-Mail')}:</b></h5>
          <InputGroup className='d-flex flex-nowrap'>
            <FormControl
              className='mb-0'
              ref={newEmailInputEl}
              placeholder={t('@T_UserList_ChangeEmailModalTitle')}
              aria-label={t('@T_UserList_ChangeEmailModalTitle')}
            />
          </InputGroup>
        </div>

        <div className='btn-group justify-content-between pt-4'>
          <Button className='rounded py-1 px-3' disabled={isSaving} buttonType={ButtonType.DANGER} onClick={() => navigate(-1)} >
            {t('@T_UserOverview_Change_E-Mail_Cancel')}
          </Button>
          <LoadingButton className='rounded py-1 px-3' onClick={submit} disabled={!newEmailInputEl || isSaving} isLoading={isSaving}>
            {isSaving ? t('@T_UserOverview_Change_E-Mail_Save_New_E-Mail_Loading') : t('@T_UserOverview_Change_E-Mail_Save_New_E-Mail')}
          </LoadingButton>
        </div>
      </ListBody>
    </List> 
  );
};
