import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, WithTooltip } from 'components/shared';

import { teamAccessUrlDomain } from 'config/team-access-domain.config';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

export const CopyTeamAccessUrl = ({
  tenant_access_id,
}: {
  tenant_access_id: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2500);
    }
  }, [copied]);

  const copyUrl = () => {
    navigator.clipboard.writeText(teamAccessUrlDomain + tenant_access_id);
    setCopied(true);
  };
  return (
    <WithTooltip text={t('@T_Copied')} show={copied}>
      <Button className='border-0 bg-transparent' onClick={copyUrl}>
        <FontAwesomeIcon color='black' icon={faCopy} />
      </Button>
    </WithTooltip>
  );
};
