import React, { ChangeEvent } from 'react';
import './Checkbox.scss';

interface CheckboxType {
  id : string;
  handleClick: (e: ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
}

const Checkbox = ({ id, handleClick, isChecked } : CheckboxType) => {
  return (
    <input
      className='form-check-input btn-outline-danger'
      id={id}
      type='checkbox'
      onChange={handleClick}
      checked={isChecked}
    />
  );
};

export default Checkbox;
