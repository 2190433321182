import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { API } from 'aws-amplify';
import { Link } from 'react-router-dom';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { ModelSortDirection, WatchedItemType, WatchedItem } from 'api/API';
import { listWatchedItemsByCustomUserIdAndType } from 'api/graphql/queries';
import { List, ListBody, ListHeader } from 'components/shared/list';
import { Button } from 'components/shared';
import { UserComponentBaseProps } from '../model';

interface UserWatchedItemHistoryProps extends UserComponentBaseProps {
  label: string;
  type: WatchedItemType;
}

const DATE_TIME_FORMAT = 'DD MMM YYYY HH:mm:ss';

export const UserWatchedItemHistory = ({
  user,
  label,
  type,
}: UserWatchedItemHistoryProps) => {
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().subtract(1, 'month').toDate(),
    endDate: dayjs().toDate(),
    key: 'selection',
  });
  const [completedItems, setCompletedItems] = useState<WatchedItem[]>([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const { t } = useTranslation();

  const handleSelect = ranges => setDateRange(ranges.selection);

  const fetchData = async () => {
    const response = await API.graphql({
      query: listWatchedItemsByCustomUserIdAndType,
      variables: {
        custom_user_id: user?.Attributes?.['custom:user_id'],
        typeStart_date: {
          between: [
            {
              start_date: `${dateRange.startDate.toISOString()}`,
              type,
            },
            {
              start_date: `${dateRange.endDate.toISOString()}`,
              type,
            },
          ],
        },
        sortDirection: ModelSortDirection.DESC,
      },
    });

    setCompletedItems(response?.data?.listWatchedItemsByCustomUserIdAndType?.items);
  };

  useEffect(
    () => {
      if (user && dateRange) {
        fetchData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(dateRange), user],
  );

  const getPercentageWatched = (item: WatchedItem) => {
    if (!item || !item.closed_at_seconds || !item.length_in_seconds) {
      return '-';
    }
    return `${((item.closed_at_seconds / item.length_in_seconds) * 100).toFixed(0)}%`;
  };

  const getClosingTime = (item: WatchedItem) => {
    if (!item.closed_at_seconds) {
      return '-';
    }
    const date = new Date(item.closed_at_seconds * 1000).toISOString();
    const START_ISO_STRING_INDEX = item.closed_at_seconds > 3600 ? 11 : 14;
    const END_ISO_STRING_INDEX = 19;
    return date.slice(START_ISO_STRING_INDEX, END_ISO_STRING_INDEX);
  };

  return (
    <div>
      {showDatePicker && (
        <DateRangePicker ranges={[dateRange]} onChange={handleSelect} />
      )}

      <List>
        <ListHeader title={label}>
          <Button onClick={() => setShowDatePicker(!showDatePicker)}>
            {t('@T_UserWatchedItemHistory_ToggleDatepicker')}
          </Button>
        </ListHeader>
        <ListBody>
          <table>
            <thead>
              <tr>
                <th>{t('@T_UserWatchedItemHistory_ExcerciseName')}</th>
                <th>{t('@T_UserWatchedItemHistory_ContentfulVersion')}</th>
                <th>{t('@T_UserWatchedItemHistory_TenantId')}</th>
                <th>{t('@T_UserWatchedItemHistory_Started')}</th>
                <th>{t('@T_UserWatchedItemHistory_ClosedAt')}</th>
                <th>{t('@T_UserWatchedItemHistory_Watched')}</th>
                <th>{t('@T_UserWatchedItemHistory_Completed')}</th>
              </tr>
            </thead>

            <tbody>
              {completedItems?.map(item => (
                <tr key={item?.id}>
                  <td>{item?.name}</td>
                  <td>{item?.contentful_version}</td>
                  <td>
                    <Link to={`/tenant-management/overview/${item?.tenant_id}`}>
                      {item?.tenant_id}
                    </Link>
                  </td>
                  <td>{dayjs(item?.start_date).format(DATE_TIME_FORMAT)}</td>
                  <td>{getClosingTime(item)}</td>
                  <td>{getPercentageWatched(item)}</td>
                  <td>
                    {item?.end_date
                      ? dayjs(item?.end_date).format(DATE_TIME_FORMAT)
                      : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ListBody>
      </List>
    </div>
  );
};
